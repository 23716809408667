import { gql } from '../../../generated'

export const GET_ALL_CONTACTS_QUERY = gql(`
  query GetAllPhoneContacts($companyGuid: uuid!) {
    contacts(where: {companyGuid: {_eq: $companyGuid}}) {
      contactGuid
      firstName
      lastName
      fullName
      primaryPhoneNumber {
        phoneNumber
      }
      accountContacts {
        account {
          accountGuid
          accountDisplayName
        }
      }
    }
  }
`)

import { bzExpect } from '@breezy/shared'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'
import { GET_ALL_CONTACTS_QUERY } from './ContactBook.gql'

interface ContactBookContactGqlItem {
  firstName: string
  lastName: string
  fullName: string
  contactGuid: string
  primaryPhoneNumber?: {
    phoneNumber: string
  }
  accountContacts: {
    account: {
      accountGuid: string
      accountDisplayName: string
    }
  }[]
}

export interface ContactBookContact {
  firstName: string
  lastName: string
  fullName: string
  primaryPhoneNumber: string
  accountDisplayName: string
  accountGuid: string
  contactGuid: string
}

class TrieNode {
  children: Map<string, TrieNode>
  isEndOfWord: boolean
  contactIndices: Set<number>

  constructor() {
    this.children = new Map()
    this.isEndOfWord = false
    this.contactIndices = new Set()
  }
}

class ContactTrie {
  root: TrieNode
  contacts: ContactBookContact[]

  constructor() {
    this.root = new TrieNode()
    this.contacts = []
  }

  setContacts(contacts: ContactBookContact[]) {
    this.contacts = contacts
  }

  insert(str: string, contactIndex: number) {
    let node = this.root
    for (const char of str) {
      if (!node.children.has(char)) {
        node.children.set(char, new TrieNode())
      }
      node = node.children.get(char)!
    }
    node.isEndOfWord = true
    node.contactIndices.add(contactIndex)
  }

  search(prefix: string): ContactBookContact[] {
    prefix = prefix.toLowerCase()
    let node = this.root
    for (const char of prefix) {
      if (!node.children.has(char)) {
        return []
      }
      node = node.children.get(char)!
    }
    return this.getAllContactsInSubtrie(node)
  }

  private getAllContactsInSubtrie(node: TrieNode): ContactBookContact[] {
    const contactIndices = new Set<number>()
    this.collectContactIndices(node, contactIndices)
    return Array.from(contactIndices).map(index => this.contacts[index])
  }

  private collectContactIndices(node: TrieNode, indices: Set<number>) {
    if (node.isEndOfWord) {
      for (const index of node.contactIndices) {
        indices.add(index)
      }
    }
    for (const child of node.children.values()) {
      this.collectContactIndices(child, indices)
    }
  }
}

export const useContactBook = () => {
  const companyGuid = useExpectedCompanyGuid()
  const [{ data, fetching, error }] = useQuery({
    query: GET_ALL_CONTACTS_QUERY,
    variables: { companyGuid },
  })

  const contactTrie = useMemo(() => {
    const trie = new ContactTrie()
    if (data?.contacts) {
      const mappedContacts: ContactBookContact[] = data.contacts
        .filter(
          (c: ContactBookContactGqlItem) =>
            !!c.primaryPhoneNumber && c.accountContacts.length > 0,
        )
        .map((c: ContactBookContactGqlItem) => ({
          contactGuid: c.contactGuid,
          firstName: c.firstName,
          lastName: c.lastName,
          fullName: c.fullName,
          primaryPhoneNumber: bzExpect(
            c.primaryPhoneNumber?.phoneNumber,
            'primaryPhoneNumber',
          ),
          accountDisplayName: bzExpect(
            c.accountContacts[0]?.account.accountDisplayName,
            'accountDisplayName',
          ),
          accountGuid: bzExpect(
            c.accountContacts[0]?.account.accountGuid,
            'accountGuid',
          ),
        }))

      // Use a single array to store all contacts
      trie.setContacts(mappedContacts)

      // Insert search keys for each contact
      mappedContacts.forEach((contact, index) => {
        trie.insert(contact.fullName.toLowerCase(), index)
        trie.insert(contact.lastName.toLowerCase(), index)
        const cleanedPhoneNumber = contact.primaryPhoneNumber.replace(/\D/g, '')
        trie.insert(cleanedPhoneNumber, index)
        if (cleanedPhoneNumber.length === 10) {
          trie.insert(cleanedPhoneNumber.slice(3), index) // Insert last 7 digits
        }
      })
    }
    return trie
  }, [data])

  const searchContacts = (searchTerm: string): ContactBookContact[] => {
    if (!searchTerm) return []

    // Remove non-digit characters for phone number search
    const cleanedSearchTerm = searchTerm.replace(/\D/g, '')
    // Check if the cleaned search term contains only digits
    const isNumeric = /^\d+$/.test(cleanedSearchTerm)

    if (isNumeric) {
      // If it's numeric, search by phone number (both 7 and 10 digit)
      return contactTrie.search(cleanedSearchTerm)
    } else {
      // If it's not numeric, search by name (full name or last name)
      return contactTrie.search(searchTerm.toLowerCase())
    }
  }

  return {
    searchContacts,
    fetching,
    error,
  }
}

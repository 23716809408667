import { Guid } from '@breezy/shared'
import { faImage, faList, faUndo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useMemo } from 'react'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import {
  AsyncPhotoUploadWithThumbnail,
  AsyncTakePhotoExternalUpload,
  OnPhotoUploadChange,
  usePhotoUpload,
} from '../../components/Upload/AsyncPhotoUpload'
import {
  AsyncUploadWrapper,
  UploadButton,
  useUpload,
} from '../../components/Upload/Upload'
import { useModalState } from '../../utils/react-utils'
import { OptionCartItem } from './estimatesFlowUtils'
import { LineItemImagePicker } from './LineItemImagePicker'

type EditOptionFeaturedPhotoWithThumbnailProps = {
  thumbnail: {
    width: number
    height: number
  }
  sourcePhotoUrl: string | undefined
  photoUrl?: string | undefined
  onPhotoUploadChange: OnPhotoUploadChange

  onLineItemPhotoSelect: ({
    photoGuid,
    cdnUrl,
  }: {
    photoGuid?: Guid
    cdnUrl?: string
  }) => void
  onRevertToDefaultPhoto?: () => void
  lineItems: OptionCartItem[]
  chooseFeaturedImageOpen: boolean
  setChooseFeaturedImageOpen: (open: boolean) => void
}

export const EditOptionFeaturedPhotoWithThumbnail =
  React.memo<EditOptionFeaturedPhotoWithThumbnailProps>(
    ({
      thumbnail,
      sourcePhotoUrl,
      photoUrl,
      onPhotoUploadChange,
      onLineItemPhotoSelect,
      onRevertToDefaultPhoto,
      lineItems,
      chooseFeaturedImageOpen,
      setChooseFeaturedImageOpen,
    }) => {
      const [lineItemPickerOpen, openLineItemPicker, closeLineItemPicker] =
        useModalState()

      const openChooseImage = useCallback(() => {
        setChooseFeaturedImageOpen(true)
      }, [setChooseFeaturedImageOpen])

      const closeChooseImage = useCallback(() => {
        setChooseFeaturedImageOpen(false)
      }, [setChooseFeaturedImageOpen])

      const handleRevertToDefault = useCallback(() => {
        closeChooseImage()
        if (onRevertToDefaultPhoto) {
          onRevertToDefaultPhoto()
        }
      }, [closeChooseImage, onRevertToDefaultPhoto])

      const handlePhotoUploadChange: OnPhotoUploadChange = useCallback(
        (data, record) => {
          onPhotoUploadChange(data, record)
          closeChooseImage()
        },
        [onPhotoUploadChange, closeChooseImage],
      )
      const { loading, ...useUploadProps } = usePhotoUpload(
        handlePhotoUploadChange,
      )
      const uploadPhoto = useUpload(useUploadProps)

      const handleLineItemSelect = useCallback(
        (selectedItemGuid: Guid) => {
          const selectedItem = lineItems.find(
            item => item.cartItemGuid === selectedItemGuid,
          )

          if (selectedItem) {
            onLineItemPhotoSelect({
              photoGuid: selectedItem.photoGuid,
              cdnUrl: selectedItem.photoCdnUrl,
            })
          } else {
            onLineItemPhotoSelect({
              photoGuid: undefined,
              cdnUrl: undefined,
            })
          }
          closeLineItemPicker()
          closeChooseImage()
        },
        [
          lineItems,
          onLineItemPhotoSelect,
          closeLineItemPicker,
          closeChooseImage,
        ],
      )

      const close = useCallback(() => {
        closeChooseImage()
        closeLineItemPicker()
      }, [closeChooseImage, closeLineItemPicker])

      const imageActionsContent = useMemo(() => {
        // Select Line Item Open
        if (lineItemPickerOpen) {
          return (
            <LineItemImagePicker
              lineItems={lineItems}
              onSave={handleLineItemSelect}
              onCancel={closeLineItemPicker}
            />
          )
        }

        // Replace Image Open

        return (
          <OnsiteModalContent
            header="Choose featured image"
            headerBordered
            onClose={close}
          >
            <div className="flex flex-col gap-3">
              <UploadButton
                onClick={openLineItemPicker}
                icon={<FontAwesomeIcon icon={faList} />}
              >
                Link to line item
              </UploadButton>
              <AsyncUploadWrapper accept="image/*" {...useUploadProps}>
                <UploadButton
                  disabled={loading}
                  icon={<FontAwesomeIcon icon={faImage} />}
                  loading={loading}
                >
                  Choose from library
                </UploadButton>
              </AsyncUploadWrapper>
              {onRevertToDefaultPhoto && (
                <UploadButton
                  icon={<FontAwesomeIcon icon={faUndo} />}
                  onClick={handleRevertToDefault}
                >
                  Revert to Default
                </UploadButton>
              )}
              <AsyncTakePhotoExternalUpload
                uploadPhoto={uploadPhoto}
                loading={loading}
              />
            </div>
          </OnsiteModalContent>
        )
      }, [
        lineItemPickerOpen,
        close,
        openLineItemPicker,
        useUploadProps,
        loading,
        onRevertToDefaultPhoto,
        handleRevertToDefault,
        uploadPhoto,
        lineItems,
        handleLineItemSelect,
        closeLineItemPicker,
      ])

      return (
        <>
          <AsyncPhotoUploadWithThumbnail
            thumbnail={thumbnail}
            sourcePhotoUrl={sourcePhotoUrl}
            photoUrl={photoUrl}
            onPhotoUploadChange={onPhotoUploadChange}
            onOpenActions={openChooseImage}
            loading={loading}
          />

          {chooseFeaturedImageOpen && (
            <OnsiteModal onClose={close}>{imageActionsContent}</OnsiteModal>
          )}
        </>
      )
    },
  )

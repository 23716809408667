import { BulkPricebookCategory, BulkPricebookItemRow } from '@breezy/shared'
import { faTags } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useMemo, useState } from 'react'
import { BzSteps } from '../../../elements/BzStepper/BzStepper'
import Switch from '../../../elements/Switch/Switch'
import { useQuickbooksOnlineEnabled } from '../../../providers/CompanyFinancialConfigWrapper'
import { useFlatfilePricebookImport } from '../../Flatfile/useFlatfilePricebookImport'
import FullScreenModal from '../../FullScreenModal/FullScreenModal'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'
import PricebookImportReview from '../PricebookImportReview'
import { usePricebookAdmin } from './usePricebookAdmin'

export const useBulkImportWizard = () => {
  const [open, setOpen] = useState(false)
  const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()
  const {
    bulkPricebookImportClear,
    bulkPricebookImportSetPendingCategories,
    bulkPricebookImportSetPendingItems,
    categories,
    qboIncomeAccounts,
  } = usePricebookAdmin()

  const [currentStep, setCurrentStep] = React.useState(0)
  const moveNext = useCallback(() => {
    setCurrentStep(curr => curr + 1)
  }, [])

  const moveBack = useCallback(() => {
    setCurrentStep(curr => curr - 1)
  }, [])

  const close = useCallback(
    (_: boolean) => {
      setOpen(false)
      setCurrentStep(0)
      bulkPricebookImportClear()
    },
    [bulkPricebookImportClear],
  )

  const stageImportRows = useCallback(
    (
      pricebookCategoryCells: BulkPricebookCategory[],
      pricebookItemCells: BulkPricebookItemRow[],
    ) => {
      bulkPricebookImportSetPendingCategories(pricebookCategoryCells)
      bulkPricebookImportSetPendingItems(pricebookItemCells)
    },
    [
      bulkPricebookImportSetPendingCategories,
      bulkPricebookImportSetPendingItems,
    ],
  )

  const { FlatfileImporter, show, initialize } = useFlatfilePricebookImport({
    stageImportRows,
    onContinue: moveNext,
    categories,
    isQuickbooksOnlineEnabled,
    qboIncomeAccounts,
  })

  const title = useMemo(() => {
    switch (currentStep) {
      case 0:
        return 'Import Pricebook'
      case 1:
        return 'Review Updates'
      default:
        return 'Complete'
    }
  }, [currentStep])

  const wizard = useMemo(
    () => (
      <FullScreenModal isOpen={open} setIsOpen={close}>
        <div className="mb-12 flex items-center gap-3 text-[24px] font-semibold">
          <div className="flex items-center gap-3 text-[24px] font-semibold">
            {title} <FontAwesomeIcon icon={faTags} />
          </div>
          <div className="flex w-full flex-1 justify-center">
            <BzSteps
              className="min-w-0 max-w-[500px] flex-1 justify-self-center"
              steps={['Import', 'Review']}
              currentStep={currentStep}
            />
          </div>
        </div>
        <div className="relative flex flex-col">
          <Switch value={currentStep}>
            {{
              0: () => null,
              1: () => (
                <PricebookImportReview
                  onBack={() => {
                    show()
                    moveBack()
                  }}
                  onClose={() => close(true)}
                />
              ),
              default: () => <LoadingSpinner />,
            }}
          </Switch>
          {FlatfileImporter}
        </div>
      </FullScreenModal>
    ),
    [open, close, title, currentStep, FlatfileImporter, show, moveBack],
  )

  const toggleWizard = useCallback(() => {
    setOpen(true)
    initialize()
  }, [initialize])

  return {
    wizard,
    toggleWizard,
  }
}

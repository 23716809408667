import { gql } from '../../generated'

export const CANCEL_APPOINTMENT_MUTATION = gql(/* GraphQL */ `
  mutation CancelAppointment(
    $jobAppointmentGuid: uuid!
    $cancellationStatus: JobAppointmentCancellationStatusesInsertInput!
  ) {
    deleteJobAppointmentAssignments(
      where: { jobAppointmentGuid: { _eq: $jobAppointmentGuid } }
    ) {
      __typename
    }
    insertJobAppointmentCancellationStatusesOne(
      object: $cancellationStatus
      onConflict: {
        constraint: job_appointment_cancellation_statuses_job_appointment_guid_key
        updateColumns: [canceled, note, reason]
      }
    ) {
      __typename
    }
  }
`)

export const DELETE_ASSIGNMENT_MUTATION = gql(/* GraphQL */ `
  mutation DeleteAssignment($jobAppointmentAssignmentGuid: uuid!) {
    deleteJobAppointmentAssignmentsByPk(
      jobAppointmentAssignmentGuid: $jobAppointmentAssignmentGuid
    ) {
      __typename
    }
    deleteJobAppointmentAssignmentStatuses(
      where: {
        jobAppointmentAssignmentGuid: { _eq: $jobAppointmentAssignmentGuid }
      }
    ) {
      affectedRows
    }
  }
`)

import { CompanyGuid } from '@breezy/shared'
import React from 'react'

import { useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { DocumentType, gql } from '../../generated'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'

export type CompanyLeadSources = {
  companyMarketWebsiteLeadSourceGuid: string
}

type WithCompanyLeadSourcesProps = {
  loadingComponent?: JSX.Element
  render: (leadSources: CompanyLeadSources) => JSX.Element
}

const COMPANY_LEAD_SOURCES_QUERY = gql(/* GraphQL */ `
  query FetchCompanyLeadSources($where: CompanyLeadSourcesBoolExp!) {
    companyLeadSources(where: $where) {
      companyLeadSourceGuid
    }
  }
`)

type CompanyLeadSourcesQuery = DocumentType<typeof COMPANY_LEAD_SOURCES_QUERY>

const convertQueryToCompanyLeadSources = (
  data: CompanyLeadSourcesQuery,
): CompanyLeadSources => {
  return {
    companyMarketWebsiteLeadSourceGuid:
      data?.companyLeadSources[0]?.companyLeadSourceGuid,
  }
}

const useFetchCompanyLeadSources = (companyGuid: CompanyGuid) => {
  const companyLeadSourcesQuery = useQuery({
    query: COMPANY_LEAD_SOURCES_QUERY,
    variables: {
      where: {
        companyGuid: { _eq: companyGuid },
        canonicalLeadSourceName: { _eq: 'Marketing Website' },
      },
    },
  })

  return companyLeadSourcesQuery
}

export const WithCompanyLeadSources = React.memo<WithCompanyLeadSourcesProps>(
  ({ render, loadingComponent }) => {
    const companyGuid = useExpectedCompanyGuid()
    const companyLeadSourcesQuery = useFetchCompanyLeadSources(companyGuid)

    return (
      <GqlQueryLoader
        query={companyLeadSourcesQuery}
        loadingComponent={loadingComponent}
        idleComponent={loadingComponent}
        render={data => {
          return render(convertQueryToCompanyLeadSources(data))
        }}
      />
    )
  },
)

// Necessary for fake raw dummy data
/* eslint-disable breezy/no-to-iso-date-string */
/**
 * Our various widgets show an overlay when they have no data. Behind the overlay is a very blurry version of the chart
 * if data existed. The purpose of these variables is to provide dummy data sufficient to display the table.
 */
import {
  AvgInvoiceByJobTypeReportData,
  BucketedEarnedRevenueDatum,
  BzDateFns,
  EquipmentType,
  IsoDateString,
  JobClass,
  JobsByClassReportIndividualData,
  JobsByLeadSourceDatum,
  MaintenancePlanPeriodicSummaryReport,
  RoleId,
} from '@breezy/shared'
import { UpcomingAppointmentWidgetAppointment } from './UpcomingAppointmentsWidget'

// TODO
export const DUMMY_BACKGROUND_UPCOMING_APPOINTMENTS_DATA: UpcomingAppointmentWidgetAppointment[] =
  [
    {
      appointmentGuid: '8560b3ac-a575-42f8-8546-4c7e3105daf6',
      appointmentReferenceNumber: 'FRD-WT7K',
      appointmentWindowEnd: BzDateFns.toIsoDateString(
        '2023-11-27T19:00:00+00:00',
      ),
      appointmentWindowStart: BzDateFns.toIsoDateString(
        '2023-11-27T16:00:00+00:00',
      ),
      appointmentType: 'Site Visit',
      appointmentCanceled: false,
      appointmentStatus: 'ASSIGNED',
      assignments: [
        {
          assignmentStart: BzDateFns.toIsoDateString(
            '2023-11-27T18:30:00+00:00',
          ),
          assignmentEnd: BzDateFns.toIsoDateString('2023-11-27T19:30:00+00:00'),
          assignmentGuid: '8d9f33c6-d826-46b9-8522-73a2bf09f54a',
          assignmentStatus: {
            jobAppointmentAssignmentStatusType: 'TO_DO',
          },
          technician: {
            firstName: 'Marcella',
            lastName: 'Stokes',
            userGuid: '6879d14d-4219-4726-9ab3-0655c1c41a87',
            roles: [
              {
                role: RoleId.MAINTENANCE_TECHNICIAN,
              },
            ],
            phoneNumbers: [
              {
                phoneNumber: {
                  companyGuid: 'c3717661-075d-4dc3-9321-69dbbbdcd6e9',
                  phoneNumber: '(581) 633-5037',
                  phoneNumberGuid: '1cbecfec-c79b-4049-a573-40b09e79b98f',
                  type: 'MOBILE',
                },
              },
            ],
            emailAddress: 'test.user+technician.maintenance.2@ryanhvac.com',
          },
        },
        {
          assignmentStart: BzDateFns.toIsoDateString(
            '2023-11-27T16:00:00+00:00',
          ),
          assignmentEnd: BzDateFns.toIsoDateString('2023-11-27T17:30:00+00:00'),
          assignmentGuid: 'f95f556e-f1c7-49ec-95ae-169b63829eba',
          assignmentStatus: {
            jobAppointmentAssignmentStatusType: 'TO_DO',
          },
          technician: {
            firstName: 'Adan',
            lastName: 'Blanda',
            userGuid: '5037bf0c-7dd0-486d-a52d-3cedc1c15696',
            roles: [
              {
                role: RoleId.SERVICE_TECHNICIAN,
              },
            ],
            phoneNumbers: [
              {
                phoneNumber: {
                  companyGuid: 'c3717661-075d-4dc3-9321-69dbbbdcd6e9',
                  phoneNumber: '(855) 547-4118',
                  phoneNumberGuid: 'b9236e71-ade8-4318-9a26-d51957b9ffa0',
                  type: 'MOBILE',
                },
              },
            ],
            emailAddress: 'test.user+technician.service.1@ryanhvac.com',
          },
        },
      ],
      description: '',
      job: {
        equipmentTypeJobLinks: [
          {
            estimatedEquipmentAge: 'Unknown age',
            equipmentType: EquipmentType.ROOFTOP_UNIT,
            relationshipType: 'LINKED',
            equipmentTypeName: {
              name: 'ROOFTOP_UNIT',
            },
          },
        ],
        jobGuid: 'af2b38bf-c688-41c6-93f9-c93e75196a37',
        jobType: {
          name: 'A/C Tune-up',
          jobTypeGuid: 'fb4a4bf3-4c68-4fe2-a4a1-ea044c6a213b',
          jobClass: JobClass.MAINTENANCE,
          jobLifecycleGuid: '37038aab-28d0-4b48-a9c3-776b95f6a0ee',
        },
        accountGuid: '5b6a20f7-9518-4118-8295-e3118f5e7a83',
        account: {
          accountDisplayName: 'Kuphal, Titus • South Reecemouth • Commercial',
        },
        displayId: 866,
        location: {
          address: {
            addressGuid: '32fd35f2-9db8-4e20-8a84-3beb7f593c5d',
            canonicalFullAddress:
              '846 Mosciski Junctions||South Reecemouth|IN|46970',
            city: 'South Reecemouth',
            line1: '846 Mosciski Junctions',
            stateAbbreviation: 'IN',
            zipCode: '46970',
            geoLocation: {
              type: 'Point',
              coordinates: [0, 0],
            },
          },
          locationGuid: 'f1155a69-7000-4254-87cb-c9a97081e4ea',
          propertyType: 'unknown',
        },
        pointOfContact: {
          firstName: 'Titus',
          lastName: 'Kuphal',
          primaryPhoneNumber: {
            phoneNumber: '(477) 966-2347',
            type: 'MOBILE',
          },
          primaryEmailAddress: {
            emailAddress: 'Titus69@yahoo.com',
          },
        },
      },
      cancellationStatus: {
        canceled: false,
      },
      confirmationStatus: {
        confirmed: false,
      },
      technicians: [
        {
          firstName: 'Marcella',
          lastName: 'Stokes',
          userGuid: '6879d14d-4219-4726-9ab3-0655c1c41a87',
          roles: [
            {
              role: RoleId.MAINTENANCE_TECHNICIAN,
              name: 'Maintenance Tech',
            },
          ],
          phoneNumbers: [
            {
              companyGuid: 'c3717661-075d-4dc3-9321-69dbbbdcd6e9',
              phoneNumber: '(581) 633-5037',
              phoneNumberGuid: '1cbecfec-c79b-4049-a573-40b09e79b98f',
              type: 'MOBILE',
            },
          ],
          emailAddress: 'test.user+technician.maintenance.2@ryanhvac.com',
          avatarShortString: 'MS',
          avatarColor: '#BAE637',
          formattedScheduledDay: 'Today',
          formattedScheduledDateRange: '8:00–11:00 AM',
        },
        {
          firstName: 'Adan',
          lastName: 'Blanda',
          userGuid: '5037bf0c-7dd0-486d-a52d-3cedc1c15696',
          roles: [
            {
              role: RoleId.SERVICE_TECHNICIAN,
              name: 'Service Tech',
            },
          ],
          phoneNumbers: [
            {
              companyGuid: 'c3717661-075d-4dc3-9321-69dbbbdcd6e9',
              phoneNumber: '(855) 547-4118',
              phoneNumberGuid: 'b9236e71-ade8-4318-9a26-d51957b9ffa0',
              type: 'MOBILE',
            },
          ],
          emailAddress: 'test.user+technician.service.1@ryanhvac.com',
          avatarShortString: 'AB',
          avatarColor: '#EB2F96',
          formattedScheduledDay: 'Today',
          formattedScheduledDateRange: '8:00–11:00 AM',
        },
      ],
      formattedDay: 'Today',
      formattedDateRange: '8:00–11:00 AM',
      sendConfirmationEnabled: true,
      notificationType: 'SMS',
      sendConfirmationTo: '(477) 966-2347',
      confirmationLastSentAt: BzDateFns.toIsoDateString(
        '2023-11-26T16:00:00+00:00',
      ),
      sendReminderEnabled: true,
      reminderLastSentAt: BzDateFns.toIsoDateString(
        '2023-11-26T12:00:00+00:00',
      ),
    },
    {
      appointmentGuid: '5a605077-2cc1-453d-a626-8242b1f87212',
      appointmentReferenceNumber: 'VTT-AX6A',
      appointmentWindowEnd: BzDateFns.toIsoDateString(
        '2023-11-27T19:00:00+00:00',
      ),
      appointmentWindowStart: BzDateFns.toIsoDateString(
        '2023-11-27T16:00:00+00:00',
      ),
      appointmentType: 'Callback',
      appointmentCanceled: false,
      appointmentStatus: 'ASSIGNED',
      assignments: [
        {
          assignmentStart: BzDateFns.toIsoDateString(
            '2023-11-27T16:00:00+00:00',
          ),
          assignmentEnd: BzDateFns.toIsoDateString('2023-11-27T17:30:00+00:00'),
          assignmentGuid: 'b0435bb5-f26e-4fef-b0f2-cd83e1fb257c',
          assignmentStatus: {
            jobAppointmentAssignmentStatusType: 'TO_DO',
          },
          technician: {
            firstName: 'Watson',
            lastName: 'Crona',
            userGuid: 'e1da1b1d-6bf1-479f-8edf-922d9040d624',
            roles: [
              {
                role: RoleId.SALES_TECHNICIAN,
              },
            ],
            phoneNumbers: [
              {
                phoneNumber: {
                  companyGuid: 'c3717661-075d-4dc3-9321-69dbbbdcd6e9',
                  phoneNumber: '(802) 487-1164',
                  phoneNumberGuid: '966dbcae-935e-4972-966d-3cf2b933f07d',
                  type: 'MOBILE',
                },
              },
            ],
            emailAddress: 'test.user+technician.sales.1@ryanhvac.com',
          },
        },
      ],
      job: {
        equipmentTypeJobLinks: [
          {
            estimatedEquipmentAge: 'Unknown age',
            equipmentType: EquipmentType.ZONING_SYSTEM,
            relationshipType: 'LINKED',
            equipmentTypeName: {
              name: 'ZONING_SYSTEM',
            },
          },
        ],
        jobGuid: 'd0856683-b9fb-4669-a249-794e3bce962c',
        jobType: {
          name: 'Miscellaneous Service',
          jobTypeGuid: '9ae9baaa-03e2-4a16-8df3-dc82bc9fc8f0',
          jobClass: JobClass.SERVICE,
          jobLifecycleGuid: '0be46fd8-8d52-4944-a03b-818bbba7a15e',
        },
        accountGuid: '33dcb497-7cc2-44b5-8b83-eafa50aaf0ef',
        account: {
          accountDisplayName:
            'Greenfelder, Stefanie • Lednershire • Residential',
        },
        displayId: 78,
        location: {
          address: {
            addressGuid: '2a95d82c-2e03-4253-add7-801d791bf521',
            canonicalFullAddress: '32033 Ibrahim Camp||Lednershire|NM|87573',
            city: 'Lednershire',
            line1: '32033 Ibrahim Camp',
            stateAbbreviation: 'NM',
            zipCode: '87573',
            geoLocation: {
              type: 'Point',
              coordinates: [0, 0],
            },
          },
          locationGuid: 'a01f647a-7146-4898-9891-23fe0999fc44',
          propertyType: 'unknown',
        },
        pointOfContact: {
          firstName: 'Stefanie',
          lastName: 'Greenfelder',
          primaryPhoneNumber: {
            phoneNumber: '(379) 964-3260',
            type: 'MOBILE',
          },
          primaryEmailAddress: {
            emailAddress: 'Stefanie.Greenfelder@hotmail.com',
          },
        },
      },
      cancellationStatus: {
        canceled: false,
      },
      confirmationStatus: {
        confirmed: false,
      },
      technicians: [
        {
          firstName: 'Watson',
          lastName: 'Crona',
          userGuid: 'e1da1b1d-6bf1-479f-8edf-922d9040d624',
          roles: [
            {
              role: RoleId.SALES_TECHNICIAN,
              name: 'Sales Tech',
            },
          ],
          phoneNumbers: [
            {
              companyGuid: 'c3717661-075d-4dc3-9321-69dbbbdcd6e9',
              phoneNumber: '(802) 487-1164',
              phoneNumberGuid: '966dbcae-935e-4972-966d-3cf2b933f07d',
              type: 'MOBILE',
            },
          ],
          emailAddress: 'test.user+technician.sales.1@ryanhvac.com',
          avatarShortString: 'WC',
          avatarColor: '#FFBB96',
          formattedScheduledDay: 'Today',
          formattedScheduledDateRange: '8:00–11:00 AM',
        },
      ],
      formattedDay: 'Today',
      formattedDateRange: '8:00–11:00 AM',
      sendConfirmationEnabled: true,
      notificationType: 'EMAIL',
      sendConfirmationTo: 'Stefanie.Greenfelder@hotmail.com',
      confirmationLastSentAt: BzDateFns.toIsoDateString(
        '2023-11-26T15:30:00+00:00',
      ),
      sendReminderEnabled: false,
      reminderLastSentAt: undefined,
    },
    {
      appointmentGuid: 'db42ea2c-65c5-4c6c-8dd4-59cf9c6ccbb7',
      appointmentReferenceNumber: 'WF7-FUDD',
      appointmentWindowEnd: BzDateFns.toIsoDateString(
        '2023-11-27T19:00:00+00:00',
      ),
      appointmentWindowStart: BzDateFns.toIsoDateString(
        '2023-11-27T16:00:00+00:00',
      ),
      appointmentType: 'Site Visit',
      appointmentCanceled: false,
      appointmentStatus: 'ASSIGNED',
      assignments: [
        {
          assignmentStart: BzDateFns.toIsoDateString(
            '2023-11-27T16:00:00+00:00',
          ),
          assignmentEnd: BzDateFns.toIsoDateString('2023-11-28T00:30:00+00:00'),
          assignmentGuid: '71daa771-4886-4319-8d4b-6a7d4c74befb',
          assignmentStatus: {
            jobAppointmentAssignmentStatusType: 'TO_DO',
          },
          technician: {
            firstName: 'Adan',
            lastName: 'Blanda',
            userGuid: '5037bf0c-7dd0-486d-a52d-3cedc1c15696',
            roles: [
              {
                role: RoleId.SERVICE_TECHNICIAN,
              },
            ],
            phoneNumbers: [
              {
                phoneNumber: {
                  companyGuid: 'c3717661-075d-4dc3-9321-69dbbbdcd6e9',
                  phoneNumber: '(855) 547-4118',
                  phoneNumberGuid: 'b9236e71-ade8-4318-9a26-d51957b9ffa0',
                  type: 'MOBILE',
                },
              },
            ],
            emailAddress: 'test.user+technician.service.1@ryanhvac.com',
          },
        },
      ],
      description: '',
      job: {
        equipmentTypeJobLinks: [
          {
            estimatedEquipmentAge: '3 - 6 y/o',
            equipmentType: EquipmentType.BRANCH_BOX,
            relationshipType: 'INSTALLING',
            equipmentTypeName: {
              name: 'BRANCH_BOX',
            },
          },
        ],
        jobGuid: 'dbe41a67-b97e-4964-a78a-0a0e82dd12b8',
        jobType: {
          name: 'A/C Install',
          jobTypeGuid: 'bf01bc4e-7f68-4e15-b995-a5a644556e26',
          jobClass: JobClass.INSTALL,
          jobLifecycleGuid: 'aa7cf3ca-6232-4c03-b914-5b5401a29681',
        },
        accountGuid: '1790782c-c000-4c80-981c-4de281cdbc1e',
        account: {
          accountDisplayName: 'Mohr, Saul • Aftonworth • Residential',
        },
        displayId: 585,
        location: {
          address: {
            addressGuid: '80c3c5ab-68f0-4bb8-b90d-7979f271bbd2',
            canonicalFullAddress:
              '7131 N Jefferson Street||Aftonworth|FL|34620',
            city: 'Aftonworth',
            line1: '7131 N Jefferson Street',

            stateAbbreviation: 'FL',
            zipCode: '34620',
            geoLocation: {
              type: 'Point',
              coordinates: [0, 0],
            },
          },
          locationGuid: '3f6c027f-b79a-482b-be8f-a8e2f97c44a5',
          propertyType: 'unknown',
        },
        pointOfContact: {
          firstName: 'Saul',
          lastName: 'Mohr',
          primaryPhoneNumber: {
            phoneNumber: '(276) 969-5135',
            type: 'MOBILE',
          },
          primaryEmailAddress: {
            emailAddress: 'Saul46@hotmail.com',
          },
        },
      },
      cancellationStatus: {
        canceled: false,
      },
      confirmationStatus: {
        confirmed: false,
      },
      technicians: [
        {
          firstName: 'Adan',
          lastName: 'Blanda',
          userGuid: '5037bf0c-7dd0-486d-a52d-3cedc1c15696',
          roles: [
            {
              role: RoleId.SERVICE_TECHNICIAN,
              name: 'Service Tech',
            },
          ],
          phoneNumbers: [
            {
              companyGuid: 'c3717661-075d-4dc3-9321-69dbbbdcd6e9',
              phoneNumber: '(855) 547-4118',
              phoneNumberGuid: 'b9236e71-ade8-4318-9a26-d51957b9ffa0',
              type: 'MOBILE',
            },
          ],
          emailAddress: 'test.user+technician.service.1@ryanhvac.com',
          avatarShortString: 'AB',
          avatarColor: '#EB2F96',
          formattedScheduledDay: 'Today',
          formattedScheduledDateRange: '8:00–11:00 AM',
        },
      ],
      formattedDay: 'Today',
      formattedDateRange: '8:00–11:00 AM',
      sendConfirmationEnabled: false,
      notificationType: 'SMS',
      sendConfirmationTo: '(276) 969-5135',
      confirmationLastSentAt: undefined,
      sendReminderEnabled: true,
      reminderLastSentAt: BzDateFns.toIsoDateString(
        '2023-11-26T14:00:00+00:00',
      ),
    },
    {
      appointmentGuid: 'd9ce31bf-8be8-42bb-b11d-571354912aa0',
      appointmentReferenceNumber: 'WQY-X69Y',
      appointmentWindowEnd: BzDateFns.toIsoDateString(
        '2023-11-27T19:00:00+00:00',
      ),
      appointmentWindowStart: BzDateFns.toIsoDateString(
        '2023-11-27T16:00:00+00:00',
      ),
      appointmentType: 'Pre-Install Job Walk',
      assignments: [
        {
          assignmentStart: BzDateFns.toIsoDateString(
            '2023-11-27T16:00:00+00:00',
          ),
          assignmentEnd: BzDateFns.toIsoDateString('2023-11-28T00:30:00+00:00'),
          assignmentGuid: '71daa771-4886-4319-8d4b-6a7d4c74befb',
          assignmentStatus: {
            jobAppointmentAssignmentStatusType: 'TO_DO',
          },
          technician: {
            firstName: 'Adan',
            lastName: 'Blanda',
            userGuid: '5037bf0c-7dd0-486d-a52d-3cedc1c15696',
            roles: [
              {
                role: RoleId.SERVICE_TECHNICIAN,
              },
            ],
            phoneNumbers: [
              {
                phoneNumber: {
                  companyGuid: 'c3717661-075d-4dc3-9321-69dbbbdcd6e9',
                  phoneNumber: '(855) 547-4118',
                  phoneNumberGuid: 'b9236e71-ade8-4318-9a26-d51957b9ffa0',
                  type: 'MOBILE',
                },
              },
            ],
            emailAddress: 'test.user+technician.service.1@ryanhvac.com',
          },
        },
      ],
      description: '',
      appointmentCanceled: false,
      appointmentStatus: 'ASSIGNED',
      job: {
        equipmentTypeJobLinks: [
          {
            estimatedEquipmentAge: '15+ y/o',
            equipmentType: EquipmentType.OIL_FURNACE,
            relationshipType: 'LINKED',
            equipmentTypeName: {
              name: 'OIL_FURNACE',
            },
          },
        ],
        jobGuid: 'c8f85681-40d7-4163-a402-49daf8106932',
        jobType: {
          name: 'No Cold Air',
          jobTypeGuid: '635f6cac-2d6a-49c0-b3fd-1bbd69580cf6',
          jobClass: JobClass.SERVICE,
          jobLifecycleGuid: '0be46fd8-8d52-4944-a03b-818bbba7a15e',
        },
        accountGuid: '56b3494d-89de-4de9-a0a7-79255ffabde1',
        account: {
          accountDisplayName: 'Thompson, Curtis • Sherwoodland • Commercial',
        },
        displayId: 661,
        location: {
          address: {
            addressGuid: '356fc1e3-3314-4131-89d5-6c3e672ca5b8',
            canonicalFullAddress: '1899 Auer Wall||Sherwoodland|NH|03342',
            city: 'Sherwoodland',
            line1: '1899 Auer Wall',
            stateAbbreviation: 'NH',
            zipCode: '03342',
            geoLocation: {
              type: 'Point',
              coordinates: [0, 0],
            },
          },
          locationGuid: 'da428ab7-3014-42e5-a465-3be2eea50634',
          propertyType: 'unknown',
        },
        pointOfContact: {
          firstName: 'Curtis',
          lastName: 'Thompson',
          primaryPhoneNumber: {
            phoneNumber: '(706) 956-0580',
            type: 'MOBILE',
          },
          primaryEmailAddress: {
            emailAddress: 'Curtis_Thompson18@hotmail.com',
          },
        },
      },
      cancellationStatus: {
        canceled: false,
      },
      confirmationStatus: {
        confirmed: false,
      },
      technicians: [
        {
          firstName: 'Adan',
          lastName: 'Blanda',
          userGuid: '5037bf0c-7dd0-486d-a52d-3cedc1c15696',
          roles: [
            {
              role: RoleId.SERVICE_TECHNICIAN,
              name: 'Service Tech',
            },
          ],
          phoneNumbers: [
            {
              companyGuid: 'c3717661-075d-4dc3-9321-69dbbbdcd6e9',
              phoneNumber: '(855) 547-4118',
              phoneNumberGuid: 'b9236e71-ade8-4318-9a26-d51957b9ffa0',
              type: 'MOBILE',
            },
          ],
          emailAddress: 'test.user+technician.service.1@ryanhvac.com',
          avatarShortString: 'AB',
          avatarColor: '#EB2F96',
          formattedScheduledDay: 'Today',
          formattedScheduledDateRange: '8:00–11:00 AM',
        },
      ],
      formattedDay: 'Today',
      formattedDateRange: '8:00–11:00 AM',
      sendConfirmationEnabled: true,
      notificationType: 'SMS',
      sendConfirmationTo: '(706) 956-0580',
      confirmationLastSentAt: BzDateFns.toIsoDateString(
        '2023-11-26T17:00:00+00:00',
      ),
      sendReminderEnabled: true,
      reminderLastSentAt: BzDateFns.toIsoDateString(
        '2023-11-26T13:00:00+00:00',
      ),
    },
  ]

export const DUMMY_BACKGROUND_JOBS_BY_CLASS_DATA: JobsByClassReportIndividualData =
  {
    SERVICE: 24,
    MAINTENANCE: 24,
    INSTALL: 9,
    SALES: 8,
  }

export const DUMMY_BACKGROUND_AVG_INVOICE_BY_JOB_TYPE_DATA: AvgInvoiceByJobTypeReportData =
  {
    'A/C & Furnace Install': {
      avgInvoice: 5250,
      totalJobs: 1,
    },
    'A/C Install': {
      avgInvoice: 5250,
      totalJobs: 1,
    },
    'A/C Leaking Water': {
      avgInvoice: 362.5,
      totalJobs: 2,
    },
    'A/C Tune-up': {
      avgInvoice: 184,
      totalJobs: 7,
    },
    'Duct Cleaning': {
      avgInvoice: 126,
      totalJobs: 3,
    },
    'Equipment Repair': {
      avgInvoice: 291.6666666666667,
      totalJobs: 3,
    },
    'Filter Change': {
      avgInvoice: 72,
      totalJobs: 14,
    },
    'Furnace Install': {
      avgInvoice: 6283.333333333333,
      totalJobs: 3,
    },
    'Furnace Tune-up': {
      avgInvoice: 78.58333333333333,
      totalJobs: 12,
    },
    'IAQ Install': {
      avgInvoice: 8612.5,
      totalJobs: 2,
    },
    'Miscellaneous Install': {
      avgInvoice: 7150,
      totalJobs: 3,
    },
    'Miscellaneous Maintenance': {
      avgInvoice: 72,
      totalJobs: 6,
    },
    'Miscellaneous Service': {
      avgInvoice: 295.8333333333333,
      totalJobs: 6,
    },
    'No Cold Air': {
      avgInvoice: 256.25,
      totalJobs: 4,
    },
    'No Hot Air': {
      avgInvoice: 306.25,
      totalJobs: 4,
    },
    'Thermostat Issue': {
      avgInvoice: 291.6666666666667,
      totalJobs: 9,
    },
  }
export const DUMMY_BACKGROUND_BUCKETED_EARNED_REVENUE_DATA: BucketedEarnedRevenueDatum[] =
  [
    {
      date: BzDateFns.toIsoDateString('2023-10-01T21:44:50Z'),
      value: 8192.853757643219,
    },
    {
      date: BzDateFns.toIsoDateString('2023-09-30T21:44:50Z'),
      value: 4268.283374882114,
    },
    {
      date: BzDateFns.toIsoDateString('2023-09-29T21:44:50Z'),
      value: 6779.073558049671,
    },
    {
      date: BzDateFns.toIsoDateString('2023-09-28T21:44:50Z'),
      value: 8757.23258069791,
    },
    {
      date: BzDateFns.toIsoDateString('2023-09-27T21:44:50Z'),
      value: 6550.741093651122,
    },
    {
      date: BzDateFns.toIsoDateString('2023-09-26T21:44:50Z'),
      value: 9805.8494767286,
    },
    {
      date: BzDateFns.toIsoDateString('2023-09-25T21:44:50Z'),
      value: 9147.236005484247,
    },
  ]

const UNCASTED_DUMMY_MAINTENANCE_PLAN_DATA: Record<
  string,
  MaintenancePlanPeriodicSummaryReport[IsoDateString]
> = {
  '2023-08-21T07:00:00Z': {
    periodStart: BzDateFns.toIsoDateString('2023-08-21T07:00:00Z'),
    periodEnd: BzDateFns.toIsoDateString('2023-08-28T06:59:59.999Z'),
    numActivePlans: 80,
    totalRecurringMonthlyRevenue: 8100,
    totalUnusedCredits: 70,
  },
  '2023-08-28T07:00:00Z': {
    periodStart: BzDateFns.toIsoDateString('2023-08-28T07:00:00Z'),
    periodEnd: BzDateFns.toIsoDateString('2023-09-04T06:59:59.999Z'),
    numActivePlans: 82,
    totalRecurringMonthlyRevenue: 8250,
    totalUnusedCredits: 80,
  },
  '2023-09-04T07:00:00Z': {
    periodStart: BzDateFns.toIsoDateString('2023-09-04T07:00:00Z'),
    periodEnd: BzDateFns.toIsoDateString('2023-09-11T06:59:59.999Z'),
    numActivePlans: 85,
    totalRecurringMonthlyRevenue: 8510,
    totalUnusedCredits: 75,
  },
  '2023-09-11T07:00:00Z': {
    periodStart: BzDateFns.toIsoDateString('2023-09-11T07:00:00Z'),
    periodEnd: BzDateFns.toIsoDateString('2023-09-18T06:59:59.999Z'),
    numActivePlans: 89,
    totalRecurringMonthlyRevenue: 9000,
    totalUnusedCredits: 95,
  },
  '2023-09-18T07:00:00Z': {
    periodStart: BzDateFns.toIsoDateString('2023-09-18T07:00:00Z'),
    periodEnd: BzDateFns.toIsoDateString('2023-09-25T06:59:59.999Z'),
    numActivePlans: 91,
    totalRecurringMonthlyRevenue: 9200,
    totalUnusedCredits: 92,
  },
  '2023-09-25T07:00:00Z': {
    periodStart: BzDateFns.toIsoDateString('2023-09-25T07:00:00Z'),
    periodEnd: BzDateFns.toIsoDateString('2023-10-02T06:59:59.999Z'),
    numActivePlans: 98,
    totalRecurringMonthlyRevenue: 9700,
    totalUnusedCredits: 81,
  },
  '2023-10-02T07:00:00Z': {
    periodStart: BzDateFns.toIsoDateString('2023-10-02T07:00:00Z'),
    periodEnd: BzDateFns.toIsoDateString('2023-10-09T06:59:59.999Z'),
    numActivePlans: 100,
    totalRecurringMonthlyRevenue: 10000,
    totalUnusedCredits: 90,
  },
}
export const DUMMY_MAINTENANCE_PLAN_DATA =
  UNCASTED_DUMMY_MAINTENANCE_PLAN_DATA as MaintenancePlanPeriodicSummaryReport

export const DUMMY_JOBS_BY_LEAD_SOURCE_DATA: JobsByLeadSourceDatum[] = [
  {
    leadSource: 'Existing Customer',
    numJobs: 4,
    revenue: 2610.567829242636,
  },
  {
    leadSource: 'Yelp',
    numJobs: 2,
    revenue: 1704.8606231788642,
  },
  {
    leadSource: 'Television Ad',
    numJobs: 1,
    revenue: 1411.8377035699968,
  },
  {
    leadSource: 'Radio Ad',
    numJobs: 3,
    revenue: 1145.4532311982996,
  },
  {
    leadSource: 'Google',
    numJobs: 3,
    revenue: 1518.391492518676,
  },
  {
    leadSource: 'Angi',
    numJobs: 5,
    revenue: 1038.8994422496205,
  },
  {
    leadSource: 'Thumbtack',
    numJobs: 4,
    revenue: 1704.8606231788642,
  },
  {
    leadSource: 'Facebook',
    numJobs: 3,
    revenue: 1598.3068342301854,
  },
  {
    leadSource: 'Email Campaign',
    numJobs: 3,
    revenue: 665.9611809292439,
  },
  {
    leadSource: 'Billboard',
    numJobs: 3,
    revenue: 2157.71422621075,
  },
  {
    leadSource: 'Newspaper Ad',
    numJobs: 2,
    revenue: 1331.9223618584879,
  },
  {
    leadSource: 'Direct Mail Campaign',
    numJobs: 4,
    revenue: 1225.3685729098088,
  },
  {
    leadSource: 'Vehicle Wrap',
    numJobs: 3,
    revenue: 2477.3755930567872,
  },
  {
    leadSource: 'Convention / Trade Show',
    numJobs: 5,
    revenue: 825.7918643522625,
  },
  {
    leadSource: 'Local Ad',
    numJobs: 4,
    revenue: 1598.3068342301854,
  },
  {
    leadSource: 'YouTube Ad',
    numJobs: 4,
    revenue: 1411.8377035699968,
  },
  {
    leadSource: 'Podcast Ad',
    numJobs: 5,
    revenue: 879.068758826602,
  },
  {
    leadSource: 'Marketing Website',
    numJobs: 4,
    revenue: 1598.3068342301854,
  },
  {
    leadSource: 'Customer Referral',
    numJobs: 3,
    revenue: 452.8536030318859,
  },
  {
    leadSource: 'Contractor Referral',
    numJobs: 3,
    revenue: 1385.1992563328274,
  },
  {
    leadSource: 'Supplier Referral',
    numJobs: 4,
    revenue: 1944.6066483133923,
  },
  {
    leadSource: 'Distributor Referral',
    numJobs: 2,
    revenue: 1518.391492518676,
  },
  {
    leadSource: 'Manufacturer Referral',
    numJobs: 2,
    revenue: 1305.283914621318,
  },
  {
    leadSource: 'Utility Company Referral',
    numJobs: 5,
    revenue: 1704.8606231788642,
  },
  {
    leadSource: 'Referral (Other)',
    numJobs: 5,
    revenue: 665.9611809292439,
  },
  {
    leadSource: 'Unknown',
    numJobs: 3,
    revenue: 665.9611809292439,
  },
  {
    leadSource: 'Other',
    numJobs: 1,
    revenue: 1065.5378894867904,
  },
  {
    leadSource: 'Yard Sign',
    numJobs: 4,
    revenue: 1038.8994422496205,
  },
]

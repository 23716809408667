import {
  BzDateFns,
  INVOICE_V2_FRIENDLY_DELIVERY_NAME,
  InvoiceHistoryInfo,
  InvoicePayment,
  IsoDateString,
  PaymentMethodDisplayNames,
  formatMoney,
  usCentsToUsd,
} from '@breezy/shared'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faCommentDollar,
  faEdit,
  faEnvelope,
  faHandHoldingDollar,
  faMoneyBill,
  faSignature,
  faSwap,
  faSync,
} from '@fortawesome/pro-regular-svg-icons'
import { faRetweet } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { OnsitePageSection } from '../../../adam-components/OnsitePage/OnsitePageSection'
import { OnsitePageSimpleSectionItem } from '../../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import PaymentStatusTag from '../../../components/Payments/PaymentStatusTag'
import useIsMobile from '../../../hooks/useIsMobile'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useModalState } from '../../../utils/react-utils'
import { InvoicePaymentRefundStatusTag } from './InvoicePaymentRefundStatusTag'

type InvoiceHistoryProps = {
  history: InvoiceHistoryInfo[]
  payments: InvoicePayment[]
}

type InvoiceHistoryItemProps = {
  header: string
  headerExtra?: React.ReactNode
  subHeader: string
  subHeaderExtra?: React.ReactNode
  icon: IconProp
}

const InvoiceHistoryItem = React.memo<
  InvoiceHistoryItemProps & { index: number }
>(({ header, headerExtra, subHeader, subHeaderExtra, icon, index }) => {
  return (
    <div
      key={`${header}-item-${index}`}
      className="relative flex flex-row items-center py-1.5 transition-opacity"
    >
      <div className="flex h-11 w-11 items-center justify-center rounded-full bg-bz-gray-300">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="ml-3 flex-1">
        <div className="flex gap-2 text-base font-semibold">
          {header}
          {headerExtra}
        </div>
        <div className="flex flex-col text-sm font-normal text-bz-gray-800">
          {subHeader}
          {subHeaderExtra}
        </div>
      </div>
    </div>
  )
})

type RefundMessageProps = {
  amountUsc: number
  occurredAt: IsoDateString
}

const RefundMessage = React.memo<RefundMessageProps>(
  ({ amountUsc, occurredAt }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    return (
      <div className="flex items-center gap-1 text-bz-orange-800">
        <FontAwesomeIcon icon={faRetweet} />
        <div>{`Refunded ${formatMoney(
          usCentsToUsd(amountUsc),
        )} on ${BzDateFns.formatFromISO(
          occurredAt,
          'MMM. dd, yyyy',
          tzId,
        )}`}</div>
      </div>
    )
  },
)

export const InvoiceHistoryContent = React.memo<InvoiceHistoryProps>(
  ({ history, payments }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const items = useMemo<InvoiceHistoryItemProps[]>(() => {
      const items: InvoiceHistoryItemProps[] = []

      for (const event of history) {
        if (event.eventType === 'PAYMENT_MADE') {
          const payment = payments.find(
            p => p.paymentRecordGuid === event.metadata.paymentRecordGuid,
          )

          if (!payment) {
            continue
          }

          const hasRefund = payment.refunds.length > 0

          if (hasRefund) {
            const paymentRefundAmount = payment.refunds.reduce(
              (acc, refund) => acc + refund.amountUsc,
              0,
            )
            const refundMode =
              paymentRefundAmount < payment.paidUsc ? 'partial' : 'full'

            items.push({
              icon: faMoneyBill,
              header: event.title,
              headerExtra: <InvoicePaymentRefundStatusTag mode={refundMode} />,
              subHeader: `${BzDateFns.formatFromISO(
                event.occurredAt,
                'MMM. dd, yyyy',
                tzId,
              )} • ${PaymentMethodDisplayNames[payment.paymentMethod]}`,
              subHeaderExtra: (
                <div className="flex flex-col">
                  {payment.refunds.map(refund => (
                    <RefundMessage
                      amountUsc={refund.amountUsc}
                      occurredAt={refund.occurredAt}
                    />
                  ))}
                </div>
              ),
            })
          } else {
            items.push({
              icon: faMoneyBill,
              header: event.title,
              headerExtra: <PaymentStatusTag status={payment.status} />,
              subHeader: `${BzDateFns.formatFromISO(
                event.occurredAt,
                'MMM. dd, yyyy',
                tzId,
              )} • ${PaymentMethodDisplayNames[payment.paymentMethod]}`,
            })
          }
        }

        if (event.eventType === 'CREATED') {
          items.push({
            icon: faEdit,
            header: event.title,
            subHeader: `${BzDateFns.formatFromISO(
              event.occurredAt,
              'MMM. dd, yyyy',
              tzId,
            )} • ${event.createdByUserFullName}`,
          })
        }

        if (event.eventType === 'UPDATED') {
          items.push({
            icon: faEdit,
            header: event.title,
            subHeader: `${BzDateFns.formatFromISO(
              event.occurredAt,
              'MMM. dd, yyyy',
              tzId,
            )} • ${event.createdByUserFullName}`,
          })
        }

        if (event.eventType === 'PRESENTED') {
          items.push({
            icon: faCommentDollar,
            header: event.title,
            subHeader: `${BzDateFns.formatFromISO(
              event.occurredAt,
              'MMM. dd, yyyy',
              tzId,
            )} • ${event.createdByUserFullName}`,
          })
        }

        if (event.eventType === 'SIGNED') {
          items.push({
            icon: faSignature,
            header: event.title,
            subHeader: `${BzDateFns.formatFromISO(
              event.occurredAt,
              'MMM. dd, yyyy',
              tzId,
            )} • ${event.createdByUserFullName}`,
          })
        }

        if (event.eventType === 'QBO_SYNC') {
          items.push({
            icon: faSync,
            header: event.title,
            subHeader: `${BzDateFns.formatFromISO(
              event.occurredAt,
              'MMM. dd, yyyy',
              tzId,
            )} • ${event.createdByUserFullName}`,
          })
        }

        if (event.eventType === 'ACCOUNTING_SYNC') {
          items.push({
            icon: faSync,
            header: event.title,
            subHeader: `${BzDateFns.formatFromISO(
              event.occurredAt,
              'MMM. dd, yyyy',
              tzId,
            )} • ${event.createdByUserFullName}`,
          })
        }

        if (event.eventType === 'MANUAL_STATUS_CHANGE') {
          items.push({
            icon: faSwap,
            header: event.title,
            subHeader: `${BzDateFns.formatFromISO(
              event.occurredAt,
              'MMM. dd, yyyy',
              tzId,
            )} • ${event.createdByUserFullName}`,
          })
        }

        if (event.eventType === 'SENT') {
          items.push({
            icon: faEnvelope,
            header: event.title,
            subHeader: `${BzDateFns.formatFromISO(
              event.occurredAt,
              'MMM. dd, yyyy',
              tzId,
            )} • ${
              INVOICE_V2_FRIENDLY_DELIVERY_NAME[event.metadata.deliveryMethod]
            } • ${event.createdByUserFullName}`,
          })
        }

        if (event.eventType === 'FINANCING_APPLICATION_SENT') {
          items.push({
            icon: faHandHoldingDollar,
            header: event.title,
            subHeader: `${BzDateFns.formatFromISO(
              event.occurredAt,
              'MMM. dd, yyyy',
              tzId,
            )} • ${event.createdByUserFullName}`,
          })
        }
      }

      return items
    }, [history, payments, tzId])

    return (
      <div className="flex flex-col gap-2" data-testid="invoice-history-items">
        {items.map((item, index) => (
          <InvoiceHistoryItem
            key={`${item.header}-${index}`}
            index={index}
            {...item}
          />
        ))}
      </div>
    )
  },
)

type InvoiceHistoryTailsProps = {
  leftClassName?: string
}
const InvoiceHistoryTail = React.memo<InvoiceHistoryTailsProps>(
  ({ leftClassName }) => {
    return (
      <div
        className={classNames(
          'absolute bottom-0 top-12 w-px border-spacing-0.5 -translate-x-1/2 border border-dashed border-bz-gray-500',
          leftClassName,
        )}
      />
    )
  },
)

export const InvoiceHistorySection = React.memo<InvoiceHistoryProps>(
  ({ history, payments }) => {
    const [
      invoiceHistoryModalOpen,
      showInvoiceHistoryModal,
      hideInvoiceHistoryModal,
    ] = useModalState(false)
    const isMobile = useIsMobile()
    return (
      <OnsitePageSection title="History" hideBottomBorder>
        <OnsitePageSimpleSectionItem className="relative">
          <InvoiceHistoryTail
            leftClassName={isMobile ? 'left-[38px]' : 'left-[46px]'}
          />
          <InvoiceHistoryContent
            history={history.slice(0, 3)}
            payments={payments}
          />
          {history.length > 3 && (
            <Button
              size="large"
              className="ml-[40px] mt-3 max-w-[150px] font-semibold text-[#1677FF]"
              type="link"
              onClick={showInvoiceHistoryModal}
            >
              View more history
            </Button>
          )}
        </OnsitePageSimpleSectionItem>
        {invoiceHistoryModalOpen && (
          <InvoiceHistoryModal
            history={history}
            payments={payments}
            onClose={hideInvoiceHistoryModal}
          />
        )}
      </OnsitePageSection>
    )
  },
)

export const InvoiceHistoryModal = React.memo<
  InvoiceHistoryProps & {
    onClose: () => void
  }
>(({ history, payments, onClose }) => {
  return (
    <OnsiteBasicModal
      header="Invoice history"
      headerBordered
      open
      onClose={onClose}
    >
      <div className="relative -mb-6 flex flex-col pb-8">
        <InvoiceHistoryTail leftClassName="left-[22px]" />
        <InvoiceHistoryContent history={history} payments={payments} />
      </div>
    </OnsiteBasicModal>
  )
})

import { LinkedNoteViewModel, NoteLinkData, R, nextGuid } from '@breezy/shared'
import { Button, Select } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { trpc } from 'src/hooks/trpc'
import { WysiwygEditor } from '../WysiwygEditor/WysiwygEditor'

type NoteFormProps = {
  linkData: NoteLinkData
  onSuccess?: () => unknown
  onError?: () => unknown
  onLoadingChange?: (loading: boolean) => void
  addText?: string
  note?: LinkedNoteViewModel
  editable?: boolean
}

export const NoteForm = React.memo<NoteFormProps>(
  ({
    onSuccess,
    onError,
    linkData,
    onLoadingChange,
    addText = 'Add Note',
    note,
    editable = true,
  }) => {
    const noteGuid = note?.noteGuid || nextGuid()
    const [value, updateValue] = useState(note?.value || '')
    const [taggedUsers, setTaggedUsers] = useState<string[]>(
      note ? [...note.taggedUsers] : [],
    )

    const usersQuery = trpc.user['users:get'].useQuery()

    const userOptions = useMemo(
      () =>
        R.sort(
          R.ascend(option => option.label),
          (usersQuery.data?.users || [])
            .filter(user => !user.deactivatedAt)
            .map(user => ({
              label: `${user.firstName} ${user.lastName}`,
              value: user.userGuid,
            })),
        ),
      [usersQuery.data?.users],
    )

    const mutateNote = trpc.notes['notes:put-linked'].useMutation()

    const onSubmit = useCallback(() => {
      onLoadingChange?.(true)
      mutateNote.mutate(
        {
          linkData,
          noteGuid,
          value,
          taggedUsers,
        },
        {
          onSuccess: () => {
            onSuccess?.()
            onLoadingChange?.(false)
            updateValue('')
            setTaggedUsers([])
          },
          onError: () => {
            onError?.()
            onLoadingChange?.(false)
          },
        },
      )
    }, [
      linkData,
      mutateNote,
      onError,
      onLoadingChange,
      onSuccess,
      taggedUsers,
      value,
      noteGuid,
    ])

    return (
      <div>
        <WysiwygEditor
          value={value}
          onChange={updateValue}
          disabled={!editable}
        />

        <div className="mb-3 mt-3 flex items-center justify-between">
          {linkData.primaryNoteType === 'PHOTO' ? (
            // Makes the justify-between happy
            <span />
          ) : (
            <Select
              className="mr-2 flex-1"
              mode="multiple"
              allowClear
              placeholder="Tag users in this note"
              onChange={setTaggedUsers}
              value={taggedUsers}
              options={userOptions}
              optionFilterProp="label"
            />
          )}
          <Button
            className="account-notes-list-card-add-note-button"
            type="primary"
            onClick={onSubmit}
            disabled={value.length < 1}
          >
            {addText}
          </Button>
        </div>
      </div>
    )
  },
)

import {
  AccountLocation,
  AddressGuid,
  BzAddress,
  BzDateFns,
  bzExpect,
  BzTimeWindow,
  calculateInferredAppointmentStatus,
  DateTimeFormatter,
  formatName,
  getDisplayNameForAccountType,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  isNullish,
  JobClass,
  LoanRecord,
  LocalDate,
  PaymentStatus,
  PhotoRecordWithLinks,
  PrequalRecord,
  R,
  ZonedDateTime,
  ZoningInfo,
} from '@breezy/shared'
import {
  faEdit,
  faEllipsis,
  faHistory,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Dropdown, Row } from 'antd'
import { default as classNames, default as cn } from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useSubscription } from 'urql'
import AccountReminders from '../../components/AccountReminders/AccountReminders'
import {
  ActivityFeed,
  ActivityFeedContext,
} from '../../components/ActivityFeed/ActivityFeed'
import AddressMultiLineView from '../../components/Addresses/AddressMultiLineView/AddressMultiLineView'
import {
  AccountContactsCollapsible,
  AccountJobsCollapsible,
  AccountJobsCollapsibleJob,
  AccountLocationsCollapsible,
} from '../../components/collapsibles'
import {
  AppointmentsCollapsible,
  AppointmentsCollapsibleAppointment,
} from '../../components/collapsibles/AppointmentsCollapsible/AppointmentsCollapsible'
import { CardOnFileCollapsibleLoader } from '../../components/collapsibles/CardOnFileCollapsible/CardOnFileCollapsible'
import EstimatesCollapsible from '../../components/collapsibles/EstimatesCollapsible/EstimatesCollapsible'
import { InstalledEquipmentCollapsible } from '../../components/collapsibles/InstalledEquipmentCollapsible/InstalledEquipmentCollapsible'
import { InstalledHvacSystemsCollapsible } from '../../components/collapsibles/InstalledHvacSystemsCollapsible/InstalledHvacSystemsCollapsible'
import { InvoicesV2Collapsible } from '../../components/collapsibles/InvoicesCollapsible/InvoicesV2Collapsible'
import MaintenancePlansCollapsible from '../../components/collapsibles/MaintenancePlansCollapsible/MaintenancePlansCollapsible'
import WisetackFinancingCollapsible from '../../components/collapsibles/WisetackFinancingCollapsible/WisetackFinancingCollapsible'
import EquipmentUpsertDrawer from '../../components/EquipmentUpsertDrawer/EquipmentUpsertDrawer'
import FileElements from '../../components/Files/FileElements'
import { useFinancingWizard } from '../../components/Financing/hooks/useFinancingWizard'
import InstalledHvacSystemUpsertDrawer from '../../components/InstalledHvacSystemUpsertDrawer/InstalledHvacSystemUpsertDrawer'
import { LayoutPage } from '../../components/Layout/LayoutPage'
import { LayoutSection } from '../../components/Layout/LayoutSection'
import { LinkedCallsListView } from '../../components/LinkedCallsListView/LinkedCallsListView'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import MaintenancePlanVipIcon from '../../components/MaintenancePlanVipIcon/MaintenancePlanVipIcon'
import { MigrationJunk } from '../../components/MigrationJunk/MigrationJunk'
import { MigrationJunkView } from '../../components/MigrationJunk/MigrationJunkView'
import UpsertAppointmentDrawer from '../../components/NewAppointmentModal/UpsertAppointmentDrawer'
import { CreateAppointmentFormProps } from '../../components/NewAppointmentModal/UpsertAppointmentForm/UpsertAppointmentForm'
import NotesListCard from '../../components/Notes/NotesListCard/NotesListCard'
import BzTabs, {
  BzTab,
  useURLSettableTabIndex,
} from '../../components/Page/BzTabs/BzTabs'
import TagColumn from '../../components/Page/Columns/TagColumn/TagColumn'
import VerticalKeyValue from '../../components/Page/Columns/TagColumn/VerticalKeyValue'
import { Page } from '../../components/Page/Page'
import PaymentsCollapsible, {
  PaymentsCollapsiblePayment,
} from '../../components/Payments/PaymentsCollapsible/PaymentsCollapsible'
import { PhotoDetailDrawer } from '../../components/PhotoDetailDrawer/PhotoDetailDrawer'
import CreateNewAccountForm from '../../components/ProgressiveJobCreationModal/CreateOrEditAccountForm/CreateOrEditAccountForm'
import { QuickbooksSyncAccountButton } from '../../components/Quickbooks/QuickbooksSyncButton'
import { RichCompanyLeadSourceView } from '../../components/RichCompanyLeadSourceView/RichCompanyLeadSourceView'
import { TagList } from '../../components/Tags'
import { ServiceHistoryModal } from '../../components/TechnicianApp/MyAppointmentsPage/AppointmentDetail/ServiceHistory/ServiceHistory'
import { TrpcMultiQueryLoader } from '../../components/TrpcQueryLoader'
import { AsyncFileUpload } from '../../components/Upload/AsyncFileUpload'
import { AsyncPhotoUpload } from '../../components/Upload/AsyncPhotoUpload'
import { useSynchronousUpload } from '../../components/Upload/Upload'
import BzColumn from '../../elements/BzColumn/BzColumn'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { EmDash } from '../../elements/EmDash/EmDash'
import { AccountsDetailsQuery } from '../../generated/user/graphql'
import { TAGS_MINIMAL_FOR_COMPANY_QUERY } from '../../gql/queries/Tags.gql'
import { useCanCreateJobs } from '../../hooks/permission/useCanCreateJob'
import { useCanManageAccount } from '../../hooks/permission/useCanManageAccount'
import { useCanUseIntegratedPhone } from '../../hooks/permission/useCanUseIntegratedPhone'
import { useCanViewAccount } from '../../hooks/permission/useCanViewAccount'
import { trpc } from '../../hooks/trpc'
import { useIntercom } from '../../hooks/useIntercom'
import { useAddRecentRecord } from '../../hooks/useRecentRecord'
import {
  useQuickbooksOnlineEnabled,
  useWisetackEnabled,
} from '../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompanyTimeZoneId,
  useExpectedPrincipal,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { AccountIcon } from '../../utils/feature-icons'
import { Placeholder } from '../../utils/Placeholder'
import { useModalState, useQueryParamFlag } from '../../utils/react-utils'
import { UpsertOp } from '../../utils/upsert-utils'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import {
  ACCOUNT_DETAILS_QUERY,
  COMPANY_CONFIG_QUERY,
  LINKED_CALLS_FOR_ACCOUNT_SUBSCRIPTION,
  UPDATE_VIEWED_AT_TIME_FOR_USER,
} from './AccountDetailsPage.gql'
import { useMaintenancePlanCollapsibleData } from './accountDetailsUtils'
import {
  ArchiveAccountModal,
  useArchiveAccountModal,
} from './ArchiveAccountModal'

export const ACCOUNT_DETAILS_TAB_KEY = 'tab'

type AccountDetails = NonNullable<AccountsDetailsQuery['accountsByPk']>

const AccountDetailsPageAuthWrapper = () => {
  const accountGuid = bzExpect(
    useParams().accountGuid,
    'accountGuid',
    'Account Guid is required',
  )
  const { canView, isLoading } = useCanViewAccount(accountGuid)

  const [accountDetailsQuery, refetch] = useQuery({
    query: ACCOUNT_DETAILS_QUERY,
    variables: { accountGuid },
  })

  const fetchMigrationJunkQuery = trpc.migrationJunk[
    'migration-junk:query'
  ].useQuery({
    accountGuid,
  })

  const queries = useMemo(
    () => [fetchMigrationJunkQuery] as const,
    [fetchMigrationJunkQuery],
  )

  const account = accountDetailsQuery.data?.accountsByPk

  return (
    <Page requiresCompanyUser={true} disableLayout>
      {isLoading || accountDetailsQuery.fetching ? (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : !account ? (
        <div>Account not found</div>
      ) : canView ? (
        <TrpcMultiQueryLoader
          queries={queries}
          render={([migrationJunk]) => {
            return (
              <AccountDetailsPage
                refetch={refetch}
                account={account}
                migrationJunk={migrationJunk}
              />
            )
          }}
        />
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <Placeholder>Unauthorized to view this account</Placeholder>
        </div>
      )}
    </Page>
  )
}

type AccountWrapper = {
  account: AccountDetails
  refetch: () => void
}

type MigrationJunkWrapper = {
  migrationJunk?: MigrationJunk | null
}

type AccountDetailsPageProps = AccountWrapper & MigrationJunkWrapper
type Editable<T> = T & {
  editable?: boolean
}

const AccountDetailsPage = React.memo<AccountDetailsPageProps>(
  ({ account, refetch, migrationJunk }) => {
    const { userGuid } = useExpectedPrincipal()

    const { accountGuid } = account

    const [isEditingAccount, setIsEditingAccount] = useState(false)
    const { canManage } = useCanManageAccount(accountGuid)
    useAddRecentRecord('accounts', accountGuid)

    const [, updateViewedAtTimeForUserMutation] = useMutation(
      UPDATE_VIEWED_AT_TIME_FOR_USER,
    )

    useEffect(() => {
      updateViewedAtTimeForUserMutation({
        accountGuid,
        userGuid,
        viewedAt: BzDateFns.nowISOString(),
      })
    }, [account, accountGuid, updateViewedAtTimeForUserMutation, userGuid])

    return (
      <>
        <LayoutPage padded>
          <LayoutSection size={{ xs: 12, md: 6, xl: 12 * 0.3 }} card>
            <LeftSideAccountPane
              account={account}
              refetch={refetch}
              editable={canManage}
            />
          </LayoutSection>
          <LayoutSection
            size={{ xs: 12, md: 12, xl: 12 * 0.4 }}
            order={{ xs: 100, xl: 'DEFAULT' }}
            card
          >
            <MiddleAccountPane
              account={account}
              refetch={refetch}
              migrationJunk={migrationJunk}
              editable={canManage}
            />
          </LayoutSection>
          <LayoutSection size={{ xs: 12, md: 6, xl: 12 * 0.3 }} card>
            <RightSideAccountPane
              account={account}
              refetch={refetch}
              editable={canManage}
            />
          </LayoutSection>
        </LayoutPage>
        <EditAccountDrawer
          account={account}
          refetch={refetch}
          open={isEditingAccount}
          onClose={() => setIsEditingAccount(false)}
        />
      </>
    )
  },
)

type EditAccountDrawerProps = {
  account: AccountDetails
  refetch: () => void
  onClose: (didUpdateAccount: boolean) => void
  open: boolean
}

const EditAccountDrawer = React.memo<EditAccountDrawerProps>(
  ({ account, onClose, refetch, open }) => {
    const {
      companyGuid,
      accountGuid,
      accountType,
      accountDisplayName,
      accountManagerUserGuid,
      accountLeadSource,
      tags,
    } = account
    const message = useMessage()
    const [tagsQuery] = useQuery({
      query: TAGS_MINIMAL_FOR_COMPANY_QUERY,
      variables: { companyGuid },
    })

    return (
      <BzDrawer
        title="Edit Account"
        icon={AccountIcon}
        preferredWidth={720}
        item={open ? { onCancel: () => onClose(false) } : undefined}
        destroyOnClose
      >
        <CreateNewAccountForm
          mode="master-edit"
          onCancel={() => onClose(false)}
          onFinish={() => {
            onClose(true)
            refetch()
            message.success('Successfully updated Account')
          }}
          accountGuid={accountGuid}
          companyGuid={companyGuid}
          accountTags={tagsQuery.data?.tags ?? []}
          initialValues={{
            accountType,
            accountDisplayName,
            accountManagerUserGuid,
            leadSourceGuid: accountLeadSource[0]?.companyLeadSourceGuid,
            leadSourceReferringContactGuid:
              accountLeadSource[0]?.referringContactGuid,
            leadSourceAttributionDescription:
              accountLeadSource[0]?.attributionDescription,
            accountTagGuids: R.pluck('tagGuid', tags),
          }}
        />
      </BzDrawer>
    )
  },
)

type MiddleAccountPaneProps = Editable<AccountWrapper & MigrationJunkWrapper>

const MiddleAccountPane = React.memo<MiddleAccountPaneProps>(
  ({ account, refetch, migrationJunk, editable = true }) => {
    const {
      accountGuid,
      companyGuid,
      accountLocations,
      jobs,
      photoLinks,
      fileLinks,
    } = account
    const [editingPhoto, setEditingPhoto] =
      useState<PhotoRecordWithLinks | null>(null)

    const canUseIntegratedPhone = useCanUseIntegratedPhone()

    const [{ data: callsData, fetching: callsFetching }] = useSubscription({
      query: LINKED_CALLS_FOR_ACCOUNT_SUBSCRIPTION,
      variables: {
        accountGuid,
        companyGuid,
      },
      pause: !canUseIntegratedPhone,
    })

    const photos = useMemo(() => {
      // The type assertion is because of a quirk of `R.flatten`. It's making the type a big ([photo link type] | [photo
      // link type] | [photo link type] | ...) even though all those `[photo link type]`s are the same. By basically
      // saying "this is an array of the first type", it's verifying that all those possible options match, then giving
      // us a much cleaner type preview.
      const unsortedPhotos: (typeof photoLinks)[number]['photo'][] = R.uniqBy(
        R.prop('photoGuid'),
        R.pluck(
          'photo',
          R.flatten([
            photoLinks,
            R.pluck('photoLinks', accountLocations),
            jobs.map(j => [
              j.photoLinks,
              j.appointments.map(a => [
                a.photoLinks,
                R.pluck('photoLinks', a.assignments),
              ]),
            ]),
          ]),
        ),
      )
      const photoRecords = R.sortWith(
        [R.descend(R.prop('createdAt')), R.ascend(R.prop('photoGuid'))],
        unsortedPhotos,
      )
      return photoRecords
    }, [accountLocations, jobs, photoLinks])

    const files = useMemo(() => {
      // The type assertion is the same story as photos above
      const unsortedFiles: (typeof fileLinks)[number]['file'][] = R.uniqBy(
        R.prop('fileGuid'),
        R.pluck(
          'file',
          R.flatten([
            fileLinks,
            R.pluck('fileLinks', accountLocations),
            jobs.map(j => [
              j.fileLinks,
              j.appointments.map(a => [
                a.fileLinks,
                R.pluck('fileLinks', a.assignments),
              ]),
            ]),
          ]),
        ),
      )
      const fileRecords = R.sortWith(
        [R.descend(R.prop('createdAt')), R.ascend(R.prop('fileName'))],
        unsortedFiles,
      )
      return fileRecords.map(({ createdByUserGuid, ...rest }) => ({
        userGuid: createdByUserGuid,
        companyGuid,
        ...rest,
      }))
    }, [accountLocations, companyGuid, fileLinks, jobs])

    const photoElements = useMemo(() => {
      return photos.map(photo => (
        <div
          className={cn(
            'mb-2 flex flex-col items-center',
            editable ? 'hover:cursor-pointer' : '',
          )}
          key={photo.photoGuid}
          onClick={
            editable
              ? () => {
                  setEditingPhoto(photo)
                }
              : undefined
          }
        >
          <img src={photo.cdnUrl} alt="belongs to account" />
        </div>
      ))
    }, [editable, photos])

    const links = useMemo(() => ({ accountGuid }), [accountGuid])
    const onUploadChange = useSynchronousUpload(refetch)

    const tabs = useMemo<BzTab[]>(() => {
      const t: BzTab[] = []

      t.push({
        title: 'Reminders',
        content: <AccountReminders accountGuid={accountGuid} />,
      })

      if (canUseIntegratedPhone && callsData && callsData.accounts.length > 0) {
        t.push({
          title: 'Calls',
          content: (
            <LinkedCallsListView
              calls={callsData.accounts[0].integratedPhoneCalls}
              isLoading={callsFetching}
            />
          ),
        })
      }

      t.push(
        {
          title: 'Notes',
          content: (
            <NotesListCard
              linkData={{
                primaryNoteType: 'ACCOUNT',
                accountGuid,
              }}
              editable={editable}
            />
          ),
        },
        {
          title: 'Photos',
          content: (
            <div className="w-full">
              {editable && (
                <AsyncPhotoUpload
                  links={links}
                  onPhotoUploadChange={onUploadChange}
                />
              )}
              <div className="my-3 w-full columns-2 gap-2">{photoElements}</div>
            </div>
          ),
        },
        {
          title: 'Attachments',
          content: (
            <div className="w-full">
              {editable && (
                <div className="mb-3">
                  <AsyncFileUpload
                    links={links}
                    onFileUploadChange={onUploadChange}
                  />
                </div>
              )}
              <div className="w-full">
                <FileElements
                  onMutate={refetch}
                  files={files}
                  editable={editable}
                />
              </div>
            </div>
          ),
        },
        {
          title: 'Activity',
          content: (
            <ActivityFeed
              context={ActivityFeedContext.Account}
              accountGuid={accountGuid}
            />
          ),
        },
      )

      if (migrationJunk && Object.keys(migrationJunk.junk).length > 0) {
        t.push({
          title: migrationJunk.uiLabel ?? 'Legacy Migration Data',
          content: <MigrationJunkView migrationJunk={migrationJunk} />,
        })
      }

      return t
    }, [
      accountGuid,
      editable,
      links,
      onUploadChange,
      photoElements,
      refetch,
      files,
      migrationJunk,
      canUseIntegratedPhone,
      callsData,
      callsFetching,
    ])

    const [activeTabIndex, setActiveTabIndex] = useURLSettableTabIndex(
      tabs,
      0,
      ACCOUNT_DETAILS_TAB_KEY,
    )

    const activeTab = tabs[activeTabIndex]

    useIntercom({ isLauncherVisible: isNullish(editingPhoto) })

    return (
      <div className="flex flex-col space-y-3 p-6">
        <BzTabs
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
        />
        {activeTab.content}
        {editable && editingPhoto && (
          <PhotoDetailDrawer
            photo={editingPhoto}
            onClose={() => {
              setEditingPhoto(null)
            }}
            onDelete={() => {
              setEditingPhoto(null)
              refetch()
            }}
            editable={editable}
          />
        )}
      </div>
    )
  },
)

const RightSideAccountPane = ({
  account,
  refetch,
  editable = true,
}: Editable<AccountWrapper>) => {
  const {
    accountGuid,
    companyGuid,
    jobs,
    maintenancePlans,
    wisetackLoanRecords,
    wisetackPrequalRecords,
    paymentRecords,
  } = account
  const wisetackEnabled = useWisetackEnabled()

  const canCreateNewJobs = useCanCreateJobs()

  const [createApptModalOpen, setCreateApptModalOpen] = useState(false)

  const apptDrawerJobs: NonNullable<CreateAppointmentFormProps['jobList']> =
    useMemo(
      () =>
        jobs.map(job => ({
          jobGuid: job.jobGuid,
          displayId: job.displayId,
          jobType: job.jobType.name,
          jobClass: job.jobType.jobClass,
          address: {
            line1: job.location.address.line1,
            line2: job.location.address.line2,
            city: job.location.address.city,
            stateAbbreviation: job.location.address.stateAbbreviation,
          },
        })),
      [jobs],
    )

  const [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizard,
    closeMaintenancePlanWizard,
  ] = useMaintenancePlanWizardFlags('mpw', 'account-details')
  const maintenancePlansCollapsibleData =
    useMaintenancePlanCollapsibleData(maintenancePlans)

  const jobsCollapsibleJobs = useMemo<AccountJobsCollapsibleJob[]>(
    () =>
      jobs.map(({ createdAt, location, tags, ...rest }) => ({
        jobCreatedAt: ZonedDateTime.parse(
          createdAt,
          DateTimeFormatter.ISO_OFFSET_DATE_TIME,
        ),
        serviceLocation: {
          location,
        },
        tags: tags.map(({ tagGuid, addedByUserGuid, tag }) => ({
          tagGuid,
          companyGuid,
          createdByUserGuid: addedByUserGuid,
          ...tag,
        })),
        ...rest,
      })),
    [companyGuid, jobs],
  )

  const appointmentsCollapsibleAppointments = useMemo<
    AppointmentsCollapsibleAppointment[]
  >(
    () =>
      R.flatten(
        jobs.map(({ appointments, location, jobType }) =>
          appointments.map(
            ({
              assignments: rawAssignments,
              jobAppointmentGuid,
              cancellationStatus,
              appointmentWindowStart,
              appointmentWindowEnd,
              appointmentReferenceNumber,
              appointmentType,
              sendConfirmationEnabled,
              notificationType,
              sendConfirmationTo,
              sendReminderEnabled,
              reminderLastSentAt,
            }) => {
              const canceled = cancellationStatus?.canceled ?? false

              const assignments = rawAssignments.map(
                ({
                  assignmentStatus,
                  jobAppointmentAssignmentGuid,
                  technicianUserGuid,
                  assignmentStart,
                  assignmentEnd,
                  technician,
                }) => ({
                  assignmentStatus:
                    assignmentStatus?.jobAppointmentAssignmentStatusType ??
                    'TO_DO',
                  assignmentGuid: jobAppointmentAssignmentGuid,
                  technicianUserGuid,
                  technician: {
                    user: technician,
                  },
                  timeWindow: new BzTimeWindow(
                    ZonedDateTime.parse(
                      assignmentStart,
                      DateTimeFormatter.ISO_OFFSET_DATE_TIME,
                    ),
                    ZonedDateTime.parse(
                      assignmentEnd,
                      DateTimeFormatter.ISO_OFFSET_DATE_TIME,
                    ),
                  ),
                }),
              )
              return {
                appointmentGuid: jobAppointmentGuid,
                assignments,
                appointmentType,
                appointmentReferenceNumber,
                canceled,
                appointmentStatus: calculateInferredAppointmentStatus(
                  canceled,
                  assignments,
                ),
                address: BzAddress.create(location.address),
                jobType,
                timeWindow: new BzTimeWindow(
                  ZonedDateTime.parse(
                    appointmentWindowStart,
                    DateTimeFormatter.ISO_OFFSET_DATE_TIME,
                  ),
                  ZonedDateTime.parse(
                    appointmentWindowEnd,
                    DateTimeFormatter.ISO_OFFSET_DATE_TIME,
                  ),
                ),
                sendConfirmationEnabled,
                notificationType,
                sendConfirmationTo,
                sendReminderEnabled,
                reminderLastSentAt,
              }
            },
          ),
        ),
      ),
    [jobs],
  )

  const loanRecords = useMemo<LoanRecord[]>(
    () =>
      wisetackLoanRecords.map(
        ({
          wisetackLoanRecordGuid,
          wisetackMerchantGuid,
          wisetackLoanStatuses,
          wisetackTransactionGuid,
          contact,
          createdAt,
          updatedAt,
          ...rest
        }) => {
          const loanRecordStatuses = wisetackLoanStatuses.map(
            ({ createdAt, ...rest }) => ({
              // Note that though this field is `nullable`, there are no records in prod where it's `null` and we don't
              // know why it's nullable.
              createdAt: createdAt ?? BzDateFns.nowISOString(),
              ...rest,
            }),
          )
          return {
            loanRecordGuid: wisetackLoanRecordGuid,
            merchantGuid: wisetackMerchantGuid,
            externalTransactionGuid: wisetackTransactionGuid,
            loanRecordStatuses,
            // Note that in the query `wisetackLoanStatuses` is sorted by `createdAt` in descending order
            latestLoanRecordStatus: loanRecordStatuses[0],
            contactName: contact.fullName ?? '',
            // Note that though `createdAt` and `updatedAt` are nullable on this table, we don't know why and there are no
            // records in prod where they are `null`.
            createdAt: createdAt ?? BzDateFns.nowISOString(),
            updatedAt: updatedAt ?? BzDateFns.nowISOString(),
            ...rest,
          }
        },
      ),
    [wisetackLoanRecords],
  )

  const prequalRecords = useMemo<PrequalRecord[]>(
    // TODO: I don't to do this now since the query uses a fragment that's used in other places, but we can alias the
    // names right in the gql query so most of this isn't necessary.
    () =>
      wisetackPrequalRecords.map(
        ({
          wisetackPrequalRecordGuid,
          wisetackMerchantGuid,
          wisetackPrequalGuid,
          createdAt,
          updatedAt,
          ...rest
        }) => ({
          prequalRecordGuid: wisetackPrequalRecordGuid,
          merchantGuid: wisetackMerchantGuid,
          externalPrequalGuid: wisetackPrequalGuid,
          // As with the loan records, these are nullable for no discernable reason.
          createdAt: createdAt ?? BzDateFns.nowISOString(),
          updatedAt: updatedAt ?? BzDateFns.nowISOString(),
          ...rest,
        }),
      ),
    [wisetackPrequalRecords],
  )

  const payments = useMemo<PaymentsCollapsiblePayment[]>(() => {
    const loanRecordMap = R.indexBy(R.prop('loanRecordGuid'), loanRecords)
    return paymentRecords.map(
      ({
        paymentRecordGuid,
        paymentStatuses,
        paymentReferenceNumber,
        paymentLink,
        ...rest
      }) => ({
        guid: paymentRecordGuid,
        referenceNumber: paymentReferenceNumber,
        status: paymentStatuses[0]?.paymentStatus ?? PaymentStatus.SUBMITTING,
        loanRecord: loanRecordMap[paymentLink?.wisetackLoanRecordGuid ?? ''],
        ...rest,
      }),
    )
  }, [loanRecords, paymentRecords])

  return (
    <BzColumn noPaddingMobile>
      <MaintenancePlansCollapsible
        plans={maintenancePlansCollapsibleData}
        createNew={() => {
          openMaintenancePlanWizard()
        }}
      />
      {maintenancePlanWizardOpen && (
        <MaintenancePlanWizard
          onRamp="account-details"
          accountGuid={accountGuid}
          onClose={closeMaintenancePlanWizard}
        />
      )}
      <AccountJobsCollapsible
        accountGuid={accountGuid}
        jobs={jobsCollapsibleJobs}
        canCreateNewJob={editable && canCreateNewJobs}
      />

      <AppointmentsCollapsible
        appointments={appointmentsCollapsibleAppointments}
        onAppointmentEdited={() => {
          refetch()
        }}
        editable={editable}
        onPlus={() => setCreateApptModalOpen(true)}
      />

      {wisetackEnabled && (
        <WisetackFinancingCollapsible
          accountGuid={accountGuid}
          loanRecords={loanRecords}
          prequalRecords={prequalRecords}
        />
      )}

      <PaymentsCollapsible payments={payments} />

      <InvoicesV2Collapsible accountGuid={accountGuid} />

      <EstimatesCollapsible guids={{ accountGuid }} allowCreate={false} />

      <UpsertAppointmentDrawer
        mode="create"
        jobGuid={apptDrawerJobs[0]?.jobGuid ?? ''}
        jobClass={apptDrawerJobs[0]?.jobClass ?? JobClass.INSTALL}
        open={createApptModalOpen}
        onCancel={() => setCreateApptModalOpen(false)}
        onAppointmentCreated={() => {
          setCreateApptModalOpen(false)
          refetch()
        }}
        jobList={apptDrawerJobs}
        labelClassName="semibold_14_22 grey9"
      />
    </BzColumn>
  )
}

export const LeftSideAccountPane = React.memo<Editable<AccountWrapper>>(
  ({ account, refetch: providedRefetch, editable = true }) => {
    const {
      accountGuid,
      companyGuid,
      accountContacts,
      accountLocations: rawAccountLocations,
      accountReferenceNumber,
      accountDisplayName,
      maintenancePlans,
      accountType,
      accountCreatedAt,
      accountManager,
      accountLeadSource,
      mailingAddress,
      tags: rawTags,
      archived,
    } = account
    const tzId = useExpectedCompanyTimeZoneId()
    const message = useMessage()
    const wisetackEnabled = useWisetackEnabled()
    const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()

    const qboStaleAccountQuery = trpc.qbo[
      'finance-app:get-stale-accounts'
    ].useQuery(
      {
        accountGuid: accountGuid,
      },
      {
        enabled: isQuickbooksOnlineEnabled,
      },
    )

    const [companyConfigQuery] = useQuery({
      query: COMPANY_CONFIG_QUERY,
      variables: { companyGuid },
    })

    const refetch = useCallback(() => {
      providedRefetch()

      if (isQuickbooksOnlineEnabled) {
        qboStaleAccountQuery.refetch()
      }
    }, [providedRefetch, qboStaleAccountQuery, isQuickbooksOnlineEnabled])

    const [editAccountDrawerOpen, startEditAccount, stopEditAccount] =
      useQueryParamFlag('edit')
    const { showFinancingWizard, financingWizard } = useFinancingWizard({
      accountGuid,
      onCancel: refetch,
    })

    const [accountLocations, installedEquipment, hvacSystems] = useMemo<
      [AccountLocation[], InstalledEquipmentSummary[], InstalledHvacSystem[]]
    >(() => {
      const EQUIPMENT_DATE_FIELDS = [
        'installationDate',
        'estimatedEndOfLifeDate',
        'manufacturerWarrantyStartDate',
        'manufacturerWarrantyEndDate',
        'laborWarrantyStartDate',
        'laborWarrantyEndDate',
        'manufacturingDate',
      ] as const

      const parseEquipment = (
        equipment: (typeof rawAccountLocations)[number]['location']['installedEquipment'][number],
      ): Omit<InstalledEquipmentSummary, 'locationGuid'> => {
        const dateFields = R.pick(EQUIPMENT_DATE_FIELDS, equipment)
        return {
          ...R.mapObjIndexed(
            value => (value ? LocalDate.parse(value) : undefined),
            dateFields,
          ),
          ...R.omit(EQUIPMENT_DATE_FIELDS, equipment),
        }
      }

      const installedEquipment: InstalledEquipmentSummary[] = []
      const hvacSystems: InstalledHvacSystem[] = []

      const accountLocations = rawAccountLocations.map(
        ({ location, ...rest }) => {
          const myInstalledEquipment: InstalledEquipmentSummary[] = (
            location.installedEquipment ?? []
          ).map(equipment => ({
            locationGuid: location.locationGuid,
            ...parseEquipment(equipment),
          }))

          const myHvacSystems: InstalledHvacSystem[] = (
            location.installedHvacSystems ?? []
          ).map(({ installedEquipment, zoningInfo, ...rest }) => ({
            locationGuid: location.locationGuid,
            installedEquipment: installedEquipment.map(equipment => ({
              locationGuid: location.locationGuid,
              ...parseEquipment(equipment),
            })),
            zoningInfo: zoningInfo as ZoningInfo,
            ...rest,
          }))

          installedEquipment.push(...myInstalledEquipment)
          hvacSystems.push(...myHvacSystems)

          const { maintenancePlans, estimatedBuildDate, ...restLocation } =
            location

          return {
            maintenancePlanGuids: R.pluck(
              'maintenancePlanGuid',
              location.maintenancePlans,
            ),
            location: {
              ...restLocation,
              estimatedBuildDate: estimatedBuildDate
                ? LocalDate.parse(estimatedBuildDate)
                : undefined,
              installedEquipment: myInstalledEquipment,
              installedHvacSystems: myHvacSystems,
            },
            ...rest,
          }
        },
      )

      return [accountLocations, installedEquipment, hvacSystems]
    }, [rawAccountLocations])

    const [installedEquipmentMutState, setInstalledEquipmentMutState] =
      useState<UpsertOp<InstalledEquipmentSummary>>()

    const closeEquipmentEdit = useCallback(
      () => setInstalledEquipmentMutState(undefined),
      [],
    )
    const onEquipmentEdit = useCallback(() => {
      refetch()
      closeEquipmentEdit()
    }, [closeEquipmentEdit, refetch])

    const [hvacSystemMutState, setHvacSystemMutState] =
      useState<UpsertOp<InstalledHvacSystem>>()

    const closeHvacSystemEdit = useCallback(
      () => setHvacSystemMutState(undefined),
      [],
    )
    const onHvacSystemEdit = useCallback(() => {
      refetch()
      closeHvacSystemEdit()
    }, [closeHvacSystemEdit, refetch])

    const qboParams = useMemo(() => ({ accountGuid }), [accountGuid])

    const billingAddressUpsertMutation =
      trpc.accounts['account-billing-address:upsert'].useMutation()

    const onBillingAddressSet = useCallback(
      (addressGuid: AddressGuid) => {
        billingAddressUpsertMutation.mutate(
          {
            accountGuid,
            mailingAddressGuid: addressGuid,
          },
          {
            onSuccess() {
              refetch()
              message.success('Successfully updated billing address')
            },
          },
        )
      },
      [billingAddressUpsertMutation, accountGuid, refetch, message],
    )

    useIntercom({
      isLauncherVisible:
        isNullish(installedEquipmentMutState) && isNullish(hvacSystemMutState),
    })

    const maintenancePlan = useMemo(() => {
      if (!maintenancePlans.length) {
        return undefined
      }
      return {
        status: maintenancePlans[0].status,
        planTypeName:
          maintenancePlans[0].maintenancePlanDefinition?.marketingInfo?.name ??
          '',
      }
    }, [maintenancePlans])

    const leadSource = useMemo(() => {
      if (!accountLeadSource.length) {
        return undefined
      }
      const leadSource = accountLeadSource[0]
      return {
        ...leadSource,
        leadSource: {
          name:
            leadSource.companyLeadSource?.canonicalLeadSourceNameOverride ??
            leadSource.companyLeadSource?.canonicalLeadSourceName ??
            'Unknown Lead Source',
        },
      }
    }, [accountLeadSource])

    const tags = useMemo(
      () => rawTags.map(({ tagGuid, tag }) => ({ tagGuid, ...tag })),
      [rawTags],
    )

    const { archiveAccount, closeConfirmProps } = useArchiveAccountModal(
      accountGuid,
      archived,
    )

    const [
      serviceHistoryModalOpen,
      openServiceHistoryModal,
      closeServiceHistoryModal,
    ] = useModalState()

    return (
      <>
        <TagColumn
          title={`Account${archived ? ' (Archived)' : ''}`}
          icon={AccountIcon}
          headerCssClass={classNames(
            archived
              ? 'bg-bz-text-tertiary'
              : 'bg-gradient-to-r to-[#4994EC] from-[#3063BA]',
          )}
          refId={accountReferenceNumber}
        >
          <BzColumn noPadding>
            <div className="flex justify-between">
              <div className="account-display-name card-title-md w-full">
                {accountDisplayName}
              </div>

              <div className="row flex items-center space-x-2">
                <MaintenancePlanVipIcon maintenancePlan={maintenancePlan} />

                {editable && isQuickbooksOnlineEnabled && (
                  <QuickbooksSyncAccountButton
                    loading={qboStaleAccountQuery.isLoading}
                    staleInfo={qboStaleAccountQuery.data?.[accountGuid]}
                    params={qboParams}
                    className="ml-2"
                    onSuccess={qboStaleAccountQuery.refetch}
                  />
                )}
              </div>
            </div>
            <div className="mt-3 flex flex-row flex-wrap space-x-2">
              {editable && (
                <Button
                  type="primary"
                  icon={<FontAwesomeIcon icon={faEdit} />}
                  onClick={startEditAccount}
                  data-testid="edit-account-button"
                >
                  Edit
                </Button>
              )}

              <Button
                type="default"
                icon={<FontAwesomeIcon icon={faHistory} />}
                onClick={openServiceHistoryModal}
                data-dd-action-name="bz-service-history-account-details"
              >
                Service History
              </Button>

              {editable && (
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: [
                      ...(wisetackEnabled
                        ? [
                            {
                              key: 'Send Financing',
                              label: 'Send Financing',
                              onClick: () => showFinancingWizard(),
                            },
                          ]
                        : []),
                      {
                        key: 'Archive Account',
                        label: archived
                          ? 'Unarchive Account'
                          : 'Archive Account',
                        danger: !archived,
                        onClick: archiveAccount,
                      },
                    ],
                  }}
                >
                  <Button icon={<FontAwesomeIcon icon={faEllipsis} />} />
                </Dropdown>
              )}
            </div>
            <div className="flex justify-between space-x-1">
              <VerticalKeyValue
                pair={{
                  key: 'Account Type',
                  value: getDisplayNameForAccountType(accountType),
                }}
              />
              {companyConfigQuery.data?.companyConfigByPk
                ?.accountManagerEnabled && accountManager ? (
                <VerticalKeyValue
                  pair={{
                    key: 'Account Manager',
                    value: formatName(accountManager),
                  }}
                />
              ) : null}
              <VerticalKeyValue
                pair={{
                  key: 'Created On',
                  value: BzDateFns.formatFromISO(
                    accountCreatedAt,
                    'MMM d, yyyy',
                    tzId,
                  ),
                }}
              />
              <VerticalKeyValue
                pair={{
                  key: 'Lead Source',
                  value: leadSource ? (
                    <RichCompanyLeadSourceView
                      accountGuid={accountGuid}
                      leadSource={leadSource}
                    />
                  ) : (
                    <EmDash />
                  ),
                }}
              />
            </div>
            {mailingAddress && (
              <Row>
                <Col span={24}>
                  <div className="mt-5">
                    <h4 className="gray9">Billing Address</h4>
                    <AddressMultiLineView
                      address={BzAddress.create(mailingAddress)}
                      labelClassName="semibold_14_22"
                    />
                  </div>
                </Col>
              </Row>
            )}
            <div className="flex flex-col space-y-3">
              <span className="font-semibold text-bz-gray-900">Tags</span>
              <TagList tags={tags} spacingY={2} />
            </div>
            <Divider />
            <div className="mt-4">
              <AccountContactsCollapsible
                accountGuid={accountGuid}
                accountContacts={accountContacts}
                editable={editable}
                refetch={refetch}
              />
            </div>
            <div className="mt-3">
              <AccountLocationsCollapsible
                accountGuid={accountGuid}
                accountLocations={accountLocations}
                editable={editable}
                refetch={refetch}
                onBillingAddressSet={onBillingAddressSet}
              />
            </div>
            <CardOnFileCollapsibleLoader
              accountGuid={accountGuid}
              editable={editable}
            />
            <InstalledEquipmentCollapsible
              installedEquipment={installedEquipment}
              onEdit={setInstalledEquipmentMutState}
              onAdd={() => setInstalledEquipmentMutState('create new')}
              editable={editable}
            />
            <InstalledHvacSystemsCollapsible
              installedHvacSystems={hvacSystems}
              onEditInstalledHvacSystem={hvacSystem =>
                setHvacSystemMutState(hvacSystem)
              }
              onEditInstalledEquipment={setInstalledEquipmentMutState}
              onAddInstalledHvacSystem={() =>
                setHvacSystemMutState('create new')
              }
              editable={editable}
            />
          </BzColumn>
        </TagColumn>
        <EditAccountDrawer
          account={account}
          open={editAccountDrawerOpen}
          onClose={stopEditAccount}
          refetch={refetch}
        />
        {serviceHistoryModalOpen && (
          <ServiceHistoryModal
            locationGuid={
              account.accountLocations[0]?.location.locationGuid ?? ''
            }
            onClose={closeServiceHistoryModal}
          />
        )}

        {installedEquipmentMutState === 'create new' && (
          <EquipmentUpsertDrawer
            mode="create-for-account"
            availableLocations={R.pluck('location', accountLocations)}
            isOpen={!!installedEquipmentMutState}
            onCancel={closeEquipmentEdit}
            onMutate={onEquipmentEdit}
          />
        )}

        {installedEquipmentMutState &&
          installedEquipmentMutState !== 'create new' && (
            <EquipmentUpsertDrawer
              mode="update"
              location={bzExpect(
                accountLocations
                  .map(al => al.location)
                  .find(
                    l =>
                      l.locationGuid ===
                      installedEquipmentMutState.locationGuid,
                  ),
              )}
              initialValues={installedEquipmentMutState}
              isOpen={!!installedEquipmentMutState}
              onCancel={closeEquipmentEdit}
              onMutate={onEquipmentEdit}
            />
          )}

        {hvacSystemMutState === 'create new' && (
          <InstalledHvacSystemUpsertDrawer
            mode="create-for-account"
            availableLocations={accountLocations.map(al => al.location)}
            isOpen={!!hvacSystemMutState}
            onCancel={closeHvacSystemEdit}
            onMutate={onHvacSystemEdit}
          />
        )}

        {hvacSystemMutState && hvacSystemMutState !== 'create new' && (
          <InstalledHvacSystemUpsertDrawer
            mode="update"
            location={bzExpect(
              accountLocations
                .map(al => al.location)
                .find(l => l.locationGuid === hvacSystemMutState.locationGuid),
            )}
            initialValues={hvacSystemMutState}
            isOpen={!!hvacSystemMutState}
            onCancel={closeHvacSystemEdit}
            onMutate={onHvacSystemEdit}
          />
        )}
        {financingWizard}
        <ArchiveAccountModal {...closeConfirmProps} archived={archived} />
      </>
    )
  },
)

export default AccountDetailsPageAuthWrapper

import classNames from 'classnames'
import React from 'react'

type LabeledItemGridProps = {
  items: React.ReactNode[]
  // If this is true, then the widths of labels/values will work like "columns", where the longest label determines the
  // width of all the labels, etc.
  asGrid?: boolean
  labelNoWrap?: boolean
}

export const LabeledItemGrid = React.memo<LabeledItemGridProps>(
  ({ items, asGrid, labelNoWrap }) => {
    if (asGrid) {
      return (
        <div className="grid grid-cols-[1fr_auto] gap-1.5">
          {items.map((content, i) => (
            <div
              key={i}
              className={classNames(
                i % 2
                  ? 'truncate text-right'
                  : ['font-semibold', { 'whitespace-nowrap': labelNoWrap }],
              )}
            >
              {content}
            </div>
          ))}
        </div>
      )
    }
    const rows: React.ReactNode[] = []
    for (let i = 0; i < items.length; i += 2) {
      rows.push(
        <div key={i} className="mb-1.5 flex flex-row items-start">
          <div
            className={classNames('font-semibold', {
              'whitespace-nowrap': labelNoWrap,
            })}
          >
            {items[i]}
          </div>
          {i < items.length + 1 ? (
            <div className="ml-1.5 min-w-0 flex-1 truncate text-right">
              {items[i + 1]}
            </div>
          ) : undefined}
        </div>,
      )
    }

    return <div>{rows}</div>
  },
)

import { BzDateFns, BzDuration, IsoDateString } from '@breezy/shared'
import {
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faPhoneMissed,
  faVoicemail,
} from '@fortawesome/pro-solid-svg-icons'
import { Typography } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import FaIconWithCircularBackground from '../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import {
  useExpectedCompanyTimeZoneId,
  useExpectedUserGuid,
} from '../../providers/PrincipalUser'
import { CommAvatar } from './CommAvatar'
import { ActionMenuEllipsis } from './CommConvoActionMenu'
import { RecentPhoneCall } from './CommsPage.gql'
import {
  getAvatarText,
  getBreezyFormatExternalPhoneNumber,
  getDisplayNameShortFormat,
  getFormattedDate,
  getUserAvatarText,
} from './CommUtils'

type ConvoJobLinkProps = {
  jobGuid: string
  jobDisplayId: string
}

type ConvoElementProps = {
  call: RecentPhoneCall
}

type ConvoShellDataProps = {
  startedAt: IsoDateString
  avatarText: string

  integratedPhoneCallGuid: string
  accountGuid?: string
  linkedJob?: ConvoJobLinkProps
}

export const ConvoElement: React.FC<ConvoElementProps> = ({ call }) => {
  const userGuid = useExpectedUserGuid()
  const avatarText = getAvatarText(call.contact)
  const phoneNumber = getBreezyFormatExternalPhoneNumber(call)
  const displayName = getDisplayNameShortFormat(call.contact, phoneNumber)
  const userAvatarText = getUserAvatarText(call.user)
  const linkedJob = useMemo(
    () =>
      call.integratedPhoneCallJobs[0]
        ? {
            jobGuid: call.integratedPhoneCallJobs[0].jobGuid,
            jobDisplayId:
              call.integratedPhoneCallJobs[0].job.displayId.toString(),
          }
        : undefined,
    [call.integratedPhoneCallJobs],
  )

  const integratedPhoneCallGuid = call.integratedPhoneCallGuid
  const accountGuid = call.accountGuid

  const userOrYouDisplayName =
    call.user?.userGuid === userGuid ? 'You' : call.user?.firstName ?? 'You'

  const userOrYouLongDisplayName =
    call.user?.userGuid === userGuid
      ? 'You'
      : `${call.user?.firstName} ${call.user?.lastName}`

  if (call.direction === 'inbound' && !call.endedAt) {
    return (
      <ConvoInboundInProgressCallElement
        startedAt={call.startedAt}
        avatarText={avatarText}
        contactDisplayName={displayName}
        userDisplayName={userOrYouDisplayName}
        linkedJob={linkedJob}
        integratedPhoneCallGuid={integratedPhoneCallGuid}
        accountGuid={accountGuid}
      />
    )
  } else if (
    call.direction === 'inbound' &&
    !call.integratedPhoneMissedCalls.length
  ) {
    return (
      <ConvoInboundAnsweredCallElement
        startedAt={call.startedAt}
        endedAt={call.endedAt}
        avatarText={avatarText}
        contactDisplayName={displayName}
        userDisplayName={userOrYouDisplayName}
        recordingUrl={call.integratedPhoneCallRecording?.recordingUrl}
        transcript={call.integratedPhoneCallRecording?.recordingTranscript}
        recordingSummary={call.integratedPhoneCallRecording?.recordingSummary}
        linkedJob={linkedJob}
        integratedPhoneCallGuid={integratedPhoneCallGuid}
        accountGuid={accountGuid}
      />
    )
  } else if (
    call.direction === 'inbound' &&
    call.integratedPhoneMissedCalls.length &&
    call.integratedPhoneMissedCalls[0].integratedPhoneVoicemails.length
  ) {
    const voicemail =
      call.integratedPhoneMissedCalls[0].integratedPhoneVoicemails[0]
    return (
      <ConvoInboundVoicemailElement
        startedAt={call.startedAt}
        avatarText={avatarText}
        displayName={displayName}
        recordingUrl={voicemail.recordingUrl}
        transcript={voicemail.recordingTranscript}
        recordingSummary={voicemail.recordingSummary}
        linkedJob={linkedJob}
        integratedPhoneCallGuid={integratedPhoneCallGuid}
        accountGuid={accountGuid}
      />
    )
  } else if (
    call.direction === 'inbound' &&
    call.integratedPhoneMissedCalls.length
  ) {
    return (
      <ConvoInboundMissedCallElement
        startedAt={call.startedAt}
        avatarText={avatarText}
        contactDisplayName={displayName}
        linkedJob={linkedJob}
        integratedPhoneCallGuid={integratedPhoneCallGuid}
        accountGuid={accountGuid}
      />
    )
  } else if (call.direction === 'outbound' && !call.endedAt) {
    return (
      <ConvoOutboundInProgressCallElement
        startedAt={call.startedAt}
        avatarText={userAvatarText}
        userDisplayName={userOrYouDisplayName}
        userLongDisplayName={userOrYouLongDisplayName}
        linkedJob={linkedJob}
        integratedPhoneCallGuid={integratedPhoneCallGuid}
        accountGuid={accountGuid}
      />
    )
  } else if (call.direction === 'outbound' && !call.answeredAt) {
    return (
      <ConvoOutboundMissedCallElement
        startedAt={call.startedAt}
        avatarText={userAvatarText}
        userDisplayName={userOrYouDisplayName}
        userLongDisplayName={userOrYouLongDisplayName}
        recordingUrl={call.integratedPhoneCallRecording?.recordingUrl}
        transcript={call.integratedPhoneCallRecording?.recordingTranscript}
        recordingSummary={call.integratedPhoneCallRecording?.recordingSummary}
        linkedJob={linkedJob}
        integratedPhoneCallGuid={integratedPhoneCallGuid}
        accountGuid={accountGuid}
      />
    )
  } else if (call.direction === 'outbound') {
    return (
      <ConvoOutboundAnsweredCallElement
        startedAt={call.startedAt}
        endedAt={call.endedAt}
        avatarText={userAvatarText}
        userDisplayName={userOrYouDisplayName}
        userLongDisplayName={userOrYouLongDisplayName}
        recordingUrl={call.integratedPhoneCallRecording?.recordingUrl}
        transcript={call.integratedPhoneCallRecording?.recordingTranscript}
        recordingSummary={call.integratedPhoneCallRecording?.recordingSummary}
        linkedJob={linkedJob}
        integratedPhoneCallGuid={integratedPhoneCallGuid}
        accountGuid={accountGuid}
      />
    )
  }

  return (
    <div className="h-[38px] text-gray-500">
      ... Unknown Element Type - Please notify the Breezy support team if you
      ever see this...
    </div>
  )
}

type ConvoInboundAnsweredCallElementProps = ConvoShellDataProps & {
  contactDisplayName: string
  endedAt?: IsoDateString
  recordingUrl?: string
  transcript?: string
  recordingSummary?: string
  userDisplayName: string
}

export const ConvoInboundAnsweredCallElement: React.FC<
  ConvoInboundAnsweredCallElementProps
> = ({
  startedAt,
  endedAt,
  avatarText,
  recordingUrl,
  transcript,
  recordingSummary,
  contactDisplayName,
  userDisplayName,
  linkedJob,
  integratedPhoneCallGuid,
  accountGuid,
}) => {
  const now = new Date()
  const endTime = endedAt ? new Date(endedAt) : now
  const startTime = new Date(startedAt)
  const durationInMilliseconds = endTime.getTime() - startTime.getTime()
  const duration = new BzDuration(durationInMilliseconds).formatMSS()

  return (
    <ConvoShellInbound
      startedAt={startedAt}
      avatarText={avatarText}
      contactDisplayName={contactDisplayName}
      linkedJob={linkedJob}
      integratedPhoneCallGuid={integratedPhoneCallGuid}
    >
      <div className="flex max-w-[400px] flex-col gap-y-3">
        <div className="flex items-center justify-between gap-x-2 text-sm text-gray-600">
          <div className="flex items-center gap-x-2">
            <FaIconWithCircularBackground
              iconDefinition={faPhoneArrowDownLeft}
              backgroundColor="#8d8d8f"
              diameterPx={36}
              color="white"
              noRightMargin
            />
            <div>
              <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
                Call ended
              </div>
              <div className="flex flex-row pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
                {userDisplayName} answered •{' '}
                <DurationText duration={duration} />
              </div>
            </div>
          </div>
          <ActionMenuEllipsis
            integratedPhoneCallGuid={integratedPhoneCallGuid}
            jobGuid={linkedJob?.jobGuid}
            accountGuid={accountGuid}
          />
        </div>
        <TranscriptSummaryAndRecording
          recordingSummary={recordingSummary}
          transcript={transcript}
          recordingUrl={recordingUrl}
          className="w-[400px]"
        />
      </div>
    </ConvoShellInbound>
  )
}

type ConvoOutboundAnsweredCallElementProps = ConvoShellDataProps & {
  userDisplayName: string
  userLongDisplayName: string
  endedAt?: IsoDateString
  recordingUrl?: string
  transcript?: string
  recordingSummary?: string
}

export const ConvoOutboundAnsweredCallElement: React.FC<
  ConvoOutboundAnsweredCallElementProps
> = ({
  startedAt,
  endedAt,
  avatarText,
  userDisplayName,
  userLongDisplayName,
  recordingUrl,
  transcript,
  recordingSummary,
  linkedJob,
  integratedPhoneCallGuid,
  accountGuid,
}) => {
  const now = new Date()
  const endTime = endedAt ? new Date(endedAt) : now
  const startTime = new Date(startedAt)
  const durationInMilliseconds = endTime.getTime() - startTime.getTime()
  const duration = new BzDuration(durationInMilliseconds).formatMSS()

  return (
    <ConvoShellOutbound
      startedAt={startedAt}
      avatarText={avatarText}
      userLongDisplayName={userLongDisplayName}
      linkedJob={linkedJob}
      integratedPhoneCallGuid={integratedPhoneCallGuid}
    >
      <div className="flex max-w-[400px] flex-col gap-y-3">
        <div className="flex items-center justify-between gap-x-2 text-sm text-gray-600">
          <div className="flex items-center gap-x-2">
            <FaIconWithCircularBackground
              iconDefinition={faPhoneArrowUpRight}
              backgroundColor="#1677ff"
              diameterPx={36}
              color="white"
              noRightMargin
            />
            <div>
              <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
                Call ended
              </div>
              <div className="mr-1 flex flex-row pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
                {userDisplayName} called • <DurationText duration={duration} />
              </div>
            </div>
          </div>
          <ActionMenuEllipsis
            integratedPhoneCallGuid={integratedPhoneCallGuid}
            jobGuid={linkedJob?.jobGuid}
            accountGuid={accountGuid}
          />
        </div>
        <TranscriptSummaryAndRecording
          recordingSummary={recordingSummary}
          transcript={transcript}
          recordingUrl={recordingUrl}
          className="w-[400px]"
        />
      </div>
    </ConvoShellOutbound>
  )
}

type ConvoInboundMissedCallElementProps = ConvoShellDataProps & {
  contactDisplayName: string
}

export const ConvoInboundMissedCallElement: React.FC<
  ConvoInboundMissedCallElementProps
> = ({
  startedAt,
  avatarText,
  contactDisplayName,
  linkedJob,
  integratedPhoneCallGuid,
  accountGuid,
}) => {
  return (
    <ConvoShellInbound
      startedAt={startedAt}
      avatarText={avatarText}
      contactDisplayName={contactDisplayName}
      linkedJob={linkedJob}
      integratedPhoneCallGuid={integratedPhoneCallGuid}
    >
      <div className="flex items-center justify-between gap-x-2 text-sm text-gray-600">
        <div className="flex flex-row items-center gap-x-2">
          <FaIconWithCircularBackground
            iconDefinition={faPhoneMissed}
            backgroundColor="#F5222D"
            diameterPx={36}
            color="white"
            noRightMargin
          />
          <div>
            <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
              Missed call
            </div>
            <div className="pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
              You didn't answer
            </div>
          </div>
        </div>
        <ActionMenuEllipsis
          integratedPhoneCallGuid={integratedPhoneCallGuid}
          jobGuid={linkedJob?.jobGuid}
          accountGuid={accountGuid}
        />
      </div>
    </ConvoShellInbound>
  )
}

type ConvoOutboundMissedCallElementProps = ConvoShellDataProps & {
  userDisplayName: string
  userLongDisplayName: string
  recordingUrl?: string
  transcript?: string
  recordingSummary?: string
}

export const ConvoOutboundMissedCallElement: React.FC<
  ConvoOutboundMissedCallElementProps
> = ({
  startedAt,
  avatarText,
  userDisplayName,
  userLongDisplayName,
  recordingUrl,
  transcript,
  recordingSummary,
  linkedJob,
  integratedPhoneCallGuid,
  accountGuid,
}) => {
  return (
    <ConvoShellOutbound
      startedAt={startedAt}
      avatarText={avatarText}
      userLongDisplayName={userLongDisplayName}
      linkedJob={linkedJob}
      integratedPhoneCallGuid={integratedPhoneCallGuid}
    >
      <div className="flex max-w-[400px] flex-col gap-y-3">
        <div className="flex items-center justify-between gap-x-2 text-sm text-gray-600">
          <div className="flex items-center gap-x-2">
            <FaIconWithCircularBackground
              iconDefinition={faPhoneMissed}
              backgroundColor="#F5222D"
              diameterPx={36}
              color="white"
              noRightMargin
            />
            <div>
              <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
                Missed call
              </div>
              <div className="pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
                {userDisplayName} called, no answer
              </div>
            </div>
          </div>
          <ActionMenuEllipsis
            integratedPhoneCallGuid={integratedPhoneCallGuid}
            jobGuid={linkedJob?.jobGuid}
            accountGuid={accountGuid}
          />
        </div>
        <TranscriptSummaryAndRecording
          recordingSummary={recordingSummary}
          transcript={transcript}
          recordingUrl={recordingUrl}
          className="w-[400px]"
        />
      </div>
    </ConvoShellOutbound>
  )
}

type ConvoInboundVoicemailElementProps = ConvoShellDataProps & {
  displayName: string
  recordingUrl: string
  transcript?: string
  recordingSummary?: string
}

export const ConvoInboundVoicemailElement: React.FC<
  ConvoInboundVoicemailElementProps
> = ({
  startedAt,
  avatarText,
  displayName,
  recordingUrl,
  transcript,
  recordingSummary,
  linkedJob,
  integratedPhoneCallGuid,
  accountGuid,
}) => {
  return (
    <ConvoShellInbound
      startedAt={startedAt}
      avatarText={avatarText}
      contactDisplayName={displayName}
      linkedJob={linkedJob}
      integratedPhoneCallGuid={integratedPhoneCallGuid}
    >
      <div className="flex max-w-[400px] flex-col gap-y-3">
        <div className="flex items-center justify-between gap-x-2 text-sm text-gray-600">
          <div className="flex items-center gap-x-2">
            <FaIconWithCircularBackground
              iconDefinition={faVoicemail}
              backgroundColor="#F5222D"
              diameterPx={36}
              color="white"
              noRightMargin
            />
            <div>
              <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
                Missed call
              </div>
              <div className="pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
                You didn't answer
              </div>
            </div>
          </div>
          <ActionMenuEllipsis
            integratedPhoneCallGuid={integratedPhoneCallGuid}
            jobGuid={linkedJob?.jobGuid}
            accountGuid={accountGuid}
          />
        </div>
        {recordingSummary && (
          <div className="w-[400px]">
            <div className="text-xs font-semibold leading-5 text-[#1F1F1F]">
              Voicemail Summary
            </div>
            <div className="mt-1 text-[13px] font-normal leading-[18px] text-[#595959]">
              {recordingSummary}
            </div>
          </div>
        )}
        {transcript && (
          <div className="w-[400px]">
            <div className="text-xs font-semibold leading-5 text-[#1F1F1F]">
              Voicemail Transcript
            </div>
            <div className="mt-1 text-[13px] font-normal leading-[18px] text-[#595959]">
              {transcript}
            </div>
          </div>
        )}
        {recordingUrl && (
          <audio controls className="h-10 w-full min-w-[320px] rounded-md">
            <source src={recordingUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
      </div>
    </ConvoShellInbound>
  )
}

type ConvoInboundInProgressCallElementProps = ConvoShellDataProps & {
  contactDisplayName: string
  userDisplayName: string
}

export const ConvoInboundInProgressCallElement: React.FC<
  ConvoInboundInProgressCallElementProps
> = ({
  startedAt,
  avatarText,
  contactDisplayName,
  userDisplayName,
  linkedJob,
  integratedPhoneCallGuid,
  accountGuid,
}) => {
  const [duration, setDuration] = useState('00:00')

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date()
      const elapsedSeconds = Math.floor(
        (now.getTime() - new Date(startedAt).getTime()) / 1000,
      )
      setDuration(formatDuration(elapsedSeconds))
    }, 1000)

    return () => clearInterval(timer)
  }, [startedAt])

  return (
    <ConvoShellInbound
      startedAt={startedAt}
      avatarText={avatarText}
      contactDisplayName={contactDisplayName}
      linkedJob={linkedJob}
      integratedPhoneCallGuid={integratedPhoneCallGuid}
    >
      <div className="flex items-center justify-between gap-x-2 text-sm text-gray-600">
        <div className="flex items-center gap-x-2">
          <FaIconWithCircularBackground
            iconDefinition={faPhoneArrowDownLeft}
            backgroundColor="#52c41a"
            diameterPx={36}
            color="white"
            noRightMargin
          />
          <div>
            <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
              Call in progress
            </div>
            <div className="flex flex-row pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
              {userDisplayName} answered • <DurationText duration={duration} />
            </div>
          </div>
        </div>
        <ActionMenuEllipsis
          integratedPhoneCallGuid={integratedPhoneCallGuid}
          jobGuid={linkedJob?.jobGuid}
          accountGuid={accountGuid}
        />
      </div>
    </ConvoShellInbound>
  )
}

type ConvoOutboundInProgressCallElementProps = ConvoShellDataProps & {
  userDisplayName: string
  userLongDisplayName: string
}

export const ConvoOutboundInProgressCallElement: React.FC<
  ConvoOutboundInProgressCallElementProps
> = ({
  startedAt,
  avatarText,
  userDisplayName,
  userLongDisplayName,
  linkedJob,
  integratedPhoneCallGuid,
  accountGuid,
}) => {
  const [duration, setDuration] = useState('00:00')

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date()
      const elapsedSeconds = Math.floor(
        (now.getTime() - new Date(startedAt).getTime()) / 1000,
      )
      setDuration(formatDuration(elapsedSeconds))
    }, 1000)

    return () => clearInterval(timer)
  }, [startedAt])

  return (
    <ConvoShellOutbound
      startedAt={startedAt}
      avatarText={avatarText}
      userLongDisplayName={userLongDisplayName}
      linkedJob={linkedJob}
      integratedPhoneCallGuid={integratedPhoneCallGuid}
    >
      <div className="flex items-center justify-between gap-x-2 text-sm text-gray-600">
        <div className="flex items-center gap-x-2">
          <FaIconWithCircularBackground
            iconDefinition={faPhoneArrowUpRight}
            backgroundColor="#52c41a"
            diameterPx={36}
            color="white"
            noRightMargin
          />
          <div>
            <div className="text-sm font-semibold leading-5 text-[#1F1F1F]">
              Call in progress
            </div>
            <div className="flex flex-row pr-1 text-[13px] font-normal leading-[16px] text-[#595959]">
              {userDisplayName} {userDisplayName === 'You' ? 'are' : 'is'}{' '}
              calling • <DurationText duration={duration} />
            </div>
          </div>
        </div>
        <ActionMenuEllipsis
          integratedPhoneCallGuid={integratedPhoneCallGuid}
          jobGuid={linkedJob?.jobGuid}
          accountGuid={accountGuid}
        />
      </div>
    </ConvoShellOutbound>
  )
}

const formatDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`
}

type ConvoShellInboundProps = ConvoShellDataProps & {
  contactDisplayName: string
  children: React.ReactNode
}

const useFormattedDate = (startedAt: IsoDateString): string => {
  const tz = useExpectedCompanyTimeZoneId()

  return useMemo(() => {
    const today = BzDateFns.now(tz)
    const startDate = BzDateFns.parseISO(startedAt, tz)
    return getFormattedDate(startDate, today)
  }, [startedAt, tz])
}

export const ConvoShellInbound: React.FC<ConvoShellInboundProps> = ({
  startedAt,
  children,
  avatarText,
  contactDisplayName,
  linkedJob,
  accountGuid,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const formattedDate = useFormattedDate(startedAt)

  return (
    <div
      className="flex flex-col gap-y-6"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-full text-center text-xs font-normal leading-5 text-[#595959]">
        {formattedDate}
      </div>
      <div className="flex h-full">
        <div className="group relative flex items-end gap-x-2">
          <CommAvatar avatarText={avatarText} />
          <div className="flex-1">
            <div className="flex flex-row justify-between">
              <div className="mb-[2px] pl-[10px] text-xs font-normal leading-5 text-[#595959]">
                {contactDisplayName}
              </div>
            </div>
            <div
              className={`rounded-2xl p-[10px] ${
                isHovered ? 'bg-[#F0F0F0]' : 'bg-[#F5F5F5]'
              }`}
            >
              {children}
            </div>
          </div>
        </div>
        {isHovered && <HoveredTime startedAt={startedAt} />}
      </div>
      {linkedJob && <JobLinkRow linkedJob={linkedJob} />}
    </div>
  )
}

type ConvoShellOutboundProps = ConvoShellDataProps & {
  userLongDisplayName: string
  children: React.ReactNode
}

export const ConvoShellOutbound: React.FC<ConvoShellOutboundProps> = ({
  startedAt,
  children,
  avatarText,
  userLongDisplayName,
  linkedJob,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const formattedDate = useFormattedDate(startedAt)

  return (
    <div
      className="flex flex-col space-y-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-full text-center text-xs font-normal leading-5 text-[#595959]">
        {formattedDate}
      </div>
      <div className="flex h-full items-end justify-end gap-x-2">
        {isHovered && <HoveredTime startedAt={startedAt} />}
        <div className="group relative flex items-end gap-x-2">
          <div className="flex-1">
            <div className="flex flex-row justify-between">
              <div className="mb-[2px] w-full pr-[10px] text-right text-xs font-normal leading-5 text-[#595959]">
                {userLongDisplayName}
              </div>
            </div>
            <div
              className={`rounded-2xl p-[10px] ${
                isHovered ? 'bg-[#b5daf8]' : 'bg-[#e6f4ff]'
              }`}
            >
              {children}
            </div>
          </div>
        </div>
        <CommAvatar avatarText={avatarText} useCompanyColors />
      </div>
      {linkedJob && <JobLinkRow linkedJob={linkedJob} />}
    </div>
  )
}

const JobLinkRow: React.FC<{ linkedJob: ConvoJobLinkProps }> = ({
  linkedJob,
}) => {
  return (
    <Link
      to={`/jobs/${linkedJob.jobGuid}`}
      className="w-full text-center text-xs font-normal leading-5 text-[#1677FF]"
    >
      View Linked Job #{linkedJob.jobDisplayId}
    </Link>
  )
}

type HoveredTimeProps = {
  startedAt: IsoDateString
}

const HoveredTime: React.FC<HoveredTimeProps> = ({ startedAt }) => {
  const tz = useExpectedCompanyTimeZoneId()
  const localDate = BzDateFns.parseISO(startedAt, tz)
  return (
    <div
      className={`mx-2 mb-3 mt-auto flex h-full items-end justify-end text-xs font-normal leading-5 text-[#595959]`}
    >
      {localDate.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      })}
    </div>
  )
}

const DurationText = ({ duration }: { duration: string }) => {
  return (
    <div className="w-[40px] text-[13px] font-normal leading-[16px] text-[#595959]">
      {duration}
    </div>
  )
}

const formatTranscript = (transcript: string | undefined) => {
  if (!transcript) return undefined

  return transcript
    .split('\n')
    .filter(line => line.trim() !== '')
    .map((line, index) => {
      if (line.startsWith('Agent:') || line.startsWith('Customer:')) {
        return `\n${line}`
      }
      return line
    })
    .join('\n')
    .replace(/\n{2,}/g, '\n')
}

export const TranscriptSummaryAndRecording = ({
  recordingSummary,
  transcript,
  recordingUrl,
  className,
}: {
  recordingSummary?: string
  transcript?: string
  recordingUrl?: string
  className?: string
}) => {
  const formattedTranscript = formatTranscript(transcript)
  return (
    <>
      {recordingSummary && (
        <div className={className}>
          <div className="text-xs font-semibold leading-5 text-[#1F1F1F]">
            Summary
          </div>
          <div className="mt-1 text-[13px] font-normal leading-[18px] text-[#595959]">
            {recordingSummary}
          </div>
        </div>
      )}
      {transcript && (
        <div className={className}>
          <div className="mt-2 text-[13px] font-normal leading-[18px] text-[#595959]">
            <Typography.Paragraph
              ellipsis={{
                rows: !recordingSummary ? 12 : 4,
                expandable: true,
                symbol: 'View More',
              }}
              className="mb-0 text-[13px] font-normal leading-[18px] text-[#595959]"
            >
              <div className="text-xs font-semibold leading-5 text-[#1F1F1F]">
                Transcript
              </div>
              {formattedTranscript &&
                formattedTranscript.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < formattedTranscript.split('\n').length - 1 && (
                      <div className="h-[4px]"></div>
                    )}
                  </React.Fragment>
                ))}
            </Typography.Paragraph>
          </div>
        </div>
      )}
      {recordingUrl && (
        <audio controls className="h-10 w-full min-w-[320px] rounded-md">
          <source src={recordingUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </>
  )
}

import { CompanyCreatorV2Input } from '@breezy/shared'
import { Input } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'

export const CompanyDetailsSectionCompanyCollapsible = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext<CompanyCreatorV2Input>()

  return (
    <div className="flex w-full flex-col gap-3">
      <Controller
        control={control}
        name="company.companyGuid"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Company Guid</span>
            <Input
              {...field}
              placeholder="Optionally provide a company guid to use for this company"
              status={errors.company?.companyGuid ? 'error' : undefined}
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="company.name"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Company Name</span>
            <Input
              {...field}
              placeholder="Enter company name here..."
              status={errors.company?.name ? 'error' : undefined}
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="company.timezone"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Time Zone</span>
            <Input
              {...field}
              placeholder="Enter time zone here..."
              status={errors.company?.timezone ? 'error' : undefined}
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="billingProfile.websiteUrl"
        render={({ field }) => (
          <label className="flex flex-1 flex-col gap-1">
            <span>Website URL</span>
            <Input
              {...field}
              placeholder="Enter website url here..."
              status={errors.billingProfile?.websiteUrl ? 'error' : undefined}
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="billingProfile.websiteDisplayName"
        render={({ field }) => (
          <label className="flex flex-1 flex-col gap-1">
            <span>Website Display Name</span>
            <Input
              {...field}
              placeholder="Enter website display name here..."
              status={
                errors.billingProfile?.websiteDisplayName ? 'error' : undefined
              }
            />
          </label>
        )}
      />

      <Controller
        control={control}
        name="billingProfile.logoUrl"
        render={({ field }) => (
          <label className="flex flex-1 flex-col gap-1">
            <span>Company Logo URL</span>
            <Input
              {...field}
              placeholder="Enter company logo url here..."
              status={errors.billingProfile?.logoUrl ? 'error' : undefined}
            />
          </label>
        )}
      />
    </div>
  )
}

import { PageHeader } from '@ant-design/pro-components'
import { bzOptional, HtmlString, htmlStringSchema } from '@breezy/shared'
import { faMessageDollar } from '@fortawesome/pro-regular-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Card, Form } from 'antd'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'urql'
import { z } from 'zod'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import {
  CategorySection,
  SubSection,
} from '../../components/Settings/SettingsBuilderComponents'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { RichTextAreaField } from '../../elements/Forms/RichTextAreaField'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { gql } from '../../generated/user'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useFetchJobRequestsCompanyConfig } from '../../hooks/useFetchJobRequestsCompanyConfig'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'

const DEFAULT_EMERGENCY_REQUESTS_MESSAGE_HTML = `<p><b>If you need emergency service</b> outside of our normal business hours, please call us at (917) 445-8974.</p>`

export const jobRequestsSettingsFormSchema = z.object({
  emergencyRequestsMessageHtml: bzOptional(htmlStringSchema).default(
    DEFAULT_EMERGENCY_REQUESTS_MESSAGE_HTML,
  ),
})

export type JobRequestsSettingsFormData = z.infer<
  typeof jobRequestsSettingsFormSchema
>

const UPSERT_COMPANY_CONFIG_JOB_REQUESTS_SETTINGS_MUTATION = gql(/* GraphQL */ `
  mutation UpsertCompanyConfigJobRequestsSettings(
    $object: JobRequestsCompanyConfigInsertInput!
    $onConflict: JobRequestsCompanyConfigOnConflict
  ) {
    insertJobRequestsCompanyConfigOne(
      object: $object
      onConflict: $onConflict
    ) {
      companyGuid
    }
  }
`)

export const JobRequestsSettingsPage = React.memo(() => {
  const companyGuid = useExpectedCompanyGuid()
  const jobRequestsFeatureFlag = useFeatureFlag('job-leads')

  const { jobRequestsSettingsQuery } =
    useFetchJobRequestsCompanyConfig(companyGuid)

  if (!jobRequestsFeatureFlag) {
    return (
      <div className="flex w-full min-w-[700px] flex-col">
        <h1>Coming Soon </h1>
      </div>
    )
  }

  return (
    <GqlQueryLoader
      query={jobRequestsSettingsQuery}
      render={data => (
        <JobRequestsSettingsPageInner
          emergencyRequestsMessageHtml={
            data?.jobRequestsCompanyConfigByPk?.emergencyRequestsMessageHtml
          }
        />
      )}
    />
  )
})

type JobRequestsSettingsPageProps = {
  emergencyRequestsMessageHtml?: HtmlString
}

const JobRequestsSettingsPageInner = React.memo(
  ({ emergencyRequestsMessageHtml }: JobRequestsSettingsPageProps) => {
    const companyGuid = useExpectedCompanyGuid()
    const message = useMessage()
    const {
      formState: { errors, isDirty },
      control,
      handleSubmit,
      reset,
    } = useForm<JobRequestsSettingsFormData>({
      resolver: zodResolver(jobRequestsSettingsFormSchema),
      defaultValues: {
        emergencyRequestsMessageHtml:
          emergencyRequestsMessageHtml ??
          DEFAULT_EMERGENCY_REQUESTS_MESSAGE_HTML,
      },
    })

    const [{ fetching: submitting }, executeMutation] = useMutation(
      UPSERT_COMPANY_CONFIG_JOB_REQUESTS_SETTINGS_MUTATION,
    )

    useEffect(() => {
      // This hook resets the form if the job requests settings change
      reset({
        emergencyRequestsMessageHtml,
      })
    }, [emergencyRequestsMessageHtml, reset])

    const onSubmit = handleSubmit(async data => {
      try {
        await executeMutation({
          object: {
            companyGuid,
            emergencyRequestsMessageHtml: data.emergencyRequestsMessageHtml,
          },
          onConflict: {
            constraint: 'job_requests_company_config_pkey',
            updateColumns: ['emergencyRequestsMessageHtml'],
          },
        })

        message.success('Job Requests settings updated')
      } catch (e) {
        message.error('Failed to update Job Requests settings')
      }
    })

    return (
      <>
        <div className="flex w-full min-w-[700px] flex-col">
          <PageHeader
            title={
              <PageTitle
                title={
                  <div className="flex items-center space-x-2">
                    <span>Job Requests</span>
                  </div>
                }
                icon={faMessageDollar}
              />
            }
          />

          <Card className="min-h-0 w-full overflow-auto p-2">
            <Form onSubmitCapture={onSubmit} layout="vertical">
              <div className="flex flex-row items-start justify-between border-0 border-b border-solid border-b-bz-gray-400 pb-4">
                <div className="max-w-[768px] text-bz-gray-900">
                  Job Requests are generated through various mediums outside of
                  Breezy and the primary purpose is to capture leads that can be
                  later converted to jobs.
                </div>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={!isDirty}
                >
                  Save Changes
                </Button>
              </div>
              <div className="divide-y divide-solid divide-bz-gray-400">
                <CategorySection
                  title="Web Form"
                  subtitle="This web form is used to collect job requests from your company website."
                  layout={{ leftContainerFlex: 1, rightContainerFlex: 2 }}
                >
                  <SubSection
                    title="Emergency Requests Message"
                    subtitle="This message is displayed to customers when they finish filling out the web job request form and gives them information for how to get in touch with you if it's an emergency."
                  >
                    <ReactHookFormItem
                      control={control}
                      name="emergencyRequestsMessageHtml"
                      errors={errors}
                      render={({ field }) => (
                        <RichTextAreaField disabled={submitting} {...field} />
                      )}
                    />
                  </SubSection>
                </CategorySection>
              </div>
            </Form>
          </Card>
        </div>
      </>
    )
  },
)

import { Alert } from 'antd'
import { m } from '../../utils/react-utils'

type BzTitleAndExplanationProps = {
  title: string
  description: string
  attentionType?: 'success' | 'info' | 'warning' | 'error'
  titleClassName?: string
  className?: string
}

export const BzTitleAndExplanation = m<BzTitleAndExplanationProps>(
  ({ title, description, attentionType, titleClassName, className }) => (
    <div className={`column mr-6 ${className ?? ''}`}>
      <h3 className={`grey9 ${titleClassName ?? ''}`}>{title}</h3>
      {attentionType && (
        <Alert message={description} type={attentionType} className="mb-4" />
      )}
      {!attentionType && (
        <p className="regular_14_22 grey8 max-w-[700px] font-light">
          {description}
        </p>
      )}
    </div>
  ),
)

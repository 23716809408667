import { BzDateFns } from '@breezy/shared'
import { MbscCalendarDayData } from '@mobiscroll/react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { useWeatherForecast } from '../../apis/weather'
import {
  useExpectedCompany,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { useStrictContext } from '../../utils/react-utils'
import { SchedulePageContext } from './scheduleUtils'

const getDayOfWeekName = (date: Date) =>
  ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]

export const useRenderDay = () => {
  const { zipCode } = useExpectedCompany()
  const tzId = useExpectedCompanyTimeZoneId()
  const { scheduleView, selectedDate } = useStrictContext(SchedulePageContext)

  const selectedLocalDate = useMemo(
    () => BzDateFns.formatLocalDate(BzDateFns.parseISO(selectedDate, tzId)),
    [selectedDate, tzId],
  )

  const [forecastDays] = useWeatherForecast(selectedLocalDate, zipCode, 7)
  const showWeather = useMemo(
    () => zipCode && scheduleView === 'ONE_WEEK',
    [scheduleView, zipCode],
  )

  const renderWeatherDay = useCallback(
    (d: MbscCalendarDayData) => {
      const localDate = BzDateFns.formatLocalDate(d.date)
      const day = forecastDays[localDate]

      const dayOfWeekName = getDayOfWeekName(d.date)
      const dayNumber = d.date.getDate()
      const isCurrentDay = new Date().getDate() === d.date.getDate()
      const forecastLink = `https://www.weatherapi.com/weather/q/${zipCode}`

      return (
        <div className="mbsc-schedule-header-item mbsc-flex-1-0 mbsc-schedule-col-width mbsc-windows mbsc-ltr mbsc-schedule-header-item-large mb-0 w-full">
          <div
            className={classNames(
              'mbsc-windows mbsc-ltr mr-[-1px] flex flex-col gap-y-1 border-0 border-r border-t border-solid border-[#e6e6e6] px-2 py-2 pb-0',
              {
                today: isCurrentDay,
              },
            )}
          >
            <div
              className={classNames(
                'center-children-vh flex flex-row justify-between',
                {
                  'text-[#0078d7]': isCurrentDay,
                },
              )}
            >
              <div>{dayOfWeekName.slice(0, 3)}</div>
              <div>{dayNumber}</div>
            </div>
            {(day && (
              <a href={forecastLink} target="_blank" rel="noreferrer">
                <div className="row center-children-vh flex w-full justify-between">
                  <div className="h-6 max-h-6 w-6 max-w-6">
                    {day && (
                      <Tooltip title={day.weatherCondition}>
                        <img src={day.weatherIconSrc} alt="weather" />
                      </Tooltip>
                    )}
                  </div>
                  <div className="flex flex-row justify-between">
                    <div className="text-sm text-black">
                      {day.highTempF.toFixed(0)}°
                    </div>
                    <div className="ml-2 text-sm text-gray-400">
                      {day.lowTempF.toFixed(0)}°
                    </div>
                  </div>
                </div>
              </a>
            )) || <div className="min-h-6 w-full" />}
          </div>
        </div>
      )
    },
    [forecastDays, zipCode],
  )

  const renderDay = useMemo(() => {
    return showWeather ? renderWeatherDay : undefined
  }, [showWeather, renderWeatherDay])
  return renderDay
}

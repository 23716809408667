import {
  EquipmentDisplayNames,
  JobLeadEstimateTypeDisplayNames,
  phoneUtils,
} from '@breezy/shared'
import CopyToClipboard, {
  CopyLabel,
} from '../../elements/CopyToClipboard/CopyToClipboard'
import { JobAvailabilitiesView } from './JobLeadAvailabilityView'
import { NotCapturedView } from './NotCapturedView'
import { JobLead } from './types'
import { jobLeadsHeader } from './utils'

type JobLeadContextViewProps = {
  jobLead: JobLead
}

export const JobLeadContextView = ({ jobLead }: JobLeadContextViewProps) => {
  return (
    <div className="overflow-hidden">
      <div className="w-full rounded-lg border border-solid border-bz-gray-500 bg-bz-gray-200 p-3 text-base">
        <div className="text-md w-full font-semibold">
          {jobLeadsHeader(jobLead)}
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-2">
            <div className="mt-3 text-sm font-semibold">Summary</div>
            <div className="mt-1 text-sm">
              {jobLead.jobSummary ?? <NotCapturedView />}
            </div>
          </div>
          <div className="col-span-2">
            <div className="text-sm font-semibold">Availability</div>
            <div className="mt-1 text-sm">
              <JobAvailabilitiesView jobLead={jobLead} withGap />
            </div>
          </div>
          <div className="col-span-2">
            <div className="text-sm font-semibold">Address</div>
            <div className="mt-1 text-sm">
              {!jobLead.serviceAddressLine1 &&
              !jobLead.serviceAddressCity &&
              !jobLead.serviceAddressZipCode ? (
                <NotCapturedView />
              ) : (
                <div className="flex flex-col space-y-1">
                  {jobLead.serviceAddressLine1 && (
                    <div>
                      {jobLead.serviceAddressLine1}
                      {jobLead.serviceAddressLine2
                        ? ` ${jobLead.serviceAddressLine2}`
                        : ' '}
                      {(jobLead.serviceAddressCity ||
                        jobLead.serviceAddressZipCode) &&
                        [
                          jobLead.serviceAddressCity,
                          jobLead.serviceAddressStateAbbreviation,
                          jobLead.serviceAddressZipCode,
                        ]
                          .filter(Boolean)
                          .join(', ')}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {jobLead.equipmentTypes?.length ? (
            <div>
              <div className="text-sm font-semibold">Equipment</div>
              <div className="mt-1 break-words text-sm">
                {jobLead.equipmentTypes
                  .map(equipmentType => EquipmentDisplayNames[equipmentType])
                  .join(', ')}
              </div>
            </div>
          ) : null}
          {jobLead.estimateType && (
            <div>
              <div className="text-sm font-semibold">Estimate Type</div>
              <div className="mt-1 break-words text-sm">
                {JobLeadEstimateTypeDisplayNames[jobLead.estimateType]}
              </div>
            </div>
          )}
          <div className="col-span-2">
            <div className="text-sm font-semibold">Contact</div>
            <div className="mt-1 break-words text-sm">
              {!jobLead.contactEmailAddress && !jobLead.contactPhoneNumber && (
                <NotCapturedView />
              )}
              <div className="flex flex-col space-y-1">
                {jobLead.contactPhoneNumber && (
                  <CopyToClipboard
                    payload={phoneUtils.tryFormat(jobLead.contactPhoneNumber)}
                    label={
                      <CopyLabel
                        label={phoneUtils.tryFormat(jobLead.contactPhoneNumber)}
                      />
                    }
                  />
                )}
                {jobLead.contactEmailAddress && (
                  <CopyToClipboard
                    payload={jobLead.contactEmailAddress}
                    label={<CopyLabel label={jobLead.contactEmailAddress} />}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { LinkedNoteViewModel, nextGuid, NoteLinkData, R } from '@breezy/shared'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spin } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { OnsiteConfirmModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import BzSelect from '../../../elements/BzSelect/BzSelect'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'
import { WysiwygEditor } from '../../WysiwygEditor/WysiwygEditor'

type NoteEditModalProps = {
  header: string
  note?: LinkedNoteViewModel
  onMutate?: () => void
  onCancel: () => void
  linkData: NoteLinkData
}

export const NoteEditModal = React.memo<NoteEditModalProps>(
  ({ header, note, onMutate, onCancel, linkData }) => {
    const message = useMessage()
    const noteGuid = useMemo(
      () => note?.noteGuid || nextGuid(),
      [note?.noteGuid],
    )

    const [value, setValue] = useState(note?.value ?? '')

    const [taggedUsers, setTaggedUsers] = useState<string[]>(
      note ? [...note.taggedUsers] : [],
    )

    const usersQuery = trpc.user['users:get'].useQuery()

    const userOptions = useMemo(
      () =>
        R.sort(
          R.ascend(option => option.label),
          (usersQuery.data?.users || [])
            .filter(user => !user.deactivatedAt)
            .map(user => ({
              label: `${user.firstName} ${user.lastName}`,
              value: user.userGuid,
            })),
        ),
      [usersQuery.data?.users],
    )

    const mutateNote = trpc.notes['notes:put-linked'].useMutation()

    const onConfirm = useCallback(() => {
      mutateNote.mutate(
        {
          linkData,
          noteGuid,
          value,
          taggedUsers,
        },
        {
          onSuccess: () => {
            onMutate?.()
            onCancel()
          },
          onError: () => {
            message.error(
              'There was a problem saving your note. Try again later.',
            )
          },
        },
      )
    }, [
      mutateNote,
      linkData,
      noteGuid,
      value,
      taggedUsers,
      onMutate,
      onCancel,
      message,
    ])
    return (
      <OnsiteConfirmModal
        header={header}
        onCancel={onCancel}
        onConfirm={onConfirm}
        confirmDisabled={mutateNote.isLoading || value.length === 0}
      >
        <Spin
          indicator={<FontAwesomeIcon icon={faSpinnerThird} spin />}
          spinning={mutateNote.isLoading}
        >
          <WysiwygEditor value={value} onChange={setValue} />
          <BzSelect
            className="mt-3 w-full"
            mode="multiple"
            title="Tag users"
            options={userOptions}
            values={taggedUsers}
            onChange={setTaggedUsers}
            placeholder="Tag users in this note"
          />
        </Spin>
      </OnsiteConfirmModal>
    )
  },
)

import {
  APPOINTMENT_TYPES,
  AppointmentAssignment,
  AppointmentType,
  BzCompanySchedulingConfig,
  BzDateFns,
  bzExpect,
  BzTimeWindow,
  ComprehensiveAppointmentDetails,
  DateTimeFormatter,
  DEFAULT_APPOINTMENT_TYPE_MAP,
  DEFAULT_SCHEDULING_CAPABILITY,
  Dfns,
  eligibleToBeAssignedToJobAppointments,
  ENGLISH_LOCALE,
  fuzzyMatch,
  getTechnicianRolesFromUserRoles,
  Guid,
  isNullish,
  JobAppointmentGuid,
  JobClass,
  JobGuid,
  LocalDate,
  LocalDateTime,
  LocalTime,
  MatchToShape,
  nativeJs,
  nextGuid,
  NotificationPreferenceType,
  Technician,
  toTechnicianRoleShortName,
  UpsertAppointmentAndAssignmentDTO,
  ZonedDateTime,
  ZoneId,
} from '@breezy/shared'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button, Divider, Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { VisitConfirmationCard } from '../../../components/VisitConfirmation/VisitConfirmationCard'
import BzSelect from '../../../elements/BzSelect/BzSelect'
import ThinDivider from '../../../elements/ThinDivider'
import { useFetchCompanyAppointmentArrivalWindows } from '../../../hooks/fetch/useFetchCompanyAppointmentArrivalWindows'
import { trpc } from '../../../hooks/trpc'
import { JobLeadContextView } from '../../../pages/JobLeadsPage/JobLeadContextView'
import { FETCH_JOB_LEAD_BY_JOB_LEAD_GUID } from '../../../pages/JobLeadsPage/JobLeads.gql'
import { JobLead } from '../../../pages/JobLeadsPage/types'
import {
  useExpectedCompany,
  useExpectedCompanyTimeZoneId,
} from '../../../providers/PrincipalUser'
import { gray7 } from '../../../themes/theme'
import { useMessage } from '../../../utils/antd-utils'
import { DateFormat } from '../../../utils/DateUtils'
import { toRenderableTechnician } from '../../../utils/TechnicianResource'
import { validatorNotFalsy } from '../../../utils/validators'
import { BehindFeatureFlag } from '../../BehindFeatureFlag'
import { Calendar } from '../../DatePicker/DatePicker'
import { EndOfAppointmentAlertBanner } from '../../EndOfAppointmentAlertBanner/EndOfAppointmentAlertBanner'
import { FormCancelSubmitButtons } from '../../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'
import { LoadingSpinner } from '../../LoadingSpinner'
import TechnicianResourceView from '../../PersonResourceView/TechnicianResourceView'
import { useJobPointOfContact } from '../../VisitConfirmation/useJobPointOfContact'
import { useVisitConfirmation } from '../../VisitConfirmation/visitConfirmationUtils'
import { ArrivalWindowForm } from './ArrivalWindowForm'
import { COMPANY_USERS_QUERY } from './UpsertAppointmentForm.gql'
import './UpsertAppointmentForm.less'

export type EditComprehensiveAppointmentDetails = MatchToShape<
  ComprehensiveAppointmentDetails,
  {
    timeWindow: true
    appointmentType: true
    description: true
    endOfAppointmentNextSteps: true
    appointmentStatus: true
    assignments: {
      assignmentGuid: true
      technicianUserGuid: true
      timeWindow: true
    }
    sendConfirmationEnabled: true
    notificationType: true
    sendConfirmationTo: true
    sendReminderEnabled: true
  }
>

export type PendingUpsertAppointmentAndAssignmentForm = Omit<
  UpsertAppointmentAndAssignmentDTO,
  'arrivalWindow'
> & {
  arrivalWindow: Partial<UpsertAppointmentAndAssignmentDTO['arrivalWindow']>
}

type UpsertAppointmentFormTechnician = MatchToShape<
  Technician,
  {
    user: {
      id: true
      firstName: true
      lastName: true
      deactivatedAt: true
    }
    roles: true
    schedulingCapability: true
  }
>

export type BaseUpsertAppointmentFormProps = {
  onCancel?: () => void
  jobGuid: string
  jobClass: JobClass
  labelClassName?: string
  justifyFormCancelSubmitButtons?: 'start' | 'end'
  showDivider?: boolean
  showFormCancelSubmitButtons?: boolean
  hideTechnicianAssignments?: boolean
  hideArrivalWindowForm?: boolean
  onChange?: (data: PendingUpsertAppointmentAndAssignmentForm) => void
  jobList?: {
    jobGuid: string
    displayId: number
    jobType: string
    jobClass: JobClass
    address: {
      line1: string
      line2?: string
      city: string
      stateAbbreviation: string
    }
  }[]
  hideJobSelect?: boolean
}

export type CreateAppointmentFormProps = {
  mode: 'create'
  onAppointmentCreated: (appointmentGuid: string) => void
  appointmentGuid?: undefined
  onAppointmentEdited?: undefined
  comprehensiveAppointment?: undefined
  jobLeadGuid?: Guid
} & BaseUpsertAppointmentFormProps

export type UpdateAppointmentFormProps = {
  mode: 'edit'
  appointmentGuid: string
  comprehensiveAppointment: EditComprehensiveAppointmentDetails
  onAppointmentEdited: (appointmentGuid: string) => void
  onAppointmentCreated?: undefined
  jobLeadGuid?: undefined
} & BaseUpsertAppointmentFormProps

export type UpsertAppointmentFormProps =
  | CreateAppointmentFormProps
  | UpdateAppointmentFormProps

export const UpsertAppointmentForm = ({
  onCancel,
  onAppointmentCreated,
  onAppointmentEdited,
  appointmentGuid,
  jobGuid,
  jobClass,
  mode,
  comprehensiveAppointment,
  labelClassName = '',
  justifyFormCancelSubmitButtons = 'end',
  showDivider = true,
  showFormCancelSubmitButtons = true,
  hideTechnicianAssignments,
  onChange,
  hideArrivalWindowForm,
  jobList,
  hideJobSelect,
  jobLeadGuid,
}: UpsertAppointmentFormProps) => {
  const { companyGuid } = useExpectedCompany()

  const appointmentArrivalWindows = useFetchCompanyAppointmentArrivalWindows()
  const fetchJobLeadQuery = useQuery({
    query: FETCH_JOB_LEAD_BY_JOB_LEAD_GUID,
    pause: jobLeadGuid ? false : true,
    variables: {
      jobLeadGuid: jobLeadGuid ?? '',
    },
  })

  const fetchingJobLead = jobLeadGuid && fetchJobLeadQuery[0].fetching

  const { pointOfContact, fetchingJobPointOfContact } =
    useJobPointOfContact(jobGuid)

  // This and `companyUsersAsTechnicians` should probably be done on the server, but as of 2024-05-17 this should be
  // okay to do. This will need to be updated if we expect the average number of users per company to grow by a lot
  // in the future.
  const [fetchCompanyUsersRes] = useQuery({
    query: COMPANY_USERS_QUERY,
    variables: { companyGuid },
  })

  const companyUsersAsTechnicians: UpsertAppointmentFormTechnician[] =
    useMemo(() => {
      if (isNullish(fetchCompanyUsersRes.data)) {
        return []
      }

      return fetchCompanyUsersRes.data.users.map(
        user =>
          ({
            user: {
              id: user.userGuid,
              firstName: user.firstName,
              lastName: user.lastName,
              deactivatedAt: user.deactivatedAt,
            },
            roles: getTechnicianRolesFromUserRoles(
              user.userRoles.map(role => ({
                role: role.roleById.role,
                name: role.roleById.name,
              })),
            ),
            schedulingCapability:
              user.companyUser?.schedulingCapability ??
              DEFAULT_SCHEDULING_CAPABILITY,
          } satisfies UpsertAppointmentFormTechnician),
      )
    }, [fetchCompanyUsersRes.data])

  if (
    appointmentArrivalWindows.isLoading ||
    !appointmentArrivalWindows.data ||
    fetchCompanyUsersRes.fetching ||
    !fetchCompanyUsersRes.data ||
    fetchingJobLead ||
    fetchingJobPointOfContact
  )
    return <LoadingSpinner />

  return (
    <UpsertAppointmentFormInner
      appointmentArrivalWindows={appointmentArrivalWindows.data}
      technicians={companyUsersAsTechnicians}
      mode={mode}
      jobGuid={jobGuid}
      jobClass={jobClass}
      pointOfContact={pointOfContact}
      showDivider={showDivider}
      showFormCancelSubmitButtons={showFormCancelSubmitButtons}
      onCancel={onCancel}
      labelClassName={labelClassName}
      justifyFormCancelSubmitButtons={justifyFormCancelSubmitButtons}
      comprehensiveAppointment={comprehensiveAppointment}
      appointmentGuid={appointmentGuid}
      onAppointmentCreated={onAppointmentCreated}
      onAppointmentEdited={onAppointmentEdited}
      hideTechnicianAssignments={hideTechnicianAssignments}
      onChange={onChange}
      hideArrivalWindowForm={hideArrivalWindowForm}
      jobList={jobList}
      hideJobSelect={hideJobSelect}
      jobLead={fetchJobLeadQuery[0].data?.jobLeads[0]}
    />
  )
}

const APPOINTMENT_TYPE_SELECT_OPTIONS = APPOINTMENT_TYPES.map(value => ({
  label: value,
  value,
}))

export const UpsertAppointmentFormInner = ({
  comprehensiveAppointment,
  appointmentArrivalWindows,
  technicians,
  jobClass,
  mode,
  jobGuid,
  pointOfContact,
  appointmentGuid,
  onAppointmentCreated,
  onAppointmentEdited,
  labelClassName,
  showDivider,
  showFormCancelSubmitButtons,
  justifyFormCancelSubmitButtons,
  onCancel,
  hideTechnicianAssignments,
  onChange,
  hideArrivalWindowForm,
  jobList,
  hideJobSelect,
  jobLead,
}: {
  comprehensiveAppointment?: EditComprehensiveAppointmentDetails
  appointmentArrivalWindows: BzCompanySchedulingConfig
  technicians: UpsertAppointmentFormTechnician[]
  mode: 'create' | 'edit'
  jobGuid: JobGuid
  jobClass: JobClass
  pointOfContact: {
    notificationPreferenceType: NotificationPreferenceType
    primaryEmailAddress?: string
    primaryPhoneNumber?: string
  }
  appointmentGuid?: JobAppointmentGuid
  onAppointmentCreated?: (appointmentGuid: string) => void
  onAppointmentEdited?: (appointmentGuid: string) => void
  labelClassName?: string
  showDivider: boolean
  showFormCancelSubmitButtons: boolean
  justifyFormCancelSubmitButtons?: 'start' | 'end'
  onCancel?: () => void
  hideTechnicianAssignments?: boolean
  onChange?: (data: PendingUpsertAppointmentAndAssignmentForm) => unknown
  hideArrivalWindowForm?: boolean
  jobList?: BaseUpsertAppointmentFormProps['jobList']
  hideJobSelect?: boolean
  jobLead?: JobLead
}) => {
  const message = useMessage()
  const [isUploading, setIsUploading] = useState(false)
  const [effectiveAppointmentGuid] = useState(appointmentGuid ?? nextGuid())
  const tzId = useExpectedCompanyTimeZoneId()
  const zoneId = useMemo(() => ZoneId.of(tzId), [tzId])
  const [form] = useForm()
  const [arrivalWindowLocalDate, setArrivalWindowLocalDate] =
    useState<LocalDate>(
      comprehensiveAppointment?.timeWindow.start.toLocalDate() ??
        LocalDate.now(zoneId),
    )

  const isReadOnly = useMemo(
    () =>
      comprehensiveAppointment?.appointmentStatus === 'CANCELED' ||
      comprehensiveAppointment?.appointmentStatus === 'COMPLETED',
    [comprehensiveAppointment?.appointmentStatus],
  )

  const [arrivalLocalTimeWindow, setArrivalLocalTimeWindow] = useState<{
    arrivalWindowStart: LocalTime
    arrivalWindowEnd: LocalTime
  } | null>(
    comprehensiveAppointment?.timeWindow
      ? {
          arrivalWindowStart:
            comprehensiveAppointment.timeWindow.start.toLocalTime(),
          arrivalWindowEnd:
            comprehensiveAppointment.timeWindow.end.toLocalTime(),
        }
      : null,
  )

  const [technicianAssignments, setTechnicianAssignments] = useState<
    AppointmentAssignment[]
  >(
    comprehensiveAppointment?.assignments.map(assignment => {
      return {
        assignmentGuid: assignment.assignmentGuid,
        jobAppointmentGuid: effectiveAppointmentGuid,
        technicianUserGuid: assignment.technicianUserGuid,
        jobGuid,
        timeWindow: assignment.timeWindow,
      }
    }) ?? [],
  )

  const upsertAppointmentMutation = trpc.appointments[
    'appointment-and-assignments:upsert'
  ].useMutation({
    onSuccess: () => {
      message.success(`Visit ${mode === 'create' ? 'created' : 'updated'}`)
      onAppointmentCreated && onAppointmentCreated(effectiveAppointmentGuid)
      onAppointmentEdited && onAppointmentEdited(effectiveAppointmentGuid)
    },
  })

  const [arrivalWindowStart, arrivalWindowEnd] = useMemo(() => {
    const startLocalDateTime = arrivalLocalTimeWindow
      ? LocalDateTime.of(
          arrivalWindowLocalDate,
          arrivalLocalTimeWindow.arrivalWindowStart,
        )
      : undefined
    const endLocalDateTime = arrivalLocalTimeWindow
      ? LocalDateTime.of(
          arrivalWindowLocalDate,
          arrivalLocalTimeWindow.arrivalWindowEnd,
        )
      : undefined

    return [
      startLocalDateTime
        ? ZonedDateTime.of(startLocalDateTime, zoneId)
        : undefined,
      endLocalDateTime ? ZonedDateTime.of(endLocalDateTime, zoneId) : undefined,
    ]
  }, [arrivalLocalTimeWindow, arrivalWindowLocalDate, zoneId])

  const [arrivalWindowStartAt] = useMemo(() => {
    const start = arrivalWindowStart?.format(
      DateTimeFormatter.ISO_OFFSET_DATE_TIME,
    )
    return [
      // Once we migrate to js-joda, we can remove the eslint disable next line
      // eslint-disable-next-line breezy/no-to-iso-date-string
      start ? BzDateFns.toIsoDateString(start) : undefined,
    ]
  }, [arrivalWindowStart])

  const {
    isValid: isSendVisitNotificationValid,
    sendConfirmationData,
    ...visitConfirmationProps
  } = useVisitConfirmation({
    pointOfContact,
    defaultEnabled: comprehensiveAppointment?.sendConfirmationEnabled,
    defaultReminderEnabled: comprehensiveAppointment?.sendReminderEnabled,
    defaultNotificationType: comprehensiveAppointment?.notificationType,
    defaultTo: comprehensiveAppointment?.sendConfirmationTo,
    visitStartDate: arrivalWindowStartAt,
  })

  const [appointmentType, setAppointmentType] = useState<AppointmentType>(
    comprehensiveAppointment?.appointmentType ||
      DEFAULT_APPOINTMENT_TYPE_MAP[jobClass],
  )
  const [description, setDescription] = useState(
    comprehensiveAppointment?.description ?? '',
  )

  const [effectiveJobGuid, setEffectiveJobGuid] = useState(jobGuid)

  const formData = useMemo<PendingUpsertAppointmentAndAssignmentForm>(() => {
    const start = arrivalWindowStart?.format(
      DateTimeFormatter.ISO_OFFSET_DATE_TIME,
    )
    const end = arrivalWindowEnd?.format(DateTimeFormatter.ISO_OFFSET_DATE_TIME)
    return {
      appointmentGuid: effectiveAppointmentGuid,
      jobGuid: effectiveJobGuid,
      arrivalWindow: {
        // This can go away once we refactor arrival window to not use js joda
        // eslint-disable-next-line breezy/no-to-iso-date-string
        start: start ? BzDateFns.toIsoDateString(start) : undefined,
        // eslint-disable-next-line breezy/no-to-iso-date-string
        end: end ? BzDateFns.toIsoDateString(end) : undefined,
      },
      appointmentType: appointmentType || 'Other',
      description,
      assignments: technicianAssignments.map(assignment => {
        return {
          ...assignment,
          timeWindow: new BzTimeWindow(
            assignment.timeWindow.start,
            assignment.timeWindow.end,
          ).toDto(),
        }
      }),

      ...sendConfirmationData,
    }
  }, [
    appointmentType,
    arrivalWindowEnd,
    arrivalWindowStart,
    description,
    effectiveAppointmentGuid,
    effectiveJobGuid,
    sendConfirmationData,
    technicianAssignments,
  ])

  useEffect(() => {
    if (onChange) {
      onChange(formData)
    }
  }, [formData, onChange])

  useEffect(() => {
    setEffectiveJobGuid(jobGuid)
  }, [jobGuid])

  // If the user is choosing a job to create an appointment for (ex. creating an appointment not on the Job Details
  // page), we update the appointment type to match the selected job's job class. Otherwise, we just choose the
  // appointment type from the job class passed into this component
  useEffect(() => {
    if (mode !== 'create') {
      return
    }

    const selectedJob = jobList?.find(job => job.jobGuid === effectiveJobGuid)

    if (isNullish(selectedJob)) {
      setAppointmentType(
        comprehensiveAppointment?.appointmentType ||
          DEFAULT_APPOINTMENT_TYPE_MAP[jobClass],
      )
    } else {
      setAppointmentType(
        comprehensiveAppointment?.appointmentType ||
          DEFAULT_APPOINTMENT_TYPE_MAP[selectedJob.jobClass],
      )
    }
  }, [
    comprehensiveAppointment?.appointmentType,
    effectiveJobGuid,
    jobClass,
    jobList,
    mode,
  ])

  const onFormSubmit = useCallback(() => {
    if (effectiveJobGuid === '') {
      message.error('You must select a job to create a visit for')
      return
    }

    setIsUploading(true)
    bzExpect(formData.arrivalWindow.start)
    bzExpect(formData.arrivalWindow.end)

    upsertAppointmentMutation.mutate(
      {
        ...formData,
        arrivalWindow: {
          start: bzExpect(formData.arrivalWindow.start),
          end: bzExpect(formData.arrivalWindow.end),
        },
      },
      {
        onSuccess: () => setIsUploading(false),
        onError: () => setIsUploading(false),
      },
    )
  }, [effectiveJobGuid, formData, message, upsertAppointmentMutation])

  const savingDisabled = useMemo(() => {
    if (appointmentType === 'Other' && !description) {
      return true
    }
    if (!arrivalWindowStart || !arrivalWindowEnd) {
      return true
    }
    if (!isSendVisitNotificationValid) {
      return true
    }
    return false
  }, [
    appointmentType,
    description,
    arrivalWindowStart,
    arrivalWindowEnd,
    isSendVisitNotificationValid,
  ])

  if (isUploading) {
    return (
      <div className="center-children-vh flex min-h-[500px]">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="upsert-appointment-form w-full">
      {comprehensiveAppointment &&
        !isNullish(comprehensiveAppointment.endOfAppointmentNextSteps) && (
          <div className="mb-3">
            <EndOfAppointmentAlertBanner
              endOfAppointmentNextSteps={
                comprehensiveAppointment.endOfAppointmentNextSteps
              }
            />
          </div>
        )}
      {comprehensiveAppointment &&
        comprehensiveAppointment.assignments.length > 0 &&
        isNullish(comprehensiveAppointment.endOfAppointmentNextSteps) && (
          <div className="mb-3">
            <Alert
              message="Updating the date or time window of the visit will unassign the technicians assigned to the visit"
              type="warning"
            />
          </div>
        )}
      {jobLead && (
        <div className="mb-3">
          <JobLeadContextView jobLead={jobLead} />
        </div>
      )}
      <Form
        form={form}
        layout="vertical"
        validateTrigger="onBlur"
        onFinish={onFormSubmit}
        disabled={isReadOnly}
      >
        <div>
          {!hideJobSelect && (
            <Form.Item
              label="Job"
              hidden={jobGuid !== '' && isNullish(jobList)}
            >
              <BzSelect
                title="Job"
                options={
                  jobList?.map(job => ({
                    label: `#${job.displayId} (${job.jobType})`,
                    value: job.jobGuid,
                  })) ?? []
                }
                value={effectiveJobGuid}
                onChange={selectedJobGuid =>
                  setEffectiveJobGuid(selectedJobGuid)
                }
                showSearch
              />
            </Form.Item>
          )}

          <Form.Item label="Visit Type" required>
            <BzSelect
              disabled={isReadOnly}
              showSearch
              title="Visit Type"
              value={appointmentType}
              onChange={setAppointmentType}
              options={APPOINTMENT_TYPE_SELECT_OPTIONS}
            />
          </Form.Item>
          <Form.Item label="Description" required={appointmentType === 'Other'}>
            <TextArea
              disabled={isReadOnly}
              value={description}
              data-testid="appointment-description-textarea"
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Item>
        </div>

        {!hideArrivalWindowForm && (
          <>
            <Divider />
            <div className="semibold_20_28">Set Technician Arrival Window</div>
            <div className="regular_14_22 ml-2 mt-2 flex gap-2">
              <div>
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  color={gray7}
                  style={{ fontSize: '14px' }}
                />
              </div>
              <div className="regular_14_22 gray7">
                This is the time window that the customer should expect the
                technician(s) to arrive
              </div>
            </div>
            <div className="row mt-6 flex flex-wrap">
              <div className="flex flex-col sm:w-full lg:w-3/5">
                <Form.Item
                  className={labelClassName}
                  rules={[{ validator: validatorNotFalsy('Visit date') }]}
                >
                  <div>
                    <Calendar
                      fullscreen={false}
                      mode="month"
                      value={Dfns.parseISO(
                        arrivalWindowLocalDate.format(
                          DateTimeFormatter.ISO_LOCAL_DATE,
                        ),
                      )}
                      headerRender={val => {
                        return (
                          <div className="flex justify-between p-2">
                            <div className="regular_14_22 font-semibold">
                              {`${Dfns.format('MMMM yyyy', val.value)}`}
                            </div>
                            <div className="w-[100px]">
                              <Button
                                type="text"
                                onClick={() =>
                                  val.onChange(Dfns.subMonths(1, val.value))
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faChevronLeft}
                                  color={gray7}
                                  style={{ fontSize: '18px' }}
                                />
                              </Button>

                              <Button
                                type="text"
                                onClick={() =>
                                  val.onChange(Dfns.addMonths(1, val.value))
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  color={gray7}
                                  style={{ fontSize: '18px' }}
                                />
                              </Button>
                            </div>
                          </div>
                        )
                      }}
                      onSelect={date => {
                        if (!isReadOnly) {
                          setArrivalWindowLocalDate(
                            LocalDate.from(nativeJs(date)),
                          )
                          setTechnicianAssignments([])
                        }
                      }}
                    />
                  </div>
                </Form.Item>
              </div>
              <div className="mt-4 flex w-full flex-col md:w-2/5">
                <div className="w-full">
                  <div className="flex w-full gap-5">
                    <div className="flex flex-auto flex-col items-center">
                      <div className="semibold_14_22 gray7 mb-3">
                        {arrivalWindowLocalDate.format(
                          DateTimeFormatter.ofPattern(
                            DateFormat['eeee, MMM d, yyyy'],
                          ).withLocale(ENGLISH_LOCALE),
                        )}
                      </div>
                      <ArrivalWindowForm
                        jobClass={jobClass}
                        appointmentArrivalWindows={appointmentArrivalWindows}
                        arrivalWindowLocalDate={arrivalWindowLocalDate}
                        setArrivalWindowLocalDate={setArrivalWindowLocalDate}
                        arrivalLocalTimeWindow={arrivalLocalTimeWindow}
                        setArrivalLocalTimeWindow={setArrivalLocalTimeWindow}
                        setTechnicianAssignments={setTechnicianAssignments}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {arrivalWindowStart &&
          arrivalWindowEnd &&
          !hideTechnicianAssignments && (
            <>
              <Divider />
              <TechnicianAssignmentsFormFragment
                labelClassName={labelClassName}
                technicianAssignments={technicianAssignments}
                setTechnicianAssignments={setTechnicianAssignments}
                arrivalWindowStart={arrivalWindowStart}
                appointmentArrivalWindows={appointmentArrivalWindows}
                jobGuid={jobGuid}
                jobClass={jobClass}
                jobAppointmentGuid={effectiveAppointmentGuid}
                technicians={technicians}
                arrivalWindowLocalDate={arrivalWindowLocalDate}
              />
            </>
          )}
        {arrivalWindowStart && arrivalWindowEnd && (
          <BehindFeatureFlag
            enabledFeatureFlag="visit-confirmation"
            render={
              <>
                <ThinDivider
                  widthPx={1}
                  styleOverrides={{ marginTop: 20, marginBottom: 20 }}
                />
                <VisitConfirmationCard {...visitConfirmationProps} />
              </>
            }
          />
        )}
        {!isReadOnly && (
          <div>
            <div>
              {showDivider && <Divider />}
              {showFormCancelSubmitButtons && (
                <FormCancelSubmitButtons
                  onCancel={onCancel}
                  primaryButtonText={'Save'}
                  justify={justifyFormCancelSubmitButtons}
                  disabled={savingDisabled}
                />
              )}
            </div>
          </div>
        )}
      </Form>
    </div>
  )
}

const TechnicianAssignmentsFormFragment = ({
  technicianAssignments,
  setTechnicianAssignments,
  labelClassName,
  arrivalWindowStart,
  appointmentArrivalWindows,
  jobGuid,
  jobClass,
  jobAppointmentGuid,
  technicians,
  arrivalWindowLocalDate,
}: {
  labelClassName: string | undefined
  technicianAssignments: AppointmentAssignment[]
  setTechnicianAssignments: React.Dispatch<
    React.SetStateAction<AppointmentAssignment[]>
  >
  arrivalWindowStart: ZonedDateTime
  appointmentArrivalWindows: BzCompanySchedulingConfig
  jobGuid: JobGuid
  jobClass: JobClass
  jobAppointmentGuid: JobAppointmentGuid
  technicians: UpsertAppointmentFormTechnician[]
  arrivalWindowLocalDate: LocalDate
}) => {
  const technicianSelectOptions: { label: string; value: string }[] =
    useMemo(() => {
      return technicians
        .filter(
          tech =>
            (!tech.user.deactivatedAt &&
              eligibleToBeAssignedToJobAppointments(
                tech.schedulingCapability,
              )) ||
            technicianAssignments.some(
              assignment => assignment.technicianUserGuid === tech.user.id,
            ),
        )
        .sort((a, b) => a.user.firstName.localeCompare(b.user.firstName))
        .map(technician => {
          if (technician.roles.length === 0) {
            return {
              label: `${technician.user.firstName} ${technician.user.lastName}`,
              value: technician.user.id,
            }
          }

          return {
            label: `${technician.user.firstName} ${
              technician.user.lastName
            } - ${technician.roles.map(toTechnicianRoleShortName).join(', ')}`,
            value: technician.user.id,
          }
        })
    }, [technicianAssignments, technicians])

  const onTechnicianSelectChange = useCallback(
    (selectedTechnicianUserGuids: string[]) => {
      const filtered = technicianAssignments.filter(assignment =>
        selectedTechnicianUserGuids.includes(assignment.technicianUserGuid),
      )
      const newAssignments: AppointmentAssignment[] = []
      selectedTechnicianUserGuids.forEach(userGuid => {
        if (
          !filtered.find(
            assignment => assignment.technicianUserGuid === userGuid,
          )
        ) {
          newAssignments.push({
            technicianUserGuid: userGuid,
            timeWindow: new BzTimeWindow(
              arrivalWindowStart,
              arrivalWindowStart.plusMinutes(
                appointmentArrivalWindows
                  .defaultDurationForJobClass(jobClass)
                  .totalMinutes(),
              ),
            ),
            jobGuid: jobGuid,
            jobAppointmentGuid,
            assignmentGuid: nextGuid(),
          })
        }
      })

      setTechnicianAssignments([...filtered, ...newAssignments])
    },
    [
      appointmentArrivalWindows,
      arrivalWindowStart,
      jobAppointmentGuid,
      jobGuid,
      jobClass,
      setTechnicianAssignments,
      technicianAssignments,
    ],
  )

  const filterTechnicianOption = useCallback(
    (inputValue: string, option?: { label: ReactNode }) => {
      return option?.label && typeof option.label === 'string'
        ? fuzzyMatch(`${option?.label}`, inputValue)
        : false
    },
    [],
  )

  return (
    <>
      <div>
        <div className="semibold_20_28">Assign Technicians to Visit</div>
        <div className="regular_14_22 ml-2 mt-2 flex gap-2">
          <div>
            <FontAwesomeIcon
              icon={faCircleInfo}
              color={gray7}
              style={{ fontSize: '14px' }}
            />
          </div>
          <div className="regular_14_22 gray7">
            You may optionally assign one or more technicians to the visit.
            Alternatively, you can assign technicians to an visit using the
            dispatch board
          </div>
        </div>
      </div>
      <div className="mt-3 flex">
        <div className="mt-0 flex w-full flex-col">
          <div className="mt-3 flex w-full flex-col md:w-4/6">
            <Form.Item
              label="Assign Technicians (Optional)"
              className={labelClassName}
            >
              <BzSelect
                mode="multiple"
                title="Assign Technicians"
                placeholder="Assign one or more technicians to the visit"
                // TODO: This is a hack for BzSelect to accept the string array.
                // See https://getbreezyapp.atlassian.net/browse/BZ-3228
                value={
                  technicianAssignments.map(
                    assignment => assignment.technicianUserGuid,
                  ) as unknown as string
                }
                // TODO: This doesn't actually pass the data to BzSelect????
                // See https://getbreezyapp.atlassian.net/browse/BZ-3228
                values={technicianAssignments.map(
                  assignment => assignment.technicianUserGuid,
                )}
                onChange={onTechnicianSelectChange}
                filterOption={filterTechnicianOption}
                options={technicianSelectOptions}
              />
            </Form.Item>
          </div>
          <div className="mt-1 flex w-full flex-col">
            {technicianAssignments.map((assignment, assignmentIndex) => {
              return (
                <TechnicianAssignmentDetailFragment
                  key={assignment.assignmentGuid}
                  arrivalWindowStart={arrivalWindowStart}
                  arrivalWindowLocalDate={arrivalWindowLocalDate}
                  appointmentArrivalWindows={appointmentArrivalWindows}
                  assignment={assignment}
                  technicians={technicians}
                  technicianAssignments={technicianAssignments}
                  setTechnicianAssignments={setTechnicianAssignments}
                  showSeparator={
                    assignmentIndex !== technicianAssignments.length - 1
                  }
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

const TechnicianAssignmentDetailFragment = ({
  arrivalWindowStart,
  arrivalWindowLocalDate,
  appointmentArrivalWindows,
  assignment,
  technicians,
  technicianAssignments,
  setTechnicianAssignments,
  showSeparator,
}: {
  arrivalWindowStart: ZonedDateTime
  arrivalWindowLocalDate: LocalDate
  appointmentArrivalWindows: BzCompanySchedulingConfig
  assignment: AppointmentAssignment
  technicians: UpsertAppointmentFormTechnician[]
  technicianAssignments: AppointmentAssignment[]
  setTechnicianAssignments: React.Dispatch<
    React.SetStateAction<AppointmentAssignment[]>
  >
  showSeparator: boolean
}) => {
  const localTimePattern = DateTimeFormatter.ofPattern(
    DateFormat['h:mm a'],
  ).withLocale(ENGLISH_LOCALE)

  const onTechnicianAssignmentStartTimeSelectChange = useCallback(
    (newTime: string) => {
      setTechnicianAssignments(
        technicianAssignments.map(innerAssignment => {
          if (innerAssignment.assignmentGuid === assignment.assignmentGuid) {
            return {
              ...innerAssignment,
              timeWindow: {
                ...innerAssignment.timeWindow,
                start: ZonedDateTime.of(
                  LocalDateTime.of(
                    arrivalWindowStart.toLocalDate(),
                    LocalTime.parse(newTime, localTimePattern),
                  ),
                  arrivalWindowStart.zone(),
                ),
              },
            }
          }
          return innerAssignment
        }),
      )
    },
    [
      arrivalWindowStart,
      assignment.assignmentGuid,
      localTimePattern,
      setTechnicianAssignments,
      technicianAssignments,
    ],
  )

  const onTechnicianAssignmentEndTimeSelectChange = useCallback(
    (newTime: string) => {
      setTechnicianAssignments(
        technicianAssignments.map(innerAssignment => {
          if (innerAssignment.assignmentGuid === assignment.assignmentGuid) {
            return {
              ...innerAssignment,
              timeWindow: {
                ...innerAssignment.timeWindow,
                end: ZonedDateTime.of(
                  LocalDateTime.of(
                    arrivalWindowStart.toLocalDate(),
                    LocalTime.parse(newTime, localTimePattern),
                  ),
                  arrivalWindowStart.zone(),
                ),
              },
            }
          }
          return innerAssignment
        }),
      )
    },
    [
      arrivalWindowStart,
      assignment.assignmentGuid,
      localTimePattern,
      setTechnicianAssignments,
      technicianAssignments,
    ],
  )

  return (
    <div className="ml-5" key={assignment.assignmentGuid}>
      <div className="semibold_14_22 grey9">
        {
          <TechnicianResourceView
            {...toRenderableTechnician(
              bzExpect(
                technicians.find(
                  t => t.user.id === assignment.technicianUserGuid,
                ),
              ),
            )}
            userGuid={assignment.technicianUserGuid}
          />
        }
      </div>
      <div className="mt-2">
        <span>Scheduled to arrive at</span>
        <div className="mx-3 inline-block w-[125px]">
          <Form.Item className="mb-0">
            <BzSelect
              title="Start Time"
              showSearch
              size="middle"
              value={assignment.timeWindow.start.format(localTimePattern)}
              onChange={onTechnicianAssignmentStartTimeSelectChange}
              options={appointmentArrivalWindows
                .getQuantizedAppointmentStartTimesForDate(
                  arrivalWindowLocalDate,
                )
                .filter(timeSlot => {
                  if (!assignment.timeWindow.end) {
                    return true
                  }
                  return timeSlot.isBefore(assignment.timeWindow.end)
                })
                .map(time => {
                  const value = time.format(localTimePattern)
                  return {
                    label: value,
                    value,
                  }
                })}
            />
          </Form.Item>
        </div>
        and finish at
        <div className="mx-3 inline-block w-[125px]">
          <Form.Item className="mb-0 mt-2">
            <BzSelect
              title="End Time"
              size="middle"
              showSearch
              value={assignment.timeWindow.end.format(localTimePattern)}
              onChange={onTechnicianAssignmentEndTimeSelectChange}
              options={appointmentArrivalWindows
                .getQuantizedAppointmentEndTimesForDate(arrivalWindowLocalDate)
                .filter(timeSlot => {
                  if (!assignment.timeWindow.start) {
                    return true
                  }
                  return timeSlot.isAfter(assignment.timeWindow.start)
                })
                .map(time => {
                  const value = time.format(localTimePattern)
                  return {
                    label: value,
                    value,
                  }
                })}
            />
          </Form.Item>
        </div>
      </div>
      {showSeparator && <Divider className="mb-2 mt-2" />}
    </div>
  )
}

import { AutoComplete, AutoCompleteProps } from 'antd'
import classNames from 'classnames'
import React from 'react'

export type ScrollAlign = 'top' | 'bottom' | 'auto'

export type ScrollPos = {
  left?: number
  top?: number
}

export type ScrollTarget =
  | {
      index: number
      align?: ScrollAlign
      offset?: number
    }
  | {
      key: React.Key
      align?: ScrollAlign
      offset?: number
    }

export type ScrollConfig = ScrollTarget | ScrollPos

export type ScrollTo = (arg: number | ScrollConfig) => void
// Copied from rc-select: https://github.com/react-component/select/blob/ca861305cbaa0c5dae0135ce12450c915b108527/src/BaseSelect/index.tsx#L73
export interface BaseSelectRef {
  focus: (options?: FocusOptions) => void
  blur: () => void
  scrollTo: ScrollTo
  nativeElement: HTMLElement
}

type Option = { label: string; value: string }

const filterOption = (input: string, option?: Option) => {
  if (!option || !option.label) {
    return false
  }

  return option.label.toString().toLowerCase().includes(input.toLowerCase())
}

type AutoCompleteFieldProps = AutoCompleteProps<string, Option> & {
  ddActionName?: string
  name: string
}

export const AutoCompleteField = React.memo(
  React.forwardRef<BaseSelectRef, AutoCompleteFieldProps>(
    ({ name, size = 'large', className, ddActionName, ...rest }, ref) => (
      <AutoComplete<string, Option>
        ref={ref}
        id={name}
        data-dd-action-name={ddActionName}
        data-testid={name}
        size={size}
        className={classNames('w-full', className)}
        popupMatchSelectWidth={false}
        filterOption={filterOption}
        {...rest}
      />
    ),
  ),
)

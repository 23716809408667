import { DocumentType, gql } from 'src/generated'

export const COMPANY_CONFIG_SETTINGS_QUERY = gql(/* GraphQL */ `
  query CompanyConfigSettingsPageCompanyConfigSettingsQuery(
    $companyGuid: uuid!
  ) {
    companyConfigByPk(companyGuid: $companyGuid) {
      confirmationNotificationSuppressedByDefault
      defaultAccountManagerUserGuid
      hiddenEquipmentTypesJsonArray
      locationMunicipalityFieldEnabled
      visibleInstallProjectTypesJsonArray
      blurb
      maintenancePlansEnabled
      accountManagerEnabled
    }

    companyUsers(
      where: { companyGuid: { _eq: $companyGuid } }
      orderBy: { userByUserGuid: { fullName: ASC } }
    ) {
      userByUserGuid {
        userGuid
        fullName
      }
    }
  }
`)

export type CompanyConfig = DocumentType<typeof COMPANY_CONFIG_SETTINGS_QUERY>

export const COMPANY_CONFIG_UPSERT_ONE = gql(/* GraphQL */ `
  mutation CompanyConfigSettingsPageCompanyConfigUpsertOne(
    $object: CompanyConfigInsertInput!
  ) {
    insertCompanyConfigOne(
      object: $object
      onConflict: {
        constraint: company_config_pkey
        updateColumns: [
          blurb
          confirmationNotificationSuppressedByDefault
          defaultAccountManagerUserGuid
          hiddenEquipmentTypesJsonArray
          locationMunicipalityFieldEnabled
          visibleInstallProjectTypesJsonArray
          accountManagerEnabled
        ]
      }
    ) {
      __typename
    }
  }
`)

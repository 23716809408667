import { qboPayoutAccountsConfigReaderNoResults } from '@breezy/shared'
import { Col, Row } from 'antd'
import React, { useState } from 'react'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { LoadingSpinner } from '../LoadingSpinner'
import { Page } from '../Page/Page'
import AccountingOptionCheckboxes from '../QuickbooksDesktop/AccountingOptionCheckboxes'
import TrpcQueryLoader from '../TrpcQueryLoader'
import {
  QuickbooksAuthorizeButton,
  useQuickbooksAuthorizeButtonProps,
} from './QuickbooksAuthorizeButton'
import { QuickbooksCartItemAccountsConfig } from './QuickbooksCartItemTypeAccountsConfig'
import { QuickbooksPayoutAccountsConfig } from './QuickbooksPayoutAccountsConfig'
import { QuickbooksReauthNeededNonActionBanner } from './QuickbooksReauthNeededBanner'

const QBOPayoutConfigWrapper = React.memo(() => {
  const qboPayoutAccountsQuery =
    trpc.qbo['finance-app:get-payout-accounts-config'].useQuery()
  return (
    <TrpcQueryLoader
      query={qboPayoutAccountsQuery}
      loadingComponent={
        <div className="flex-items-center min-h-[320px] min-w-[240px]">
          <LoadingSpinner />
        </div>
      }
      render={data => (
        <QuickbooksPayoutAccountsConfig
          cfg={qboPayoutAccountsConfigReaderNoResults(data) ? undefined : data}
        />
      )}
    />
  )
})

export const QuickbooksConfigPage = React.memo(() => {
  const qboAuthorizeButtonProps = useQuickbooksAuthorizeButtonProps()

  const [reauthInitiated, setIsReauthInitiated] = useState(false)

  return (
    <Page requiresCompanyUser={true} className="p-0">
      <div className="card overflow-y-scroll">
        <Col>
          <Row className="mb-2">
            <img
              alt="Sync with Quickbooks"
              src="https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png"
              className="m-1 h-6 w-6"
            />
            <h2 className="card-title-lg ml1 semibold_20_28 grey9">
              Quickbooks Online
            </h2>
          </Row>
          {!reauthInitiated && <QuickbooksReauthNeededNonActionBanner />}
          <ThinDivider />
          <QuickbooksAuthorizeButton
            {...qboAuthorizeButtonProps}
            onClick={() => setIsReauthInitiated(true)}
          />
          {qboAuthorizeButtonProps.isAuthorized && (
            <>
              <ThinDivider />
              <div className="pb-2 text-lg font-semibold">
                Auto Sync Options
              </div>
              <AccountingOptionCheckboxes />
              <ThinDivider />
              <QuickbooksCartItemAccountsConfig />
              <ThinDivider />
              <QBOPayoutConfigWrapper />
            </>
          )}
        </Col>
      </div>
    </Page>
  )
})

import { Map, MapProps } from '@vis.gl/react-google-maps'
import React from 'react'
import { BREEZY_LAT_LNG, DEFAULT_BREEZY_ZOOM_LEVEL } from './MapView'

import './ReactMapView.less'
const ReactMapView = React.memo<React.PropsWithChildren<MapProps>>(
  ({
    children,
    defaultCenter = BREEZY_LAT_LNG,
    defaultZoom = DEFAULT_BREEZY_ZOOM_LEVEL,
    ...rest
  }) => {
    return (
      <Map
        key={rest.mapId}
        {...rest}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
      >
        {children}
      </Map>
    )
  },
)

export default ReactMapView

import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProgressiveAccountCreationModalV2 } from '../../components/ProgressiveAccountCreationModal/ProgressiveAccountCreationModalV2'
import { m } from '../../utils/react-utils'

const AccountCreationPage = m(() => {
  const navigate = useNavigate()

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        navigate(-1)
      }
    },
    [navigate],
  )

  return <ProgressiveAccountCreationModalV2 isOpen setIsOpen={setIsOpen} />
})

export default AccountCreationPage

import { PhotoCropArea, PhotoRecord } from '@breezy/shared'
import { Slider } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import Cropper from 'react-easy-crop'
import {
  OnsiteModal,
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'
import { useModalState } from '../../../utils/react-utils'
export const PREFIX = 'img-crop'
export const ZOOM_STEP = 0.1

type UsePhotoCropProps = {
  sourcePhotoUrl: string | undefined
  onCropEdit: (record: PhotoRecord) => void
  onClose: () => void
  mode?: 'modal' | 'embedded'
}

export const usePhotoCrop = ({
  sourcePhotoUrl,
  onCropEdit,
  onClose,
  mode = 'modal',
}: UsePhotoCropProps) => {
  const [cropModalVisible, openCropModal, closeCropModal] = useModalState(false)
  const message = useMessage()

  const cropMutation = trpc.photos['photos:crop'].useMutation()

  const handleCropComplete = useCallback(
    async (croppedArea: PhotoCropArea) => {
      if (!sourcePhotoUrl) return

      try {
        const result = await cropMutation.mutateAsync({
          imageUrl: sourcePhotoUrl,
          area: croppedArea,
        })

        onCropEdit(result)
        closeCropModal()
      } catch (error) {
        console.error('Error cropping image:', error)
        message.error('Error cropping image')
      }
    },
    [sourcePhotoUrl, cropMutation, onCropEdit, message, closeCropModal],
  )

  const onCloseInner = useCallback(() => {
    closeCropModal()
    onClose()
  }, [onClose, closeCropModal])

  return {
    photoCropModalProps: {
      imageUrl: sourcePhotoUrl,
      onCropComplete: handleCropComplete,
      onClose: onCloseInner,
      loading: cropMutation.isLoading,
      mode,
    },
    cropModalVisible,
    openCropModal,
  }
}

interface PhotoCropModalProps {
  imageUrl: string | undefined
  onCropComplete: (croppedArea: PhotoCropArea) => Promise<void>
  onClose: () => void
  loading: boolean
  mode?: 'modal' | 'embedded'
  minZoom?: number
  maxZoom?: number
}

const buttonClass =
  'flex items-center justify-center h-8 w-8 bg-transparent border-0 font-inherit text-lg cursor-pointer disabled:opacity-20 disabled:cursor-default'

// This component is inspired by https://github.com/nanxiaobei/antd-img-crop
const PhotoCropModal = React.memo<PhotoCropModalProps>(
  ({
    imageUrl,
    onCropComplete,
    onClose,
    loading,
    minZoom = 1,
    maxZoom = 3,
    mode = 'modal',
  }) => {
    const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(minZoom)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<
      PhotoCropArea | undefined
    >(undefined)

    const onCropCompleteInner = useCallback(
      (_: PhotoCropArea, croppedAreaPixels: PhotoCropArea) => {
        setCroppedAreaPixels(croppedAreaPixels)
      },
      [],
    )

    const handleSubmit = useCallback(async () => {
      if (croppedAreaPixels) {
        await onCropComplete(croppedAreaPixels)
      }
      onClose()
    }, [croppedAreaPixels, onCropComplete, onClose])

    const content = useMemo(() => {
      return (
        <OnsiteModalContent
          header="Edit Crop"
          onClose={onClose}
          onBack={mode === 'embedded' ? onClose : undefined}
          footer={
            <OnsiteModalFooter
              onCancel={onClose}
              onSubmit={handleSubmit}
              submitText="Apply Crop"
              loading={loading}
            />
          }
        >
          <div className="flex min-h-[358px] flex-1 flex-col gap-4">
            <div className="relative flex min-h-0 flex-1 flex-col">
              <Cropper
                image={imageUrl}
                crop={crop}
                zoom={zoom}
                minZoom={minZoom}
                maxZoom={maxZoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropCompleteInner}
                onZoomChange={setZoom}
              />
            </div>

            <section className={`mx-auto flex w-3/5 items-center`}>
              <button
                className={buttonClass}
                onClick={() => setZoom(+(zoom - ZOOM_STEP).toFixed(1))}
                disabled={zoom - ZOOM_STEP < 1}
              >
                -
              </button>
              <Slider
                className="flex-1"
                min={minZoom}
                max={maxZoom}
                step={ZOOM_STEP}
                value={zoom}
                onChange={setZoom}
              />
              <button
                className={buttonClass}
                onClick={() => setZoom(+(zoom + ZOOM_STEP).toFixed(1))}
                disabled={zoom + ZOOM_STEP > maxZoom}
              >
                +
              </button>
            </section>
          </div>
        </OnsiteModalContent>
      )
    }, [
      crop,
      handleSubmit,
      imageUrl,
      loading,
      maxZoom,
      minZoom,
      mode,
      onClose,
      onCropCompleteInner,
      zoom,
    ])

    if (mode === 'embedded') {
      return content
    }

    return (
      <OnsiteModal
        modalClassName="z-[1240]"
        open
        onClose={onClose}
        size="large"
      >
        {content}
      </OnsiteModal>
    )
  },
)

export default PhotoCropModal

import {
  AccountingIntegrationType,
  BzDateFns,
  IsoDateString,
  mapStringToFriendlyAccountingName,
} from '@breezy/shared'
import {
  faCheck,
  faExclamation,
  faSync,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { OnsitePageSection } from '../../../adam-components/OnsitePage/OnsitePageSection'
import { OnsitePageSimpleSectionItem } from '../../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import useIsMobile from '../../../hooks/useIsMobile'
import { useSyncInvoice } from '../../../hooks/useSyncInvoice'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useStrictContext } from '../../../utils/react-utils'
import { InvoiceContext } from '../invoiceUtils'

const logoUrls = {
  QBO: 'https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png',
  QBD: 'https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png',
}

type InvoiceAccountingSectionProps = {
  accountingIntegrationType: AccountingIntegrationType
  invoiceGuid: string
  isStale: boolean
  lastSyncedAt?: IsoDateString
  lastPaymentAt?: IsoDateString
  invoiceIssuedAt?: IsoDateString
  isFullyPaid?: boolean
}

export const InvoiceAccountingSection =
  React.memo<InvoiceAccountingSectionProps>(
    ({
      accountingIntegrationType,
      invoiceGuid,
      isStale,
      lastSyncedAt,
      lastPaymentAt,
      invoiceIssuedAt,
      isFullyPaid,
    }) => {
      const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

      const { onSyncClick, loading: manualSyncLoading } = useSyncInvoice({
        accountingIntegrationType,
        invoiceGuid,
        onSuccess: () => {
          setErrorMsg(undefined)
        },
        onError: setErrorMsg,
      })

      return (
        <InvoiceAccountingSectionStateless
          accountingIntegrationType={accountingIntegrationType}
          invoiceGuid={invoiceGuid}
          isStale={isStale}
          lastSyncedAt={lastSyncedAt}
          lastPaymentAt={lastPaymentAt}
          invoiceIssuedAt={invoiceIssuedAt}
          isFullyPaid={isFullyPaid}
          onSyncClick={onSyncClick}
          isLoading={manualSyncLoading}
          errorMsg={errorMsg}
        />
      )
    },
  )

type InvoiceAccountingSectionStatelessProps = {
  accountingIntegrationType: AccountingIntegrationType
  invoiceGuid: string
  isStale: boolean
  lastSyncedAt?: IsoDateString
  lastPaymentAt?: IsoDateString
  invoiceIssuedAt?: IsoDateString
  isFullyPaid?: boolean

  onSyncClick: () => Promise<void>
  isLoading: boolean
  errorMsg?: string
}

export const InvoiceAccountingSectionStateless =
  React.memo<InvoiceAccountingSectionStatelessProps>(
    ({
      accountingIntegrationType,
      isStale,
      lastSyncedAt,
      lastPaymentAt,
      invoiceIssuedAt,
      isFullyPaid,
      onSyncClick,
      isLoading,
      errorMsg,
    }) => {
      const tzId = useExpectedCompanyTimeZoneId()
      const { accountingAutoSyncSettings } = useStrictContext(InvoiceContext)
      const isMobile = useIsMobile()

      // There are certain conditions (enabled via the company settings) where an invoice is auto synced: when issued,
      // when a payment is made, and when the invoice is fully paid. We can get a race condition when one of these
      // auto-syncs is happening (based on a 60 second cron) and the user manually syncs (like if they issue the invoice
      // then compulsively hits the "sync" button). As a sketchy hack, we can say that if any of those events (provided
      // the settings are set) happened within the last 60 seconds, we "pretend" we're in a loading state. When they
      // complete, the live query will just make the button go away and say "Synced". If the crons fail for some reason
      // (rare), then the button will spin forever (unless they refresh the page or do some kind of navigation that will
      // cause this component to rerender), which is not that bad of a user experience (not as bad as hitting the button
      // and getting a cryptic error message).
      const loading = useMemo(() => {
        if (isLoading) {
          return true
        }
        // If the dates are greater than 60 seconds ago, that means they happened within the last 60 seconds.
        const sixtySecondsAgo = BzDateFns.subSeconds(
          BzDateFns.now(BzDateFns.UTC),
          60,
        )

        if (
          accountingAutoSyncSettings?.onIssued &&
          invoiceIssuedAt &&
          BzDateFns.parseISO(invoiceIssuedAt, BzDateFns.UTC) > sixtySecondsAgo
        ) {
          return true
        }
        // Both the "onPayment" and "onFullyPaid" will be based on the last payment. The difference is the "onFullyPaid"
        // only triggers if we're also now fully paid.
        if (
          lastPaymentAt &&
          (accountingAutoSyncSettings?.onPayment ||
            accountingAutoSyncSettings?.onFullyPaid)
        ) {
          const lastPaymentDate = BzDateFns.parseISO(
            lastPaymentAt,
            BzDateFns.UTC,
          )
          if (lastPaymentDate > sixtySecondsAgo) {
            // If we auto sync on any payment, then if the last payment was within the last 60 seconds, we're loading.
            // Otherwise, because of the outer `if`, we know that we must be `onFullyPaid`. If the last payment was within
            // 60 seconds and we're fully paid, that means the last payment was the one that fully paid it (otherwise
            // there would be a payment after it was fully paid which doesn't make sense). So if we're fully paid, then we
            // know the last payment was the fully-paying one, which means if they have "onFullyPaid" on then we are
            // triggering the auto sync.
            return accountingAutoSyncSettings?.onPayment || isFullyPaid
          }
        }
        return false
      }, [
        isFullyPaid,
        invoiceIssuedAt,
        lastPaymentAt,
        isLoading,
        accountingAutoSyncSettings?.onFullyPaid,
        accountingAutoSyncSettings?.onIssued,
        accountingAutoSyncSettings?.onPayment,
      ])

      const warningMessage = useMemo(() => {
        if (errorMsg) {
          return isMobile ? 'Failed' : 'Sync Failed'
        }
        return isMobile ? 'Error' : 'Not synced'
      }, [isMobile, errorMsg])

      const warningInfo = useMemo(() => {
        if (errorMsg) {
          return errorMsg
        }
        if (isStale) {
          return `The invoice is out of sync with ${accountingIntegrationType}.`
        }
      }, [isStale, errorMsg, accountingIntegrationType])

      const syncButton = useMemo(
        () => (
          <Button
            className={classNames({
              'mt-2 w-fit': isMobile,
            })}
            type="primary"
            size="large"
            onClick={() => onSyncClick()}
            loading={loading}
            icon={<FontAwesomeIcon icon={faSync} />}
          >
            Sync now
          </Button>
        ),
        [isMobile, loading, onSyncClick],
      )

      if (accountingIntegrationType === 'NONE') {
        return null
      }

      return (
        <OnsitePageSection hideBottomBorder>
          <OnsitePageSimpleSectionItem
            theme={isStale ? 'warning' : undefined}
            layoutClassName={
              isMobile
                ? `${isStale ? 'items-start' : 'items-center'} gap-3`
                : undefined
            }
            icon={
              <img
                alt={`Sync with ${accountingIntegrationType}`}
                src={logoUrls[accountingIntegrationType]}
                className="absolute inset-0"
              />
            }
            rightContent={
              !isStale ? (
                <div className="bz-text-800 flex h-full flex-row items-center space-x-2 text-sm font-semibold">
                  <div>Synced</div>
                  <div className="flex h-5 w-5 items-center justify-center rounded-full bg-bz-green-700 text-xs text-white">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center justify-center gap-3">
                  <WarningStatus message={warningMessage} info={warningInfo} />
                  {!isMobile && syncButton}
                </div>
              )
            }
            footer={isMobile && isStale && syncButton}
            subContent={
              <span className="text-bz-text-secondary">
                Last sync:{' '}
                {`${
                  lastSyncedAt
                    ? BzDateFns.formatFromISO(
                        lastSyncedAt,
                        'MMM. d, yyyy',
                        tzId,
                      )
                    : 'Never'
                }`}
              </span>
            }
          >
            {mapStringToFriendlyAccountingName(accountingIntegrationType)}
          </OnsitePageSimpleSectionItem>
        </OnsitePageSection>
      )
    },
  )

type WarningStatusProps = {
  message: string
  info?: string
}

const WarningStatus = React.memo(({ message, info }: WarningStatusProps) => {
  return (
    <div className="flex min-h-9 flex-row items-center justify-center gap-2">
      <div className="text-sm font-semibold text-bz-orange-700">{message}</div>
      <Tooltip title={info}>
        <div className="flex h-4 w-4 items-center justify-center rounded-full bg-bz-orange-700  text-xs font-semibold text-white">
          <FontAwesomeIcon icon={faExclamation} />
        </div>
      </Tooltip>
    </div>
  )
})

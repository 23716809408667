import { LoadingOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import React from 'react'
import { BreezyAIIcon } from '../../../adam-components/BreezyAI/BreezyAIIcon'
import {
  ESTIMATE_MESSAGE_GENERATOR_PRESETS,
  EstimateMessageGeneratorPreset,
} from '../hooks/useGenerateEstimateMessage'

type EstimateMessageGeneratorProps = {
  isGenerating: boolean
  onGenerateMessage: (preset: EstimateMessageGeneratorPreset) => Promise<void>
}

export const EstimateMessageGenerator =
  React.memo<EstimateMessageGeneratorProps>(
    ({ isGenerating, onGenerateMessage }) => {
      return (
        <div className="mt-4 overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg bg-bz-fill-quaternary px-2 py-1">
            {isGenerating ? (
              <div className="flex h-[32px] items-center space-x-2">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                />
                <span className="text-sm font-medium text-gray-600">
                  Generating estimate message...
                </span>
              </div>
            ) : (
              <div className="flex items-center space-x-3 whitespace-nowrap">
                <BreezyAIIcon />
                <div className="flex space-x-2 overflow-x-auto">
                  {ESTIMATE_MESSAGE_GENERATOR_PRESETS.map(preset => (
                    <Button
                      key={preset}
                      onClick={() => onGenerateMessage(preset)}
                      disabled={isGenerating}
                      className="flex-shrink-0"
                    >
                      {preset}
                    </Button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )
    },
  )

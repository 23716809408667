import { gql } from '../../generated'

export const UPDATE_END_OF_APPOINTMENT_WORKFLOW_ENABLED = gql(/* GraphQL */ `
  mutation UpdateEndOfJobAppointmentWorkflowEnabled(
    $companyGuid: uuid!
    $enabled: Boolean!
  ) {
    updateCompanyConfigByPk(
      _set: { endOfJobAppointmentWorkflowEnabled: $enabled }
      pkColumns: { companyGuid: $companyGuid }
    ) {
      __typename
    }
  }
`)

// Make sure this upsert stays in sync with the settings managed in SettingsNotificationsPage otherwise
// we risk accidentally deleting settings
export const UPDATE_END_OF_APPOINTMENT_WORKFLOW_SETTINGS = gql(/* GraphQL */ `
  mutation UpdateEndOfJobAppointmentWorkflowSettings(
    $companyGuid: uuid!
    $enabled: Boolean!
    $notificationSettings: CompanyNotificationSettingsInsertInput!
  ) {
    updateCompanyConfigByPk(
      _set: { endOfJobAppointmentWorkflowEnabled: $enabled }
      pkColumns: { companyGuid: $companyGuid }
    ) {
      __typename
    }

    insertCompanyNotificationSettingsOne(
      object: $notificationSettings
      onConflict: {
        constraint: company_notification_settings_pkey
        updateColumns: [
          replyToEmail
          estimateAcceptNotifyCreator
          estimateAcceptNotifyEmail
          estimateAcceptNotifyUserGuids
          estimateReminderCount
          estimateReminderFrequency
          estimateReviewedNotifyCreator
          estimateReviewedNotifyUserGuids
          invoicePaymentReminderCount
          invoicePaymentReminderFrequency
          paymentFailedNotifyCreator
          paymentFailedNotifyEmail
          paymentFailedNotifyUserGuids
          materialRestockReportUserGuids
          materialRestockReportEmail
          jobLeadCreatedUserGuids
          jobLeadCreatedEmail
          endOfVisitSendReviewLinkEnabled
          endOfVisitSendReviewLinkFrequency
          endOfVisitSendReviewLinkCount
          endOfVisitSendReviewLinkType
        ]
      }
    ) {
      __typename
    }
  }
`)

export const END_OF_VISIT_NOTIFICATION_SETTINGS_QUERY = gql(/* GraphQL */ `
  query GetEndOfVisitNotificationSettings($companyGuid: uuid!) {
    companyNotificationSettingsByPk(companyGuid: $companyGuid) {
      endOfVisitSendReviewLinkEnabled
      endOfVisitSendReviewLinkFrequency
      endOfVisitSendReviewLinkCount
      endOfVisitSendReviewLinkType
    }
  }
`)
export const GET_END_OF_VISIT_REVIEW_SITE_IDS = gql(/* GraphQL */ `
  query GetEndOfVisitReviewSiteIds($companyGuid: uuid!) {
    companyConfigByPk(companyGuid: $companyGuid) {
      googlePlaceId
      yelpId
      facebookId
      angiId
    }
  }
`)

import { toBreezyPhoneNumber } from '@breezy/shared'
import { faHashtag, faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, List } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useTwilioPhone } from '../../components/PhoneIntegrated/useTwilioPhone'
import {
  ContactBookContact,
  useContactBook,
} from './ContactBook/useContactBook'

type CommCallSearcherProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const CommCallSearcher = ({ isOpen, setIsOpen }: CommCallSearcherProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState<ContactBookContact[]>([])
  const { searchContacts, fetching, error } = useContactBook()
  const { beginVoiceCallTo } = useTwilioPhone()

  useEffect(() => {
    const focusSearchInput = () => {
      const searchInput = document.querySelector(
        '[data-testid="onsiteModalSearchInput"]',
      ) as HTMLInputElement
      if (searchInput) {
        searchInput.focus()
        return true
      }
      return false
    }

    const intervalId = setInterval(() => {
      if (focusSearchInput()) {
        clearInterval(intervalId)
      }
    }, 100)

    return () => clearInterval(intervalId)
  }, [isOpen])

  useEffect(() => {
    if (searchTerm && !isPhoneNumber(searchTerm)) {
      const results = searchContacts(searchTerm)
      setSearchResults(results)
    } else if (searchResults.length > 0) {
      setSearchResults([])
    }
  }, [searchTerm, searchResults, searchContacts])

  const closeModal = () => {
    setSearchTerm('')
    setIsOpen(false)
  }

  const handleCallInitiationForPhoneNumber = useCallback(
    async (phoneNumber: string) => {
      await beginVoiceCallTo({
        phoneNumber: toBreezyPhoneNumber(phoneNumber),
      })
      setSearchTerm('')
      setIsOpen(false)
    },
    [beginVoiceCallTo, setIsOpen],
  )

  const handleCallInitiationForContact = useCallback(
    async (contact: ContactBookContact) => {
      await beginVoiceCallTo({
        phoneNumber: contact.primaryPhoneNumber,
        contact: contact,
      })
      setSearchTerm('')
      setIsOpen(false)
    },
    [beginVoiceCallTo, setIsOpen],
  )

  const header = useMemo(() => <>Initiate a call</>, [])

  const highlightSearchTerm = (text: string) => {
    if (!searchTerm) return text
    try {
      const escapedSearchTerm = searchTerm.replace(
        /[.*+?^${}()|[\]\\]/g,
        '\\$&',
      )
      const parts = text.split(new RegExp(`(${escapedSearchTerm})`, 'gi'))
      return parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span key={index} className="text-[#1677FF]">
            {part}
          </span>
        ) : (
          part
        ),
      )
    } catch (error) {
      console.error('Error in highlightSearchTerm:', error)
      return text
    }
  }

  const isPhoneNumber = (value: string) => {
    return /^\d{10}$/.test(value.replace(/\D/g, ''))
  }

  return (
    <OnsiteModal open={isOpen} onClose={closeModal} size="large">
      <OnsiteModalContent
        header={header}
        onClose={closeModal}
        searchTerm={searchTerm}
        onSearch={setSearchTerm}
        searchPlaceholder="Enter a name or phone number"
        searchIcon={faHashtag}
      >
        {isPhoneNumber(searchTerm) ? (
          <List.Item
            className="flex cursor-pointer flex-row items-center border-b-0 border-l-0 border-r-0 border-t border-solid border-[#F0F0F0] px-4 py-5 hover:bg-gray-50"
            onClick={() => handleCallInitiationForPhoneNumber(searchTerm)}
          >
            <div className="flex w-full flex-row items-center justify-between overflow-hidden">
              <div className="flex w-full flex-row items-center justify-between overflow-hidden">
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <Avatar className="mr-3 flex h-[36px] w-[36px] flex-shrink-0 items-center justify-center bg-[#F0F0F0]">
                      <FontAwesomeIcon
                        icon={faUser}
                        className="text-[#8c8c8c]"
                      />
                    </Avatar>
                    <div className="block text-[16px] font-semibold leading-[24px] text-[#1F1F1F]">
                      Direct Number
                    </div>
                  </div>
                  <div className="text-right text-base leading-6 text-[#1F1F1F]">
                    {toBreezyPhoneNumber(searchTerm)}
                  </div>
                </div>
              </div>
            </div>
          </List.Item>
        ) : (
          <>
            {searchResults.length > 0 && !fetching && (
              <List
                loading={fetching}
                dataSource={searchResults}
                renderItem={contact => (
                  <List.Item
                    className="flex cursor-pointer flex-row items-center border-b-0 border-l-0 border-r-0 border-t border-solid border-[#F0F0F0] px-4 py-5 hover:bg-gray-50"
                    onClick={() => handleCallInitiationForContact(contact)}
                  >
                    <Avatar className="mr-3 flex h-[36px] w-[36px] flex-shrink-0 items-center justify-center bg-[#F0F0F0]">
                      <span className="text-sm font-semibold text-[#595959]">
                        {contact.firstName[0]}
                        {contact.lastName[0]}
                      </span>
                    </Avatar>
                    <div className="flex w-full flex-row items-center justify-between overflow-hidden">
                      <div className="mr-4 flex-grow truncate text-left">
                        <span className="block truncate text-base font-semibold leading-6 text-[#1F1F1F]">
                          {highlightSearchTerm(
                            `${contact.firstName} ${contact.lastName}`,
                          )}
                        </span>
                        <span className="block truncate text-[14px] font-normal leading-[22px] text-[#8C8C8C]">
                          {highlightSearchTerm(contact.accountDisplayName)}
                        </span>
                      </div>
                      <div className="flex-shrink-0 text-right text-base leading-6 text-[#1F1F1F]">
                        {highlightSearchTerm(
                          toBreezyPhoneNumber(contact.primaryPhoneNumber),
                        )}
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            )}
            {!searchTerm && searchResults.length === 0 && !fetching && (
              <div className="flex flex-col justify-center rounded-lg border border-solid border-[#D9D9D9] bg-[#FAFAFA] p-3 text-center">
                <p className="m-0 w-full text-left text-[14px] font-normal leading-[22px] tracking-[-0.14px] text-[#8C8C8C]">
                  Enter a name or phone number above to start a call.
                </p>
              </div>
            )}
            {fetching && <LoadingSpinner />}
            {error && <p className="text-red-500">Error: {error.message}</p>}
          </>
        )}
      </OnsiteModalContent>
    </OnsiteModal>
  )
}

export default CommCallSearcher

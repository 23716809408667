import {
  CalculatePaths,
  EquipmentDisplayNames,
  getMapAppUrl,
  JobLeadEstimateTypeDisplayNames,
  JobRequestTypeDisplayNames,
  phoneUtils,
} from '@breezy/shared'
import {
  faArchive,
  faCalendarCirclePlus,
  faCopy,
} from '@fortawesome/pro-light-svg-icons'
import {
  faCheck,
  faMessageDollar,
  faPhoneArrowDownLeft,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, message } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from 'urql'
import { BreezyAIIcon } from '../../adam-components/BreezyAI/BreezyAIIcon'
import { DetailChip } from '../../adam-components/ListPage/DetailChip'
import CopyToClipboard from '../../elements/CopyToClipboard/CopyToClipboard'
import { TimeAgo } from '../../elements/TimeAgo/TimeAgo'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { TranscriptSummaryAndRecording } from '../CommsPage/CommConvoElements'
import { JobAvailabilitiesView } from './JobLeadAvailabilityView'
import { UPDATE_JOB_LEAD_STATUS } from './JobLeads.gql'
import { NotCapturedView } from './NotCapturedView'
import { JobLead } from './types'
import { jobLeadsHeader } from './utils'

type ItemProps = React.PropsWithChildren<{
  title: React.ReactNode
  className?: string
  rightContent?: React.ReactNode
}>

export const Item = React.memo<ItemProps>(
  ({ title, children, className, rightContent }) => (
    <div className={classNames('', className)}>
      <div className="mb-1 flex flex-row items-center">
        <div className="flex-1 text-sm font-semibold">{title}</div>
        {rightContent}
      </div>
      <div>{children ? children : <NotCapturedView />}</div>
    </div>
  ),
)

type MaintenancePlanChipProps = {
  maintenancePlan: {
    name: string
    color: string
  }
  className?: string
}

const MaintenancePlanChip = React.memo<MaintenancePlanChipProps>(
  ({ maintenancePlan, className }) => {
    return (
      <DetailChip
        className={className}
        accentColor={maintenancePlan.color}
        icon={
          <FontAwesomeIcon
            icon={faScrewdriverWrench}
            style={{ color: maintenancePlan.color }}
          />
        }
      >
        {maintenancePlan.name}
      </DetailChip>
    )
  },
)

const NotAMemberChip = React.memo(() => (
  <DetailChip className="w-fit text-bz-orange-700">Not a member</DetailChip>
))

type JobLeadViewProps = {
  jobLead: JobLead
  refetch: () => void | Promise<void>
  onConvertJob: (jobLead: JobLead) => void
}

export const JobLeadView = React.memo<JobLeadViewProps>(
  ({ jobLead, refetch, onConvertJob }) => {
    const companyGuid = useExpectedCompanyGuid()

    const [{ fetching: isMutatingJobLeadStatus }, executeMutation] =
      useMutation(UPDATE_JOB_LEAD_STATUS)
    const onClickArchive = useCallback(async () => {
      await executeMutation({
        companyGuid,
        jobLeadGuid: jobLead.jobLeadGuid,
        set: { jobLeadStatus: 'archived' },
      })

      message.success(`Lead Archived`)

      refetch()
    }, [companyGuid, executeMutation, jobLead.jobLeadGuid, refetch])

    const addressToken = useMemo(() => {
      const addr = [
        jobLead.serviceAddressLine1 && jobLead.serviceAddressLine2
          ? `${jobLead.serviceAddressLine1} ${jobLead.serviceAddressLine2}`
          : jobLead.serviceAddressLine1,
        jobLead.serviceAddressCity,
        jobLead.serviceAddressStateAbbreviation,
        jobLead.serviceAddressZipCode,
      ]
        .map(str => str?.trim())
        .filter(Boolean)
        .join(', ')

      if (!addr || addr.trim().length === 0) return null
      return addr
    }, [
      jobLead.serviceAddressCity,
      jobLead.serviceAddressLine1,
      jobLead.serviceAddressLine2,
      jobLead.serviceAddressStateAbbreviation,
      jobLead.serviceAddressZipCode,
    ])

    const jobLocationMaintenancePlan = useMemo(() => {
      if (!jobLead.job?.location) return undefined
      const maintenancePlan = jobLead.job.location.maintenancePlans?.[0]
      if (!maintenancePlan) return undefined

      const name =
        maintenancePlan.maintenancePlanDefinition?.marketingInfo?.name
      const color =
        maintenancePlan.maintenancePlanDefinition?.flare?.primaryColorHex

      if (!name || !color) return undefined

      return { name, color }
    }, [jobLead.job?.location])

    const preExistingLocationMaintenancePlan = useMemo(() => {
      if (!jobLead.preExistingLocation) return undefined
      const maintenancePlan = jobLead.preExistingLocation.maintenancePlans?.[0]
      if (!maintenancePlan) return undefined

      const name =
        maintenancePlan.maintenancePlanDefinition?.marketingInfo?.name
      const color =
        maintenancePlan.maintenancePlanDefinition?.flare?.primaryColorHex

      if (!name || !color) return undefined

      return { name, color }
    }, [jobLead.preExistingLocation])

    const jobLeadMaintenancePlan = useMemo(() => {
      if (jobLead.jobLeadStatus === 'converted') {
        return jobLocationMaintenancePlan
      }
      return preExistingLocationMaintenancePlan
    }, [
      jobLead.jobLeadStatus,
      jobLocationMaintenancePlan,
      preExistingLocationMaintenancePlan,
    ])

    const account = useMemo(() => {
      const account = jobLead.job?.account
      if (!account) return undefined

      return {
        accountGuid: account.accountGuid,
        accountDisplayName: account.accountDisplayName,
      }
    }, [jobLead.job?.account])

    const jobLeadHeader = useMemo(() => jobLeadsHeader(jobLead), [jobLead])

    const onClickConvertToJob = useCallback(() => {
      onConvertJob(jobLead)
    }, [jobLead, onConvertJob])

    const additionalMetadata = jobLead.additionalMetadata

    return (
      <div className="job-leads-container flex w-full p-4">
        <div className="job-leads-container-icon-container w-15 h-15 flex-shrink-0">
          <div className="self-start">
            {(jobLead.jobLeadStatus === 'open' ||
              jobLead.jobLeadStatus === 'archived') && (
              <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-bz-gray-300 p-3">
                <FontAwesomeIcon
                  icon={
                    additionalMetadata?.type === 'phone-call'
                      ? faPhoneArrowDownLeft
                      : faMessageDollar
                  }
                  className="mt-0.5 h-[18px] w-[16px] text-bz-gray-600"
                />
              </div>
            )}
            {jobLead.jobLeadStatus === 'converted' && (
              <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-bz-green-200 p-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="mt-0.5 h-[18px] w-[16px] text-bz-green-600"
                />
              </div>
            )}
          </div>
        </div>
        <div className="job-leads-container-main-content mx-4 flex-1">
          <div className="job-leads-container-header flex-1 text-base font-semibold">
            {jobLeadHeader}
          </div>
          <div className="mt-3 grid grid-cols-2 gap-4">
            {account && (
              <div className="col-span-2">
                <Item title="Account">
                  <Link
                    to={CalculatePaths.accountDetails({
                      accountGuid: account.accountGuid,
                    })}
                  >
                    {account.accountDisplayName}
                  </Link>
                </Item>
              </div>
            )}
            <div>
              <Item title="Request Type">
                {jobLead.requestType
                  ? JobRequestTypeDisplayNames[jobLead.requestType]
                  : jobLead.jobType ?? ''}
              </Item>
            </div>
            <div>
              <Item title="Plan Member">
                {jobLeadMaintenancePlan ? (
                  <MaintenancePlanChip
                    className="w-fit"
                    maintenancePlan={jobLeadMaintenancePlan}
                  />
                ) : (
                  <NotAMemberChip />
                )}
              </Item>
            </div>
            <div className="col-span-2">
              <Item title="Additional Details">{jobLead.jobSummary}</Item>
            </div>
            {jobLead.equipmentTypes?.length ? (
              <div>
                <Item title="Equipment">
                  {jobLead.equipmentTypes
                    .map(equipmentType => EquipmentDisplayNames[equipmentType])
                    .join(', ')}
                </Item>
              </div>
            ) : null}
            {jobLead.estimateType && (
              <div>
                <Item title="Estimate Type">
                  {JobLeadEstimateTypeDisplayNames[jobLead.estimateType]}
                </Item>
              </div>
            )}
            <div>
              <Item title="Availability">
                <JobAvailabilitiesView jobLead={jobLead} />
              </Item>
            </div>

            <div>
              <Item title="Address">
                {addressToken ? (
                  <a
                    href={getMapAppUrl(
                      {
                        line1: jobLead.serviceAddressLine1 ?? '',
                        line2: jobLead.serviceAddressLine2,
                        city: jobLead.serviceAddressCity ?? '',
                        stateAbbreviation:
                          jobLead.serviceAddressStateAbbreviation ?? '',
                        zipCode: jobLead.serviceAddressZipCode ?? '',
                      },
                      'google',
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {addressToken}
                  </a>
                ) : null}
              </Item>
            </div>
            <div>
              {jobLead.contactPhoneNumber || jobLead.contactEmailAddress ? (
                <Item title="Contact">
                  {jobLead.contactPhoneNumber && (
                    <div>
                      <a href={`tel:${jobLead.contactPhoneNumber}`}>
                        {phoneUtils.tryFormat(jobLead.contactPhoneNumber)}
                      </a>
                      <CopyToClipboard
                        payload={jobLead.contactPhoneNumber}
                        className="ml-2 inline-block"
                        label={
                          <span className="flex items-center gap-2">
                            <FontAwesomeIcon icon={faCopy} />
                          </span>
                        }
                      />
                    </div>
                  )}
                  {jobLead.contactEmailAddress && (
                    <div>
                      <a href={`mailto:${jobLead.contactEmailAddress}`}>
                        {jobLead.contactEmailAddress}
                      </a>
                      <CopyToClipboard
                        payload={jobLead.contactEmailAddress}
                        className="ml-2 inline-block"
                        label={
                          <span className="flex items-center gap-2">
                            <FontAwesomeIcon icon={faCopy} />
                          </span>
                        }
                      />
                    </div>
                  )}
                </Item>
              ) : (
                <Item title="Contact">{null}</Item>
              )}
            </div>
            {jobLead.leadSource && (
              <div>
                <Item title="Lead Source">{jobLead.leadSource}</Item>
              </div>
            )}
            {jobLead.job && (
              <div>
                <Item title="Converted Job">
                  <Link
                    to={CalculatePaths.jobDetails({
                      jobGuid: jobLead.jobGuid,
                    })}
                  >
                    {jobLead.job?.jobType.name} #{jobLead.job?.displayId}
                  </Link>
                </Item>
              </div>
            )}
          </div>
          {additionalMetadata?.type === 'phone-call' &&
            additionalMetadata?.data?.transcript && (
              <div className="mt-3 max-w-[600px] rounded-2xl bg-[#F5F5F5] p-[10px]">
                <TranscriptSummaryAndRecording
                  transcript={additionalMetadata.data.transcript}
                  recordingUrl={additionalMetadata.data.recordingUrl}
                  className="w-full"
                />
              </div>
            )}
          <div className="job-leads-container-time-created mt-3 flex items-center space-x-2">
            <TimeAgo prefix="Created " date={jobLead.createdAt} />
            {additionalMetadata?.type === 'phone-call' && <BreezyAIIcon />}
          </div>
        </div>
        <div className="job-leads-container-ctas min-w-30 h-15 flex-shrink-0">
          <div className="inline-block">
            <Button
              disabled={isMutatingJobLeadStatus}
              onClick={onClickConvertToJob}
              className={classNames(
                'job-leads-container-ctas-convert-to-job',
                jobLead.jobLeadStatus === 'converted' ? 'invisible' : '',
              )}
              icon={
                <FontAwesomeIcon
                  icon={faCalendarCirclePlus}
                  className="h-[16px] w-[18px]"
                />
              }
            >
              Convert to Job
            </Button>
          </div>
          <div className="ml-2 inline-block">
            <Button
              loading={isMutatingJobLeadStatus}
              onClick={onClickArchive}
              disabled={jobLead.jobLeadStatus === 'archived'}
              // render the button but as invisible instead of hiding it so that we don't lose layout consistency
              className={classNames(
                'job-leads-container-ctas-archive',
                jobLead.jobLeadStatus === 'converted' ? 'invisible' : '',
              )}
              icon={
                <FontAwesomeIcon
                  icon={faArchive}
                  className="h-[16px] w-[18px]"
                />
              }
            />
          </div>
        </div>
      </div>
    )
  },
)

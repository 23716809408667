import { ActivityItemTypesEnum } from '@breezy/backend/src/query'
import { bzExpect, IsoDateString } from '@breezy/shared'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Timeline } from 'antd'
import { useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import rehypeRaw from 'rehype-raw'
import { useQuery } from 'urql'
import { TimeAgo } from '../../elements/TimeAgo/TimeAgo'
import { useExpectedPrincipal } from '../../providers/PrincipalUser'
import GqlQueryLoader from '../GqlQueryLoader/GqlQueryLoader'
import {
  ACTIVITY_ITEMS_BY_ACCOUNT_QUERY,
  ACTIVITY_ITEMS_BY_COMPANY_QUERY,
  ACTIVITY_ITEMS_BY_JOB_QUERY,
} from './ActivityFeed.gql'
import './ActivityFeed.less'

// TECH DEBT: Why is this different than the Domain Activity Item Type???
enum ActivityItemType {
  AppointmentConfirmed = 'APPOINTMENT_CONFIRMED',
  AppointmentScheduled = 'APPOINTMENT_SCHEDULED',
  NoteCreated = 'NOTE_CREATED',
  TechnicianArrived = 'TECHNICIAN_ARRIVED',
  TechnicianAssigned = 'TECHNICIAN_ASSIGNED',
  TextSent = 'TEXT_SENT',
  InvoicePaid = 'INVOICE_PAID',
  PhoneNumberUpdated = 'PHONE_NUMBER_UPDATED',
  EstimateClosed = 'ESTIMATE_CLOSED',
  JobCompleted = 'JOB_COMPLETED',
  Null = 'NULL',
  MAINTENANCE_PLAN_ACTIVATED = 'MAINTENANCE_PLAN_ACTIVATED',
  MAINTENANCE_PLAN_CANCELED = 'MAINTENANCE_PLAN_CANCELED',
  MAINTENANCE_PLAN_CHANGED = 'MAINTENANCE_PLAN_CHANGED',
  MAINTENANCE_PLAN_LAPSED = 'MAINTENANCE_PLAN_LAPSED',
  ESTIMATE_CREATED = 'ESTIMATE_CREATED',
  INVOICE_CREATED = 'INVOICE_CREATED',
}

export enum ActivityFeedContext {
  Dashboard = '',
  Account = ' Account',
  Job = ' Job',
}

type ActivityFeedProps = {
  context: ActivityFeedContext
  accountGuid?: string
  jobGuid?: string
}

export const ActivityFeed = ({
  context,
  accountGuid,
  jobGuid,
}: ActivityFeedProps) => {
  const principal = useExpectedPrincipal()
  const companyGuid = bzExpect(
    principal.company?.companyGuid,
    'companyGuid',
    'User Has No Company',
  )
  const content = useMemo(() => {
    switch (context) {
      case ActivityFeedContext.Dashboard:
        return <DashboardContent companyGuid={companyGuid} />
      case ActivityFeedContext.Account:
        return (
          <AccountContent companyGuid={companyGuid} accountGuid={accountGuid} />
        )

      case ActivityFeedContext.Job:
        return <JobActivityFeed companyGuid={companyGuid} jobGuid={jobGuid} />
    }
  }, [accountGuid, companyGuid, context, jobGuid])

  return (
    <div className="activity-feed">
      <h3>Recent{context} Activity</h3>
      <hr />
      <br />
      {content}
    </div>
  )
}

type ContentProps = {
  companyGuid: string
  accountGuid?: string
  jobGuid?: string
}

interface ActivityItemResult {
  activityItemGuid: string
  activityItemType: ActivityItemTypesEnum
  content: string
  createdAt: IsoDateString
}

const makeActivityFeedItems = (activityItems: ActivityItemResult[]) =>
  activityItems.length === 0
    ? [
        makeActivityFeedItem({
          activityItemType: ActivityItemType.Null,
          content: 'There is no recent activity to display',
        }),
      ]
    : activityItems.map(makeActivityFeedItem)

const DashboardContent = ({ companyGuid }: ContentProps) => {
  const activityItemsQuery = useQuery({
    query: ACTIVITY_ITEMS_BY_COMPANY_QUERY,
    variables: { companyGuid },
  })

  return (
    <GqlQueryLoader
      query={activityItemsQuery}
      render={data => (
        <Timeline items={makeActivityFeedItems(data.activityItems)} />
      )}
    />
  )
}

const AccountContent = ({ companyGuid, accountGuid }: ContentProps) => {
  const activityItemsQuery = useQuery({
    query: ACTIVITY_ITEMS_BY_ACCOUNT_QUERY,
    variables: { companyGuid, accountGuid },
  })

  return (
    <GqlQueryLoader
      query={activityItemsQuery}
      render={data => (
        <Timeline items={makeActivityFeedItems(data.activityItems)} />
      )}
    />
  )
}

export const JobActivityFeed = ({ companyGuid, jobGuid }: ContentProps) => {
  const activityItemsQuery = useQuery({
    query: ACTIVITY_ITEMS_BY_JOB_QUERY,
    variables: { companyGuid, jobGuid },
  })

  return (
    <GqlQueryLoader
      query={activityItemsQuery}
      render={data => (
        <Timeline items={makeActivityFeedItems(data.activityItems)} />
      )}
    />
  )
}

type ActivityItem = {
  activityItemType: ActivityItemType | ActivityItemTypesEnum
  content: string
  createdAt?: IsoDateString
}

export const makeActivityFeedItem = (activityItem: ActivityItem) => ({
  dot: <FontAwesomeIcon icon={faCircle} />,
  color: getActivityItemColor(activityItem),
  children: (
    <div className="activity-feed-item">
      <ReactMarkdown
        components={{
          a: props => <Link to={props.href || '/'}>{props.children}</Link>,
          code: props => (
            // For some reason, the actual note value is converted to a <code> tag. This is a hacky way to render the
            //WYSIWYG HTML output without changing the backend (should be fine for now)
            <>
              {props.children.map(child => (
                <ReactMarkdown
                  key={child as string}
                  rehypePlugins={[rehypeRaw]}
                  children={(child as string).trim()} // this will always be a string
                  className="italic"
                />
              ))}
            </>
          ),
        }}
      >
        {activityItem.content}
      </ReactMarkdown>
      <div className="row flex-end">
        {activityItem.createdAt && <TimeAgo date={activityItem.createdAt} />}
      </div>
    </div>
  ),
})

const ORANGE = '#FF7A45'
const YELLOW_ORANGE = '#faad16'
const TEAL = '#33c2c2'
const GREEN = '#52C41A'
const GREEN_YELLOW = '#a0d912'
const GREY = '#d9d9d9'
const RED = '#d5291f'

const getActivityItemColor = (activityItem: ActivityItem) => {
  switch (activityItem.activityItemType) {
    case ActivityItemType.MAINTENANCE_PLAN_LAPSED:
    case ActivityItemType.MAINTENANCE_PLAN_CANCELED:
      return RED
    case ActivityItemType.AppointmentScheduled:
    case ActivityItemType.TechnicianAssigned:
      return ORANGE
    case ActivityItemType.AppointmentConfirmed:
    case ActivityItemType.TechnicianArrived:
      return YELLOW_ORANGE
    case ActivityItemType.NoteCreated:
    case ActivityItemType.TextSent:
    case ActivityItemType.MAINTENANCE_PLAN_CHANGED:
    case ActivityItemType.ESTIMATE_CREATED:
    case ActivityItemType.INVOICE_CREATED:
      return TEAL
    case ActivityItemType.InvoicePaid:
    case ActivityItemType.EstimateClosed:
    case ActivityItemType.MAINTENANCE_PLAN_ACTIVATED:
      return GREEN
    case ActivityItemType.JobCompleted:
      return GREEN_YELLOW
    case ActivityItemType.PhoneNumberUpdated:
      return GREY
    default:
      return GREY
  }
}

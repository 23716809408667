import { Drawer } from 'antd'
import React from 'react'
import { useWindowSize } from 'react-use'
import {
  EquipmentUpsertForm,
  EquipmentUpsertFormProps,
} from '../EquipmentUpsertForm/EquipmentUpsertForm'

export type EquipmentUpsertDrawerProps = EquipmentUpsertFormProps & {
  isOpen: boolean
}

const EquipmentUpsertDrawer = React.memo<EquipmentUpsertDrawerProps>(
  ({ isOpen, ...equipmentUpsertFormProps }) => {
    const windowSize = useWindowSize()

    return (
      <Drawer
        zIndex={1005}
        title={`${
          equipmentUpsertFormProps.mode === 'update' ? 'Edit' : 'New'
        } Equipment`}
        onClose={equipmentUpsertFormProps.onCancel}
        open={isOpen}
        width={
          windowSize.width < 640
            ? windowSize.width
            : Math.min(windowSize.width, 720)
        }
        destroyOnClose
        height={windowSize.width < 640 ? windowSize.height : undefined}
        placement={windowSize.width < 640 ? 'bottom' : 'right'}
      >
        <EquipmentUpsertForm {...equipmentUpsertFormProps} />
      </Drawer>
    )
  },
)

export default EquipmentUpsertDrawer

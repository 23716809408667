import { PageHeader } from '@ant-design/pro-components'
import { PermissionV2 } from '@breezy/shared'
import { Page } from '../../components/Page/Page'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import RefundPageLoader from './RefundPage'

export const OfficeRefundPage = () => {
  return (
    <Page requiresCompanyUser={true}>
      <PageHeader title="Issue Refund" />
      <Authorized
        to={PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE}
        notAuthorizedView={
          <>
            You do not have permission to view the resource that is being
            requested
          </>
        }
      >
        <RefundPageLoader />
      </Authorized>
    </Page>
  )
}

export default OfficeRefundPage

import {
  BzDateFns,
  IsoDateString,
  QBO_ERROR_CODES,
  noOp,
  parseQboErrorInfo,
} from '@breezy/shared'
import {
  faCheck,
  faExclamation,
  faSync,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { OnsitePageSection } from '../../../adam-components/OnsitePage/OnsitePageSection'
import { OnsitePageSimpleSectionItem } from '../../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import { useQuickbooksAuthorizeButtonProps } from '../../../components/Quickbooks/QuickbooksAuthorizeButton'
import { triggerQBOUnauthModal } from '../../../components/Quickbooks/QuickbooksSyncButton'
import { trpc } from '../../../hooks/trpc'
import useIsMobile from '../../../hooks/useIsMobile'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useMessage, useModal } from '../../../utils/antd-utils'
import { useStrictContext } from '../../../utils/react-utils'
import { InvoiceContext } from '../invoiceUtils'

const useHandleQboSyncError = (
  retry: (force?: boolean) => Promise<unknown>,
) => {
  const qboAuthorizeButtonProps = useQuickbooksAuthorizeButtonProps()

  const Modal = useModal()
  return useCallback(
    (e: Error) => {
      const qboErrorInfo = parseQboErrorInfo((e as Error).message)
      let content =
        (e as Error).message || 'Please try again later or contact support.'

      if (qboErrorInfo) {
        const [errorCode, message] = qboErrorInfo

        content = message

        if (errorCode === QBO_ERROR_CODES.STALE) {
          Modal.warning({
            title: 'Overwrite Quickbooks changes?',
            content: (
              <>
                <p>
                  {message} Syncing will overwrite those changes with Breezy's
                  data.
                </p>
                <p>Would you like to sync anyway?</p>
              </>
            ),
            okText: 'Sync',
            closable: true,
            okCancel: true,
            cancelText: 'Never mind',
            onOk: async () => {
              // If we don't try/catch this, then if the next one causes an error, it
              // won't close the original modal (and you get modals stacked on
              // modals). But since a new failure will make a new modal, we don't care
              // about what happens in the "catch".
              try {
                await retry(true)
              } catch (e) {
                // noop
              }
            },
          })
          return
        } else if (errorCode === QBO_ERROR_CODES.AUTH) {
          triggerQBOUnauthModal(Modal, qboAuthorizeButtonProps, retry)
          return
        }
      }
      console.error(JSON.stringify(e, null, 2))

      Modal.error({
        title: 'Could not sync with Quickbooks',
        content,
      })

      return content
    },
    [Modal, qboAuthorizeButtonProps, retry],
  )
}

const useSyncInvoiceToQbo = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: (msg: string) => void
}) => {
  const message = useMessage()
  const { invoiceGuid } = useStrictContext(InvoiceContext)

  const syncMutation = trpc.qbo['finance-app:sync-invoice'].useMutation({
    // We handle this ourselves
    onError: noOp,
  })

  const onSync = useCallback(
    async (force?: boolean) => {
      await syncMutation.mutateAsync({
        invoiceGuid,
        force,
      })

      message.success('Successfully synced with Quickbooks.')
      onSuccess?.()
    },
    [invoiceGuid, message, onSuccess, syncMutation],
  )

  const handleQboSyncError = useHandleQboSyncError(onSync)

  const onSyncClick = useCallback(async () => {
    try {
      await onSync()
    } catch (e: unknown) {
      const errorMsg = handleQboSyncError(e as Error)
      if (errorMsg) {
        onError?.(errorMsg)
      }
    }
  }, [handleQboSyncError, onError, onSync])

  return {
    onSyncClick,
    loading: syncMutation.isLoading,
  }
}

type QboSectionProps = {
  isStale: boolean
  qboSyncedAt?: IsoDateString
  issuedAt?: IsoDateString
  lastPaymentAt?: IsoDateString
  isFullyPaid?: boolean
}
export const QboSection = React.memo<QboSectionProps>(
  ({ isStale, qboSyncedAt, issuedAt, lastPaymentAt, isFullyPaid }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const { accountingAutoSyncSettings } = useStrictContext(InvoiceContext)
    const [qboErrorMsg, setQboErrorMsg] = useState<string | undefined>(
      undefined,
    )
    const { onSyncClick, loading: manualSyncLoading } = useSyncInvoiceToQbo({
      onSuccess: () => {
        setQboErrorMsg(undefined)
      },
      onError: setQboErrorMsg,
    })

    // There are certain conditions (enabled via the company settings) where an invoice is auto synced: when issued,
    // when a payment is made, and when the invoice is fully paid. We can get a race condition when one of these
    // auto-syncs is happening (based on a 60 second cron) and the user manually syncs (like if they issue the invoice
    // then compulsively hits the "sync" button). As a sketchy hack, we can say that if any of those events (provided
    // the settings are set) happened within the last 60 seconds, we "pretend" we're in a loading state. When they
    // complete, the live query will just make the button go away and say "Synced". If the crons fail for some reason
    // (rare), then the button will spin forever (unless they refresh the page or do some kind of navigation that will
    // cause this component to rerender), which is not that bad of a user experience (not as bad as hitting the button
    // and getting a cryptic error message).
    const loading = useMemo(() => {
      if (manualSyncLoading) {
        return true
      }
      // If the dates are greater than 60 seconds ago, that means they happened within the last 60 seconds.
      const sixtySecondsAgo = BzDateFns.subSeconds(
        BzDateFns.now(BzDateFns.UTC),
        60,
      )

      if (
        accountingAutoSyncSettings?.onIssued &&
        issuedAt &&
        BzDateFns.parseISO(issuedAt, BzDateFns.UTC) > sixtySecondsAgo
      ) {
        return true
      }
      // Both the "onPayment" and "onFullyPaid" will be based on the last payment. The difference is the "onFullyPaid"
      // only triggers if we're also now fully paid.
      if (
        lastPaymentAt &&
        (accountingAutoSyncSettings?.onPayment ||
          accountingAutoSyncSettings?.onFullyPaid)
      ) {
        const lastPaymentDate = BzDateFns.parseISO(lastPaymentAt, BzDateFns.UTC)
        if (lastPaymentDate > sixtySecondsAgo) {
          // If we auto sync on any payment, then if the last payment was within the last 60 seconds, we're loading.
          // Otherwise, because of the outer `if`, we know that we must be `onFullyPaid`. If the last payment was within
          // 60 seconds and we're fully paid, that means the last payment was the one that fully paid it (otherwise
          // there would be a payment after it was fully paid which doesn't make sense). So if we're fully paid, then we
          // know the last payment was the fully-paying one, which means if they have "onFullyPaid" on then we are
          // triggering the auto sync.
          return accountingAutoSyncSettings?.onPayment || isFullyPaid
        }
      }
      return false
    }, [
      isFullyPaid,
      issuedAt,
      lastPaymentAt,
      manualSyncLoading,
      accountingAutoSyncSettings?.onFullyPaid,
      accountingAutoSyncSettings?.onIssued,
      accountingAutoSyncSettings?.onPayment,
    ])

    const isMobile = useIsMobile()

    const qboWarningMessage = useMemo(() => {
      if (qboErrorMsg) {
        return isMobile ? 'Failed' : 'Sync Failed'
      }
      return isMobile ? 'Error' : 'Not synced'
    }, [isMobile, qboErrorMsg])

    const qboWarningInfo = useMemo(() => {
      if (qboErrorMsg) {
        return qboErrorMsg
      }
      if (isStale) {
        return 'The invoice is out of sync with Quickbooks.'
      }
    }, [isStale, qboErrorMsg])

    const qboSyncButton = useMemo(
      () => (
        <Button
          className={classNames({
            'mt-2 w-fit': isMobile,
          })}
          type="primary"
          size="large"
          onClick={onSyncClick}
          loading={loading}
          icon={<FontAwesomeIcon icon={faSync} />}
        >
          Sync now
        </Button>
      ),
      [isMobile, loading, onSyncClick],
    )

    return (
      <OnsitePageSection hideBottomBorder>
        <OnsitePageSimpleSectionItem
          theme={isStale ? 'warning' : undefined}
          layoutClassName={
            isMobile
              ? `${isStale ? 'items-start' : 'items-center'} gap-3`
              : undefined
          }
          icon={
            <img
              alt="Sync with Quickbooks"
              src="https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png"
              className="absolute inset-0"
            />
          }
          rightContent={
            !isStale ? (
              <div className="bz-text-800 flex h-full flex-row items-center space-x-2 text-sm font-semibold">
                <div>Synced</div>
                <div className="flex h-5 w-5 items-center justify-center rounded-full bg-bz-green-700 text-xs text-white">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              </div>
            ) : (
              <div className="flex flex-row items-center justify-center gap-3">
                <QboWarningStatus
                  message={qboWarningMessage}
                  info={qboWarningInfo}
                />
                {!isMobile && qboSyncButton}
              </div>
            )
          }
          footer={isMobile && isStale && qboSyncButton}
          subContent={
            <span className="text-bz-text-secondary">
              Last sync:{' '}
              {`${
                qboSyncedAt
                  ? BzDateFns.formatFromISO(qboSyncedAt, 'MMM. d, yyyy', tzId)
                  : 'Never'
              }`}
            </span>
          }
        >
          QuickBooks Online
        </OnsitePageSimpleSectionItem>
      </OnsitePageSection>
    )
  },
)

type QboWarningStatusProps = {
  message: string
  info?: string
}
const QboWarningStatus = React.memo(
  ({ message, info }: QboWarningStatusProps) => {
    return (
      <div className="flex min-h-9 flex-row items-center justify-center gap-2">
        <div className="text-sm font-semibold text-bz-orange-700">
          {message}
        </div>
        <Tooltip title={info}>
          <div className="flex h-4 w-4 items-center justify-center rounded-full bg-bz-orange-700  text-xs font-semibold text-white">
            <FontAwesomeIcon icon={faExclamation} />
          </div>
        </Tooltip>
      </div>
    )
  },
)

import cn from 'classnames'
import React from 'react'

export type VapiLogoSize = 'sm' | 'md' | 'lg'
type LogoProps = {
  size?: VapiLogoSize
}

export const VapiLogo = React.memo<LogoProps>(({ size = 'md' }) => (
  <div
    className={cn(
      'flex items-center justify-center',
      size === 'sm' ? 'h-6 w-6' : '',
      size === 'md' ? 'h-8 w-8' : '',
      size === 'lg' ? 'h-11 w-11' : '',
    )}
  >
    <img src="/img/vapi-logo.png" alt="Vapi Logo" />
  </div>
))

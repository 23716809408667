import classNames from 'classnames'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { useModalState } from '../../utils/react-utils'

type QRCodeProps = {
  url: string
  size: number
  fullsizeEnlargeMultiplier?: number
  title?: string
  className?: string
  showUrlInLightbox?: boolean
  ddActionName?: string
}

export const QRCode = React.memo<QRCodeProps>(
  ({
    url,
    size,
    fullsizeEnlargeMultiplier = 2,
    title,
    showUrlInLightbox = true,
    ddActionName,
    className,
  }) => {
    const [fullScreenOpen, openFullScreen, closeFullScreen] = useModalState()

    return (
      <>
        <div
          className={classNames(
            'flex flex-col justify-center gap-1',
            className,
          )}
        >
          <div
            className={
              'h-fit w-fit cursor-pointer rounded-md border border-solid border-bz-border bg-white p-[5px]'
            }
            onClick={openFullScreen}
            data-dd-action-name={ddActionName}
          >
            <div className="flex justify-center">
              <QRCodeSVG value={url} size={size} />
            </div>
          </div>
        </div>

        {fullScreenOpen && (
          <OnsiteModal
            size="full"
            onClose={closeFullScreen}
            className="max-w-screen z-[1011] max-h-screen bg-transparent"
          >
            <OnsiteModalContent header={title} onClose={closeFullScreen}>
              <div className="flex min-h-0 flex-1 flex-col items-center gap-8 py-12">
                <div className="h-fit w-fit rounded-md border border-solid border-bz-border bg-white p-[5px]">
                  <div className="flex justify-center">
                    <QRCodeSVG
                      value={url}
                      size={size * fullsizeEnlargeMultiplier}
                    />
                  </div>
                </div>
                {showUrlInLightbox && (
                  <div className="w-full px-6 text-center">
                    <p className={`text-sm text-bz-text-secondary`}>{url}</p>
                  </div>
                )}
              </div>
            </OnsiteModalContent>
          </OnsiteModal>
        )}
      </>
    )
  },
)

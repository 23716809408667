import { BzDateTime } from '@breezy/shared'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import BzSelect from '../../elements/BzSelect/BzSelect'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { JOB_PICKER_SELECT_QUERY } from './JobPickerSelect.gql'

type JobPickerSelectProps = {
  accountGuid: string
  onChange: (jobGuid: string) => void
}

export const JobPickerSelect = ({
  accountGuid,
  onChange,
}: JobPickerSelectProps) => {
  const companyGuid = useExpectedCompanyGuid()
  const tzId = useExpectedCompanyTimeZoneId()

  const [{ data, fetching }] = useQuery({
    query: JOB_PICKER_SELECT_QUERY,
    variables: {
      accountGuid,
      companyGuid,
    },
  })

  const jobOptions = useMemo(() => {
    return data?.jobs
      .sort((a, b) => String(b.createdAt).localeCompare(String(a.createdAt)))
      .map(job => ({
        label: `Job #${job.displayId} - (${
          job.jobType.name
        }) - ${BzDateTime.fromIsoString(
          job.createdAt,
          tzId,
        ).toHumanFriendlyMonthDayYear()}`,
        value: job.jobGuid,
      }))
  }, [data, tzId])

  return (
    <BzSelect
      title="Select a Job"
      options={jobOptions ?? []}
      onChange={onChange}
      loading={fetching}
      value={undefined}
      className="w-full"
    />
  )
}

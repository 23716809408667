import {
  BzDateFns,
  IsoDateString,
  NotificationPreferenceType,
  phoneUtils,
  SendVisitConfirmationDTO,
  TimeZoneId,
} from '@breezy/shared'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { z } from 'zod'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useCompanyVisitNotificationDefault } from './useCompanyVisitNotificationDefault'

/**
 * This function is used to validate the send confirmation data if the visit confirmation card
 * is being used in a form.
 */
export const useIsSendConfirmationValid = (
  data: SendVisitConfirmationDTO | undefined,
) => {
  const visitConfirmationEnabled = useFeatureFlag('visit-confirmation')
  return useMemo(() => {
    if (!visitConfirmationEnabled) {
      return true
    }

    if (!data) {
      return true
    }

    if (!data.sendConfirmationEnabled) {
      return true
    }

    if (data?.sendConfirmationEnabled) {
      // If there's not a user to send to and the send confirmation is enabled, we can't save
      if (!data.to) {
        return false
      }

      // If the notification type is email, we need to check that the email is valid
      if (data.notificationType === 'EMAIL') {
        return z.string().email().safeParse(data.to).success
      } else {
        // If the notification type is SMS, we need to check that the phone number is valid
        return phoneUtils.isPossibleNumber(data.to ?? '', 'US')
      }
    }

    return true
  }, [visitConfirmationEnabled, data])
}

export type JobPointOfContact = {
  notificationPreferenceType: NotificationPreferenceType
  primaryEmailAddress?: string
  primaryPhoneNumber?: string
}

export const toNotificationType = (
  notificationPreferenceType: NotificationPreferenceType,
): 'EMAIL' | 'SMS' => {
  return notificationPreferenceType === NotificationPreferenceType.Sms
    ? 'SMS'
    : 'EMAIL'
}

const useIsVisitWithin24Hours = (
  tzId: TimeZoneId,
  visitStartDate?: IsoDateString,
) => {
  return useMemo(() => {
    if (!visitStartDate) {
      return false
    }

    return (
      BzDateFns.differenceInDays(
        BzDateFns.parseISO(visitStartDate, tzId),
        new Date(),
      ) < 1
    )
  }, [visitStartDate, tzId])
}

type UseVisitConfirmationProps = {
  pointOfContact: JobPointOfContact
  defaultEnabled?: boolean
  defaultReminderEnabled?: boolean
  defaultNotificationType?: 'SMS' | 'EMAIL'
  defaultTo?: string
  visitStartDate?: IsoDateString
}

export const useVisitConfirmation = ({
  pointOfContact,
  defaultEnabled,
  defaultReminderEnabled = true,
  defaultNotificationType,
  defaultTo,
  visitStartDate,
}: UseVisitConfirmationProps) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const { confirmationNotificationSuppressedByDefault } =
    useCompanyVisitNotificationDefault()
  const [sendConfirmationEnabled, setSendConfirmationEnabled] = useState(
    defaultEnabled ?? !confirmationNotificationSuppressedByDefault,
  )
  const isVisitWithin24Hours = useIsVisitWithin24Hours(tzId, visitStartDate)

  const [sendReminderEnabled, setSendReminderEnabled] = useState(
    defaultReminderEnabled && !isVisitWithin24Hours,
  )
  const [notificationType, setNotificationType] = useState(
    defaultNotificationType ??
      toNotificationType(pointOfContact.notificationPreferenceType) ??
      'EMAIL',
  )
  const [to, setTo] = useState(
    defaultTo ??
      (toNotificationType(pointOfContact.notificationPreferenceType) === 'EMAIL'
        ? pointOfContact.primaryEmailAddress
        : pointOfContact.primaryPhoneNumber),
  )

  useEffect(() => {
    // If the visit is within 24 hours, we don't want to send a reminder
    if (isVisitWithin24Hours) {
      setSendReminderEnabled(false)
    }
  }, [isVisitWithin24Hours])

  const resetToDefaultValues = useCallback(() => {
    setSendReminderEnabled(defaultReminderEnabled && !isVisitWithin24Hours)
    setNotificationType(
      defaultNotificationType ??
        toNotificationType(pointOfContact.notificationPreferenceType) ??
        'EMAIL',
    )
    setTo(
      defaultTo ??
        (toNotificationType(pointOfContact.notificationPreferenceType) ===
        'EMAIL'
          ? pointOfContact.primaryEmailAddress
          : pointOfContact.primaryPhoneNumber),
    )
  }, [
    defaultNotificationType,
    defaultReminderEnabled,
    defaultTo,
    isVisitWithin24Hours,
    pointOfContact.notificationPreferenceType,
    pointOfContact.primaryEmailAddress,
    pointOfContact.primaryPhoneNumber,
  ])

  const onChangeSendConfirmationEnabled = useCallback(
    (value: boolean) => {
      setSendConfirmationEnabled(value)
      if (!value) {
        resetToDefaultValues()
      } else if (!isVisitWithin24Hours) {
        setSendReminderEnabled(true)
      }
    },
    [isVisitWithin24Hours, resetToDefaultValues],
  )

  const onChangeNotificationType = useCallback(
    (value: 'EMAIL' | 'SMS') => {
      setNotificationType(value)
      setTo(
        value === 'EMAIL'
          ? pointOfContact.primaryEmailAddress
          : pointOfContact.primaryPhoneNumber,
      )
    },
    [pointOfContact.primaryEmailAddress, pointOfContact.primaryPhoneNumber],
  )

  const onChangeTo = useCallback((value: string) => {
    setTo(value)
  }, [])
  const onChangeSendReminderEnabled = useCallback((value: boolean) => {
    setSendReminderEnabled(value)
  }, [])

  const isValid = useIsSendConfirmationValid({
    sendConfirmationEnabled,
    notificationType,
    to,
    sendReminderEnabled,
  })

  const sendConfirmationData = useMemo<SendVisitConfirmationDTO>(() => {
    return {
      sendConfirmationEnabled,
      sendReminderEnabled,
      notificationType,
      to,
    }
  }, [sendConfirmationEnabled, sendReminderEnabled, notificationType, to])
  return {
    sendConfirmationEnabled,
    sendReminderEnabled,
    notificationType,
    to,
    onChangeSendConfirmationEnabled,
    onChangeNotificationType,
    onChangeTo,
    onChangeSendReminderEnabled,
    isValid,
    sendConfirmationData,
    isVisitWithin24Hours,
  }
}

import { toTitleCase, twilioPhoneNumberToBreezyPhone } from '@breezy/shared'
import { faPhone, faPhonePlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Button, Popover } from 'antd'
import { useMemo, useState } from 'react'
import { useCompanyPhoneSettings } from '../../hooks/useCompanyPhoneEnabled'
import CommCallSearcher from '../../pages/CommsPage/CommCallSearcher'
import { CallOverlay } from './CallOverlay'
import { useTwilioPhone } from './useTwilioPhone'

const connectedColor = 'green'
const busyColor = 'yellow'
const inactiveColor = 'red'

export const PhoneIntegratedWidget = () => {
  const { status, everInteracted, incomingCall, activeCall, outboundCall } =
    useTwilioPhone()
  const { settings } = useCompanyPhoneSettings()
  const [isCallSearcherOpen, setIsCallSearcherOpen] = useState(false)
  const [popoverVisible, setPopoverVisible] = useState(false)

  const inboundPhoneNumber = useMemo(() => {
    if (!settings?.phoneNumbers?.length) return undefined

    const phoneWithoutLeadSource = settings.phoneNumbers.find(
      phone => !phone.leadSourceName,
    )
    if (phoneWithoutLeadSource)
      return twilioPhoneNumberToBreezyPhone(phoneWithoutLeadSource.phoneNumber)

    return twilioPhoneNumberToBreezyPhone(settings.phoneNumbers[0].phoneNumber)
  }, [settings])

  const statusColor = useMemo(() => {
    if (incomingCall || activeCall || outboundCall) return busyColor
    if (status === 'connected') return connectedColor
    return inactiveColor
  }, [status, incomingCall, activeCall, outboundCall])

  const handleInitiateCall = () => {
    setIsCallSearcherOpen(true)
    setPopoverVisible(false)
  }

  const popoverContent = useMemo(
    () => (
      <div className="flex flex-col">
        {everInteracted
          ? `Status: ${toTitleCase(status)}`
          : 'Click anywhere to initialize phone'}
        <div className="mt-3 font-bold">
          {inboundPhoneNumber ? (
            <>Inbound #: {inboundPhoneNumber}</>
          ) : (
            'No inbound number configured'
          )}
        </div>
        <Button
          className="mt-3"
          onClick={handleInitiateCall}
          disabled={statusColor !== connectedColor}
          icon={<FontAwesomeIcon icon={faPhonePlus} />}
        >
          Initiate Call
        </Button>
      </div>
    ),
    [everInteracted, status, inboundPhoneNumber, statusColor],
  )

  return (
    <>
      <Popover
        title="Phone"
        placement="bottomRight"
        content={popoverContent}
        visible={popoverVisible}
        onVisibleChange={setPopoverVisible}
      >
        <Badge dot color={statusColor}>
          <FontAwesomeIcon icon={faPhone} />
        </Badge>
      </Popover>
      <CallOverlay
        incomingCall={incomingCall}
        activeCall={activeCall}
        outgoingCall={outboundCall}
      />
      <CommCallSearcher
        isOpen={isCallSearcherOpen}
        setIsOpen={setIsCallSearcherOpen}
      />
    </>
  )
}

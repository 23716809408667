import { PageHeader } from '@ant-design/pro-components'
import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons'
import React from 'react'
import { Page } from '../../components/Page/Page'
import { Card } from '../../elements/Card/Card'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { AppointmentConfigurationSettingsV2Page } from './AppointmentConfigurationSettingsV2Form'

export const AppointmentConfigurationSettingsPage = React.memo(() => {
  return (
    <Page requiresCompanyUser className="flex flex-col p-0">
      <PageHeader
        title={<PageTitle title="Visit Settings" icon={faCalendarCheck} />}
      />
      <Card className="min-h-0 w-full overflow-auto p-2">
        <AppointmentConfigurationSettingsV2Page />
      </Card>
    </Page>
  )
})

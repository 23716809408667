import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card as AntDCard, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { Styled } from '../../utils/Stylable'

type CardBodyProps = React.PropsWithChildren<{
  title?: ReactNode
  titleAction?: React.ReactNode
  titleMarginBottomClass?: string
  hideTitleDivider?: boolean
  info?: string
  infoNextToTitle?: boolean
  className?: string
}>

/** @deprecated use the card in adam-components */
export const CardBody = React.memo<CardBodyProps>(
  ({
    title,
    titleAction,
    titleMarginBottomClass = 'mb-4',
    hideTitleDivider,
    children,
    info,
    infoNextToTitle,
    className,
  }) => {
    const infoElem = info && (
      <Tooltip title={info}>
        <FontAwesomeIcon
          icon={faCircleInfo}
          className={classNames(
            'text-bz-gray-700',
            infoNextToTitle ? 'ml-2 text-sm' : 'absolute right-4 top-4',
          )}
        />
      </Tooltip>
    )
    return (
      <>
        {title ? (
          <div
            className={classNames(
              'flex flex-row items-center text-base font-semibold text-bz-gray-1000',
              {
                'border-0 border-b border-solid border-bz-gray-400 pb-4':
                  !hideTitleDivider,
              },
              titleMarginBottomClass,
            )}
          >
            <div className="flex flex-1 items-center py-1">
              {title}
              {infoNextToTitle && infoElem}
            </div>
            {titleAction && <div>{titleAction}</div>}
          </div>
        ) : null}
        <div
          className={classNames(
            'relative flex min-h-0 flex-1 flex-col',
            className,
          )}
        >
          {children}
        </div>
        {!infoNextToTitle && infoElem}
      </>
    )
  },
)

type CardProps = Styled<CardBodyProps> & {
  bodyClassName?: string
  noPadding?: boolean
}

/** @deprecated use the card in adam-components */
export const Card = React.memo<CardProps>(
  ({ style, className, bodyClassName, noPadding, ...bodyProps }) => {
    return (
      <AntDCard
        bordered={false}
        style={style}
        className={className}
        bodyStyle={{
          padding: noPadding ? '0' : '12px 16px 16px 16px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardBody {...bodyProps} className={bodyClassName} />
      </AntDCard>
    )
  },
)

import { PermissionV2 } from '@breezy/shared'
import { PermissionGroup } from './types'

export const DEFAULT_PERMISSION_CONTROLS_STATE = {
  isSuperAdmin: false,
  initialPermissions: [] as PermissionV2[],
  selectedPermissions: [] as PermissionV2[],
  CAPABILITIES: {
    customDiscounts: false,
    aiEstimateDescriptions: false,
  },
  OFFICE_EXPERIENCE: {
    enabled: false,
    defaultPermissions: [PermissionV2.USE_OFFICE_EXPERIENCE],
    selectedPermissions: [] as PermissionV2[],
    permissionCategories: {
      OFFICE_ACCOUNTS_JOBS: {
        enabled: false as boolean,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE,
              PermissionV2.OFFICE_ACCOUNTS_JOBS_VIEW,
            ],
            rankedPermissions: [
              { permission: PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE, rank: 1 },
              { permission: PermissionV2.OFFICE_ACCOUNTS_JOBS_VIEW, rank: 2 },
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      OFFICE_SCHEDULE_APPOINTMENTS: {
        enabled: false as boolean,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_MANAGE,
              PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_VIEW,
            ],
            rankedPermissions: [
              {
                permission: PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_MANAGE,
                rank: 1,
              },
              {
                permission: PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_VIEW,
                rank: 2,
              },
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      OFFICE_FINANCIAL_INFORMATION: {
        enabled: false as boolean,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE,
              PermissionV2.OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW,
              PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW,
              PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW,
              PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW,
            ],
            rankedPermissions: [
              {
                permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE,
                rank: 1,
              },
              {
                label: 'View only access',
                rank: 2,
                childPermissionGroups: [
                  {
                    defaultPermissions: [
                      PermissionV2.OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW,
                      PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW,
                      PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW,
                      PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW,
                    ],
                    checkablePermissions: [
                      PermissionV2.OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW,
                      PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW,
                      PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW,
                      PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW,
                    ],
                  },
                ],
              },
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      OFFICE_LEAD_SOURCES: {
        enabled: false as boolean,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_LEAD_SOURCES,
            ],
            rankedPermissions: [
              {
                permission:
                  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_LEAD_SOURCES,
                rank: 1,
              },
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      OFFICE_REPORTS: {
        enabled: false as boolean,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.OFFICE_REPORTS_MANAGE,
              PermissionV2.OFFICE_REPORTS_VIEW,
            ],
            rankedPermissions: [
              { permission: PermissionV2.OFFICE_REPORTS_MANAGE, rank: 1 },
              { permission: PermissionV2.OFFICE_REPORTS_VIEW, rank: 2 },
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      OFFICE_TECHNICIAN_PERFORMANCE: {
        enabled: false as boolean,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_VIEW,
              PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_MANAGE,
            ],
            rankedPermissions: [
              {
                permission: PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_MANAGE,
                rank: 1,
              },
              {
                permission: PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_VIEW,
                rank: 2,
              },
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      OFFICE_TIMESHEETS: {
        enabled: false as boolean,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.OFFICE_TIMESHEETS_VIEW,
              PermissionV2.OFFICE_TIMESHEETS_MANAGE,
            ],
            rankedPermissions: [
              { permission: PermissionV2.OFFICE_TIMESHEETS_MANAGE, rank: 1 },
              { permission: PermissionV2.OFFICE_TIMESHEETS_VIEW, rank: 2 },
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      OFFICE_COMPANY_SETTINGS: {
        enabled: false as boolean,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_MAINTENANCE_PLANS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TAGS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_NOTIFICATIONS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_DRIP_MARKETING,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_APPOINTMENT_SETTINGS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_REQUESTS,
            ],
            checkablePermissions: [
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_MAINTENANCE_PLANS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TAGS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_NOTIFICATIONS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_DRIP_MARKETING,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_APPOINTMENT_SETTINGS,
              PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_REQUESTS,
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      OFFICE_INTEGRATIONS: {
        enabled: false as boolean,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.OFFICE_INTEGRATION_ZAPIER_ACCOUNT_CONNECT,
            ],
            checkablePermissions: [
              PermissionV2.OFFICE_INTEGRATION_ZAPIER_ACCOUNT_CONNECT,
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      OFFICE_INTEGRATED_PHONE: {
        enabled: false as boolean,
        permissionGroups: [
          {
            defaultPermissions: [PermissionV2.OFFICE_INTEGRATED_PHONE],
            rankedPermissions: [
              { permission: PermissionV2.OFFICE_INTEGRATED_PHONE, rank: 1 },
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
    },
  },
  FIELD_EXPERIENCE: {
    enabled: false,
    defaultPermissions: [PermissionV2.USE_FIELD_EXPERIENCE],
    selectedPermissions: [] as PermissionV2[],
    permissionCategories: {
      FIELD_JOB_INFORMATION: {
        enabled: true,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.FIELD_JOB_INFORMATION_MANAGE_FULL,
              PermissionV2.FIELD_JOB_INFORMATION_MANAGE_ASSIGNED,
            ],
            rankedPermissions: [
              {
                permission: PermissionV2.FIELD_JOB_INFORMATION_MANAGE_FULL,
                rank: 1,
              },
              {
                permission: PermissionV2.FIELD_JOB_INFORMATION_MANAGE_ASSIGNED,
                rank: 2,
              },
            ],
            selectedPermissions: [],
          },
          {
            defaultPermissions: [PermissionV2.FIELD_JOB_INFORMATION_CREATE],
            checkablePermissions: [PermissionV2.FIELD_JOB_INFORMATION_CREATE],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      FIELD_ACCOUNTS_CONTACTS: {
        enabled: true,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL,
              PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_FULL,
              PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED,
              PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_ASSIGNED,
            ],
            rankedPermissions: [
              {
                permission: PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL,
                rank: 1,
              },
              {
                permission: PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_FULL,
                rank: 2,
              },
              {
                permission:
                  PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED,
                rank: 3,
              },
              {
                permission: PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_ASSIGNED,
                rank: 4,
              },
            ],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
      FIELD_SCHEDULE_APPOINTMENTS: {
        enabled: true,
        permissionGroups: [
          {
            defaultPermissions: [
              PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE,
              PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_TODAY,
              PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT,
            ],
            rankedPermissions: [
              {
                permission:
                  PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE,
                rank: 1,
              },
              {
                permission:
                  PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_TODAY,
                rank: 2,
              },
              {
                permission:
                  PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT,
                rank: 3,
              },
            ],
            selectedPermissions: [],
          },
          {
            defaultPermissions: [
              PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_VIEW_HISTORICAL_ASSIGNED,
            ],
            checkablePermissions: [
              PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_VIEW_HISTORICAL_ASSIGNED,
            ],
            selectedPermissions: [],
          },
          {
            defaultPermissions: [
              PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_NONE,
            ],
            rankedPermissions: [
              {
                permission:
                  PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_MANAGE,
                rank: 1,
              },
              {
                permission: PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_VIEW,
                rank: 2,
              },
              {
                permission: PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_NONE,
                rank: 3,
              },
            ],
            selectedPermissions: [],
          },
          {
            defaultPermissions: [PermissionV2.FIELD_SCHEDULE_CREATE],
            checkablePermissions: [PermissionV2.FIELD_SCHEDULE_CREATE],
            selectedPermissions: [],
          },
        ] as PermissionGroup[],
      },
    },
  },
}

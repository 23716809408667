import { debounce, isNullish, R } from '@breezy/shared'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, {
  Children,
  DOMAttributes,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useIsMobile, {
  useIsSmallScreen,
  useIsTouchScreen,
} from '../../../hooks/useIsMobile'
import { OnResize, useResizeObserver } from '../../../hooks/useResizeObserver'
import { StateSetter } from '../../../utils/react-utils'
import './Carousel.less'

const GUTTER = 12
const GUTTER_MOBILE = 6

const CONTROL_BUTTON_SLIGHT_OVERLAP = 8

// You can either specify a header or a renderHeaderWithDots function. They are mutually exclusive, so we do a
// discriminated union
type HeaderWithDotsProps = {
  // Instead of rendering the dots at the bottom, like we do by default, you can render a custom header. We give you the
  // dots, and whatever you give us we stick at the TOP of the container.
  renderHeaderWithDots?: (dots: React.ReactNode) => React.ReactNode
  header?: never
}

type HeaderWithoutDotsProps = {
  renderHeaderWithDots?: never
  header?: React.ReactNode
}

type HeaderProps = HeaderWithDotsProps | HeaderWithoutDotsProps

// When `centerItems` is true, then we center the items. Otherwise we left-align them. Margins only make sense for left
// aligned (the margins are automatically what is necessary to center the items in centered mode) so we do discriminated
// union.
type CenterItemsProps = {
  centerItems: true
  margin?: never
}

type LeftAlignProps = {
  centerItems?: false | undefined
  margin?: number
}

type AlignmentProps = CenterItemsProps | LeftAlignProps

type GutterProps = {
  gutter?: number
}

type CarouselProps = React.PropsWithChildren<
  {
    // When specified, there will be gradients on either side of the carousel of this size, so the items "fade out"
    // (makes the margins "misty")
    mistyMargin?: number
    // Optional footer. Useful to put things inside the carousel so the misty gradients don't look weird
    footer?: React.ReactNode
    maxDots?: number
    hideDots?: boolean
  } & HeaderProps &
    AlignmentProps &
    GutterProps &
    (
      | { currentIndex: number; setCurrentIndex: StateSetter<number> }
      | { currentIndex?: never; setCurrentIndex?: never }
    )
>

export const Carousel = React.memo<CarouselProps>(
  ({
    children,
    renderHeaderWithDots,
    centerItems,
    mistyMargin: externalMistyMargin,
    gutter: customGutter,
    margin = 0,
    footer,
    header: externalHeader,
    maxDots,
    hideDots,
    currentIndex: externalCurrentIndex,
    setCurrentIndex: externalSetCurrentIndex,
  }) => {
    const isMobile = useIsMobile()

    const isSmallScreen = useIsSmallScreen()

    const gutter = customGutter ?? (isMobile ? GUTTER_MOBILE : GUTTER)

    const isTouchScreen = useIsTouchScreen()

    // Space between the left edge and the first element
    const [firstChildOffset, setFirstChildOffset] = useState(0)
    // Space between the right edge and the right side of the last element
    const [lastChildOffset, setLastChildOffset] = useState(0)

    // These are the points where, once you've scrolled past them, the child at the next index is the "current element"
    // for the purposes of the dots. In other words, if you've scrolled offsets[i] - 1, then the current element is i.
    // If you've scrolled offsets[i] + 1, the current element is i + 1. For centered items, the "cutover point" (the
    // position where we consider it the "next item") is when the element (plus half a gutter) is entirely to the left
    // of the midpoint. For left-aligned items, it's the width of the item plus the gutter.
    const [offsets, setOffsets] = useState<number[]>([])

    const scrollToIndex = useCallback(
      (index: number) => {
        if (!containerRef.current) {
          return
        }
        // The distance from the left to scroll. The logic differs in centered and left-aligned mode.
        let left = 0

        if (index === 0) {
          // The padding on the left side of the container is always the first offset plus the last offset. The first
          // offset is the space to the left (where the thing starts) and the last offset is for the "bounce", because
          // it's how far you can pull it so the item lines up with the right side of the container (note that this
          // assumes the first and last items are the same size. If this needs to evolve in the future to make the items
          // different sizes, which some of the code here is written to do, then we'll have to revisit this). Since we
          // start off "snapped" to the right place, then we will have to scroll to the left to counteract that last
          // item padding (for the bounce). So the scroll position of the first item is always the last child offset.
          left = lastChildOffset
        } else if (centerItems) {
          // In centered mode, the offsets are the points where you're "half way". If you draw a line through the center
          // of the container, the selected item changes when the midpoint between the two items crosses it. So at the
          // offset you have exactly half an element (and half a gutter) to the left and half an element (and half a
          // gutter) to the right. So for "index", we have that point when the element we want is to the left. To
          // "center" it, which is to scroll to it properly, we need to take that point and go backwards by that half
          // margin and then by half the width of the item. The width of an item is the difference of its offset and the
          // one before it, minus two gutter halves. So we can then scroll backward by half that amount, then scroll a
          // little more for the half gutter. That's what we do here, but the gutters cancel each other out so they are
          // not necessary for the calculation.
          const elementWidth = offsets[index] - offsets[index - 1]
          left = offsets[index] - elementWidth / 2
        } else {
          // In left-align mode the position of the item is always the offset of the previous one. Easy.
          left = offsets[index - 1]
        }

        containerRef.current.children[0]?.scrollTo({
          left,
          behavior: 'smooth',
        })
      },
      [centerItems, lastChildOffset, offsets],
    )

    const [currentIndex, setInternalCurrentIndex] = useState(0)

    const debouncedExternalSetCurrentIndex = useMemo(
      () => debounce((index: number) => externalSetCurrentIndex?.(index), 300),
      [externalSetCurrentIndex],
    )

    const setCurrentIndex = useCallback(
      (index: number) => {
        debouncedExternalSetCurrentIndex(index)
        setInternalCurrentIndex(index)
      },
      [debouncedExternalSetCurrentIndex],
    )

    const debouncedScrollTo = useMemo(
      () => debounce(scrollToIndex, 300),
      [scrollToIndex],
    )

    useEffect(() => {
      if (
        !isNullish(externalCurrentIndex) &&
        externalCurrentIndex !== currentIndex
      ) {
        debouncedScrollTo(externalCurrentIndex)
      }
      // I don't want "currentIndex" to trigger this. Only `externalCurrentIndex`
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedScrollTo, externalCurrentIndex])

    const onScroll = useCallback<
      NonNullable<DOMAttributes<HTMLDivElement>['onScroll']>
    >(
      e => {
        const scrollLeft = (e.target as HTMLDivElement).scrollLeft
        for (let i = 0; i < offsets.length; i++) {
          const offset = offsets[i]
          if (scrollLeft < offset) {
            setCurrentIndex(i)
            break
          }
        }
      },
      [offsets, setCurrentIndex],
    )

    const containerRef = useRef<HTMLDivElement>(null)

    const [containerWidth, setContainerWidth] = useState(0)

    const onResize = useCallback<OnResize>(
      ({ width }) => {
        setContainerWidth(prevWidth => {
          if (prevWidth === width) {
            return prevWidth
          }
          // On resize, reset the state. Needs to be in `setTimeout` because iOS sucks
          setTimeout(() => scrollToIndex(0), 0)

          return width
        })
      },
      [scrollToIndex],
    )

    useResizeObserver(containerRef, onResize)

    // This is where we look at all our stuff and calculate all the offsets etc.
    useLayoutEffect(() => {
      if (!containerRef.current) {
        return
      }

      if (!containerWidth) {
        return
      }

      if (!containerRef.current.children.length) {
        return
      }

      const children = containerRef.current.children[0].children

      const firstChildWidth = children[0].getBoundingClientRect().width

      const lastChildWidth =
        // -2 because we have that fake div thing at the end.
        children[children.length - 2].getBoundingClientRect().width

      if (centerItems) {
        // Pretty straightforward. The space between the left side of the screen and the first item is the width of the
        // screen, minus the width of the item, divided in half.
        const firstChildOffset = (containerWidth - firstChildWidth) / 2
        setFirstChildOffset(firstChildOffset)

        // Same logic as above but for the last item
        const lastChildOffset = (containerWidth - lastChildWidth) / 2
        setLastChildOffset(lastChildOffset)

        const offsets = []

        // Consider an arbitrary item. If it's centered, we want to "cross over" when we scroll by half that element (it
        // was centered before, so half of it is the right-most edge) plus half a gutter. So that's where the offset has
        // to be. The next element's offset will be that number, plus its full width, plus another gutter. So that's
        // what this does. However, for the first element we have to correct for the fact that it begins centered and
        // not at the previous offset. For the first one it's like we said: half the size plus half a gutter. So we
        // subtract by that so when we add a full width and a full gutter inside the loop it comes out to what we want.
        let currentOffset = firstChildOffset - (firstChildWidth + gutter) / 2
        for (const child of children) {
          const childWidth = child.getBoundingClientRect().width
          currentOffset += childWidth + gutter
          offsets.push(currentOffset)
        }
        setOffsets(offsets)
      } else {
        // Since we're left aligned, the first item is offset just by the margin
        const firstChildOffset = margin
        setFirstChildOffset(firstChildOffset)
        // When the last item is all the way scrolled to the left, the distance between its right side and the right
        // edge is the full width, minus its width, but also minus the margin (because it's aligned to the margin on the
        // left and not the left edge of the container)
        const lastChildOffset = containerWidth - lastChildWidth - margin
        setLastChildOffset(lastChildOffset)

        const offsets = []
        // The logic is a little more straightforward than the centered logic. For an arbitrary item, we simply add the
        // width and a gutter to get where the next one starts. For the first item though, we need to start where it
        // starts. It starts scrolled to "lastChildOffset". That's because the padding to the left is the sum of the
        // first child offset (which is just the margin) and the last child offset. That's because we want to start off
        // at the margin (so add that padding), but we also want to be able to scroll the distance between the end of
        // that first item and the right side of the screen to "bounce". If the first and last items are the same width,
        // then that number is the last child offset (that assumption is true now. If it breaks down in the future we'll
        // have a bug here). On load the scroll snapping will automatically scroll to "undo" that amount, so that's the
        // amount where the first item is scrolled. So the offset is that plus the width of the item and gutter.
        let currentOffset = lastChildOffset
        for (const child of children) {
          const childWidth = child.getBoundingClientRect().width
          currentOffset += childWidth + gutter
          offsets.push(currentOffset - 1)
        }
        setOffsets(offsets)
      }
    }, [centerItems, containerWidth, gutter, margin])

    const showBigScrollArrows = !isTouchScreen && !isSmallScreen && centerItems

    const dots = useMemo(() => {
      return Children.count(children) > 1 ? (
        <div className="flex flex-row items-center gap-x-1">
          {!showBigScrollArrows && !isTouchScreen && (
            <Button
              className="mr-1"
              disabled={currentIndex === 0}
              onClick={() => scrollToIndex(currentIndex - 1)}
              shape="circle"
              icon={<FontAwesomeIcon icon={faChevronLeft} />}
            />
          )}
          {R.range(0, maxDots ?? Children.count(children)).map(index => (
            <div
              key={index}
              onClick={() => scrollToIndex(index)}
              className={classNames(
                'h-[10px] w-[10px] cursor-pointer rounded-full transition-all ease-in-out',
                index === currentIndex ? 'bg-bz-primary' : 'bg-bz-gray-600',
              )}
            />
          ))}
          {!showBigScrollArrows && !isTouchScreen && (
            <Button
              className="ml-1"
              disabled={
                currentIndex === (maxDots ?? Children.count(children)) - 1
              }
              onClick={() => scrollToIndex(currentIndex + 1)}
              shape="circle"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
            />
          )}
        </div>
      ) : null
    }, [
      showBigScrollArrows,
      isTouchScreen,
      currentIndex,
      maxDots,
      children,
      scrollToIndex,
    ])

    const header = useMemo(() => {
      let content: React.ReactNode = null
      if (externalHeader) {
        content = externalHeader
      }
      if (renderHeaderWithDots) {
        content = renderHeaderWithDots(hideDots ? null : dots)
      }
      if (content) {
        return <div className={`z-[1001]`}>{content}</div>
      }
      return null
    }, [dots, externalHeader, hideDots, renderHeaderWithDots])

    const mistyMargin = externalMistyMargin ?? firstChildOffset

    return (
      <div className="carousel relative flex h-full flex-1 flex-col">
        {header}
        <div
          ref={containerRef}
          className="relative flex h-full flex-1 flex-row justify-center overflow-y-auto"
        >
          {containerWidth > 0 && (
            <>
              <div
                className={classNames(
                  'carousel-item-container-no-scrollbars overscroll-behavior-auto my-auto flex h-fit max-h-full flex-1 snap-x snap-mandatory flex-row items-stretch overflow-x-scroll',
                  {
                    'pb-6': !renderHeaderWithDots,
                  },
                )}
                style={{
                  // The padding on the left side of the container is always the first offset plus the last offset. The
                  // first offset is the space to the left (where the thing starts) and the last offset is for the
                  // "bounce", because it's how far you can pull it so the item lines up with the right side of the
                  // container (note that this assumes the first and last items are the same size. If this needs to
                  // evolve in the future to make the items different sizes, which some of the code here is written to
                  // do, then we'll have to revisit this). The css scroll snapping will automatically scroll us to the
                  // proper position, even with the padding.
                  paddingLeft: `${firstChildOffset + lastChildOffset}px`,
                  // For center mode, since we are snapping on the center of the item it just works. For left-aligned
                  // mode, we don't want to snap to the left side of the item if there's a margin. So we can just add
                  // that so it's snapping to the left of the item plus the margin.
                  scrollPaddingLeft: centerItems
                    ? undefined
                    : `${firstChildOffset}px`,
                }}
                onScroll={onScroll}
              >
                {Children.map(children, (child, index) => {
                  return (
                    <div
                      className={centerItems ? 'snap-center' : 'snap-start'}
                      style={{
                        // Add the gutter to the right of every item, except the last one
                        marginRight:
                          index !== Children.count(children) - 1
                            ? `${gutter}px`
                            : undefined,
                      }}
                    >
                      {child}
                    </div>
                  )
                })}
                {/* Little hack to 1) make enough space so the last item can scroll all the way to the left 2) fixes some
                weird scroll snapping behavior with the last element. Now the last element is this invisible one so the
                real last element works fine. */}
                <div
                  key={lastChildOffset}
                  style={{
                    // The distance between the right side of the screen and the right side of the last item is
                    // "lastChildOffset". However, we want to add onto that to add the "bouncing". The amount to bounce
                    // is the space between the left side of that item and the left side of the container. For centered
                    // mode, is the same offset (it's centered, so the space on the left and right is the same). In
                    // left-aligned mode, it's the margin.
                    minWidth: `${
                      lastChildOffset + (centerItems ? lastChildOffset : margin)
                    }px`,
                  }}
                />
              </div>
              {showBigScrollArrows &&
                (
                  [
                    [true, faChevronLeft],
                    [false, faChevronRight],
                  ] as const
                ).map(([left, icon]) => {
                  const disabled = left
                    ? currentIndex === 0
                    : currentIndex === Children.count(children) - 1
                  return (
                    <div
                      data-testid={`big-scroll-arrow-${
                        left ? 'left' : 'right'
                      }`}
                      data-disabled={disabled}
                      key={left ? 'left' : 'right'}
                      onClick={() =>
                        scrollToIndex(currentIndex + (left ? -1 : 1))
                      }
                      className={classNames(
                        'control-button-drop-shadow border-3 absolute top-1/2 z-[1002] flex h-[88px] w-[88px] translate-y-[-50%] cursor-pointer items-center justify-center rounded-full border-solid border-white bg-bz-primary text-white transition-opacity ease-in-out',
                        left ? 'translate-x-[-100%]' : 'translate-x-[100%]',
                        {
                          'pointer-events-none opacity-50': disabled,
                        },
                      )}
                      style={{
                        [left ? 'left' : 'right']: `${
                          firstChildOffset + CONTROL_BUTTON_SLIGHT_OVERLAP
                        }px`,
                      }}
                    >
                      <FontAwesomeIcon icon={icon} className="text-[32px]" />
                    </div>
                  )
                })}
            </>
          )}
          {renderHeaderWithDots || hideDots ? null : (
            <div className="absolute inset-x-0 bottom-0 flex flex-row items-center justify-center py-2">
              {dots}
            </div>
          )}
        </div>

        {footer}
        {mistyMargin ? (
          <>
            <div
              className="pointer-events-none absolute inset-y-0 left-0 z-[1000] h-full bg-gradient-to-r from-white"
              style={{ width: `${mistyMargin}px` }}
            />
            <div
              className="pointer-events-none absolute inset-y-0 right-0 z-[1000] h-full bg-gradient-to-l from-white"
              style={{ width: `${mistyMargin}px` }}
            />
          </>
        ) : null}
      </div>
    )
  },
)

import {
  BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
  BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_SLUG,
  BulkPricebookCategory,
  BulkPricebookItemRow,
  PricebookCategory,
  QboIncomeAccount,
  nextGuid,
} from '@breezy/shared'
import api from '@flatfile/api'
import FlatfileListener from '@flatfile/listener'
import { autocast } from '@flatfile/plugin-autocast'
import { recordHook } from '@flatfile/plugin-record-hook'
import { createFlatfilePricebookCategoriesWriter } from './FlatfilePricebookCategoriesWriter'
import { createPricebookCategoryGuidReader } from './FlatfilePricebookCategoryGuidReader'
import { createFlatfileQboIncomeAccountsWriter } from './FlatfileQboIncomeAccountsWriter'
import { createFlatfileSheetReader } from './FlatfileSheetReader'
import { createFlatfileSheetRecordsReader } from './FlatfileSheetRecordsReader'
import {
  FlatfilePricebookCategoriesWriter,
  FlatfileQboIncomeAccountsWriter,
  FlatfileSheetRecordsReader,
  PricebookCategoryGuidReader,
} from './FlatfileTypes'
import { fromFlatfileRecordToBulkPricebookCategory } from './fromFlatfileRecordToBulkPricebookCategory'
import { fromFlatfileRecordToBulkPricebookItemRow } from './fromFlatfileRecordToBulkPricebookItemRow'

type FlatfilePricebookImportListenerInput = {
  categories: PricebookCategory[] | undefined
  onSubmit: (
    pricebookCategoryCells: BulkPricebookCategory[],
    pricebookItemCells: BulkPricebookItemRow[],
  ) => void
  triggerErrorModal: (errorMsg: string) => void
  isQuickbooksOnlineEnabled?: boolean
  qboIncomeAccounts?: QboIncomeAccount[]
}
type FlatfilePricebookImportListenerFn = (
  input: FlatfilePricebookImportListenerInput,
) => FlatfileListener

export const createFlatfilePricebookImportListener =
  (
    readSheetRecords: FlatfileSheetRecordsReader,
    writeCategories: FlatfilePricebookCategoriesWriter,
    writeQboIncomeAccounts: FlatfileQboIncomeAccountsWriter,
    readCategoryGuid: PricebookCategoryGuidReader,
  ): FlatfilePricebookImportListenerFn =>
  input => {
    return FlatfileListener.create(listener => {
      listener.on('workbook:created', async ({ context }) => {
        try {
          // seed the pricebook categories sheet with the categories from the app
          await writeCategories({
            workbookId: context.workbookId,
            categories: input.categories,
          })
        } catch (error) {
          console.error('Failed to seed pricebook categories sheet', error)
        }

        try {
          if (!input.qboIncomeAccounts?.length) return
          // seed the QBO income accounts sheet with the accounts from the app
          await writeQboIncomeAccounts({
            workbookId: context.workbookId,
            qboIncomeAccounts: input.qboIncomeAccounts,
          })
        } catch (error) {
          console.error('Failed to seed QBO income accounts sheet', error)
        }
      })

      listener.use(autocast(BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_SLUG))

      listener.use(
        recordHook(
          BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
          async (record, event) => {
            const workbookId = event?.context.workbookId
            if (!record.get('pricebookCategoryGuid')) {
              record.set('pricebookCategoryGuid', nextGuid())
            }

            const parentCategoryName = record.get('parentCategoryName') as
              | string
              | undefined

            if (parentCategoryName) {
              // Can't set parentCategoryName to self
              if (parentCategoryName === record.get('name')) {
                record.set('parentCategoryName', '')
                return record
              }

              const parentCategoryGuid = await readCategoryGuid({
                workbookId,
                name: parentCategoryName,
              })

              if (parentCategoryGuid) {
                record.set('parentCategoryGuid', parentCategoryGuid)
              }
            }

            return record
          },
        ),
      )

      listener.use(
        recordHook(BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_SLUG, async record => {
          if (record.get('category')) {
            const categoryLinks = record.getLinks('category')
            const pricebookCategoryGuid = categoryLinks?.[0]
              ?.pricebookCategoryGuid as string | undefined
            if (pricebookCategoryGuid) {
              record.set('pricebookCategoryGuid', pricebookCategoryGuid)
            }
          }

          if (!record.get('pricebookItemGuid')) {
            record.set('pricebookItemGuid', nextGuid())
          }

          if (!record.get('category') && record.get('pricebookCategoryGuid')) {
            record.set('pricebookCategoryGuid', '')
          }

          if (input.isQuickbooksOnlineEnabled) {
            if (record.get('qboIncomeAccountName')) {
              const qboLinks = record.getLinks('qboIncomeAccountName')
              const qboIncomeAccountId = qboLinks?.[0]?.qboIncomeAccountId as
                | string
                | undefined
              if (qboIncomeAccountId) {
                record.set('qboIncomeAccountId', qboIncomeAccountId)
              }
            }

            if (!record.get('qboIncomeAccountName')) {
              record.set('qboIncomeAccountId', '')
            }
          }

          return record
        }),
      )

      listener.filter({ job: 'workbook:submitActionFg' }, configure => {
        configure.on('job:ready', async ({ context }) => {
          const jobId = context.jobId
          try {
            await api.jobs.ack(jobId, {
              info: 'Getting started.',
              progress: 10,
            })

            const pricebookItemsRes = await readSheetRecords({
              workbookId: context.workbookId,
              slug: BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_SLUG,
            })

            const pricebookItemsCells = pricebookItemsRes.data.records.filter(
              r => r.valid,
            )

            const pricebookCategoriesRes = await readSheetRecords({
              workbookId: context.workbookId,
              slug: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
            })

            const pricebookCategoryCells =
              pricebookCategoriesRes.data.records.filter(r => r.valid)

            try {
              input.onSubmit(
                fromFlatfileRecordToBulkPricebookCategory(
                  pricebookCategoryCells,
                ),
                fromFlatfileRecordToBulkPricebookItemRow(pricebookItemsCells),
              )
            } catch (error) {
              if (error instanceof Error) {
                input.triggerErrorModal(error.message)
                console.error('Error staging flatfile cells for review', error)
              } else {
                console.error('An unexpected error occurred', error)
              }
            }

            await api.jobs.complete(jobId)
          } catch (error) {
            console.error('Error processing flatfile job', error)
            await api.jobs.fail(jobId, {
              outcome: {
                message: 'This job encountered an error.',
              },
            })
          }
        })
      })
    })
  }

export const FlatfilePricebookImportListener =
  createFlatfilePricebookImportListener(
    createFlatfileSheetRecordsReader(createFlatfileSheetReader()),
    createFlatfilePricebookCategoriesWriter(createFlatfileSheetReader()),
    createFlatfileQboIncomeAccountsWriter(createFlatfileSheetReader()),
    createPricebookCategoryGuidReader(
      createFlatfileSheetRecordsReader(createFlatfileSheetReader()),
    ),
  )

import {
  BzDateFns,
  DEFAULT_COMPANY_ENDING_WORK_HOUR,
  DEFAULT_COMPANY_STARTING_WORK_HOUR,
  Guid,
  IsoDateString,
  JobClass,
  R,
  RoleId,
  TechnicianRole,
  bzExpect,
  eligibleForScheduling,
  eligibleToBeAssignedToJobAppointments,
  nextGuid,
  toRoleDisplayName,
} from '@breezy/shared'
import { faChevronDown, faFilter } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Eventcalendar,
  MbscCalendarColor,
  MbscEventDragEvent,
  MbscResource,
  MbscSelectedDateChangeEvent,
  momentTimezone,
} from '@mobiscroll/react'
import { Button, Dropdown, Select, Tooltip } from 'antd'
import { MenuProps } from 'antd/lib'
import { default as classNames } from 'classnames'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useMutation } from 'urql'
import DatePicker from '../../components/DatePicker/DatePicker'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useCanManageSchedule } from '../../hooks/permission/useCanManageSchedule'
import { OnResize, useResizeObserver } from '../../hooks/useResizeObserver'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  DEFAULT_EVENT_COLOR_CONFIG,
  getColorForJobClass,
} from '../../utils/job-utils'
import { useHidableData, useStrictContext } from '../../utils/react-utils'
import { AppointmentEventBox } from './AppointmentEventBox'
import { AssignmentEventBox } from './AssignmentEventBox'
import { BaseEventBox } from './BaseEventBox'
import { ChecklistsDrawer } from './ChecklistsDrawer'
import { DeleteEventData, DeleteEventModal } from './DeleteEventModal'
import { InternalEventBox } from './InternalEventBox'
import { EditBlockModal, NewEventModal } from './NewEventModal'
import {
  OutsideArrivalWindowModal,
  OutsideArrivalWindowResolution,
} from './OutsideArrivalWindowModal'
import { useSchedulePendingChanges } from './PendingChanges/SchedulePendingChangesContext'
import {
  BlockChangeInfo,
  EventChangeInfo,
  NewEvent,
  SetPendingChangesArg,
  isBlockChange,
  resolveRecurringEventUpdate,
} from './PendingChanges/pendingChanges'
import {
  RecurringChangeModal,
  RecurringChangeType,
} from './RecurringChangeModal'
import {
  CompanyScheduleInfo,
  REORDER_TECHS_MUTATION,
  ScheduleAssignment,
} from './Schedule.gql'
import './Schedule.less'
import { useSchedulePlaygroundMode } from './SchedulePlaygroundModeContext'
import { ScheduleWeatherWidget } from './ScheduleWeatherWidget'
import { TechPickerModal } from './TechPickerModal'
import {
  BlockCalendarEvent,
  BzCalendarEvent,
  BzCalendarEventData,
  BzChangeInfo,
  BzEventClickEvent,
  BzEventCreateEvent,
  BzEventUpdateEvent,
  FullScheduleAppointment,
  NewAssignmentCalendarEvent,
  NewAssignmentCalendarEventData,
  NewAssignmentCreateEvent,
  NonBlockCalendarEvent,
  SchedulePageContext,
  SchedulePopoverContext,
  TechnicianResource,
  fixMbscDate,
  isBlockEvent,
  isOutsideOfArrivalWindow,
  resolveUpdateUserGuids,
  withMobiscrollBlockFixForRecurringEvent,
} from './scheduleUtils'
import { useRenderDay } from './useRenderDay'

const isValidJobClassForRole = (role: RoleId, jobClass: JobClass): boolean => {
  let validJobClasses: JobClass[] = []
  switch (role) {
    case RoleId.INSTALL_TECHNICIAN:
      validJobClasses = [JobClass.INSTALL, JobClass.UNKNOWN]
      break
    case RoleId.MAINTENANCE_TECHNICIAN:
      validJobClasses = [JobClass.MAINTENANCE, JobClass.UNKNOWN]
      break
    case RoleId.SERVICE_TECHNICIAN:
      validJobClasses = [
        JobClass.SERVICE,
        JobClass.ESTIMATE_REPAIR,
        JobClass.UNKNOWN,
      ]
      break
    case RoleId.SALES_TECHNICIAN:
      validJobClasses = [
        JobClass.SALES,
        JobClass.ESTIMATE_REPAIR,
        JobClass.ESTIMATE_REPLACE,
        JobClass.UNKNOWN,
      ]
      break
    default:
      return false
  }
  return validJobClasses.includes(jobClass)
}

const toMbscTime = (timeHour24: string | number) =>
  `${`${timeHour24}`.padStart(2, '0')}:00`

const extendEventDefault = () => ({
  color: DEFAULT_EVENT_COLOR_CONFIG.eventColor,
})

type TechElementProps = {
  resource: MbscResource
  techMap: Record<string, TechnicianResource>
  reorderTech: (techGuid: Guid, direction: 'up' | 'down') => void
}

const TechElement = React.memo<TechElementProps>(
  ({ resource, techMap, reorderTech }) => {
    const tech = techMap[resource.id]
    const roles = tech.roles
      .map(role => toRoleDisplayName(role.role))
      .join(', ')

    const fullName = `${tech.firstName} ${tech.lastName}`

    const [reorderOpen, setReorderOpen] = useState(false)

    const dropdownProps = useMemo<MenuProps>(
      () => ({
        items: [
          {
            label: 'Move Up',
            key: 'up',
            onClick: () => reorderTech(tech.userGuid, 'up'),
          },
          {
            label: 'Move Down',
            key: 'down',
            onClick: () => reorderTech(tech.userGuid, 'down'),
          },
        ],
      }),
      [reorderTech, tech.userGuid],
    )

    const rolesContainerRef = useRef<HTMLDivElement>(null)

    const [numRoleRows, setNumRoleRows] = useState(1)

    const onResize = useCallback<OnResize>(({ height }) => {
      if (!rolesContainerRef.current) {
        return
      }

      // NOTE: the style comes back as something like "14px". `parseInt` gets rid of the `px`. If it was something like
      // "14.5px" it would truncate the ".5" which is fine.
      const lineHeight = parseInt(
        window
          .getComputedStyle(rolesContainerRef.current, null)
          .getPropertyValue('line-height'),
      )

      const numRows = Math.floor(height / lineHeight)

      setNumRoleRows(numRows)
    }, [])

    useResizeObserver(rolesContainerRef, onResize)

    return (
      <div className="reorder-button-container flex h-full flex-row items-start text-xs font-normal">
        <div
          className={classNames(
            'flex min-h-8 min-w-8 items-center justify-center rounded-full bg-bz-gray-300 font-semibold text-bz-gray-800',
            {
              'opacity-30': !!tech.deactivatedAt,
            },
          )}
        >
          {tech.avatarShortString}
        </div>

        <div
          className={classNames(
            'mx-2 flex min-w-0 flex-1 flex-col self-stretch ',
            {
              'opacity-30': !!tech.deactivatedAt,
            },
          )}
        >
          <div className="truncate whitespace-nowrap font-semibold leading-5 text-bz-text">
            {fullName}
            {tech.deactivatedAt ? ' (Deactivated)' : ''}
          </div>

          <div
            className="min-h-0 flex-1 overflow-hidden leading-[14px] text-bz-text-tertiary"
            ref={rolesContainerRef}
          >
            <Tooltip title={roles} mouseEnterDelay={0.5}>
              <div
                className="overflow-hidden leading-[14px] text-bz-text-tertiary"
                ref={rolesContainerRef}
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: numRoleRows,
                }}
              >
                {roles}
              </div>
            </Tooltip>
          </div>
        </div>

        <Dropdown menu={dropdownProps} onOpenChange={setReorderOpen}>
          <Button
            size="small"
            className={classNames(
              'reorder-button absolute right-1 top-1 min-h-6 min-w-6',
              {
                'opacity-100': reorderOpen,
              },
            )}
            icon={<FontAwesomeIcon icon={faChevronDown} />}
          />
        </Dropdown>
      </div>
    )
  },
)

// Mobiscroll has this awful, unreproducible bug where it says it can't read property of undefined ("dateKey"). It
// happens when the date changes and there's no reason why it would happen. So in that case instead of giving them an
// error boundary I'm going to refresh the page, which should be less annoying.
const MobiscrollErrorBoundary = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const onError = useCallback((e: Error) => {
      if (e.message.includes('dateKey')) {
        window.location.reload()
      } else {
        throw e
      }
    }, [])
    const FallbackComponent = useCallback(() => <LoadingSpinner />, [])
    return (
      <ErrorBoundary onError={onError} FallbackComponent={FallbackComponent}>
        {children}
      </ErrorBoundary>
    )
  },
)

type ScheduleProps = {
  scheduleInfo: CompanyScheduleInfo
  techList: TechnicianResource[]
}

export const Schedule = React.memo<ScheduleProps>(
  ({ scheduleInfo, techList }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const companyGuid = useExpectedCompanyGuid()
    const message = useMessage()

    const {
      scheduleView,
      selectedDate,
      setSelectedDate,
      setSelectedAppointmentGuid,
      selectedTechGuids,
    } = useStrictContext(SchedulePageContext)

    const { setForcePopoverHidden } = useStrictContext(SchedulePopoverContext)

    const canManageSchedule = useCanManageSchedule()

    const { playgroundMode } = useSchedulePlaygroundMode()

    const {
      pendingChanges,
      setPendingChanges,
      scheduleAssignments,
      internalEvents,
      appointmentMap,
      allAppointments,
    } = useSchedulePendingChanges()

    const renderDay = useRenderDay()

    const viewSettings = useMemo(() => {
      const startTime =
        scheduleInfo.companyWorkingDisplayHours[0]?.workingStartHour ??
        DEFAULT_COMPANY_STARTING_WORK_HOUR

      const endTime =
        scheduleInfo.companyWorkingDisplayHours[0]?.workingEndHour ??
        DEFAULT_COMPANY_ENDING_WORK_HOUR

      const mbscStartTime = toMbscTime(startTime)

      // We don't allow to save the end hour to 23, but clients want their hour to extend to
      // midnight.
      const mbscEndTime = toMbscTime(endTime === 23 ? endTime + 1 : endTime)

      if (scheduleView === 'DISPATCH') {
        return {
          timeline: {
            type: 'day',
            startTime: mbscStartTime,
            endTime: mbscEndTime,
            currentTimeIndicator: true,
            allDay: false,
          },
        } as const
      }
      if (scheduleView === 'DAY') {
        return {
          schedule: {
            type: 'day',
            days: false,
            size: 1,
            startTime: mbscStartTime,
            endTime: mbscEndTime,
            allDay: false,
          },
        } as const
      }
      return {
        schedule: {
          type: 'week',
          size: 1,
          startTime: mbscStartTime,
          endTime: mbscEndTime,
          allDay: false,
        },
      } as const
    }, [scheduleInfo.companyWorkingDisplayHours, scheduleView])

    const techMap = useMemo(
      () => R.indexBy(R.prop('userGuid'), techList),
      [techList],
    )

    const { newEventMap, eventChangeMap, deletedEventMap } = pendingChanges

    const assignmentMap = useMemo(() => {
      const assignmentMap: Record<Guid, ScheduleAssignment | undefined> = {}
      for (const assignment of scheduleAssignments) {
        assignmentMap[assignment.assignmentGuid] = assignment
      }
      return assignmentMap
    }, [scheduleAssignments])

    const [events, eventMap] = useMemo(() => {
      const techGuidFilterMap = R.indexBy(R.identity, selectedTechGuids)

      const makeCommonEventData = (
        userGuids: string[],
        changes: BzChangeInfo | undefined,
        timeWindow: {
          start: IsoDateString
          end: IsoDateString
        },
      ) => ({
        start: changes?.start ?? timeWindow.start,
        end: changes?.end ?? timeWindow.end,
        userGuids,
      })
      const events: NewEvent[] = [...R.values(newEventMap)]
      const eventMap: Record<string, NewEvent> = { ...newEventMap }
      for (const assignment of scheduleAssignments) {
        if (deletedEventMap[assignment.assignmentGuid]) {
          continue
        }
        const changes = eventChangeMap[assignment.assignmentGuid]

        const resolvedUserGuids = changes?.userGuids ?? [
          assignment.technicianUserGuid,
        ]

        const event = {
          ...makeCommonEventData(resolvedUserGuids, changes, assignment),
          resource: resolvedUserGuids[0],
          id: assignment.assignmentGuid,
          assignmentGuid: assignment.assignmentGuid,
          appointmentGuid: assignment.appointmentGuid,
          color: getColorForJobClass(
            assignment.appointment.job.jobType.jobClass,
          ).eventColor,
          editable: assignment.assignmentStatus?.status !== 'COMPLETED',
        }
        if (!selectedTechGuids.length || techGuidFilterMap[event.resource]) {
          events.push(event)
        }
        eventMap[assignment.assignmentGuid] = event
      }

      for (const event of internalEvents) {
        const block = withMobiscrollBlockFixForRecurringEvent(
          { ...event, blockGuid: event.technicianCapacityBlockGuid },
          tzId,
        )

        const unmodifiedStart = event.recurrenceRule ? block.start : undefined
        const unmodifiedEnd = event.recurrenceRule ? block.end : undefined
        const unmodifiedRecurrenceExceptions = event.recurrenceRule
          ? block.recurrenceRuleExceptions
          : undefined

        if (deletedEventMap[block.blockGuid]) {
          continue
        }
        const changes = eventChangeMap[block.blockGuid]

        if (changes && !isBlockChange(changes)) {
          continue
        }

        const resolvedUserGuids = changes?.userGuids ?? block.userGuids

        if (selectedTechGuids.length) {
          let hasAnyTech = false
          for (const userGuid of resolvedUserGuids) {
            if (techGuidFilterMap[userGuid]) {
              hasAnyTech = true
              break
            }
          }
          if (!hasAnyTech) {
            continue
          }
        }

        const eventObj = {
          ...makeCommonEventData(resolvedUserGuids, changes, block),
          id: block.blockGuid,
          blockGuid: block.blockGuid,
          reasonType: changes?.reasonType ?? block.reasonType,
          reasonDescription:
            changes?.reasonDescription ?? block.reasonDescription,
          recurring: changes?.recurrenceRule ?? block.recurrenceRule,
          recurringException:
            changes?.recurrenceRuleExceptions ?? block.recurrenceRuleExceptions,
          color: DEFAULT_EVENT_COLOR_CONFIG.eventColor,
          unmodifiedStart,
          unmodifiedEnd,
          unmodifiedRecurrenceExceptions,
        }

        eventMap[block.blockGuid] = eventObj

        if (scheduleView === 'DISPATCH') {
          for (const userGuid of resolvedUserGuids) {
            if (selectedTechGuids.length && !techGuidFilterMap[userGuid]) {
              continue
            }
            events.push({
              ...eventObj,
              resource: userGuid,
            })
          }
        } else {
          events.push(eventObj)
        }
      }

      return [events, eventMap]
    }, [
      deletedEventMap,
      eventChangeMap,
      internalEvents,
      newEventMap,
      scheduleAssignments,
      scheduleView,
      selectedTechGuids,
      tzId,
    ])

    const [resourceRoleFilters, setResourceRoleFilters] = useState<RoleId[]>([])

    const resources = useMemo(() => {
      if (scheduleView !== 'DISPATCH') {
        return undefined
      }
      const roleFilterMap = resourceRoleFilters.reduce<
        Partial<Record<RoleId, true>>
      >((map, role) => ({ ...map, [role]: true }), {})

      const techGuidFilterMap = R.indexBy(R.identity, selectedTechGuids)
      const resources: MbscResource[] = []

      const techCutoff = BzDateFns.startOfDay(
        BzDateFns.parseISO(selectedDate, tzId),
      )
      for (const tech of techList) {
        if (selectedTechGuids.length && !techGuidFilterMap[tech.userGuid]) {
          continue
        }
        if (!eligibleForScheduling(tech.schedulingCapability)) {
          continue
        }

        if (
          tech.deactivatedAt &&
          BzDateFns.endOfDay(
            BzDateFns.subDays(BzDateFns.parseISO(tech.deactivatedAt, tzId), 1),
          ) < techCutoff
        ) {
          continue
        }

        for (const role of tech.roles) {
          if (!resourceRoleFilters.length || roleFilterMap[role.role]) {
            resources.push({
              id: tech.userGuid,
              name: tech.avatarShortString,
              ...(tech.deactivatedAt
                ? {
                    eventCreation: false,
                    eventDragBetweenResources: false,
                    eventDragBetweenSlots: false,
                    eventDragInTime: false,
                    eventResize: false,
                  }
                : {}),
            })
            break
          }
        }
      }
      return resources
    }, [
      resourceRoleFilters,
      scheduleView,
      selectedDate,
      selectedTechGuids,
      techList,
      tzId,
    ])

    const renderResourceHeader = useCallback(
      () => (
        <Select
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          className="w-full font-normal"
          aria-label="Filter by department"
          placeholder={
            <span>
              <FontAwesomeIcon icon={faFilter} className="mr-2" />
              Department
            </span>
          }
          value={resourceRoleFilters}
          onChange={setResourceRoleFilters}
          // TODO: should we have non-technician filters here?
          options={[
            {
              label: 'Install',
              value: TechnicianRole.INSTALL_TECHNICIAN,
            },
            {
              label: 'Sales',
              value: TechnicianRole.SALES_TECHNICIAN,
            },
            {
              label: 'Service',
              value: TechnicianRole.SERVICE_TECHNICIAN,
            },
            {
              label: 'Maintenance',
              value: TechnicianRole.MAINTENANCE_TECHNICIAN,
            },
          ]}
        />
      ),
      [resourceRoleFilters],
    )

    const onSelectedDateChange = useCallback(
      (e: MbscSelectedDateChangeEvent) => {
        if (!(typeof e.date === 'string' || e.date instanceof Date)) {
          throw new Error('Selected date is not a Date or a string')
        }

        // Mobiscroll gets very confused about time zones. It gives us the time correctly transformed to the local
        // timezone, but the timezone on the date is the local timezone. So if you're on the east coast and it's 10am,
        // and the company is on the west coast, the time will come out as 7am (what you want) but it has "-4:00" on it
        // (I would want it to be UTC). We can fix this by parsing it as the local TZ, then formatting it as the target
        // TZ. Also, I put `startOfDay` because although left and right properly do start of day, "today" just gives
        // "now".
        const typedDateWrongTZ =
          typeof e.date === 'string'
            ? fixMbscDate(e.date)
            : e.date.toISOString()

        const dateCorrectTZ = BzDateFns.parseISO(
          typedDateWrongTZ,
          BzDateFns.LOCAL_TZ,
        )

        const dateStr = BzDateFns.formatISO(
          BzDateFns.startOfDay(dateCorrectTZ),
          tzId,
        )

        setSelectedDate(dateStr)
      },
      [setSelectedDate, tzId],
    )

    const [, reorderTechMutation] = useMutation(REORDER_TECHS_MUTATION)

    const reorderTech = useCallback(
      (techGuid: Guid, direction: 'up' | 'down') => {
        const index = techList.findIndex(tech => tech.userGuid === techGuid)
        // Should not be possible
        if (index === -1) {
          return
        }
        if (
          (direction === 'up' && index === 0) ||
          (direction === 'down' && index === techList.length - 1)
        ) {
          return
        }

        const replacementIndex = index + (direction === 'up' ? -1 : 1)
        const newList = [...techList]
        const tech = newList[index]
        newList[index] = newList[replacementIndex]
        newList[replacementIndex] = tech

        reorderTechMutation({
          companyGuid,
          scheduleTechOrder: R.pluck('userGuid', newList),
        })
      },
      [companyGuid, reorderTechMutation, techList],
    )

    const renderTech = useCallback(
      (resource: MbscResource) => {
        if (scheduleView !== 'DISPATCH') {
          return null
        }
        if (resource.isParent) {
          return <div>{resource.name}</div>
        }
        return (
          <TechElement
            resource={resource}
            techMap={techMap}
            reorderTech={reorderTech}
          />
        )
      },
      [reorderTech, scheduleView, techMap],
    )

    const [checklistAppointment, setChecklistAppointment] =
      useState<FullScheduleAppointment>()

    const [checklistDrawerData, checklistDrawerOpen] =
      useHidableData(checklistAppointment)

    const [deleteEventData, setDeleteEventData] = useState<DeleteEventData>()

    const renderScheduleEvent = useCallback(
      (data: BzCalendarEventData | NewAssignmentCalendarEventData) => {
        const start = fixMbscDate(data.startDate)
        const end = fixMbscDate(data.endDate)
        if (data.original.appointmentInfo) {
          const appointment = bzExpect(
            appointmentMap[data.original.appointmentGuid ?? ''],
            'appointment',
            'Expected an appointment to exist',
          )

          return (
            <AppointmentEventBox
              jobClass={data.original.appointmentInfo.job.jobType.jobClass}
              accountDisplayName={
                data.original.appointmentInfo.job.account.accountDisplayName
              }
              setChecklistAppointment={setChecklistAppointment}
              start={start}
              end={end}
              appointment={appointment}
              assignmentGuid={data.original.assignmentGuid}
            />
          )
        }

        const showTechAvatar = scheduleView !== 'DISPATCH'
        const techs =
          showTechAvatar && data.original.userGuids
            ? data.original.userGuids.map(userGuid => techMap[userGuid])
            : undefined

        const assignment = assignmentMap[data.original?.assignmentGuid ?? '']

        if (assignment) {
          return (
            <AssignmentEventBox
              setChecklistAppointment={setChecklistAppointment}
              assignment={assignment}
              tech={techMap[assignment.technicianUserGuid]}
              start={start}
              end={end}
            />
          )
        }

        if (isBlockEvent(data.original)) {
          return (
            <InternalEventBox
              event={data.original}
              techs={techs}
              start={start}
              end={end}
              setDeleteEventData={setDeleteEventData}
              onEdit={setPendingBlockEdit}
            />
          )
        }

        if (data.original.jobClass && data.original.accountDisplayName) {
          const appointment = bzExpect(
            appointmentMap[data.original.appointmentGuid ?? ''],
            'appointment',
            'Expected an appointment to exist',
          )
          return (
            <AppointmentEventBox
              jobClass={data.original.jobClass}
              accountDisplayName={data.original.accountDisplayName}
              appointment={appointment}
              start={start}
              end={end}
              tech={techMap[`${data.resource}`]}
              assignmentGuid={data.original.assignmentGuid}
              setChecklistAppointment={setChecklistAppointment}
            />
          )
        }

        return (
          <BaseEventBox
            title="New Event"
            colorConfig={DEFAULT_EVENT_COLOR_CONFIG}
            start={start}
            end={end}
          />
        )
      },
      [appointmentMap, assignmentMap, scheduleView, techMap],
    )

    const [pendingOutsideArrivalMove, setPendingOutsideArrivalMove] = useState<{
      changeInfo: EventChangeInfo
      event: NonBlockCalendarEvent | NewAssignmentCalendarEvent
      isCreate: boolean
    }>()

    const [eventPendingTech, setEventPendingTech] = useState<
      BzCalendarEvent | NewAssignmentCalendarEvent
    >()

    const eventPendingTechValidTechs = useMemo(() => {
      if (
        !eventPendingTech ||
        (!eventPendingTech.appointmentInfo && isBlockEvent(eventPendingTech))
      ) {
        return techList
      }
      const appointment = bzExpect(
        appointmentMap[eventPendingTech.appointmentGuid],
        'appointment',
        'Expected appointment to exist for assignment getting assigned new techs.',
      )
      const assignedTechs = R.pluck(
        'technicianUserGuid',
        appointment.assignments ?? [],
      )
      // Give me the list of all techs that AREN'T already assigned to this appointment (but leave the one for the
      // selected event). If I click on the avatar of an assignment, I can't then add a tech that already has an
      // assignment for this appointment. So all of those techs need to be filtered from the tech list we give to that
      // picker. However, we need to leave the one that's assigned to the one they clicked on; if they leave it in we do
      // nothing and if they remove it we delete that assignment.
      return techList.filter(
        tech =>
          tech.userGuid === eventPendingTech.userGuids?.[0] ||
          !assignedTechs.includes(tech.userGuid),
      )
    }, [appointmentMap, eventPendingTech, techList])

    const [pendingRecurringUpdate, setPendingRecurringUpdate] = useState<{
      blockChangeInfo: BlockChangeInfo
      originalEvent: BlockCalendarEvent
      originalOccurrenceStart: IsoDateString
    }>()

    const onRecurringUpdateCancel = useCallback(
      () => setPendingRecurringUpdate(undefined),
      [],
    )
    const onRecurringUpdateOk = useCallback(
      (changeType: RecurringChangeType) => {
        setPendingRecurringUpdate(undefined)

        if (!pendingRecurringUpdate) {
          return
        }
        resolveRecurringEventUpdate(
          setPendingChanges,
          pendingRecurringUpdate.blockChangeInfo,
          pendingRecurringUpdate.originalEvent,
          pendingRecurringUpdate.originalOccurrenceStart,
          changeType,
          tzId,
        )
      },
      [pendingRecurringUpdate, setPendingChanges, tzId],
    )

    const onEventUpdate = useCallback(
      (e: BzEventUpdateEvent) => {
        if (e.event.id && e.event.start && e.event.end) {
          const common = {
            // `newEvent` exists when the item being dragged is a non-assignment item. If the incoming event is an
            // assignment, we use the updated state (which would just be `e.event`)
            userGuids: e.newEvent?.userGuids ?? e.event.userGuids,
            start: fixMbscDate(e.newEvent?.start ?? e.event.start),
            end: fixMbscDate(e.newEvent?.end ?? e.event.end),
          }

          // Special case: they drop from one tech to the other. We need to update the user guids
          if (e.event.resource !== e.oldEvent.resource) {
            common.userGuids = [`${e.event.resource}`]
          }

          if (isBlockEvent(e.event)) {
            const recurrenceRule = e.oldEvent.recurring
              ? `${e.oldEvent.recurring}`
              : undefined
            const value = {
              ...common,
              reasonType: e.event.reasonType,
              reasonDescription: e.event.reasonDescription,
              recurrenceRule,
              recurring: recurrenceRule,
              recurrenceRuleExceptions: e.oldEvent.recurringException
                ? `${e.oldEvent.recurringException}`
                : undefined,
              blockGuid: e.event.blockGuid,
              // If the block event (a non-appointment event) is moved to a different time for the same technician, we
              // just use the current event (`e.event`). If not, then that means the block event is moved to a new user.
              userGuids: resolveUpdateUserGuids(
                e.oldEvent,
                e.newEvent ?? e.event,
              ),
            }
            if (e.event.recurring) {
              const oldOccurrenceStartString = fixMbscDate(
                e.oldEventOccurrence?.start,
              )
              const newStart = BzDateFns.parseISO(common.start, tzId)
              const oldOccurrenceStart = BzDateFns.parseISO(
                oldOccurrenceStartString,
                tzId,
              )
              if (!BzDateFns.isSameDay(newStart, oldOccurrenceStart)) {
                message.error(
                  'You cannot move a recurring event to a different day',
                )
                return false
              }
              setPendingRecurringUpdate({
                originalEvent: e.oldEvent as BlockCalendarEvent,
                originalOccurrenceStart: fixMbscDate(
                  e.oldEventOccurrence?.start,
                ),
                blockChangeInfo: value,
              })
              return false
            } else {
              setPendingChanges({
                field: 'eventChangeMap',
                key: e.event.blockGuid,
                value,
              })
            }
          } else {
            const value: EventChangeInfo = {
              ...common,
              assignmentGuid: e.event.assignmentGuid,
              appointmentGuid: e.event.appointmentGuid,
            }

            const assignment = assignmentMap[e.event.assignmentGuid ?? '']
            if (assignment) {
              if (
                isOutsideOfArrivalWindow(
                  value.start,
                  value.appointmentGuid,
                  assignment.appointment.appointmentWindowStart,
                  assignment.appointment.appointmentWindowEnd,
                  pendingChanges.arrivalWindowChangeMap,
                )
              ) {
                setPendingOutsideArrivalMove({
                  changeInfo: value,
                  event: e.event,
                  isCreate: false,
                })
                return false
              }
            }
            setPendingChanges({
              field: 'eventChangeMap',
              key: e.event.assignmentGuid,
              value,
            })
          }
        }
      },
      [
        assignmentMap,
        message,
        pendingChanges.arrivalWindowChangeMap,
        setPendingChanges,
        tzId,
      ],
    )

    const onPendingOutsideArrivalMoveClose = useCallback(
      (resolution?: OutsideArrivalWindowResolution) => {
        if (!pendingOutsideArrivalMove) {
          return
        }

        if (!resolution || resolution === 'CANCEL') {
          setPendingOutsideArrivalMove(undefined)
          return
        }

        const setPendingChangeArgs: SetPendingChangesArg[] = []

        if (resolution === 'CHANGE') {
          const newStart = BzDateFns.parseISO(
            pendingOutsideArrivalMove.changeInfo.start,
            tzId,
          )

          let appointmentWindowStart: IsoDateString
          let appointmentWindowEnd: IsoDateString
          const assignment =
            assignmentMap[pendingOutsideArrivalMove.changeInfo.assignmentGuid]
          if (assignment) {
            appointmentWindowStart =
              assignment.appointment.appointmentWindowStart
            appointmentWindowEnd = assignment.appointment.appointmentWindowEnd
          } else if (pendingOutsideArrivalMove.event.appointmentInfo) {
            appointmentWindowStart =
              pendingOutsideArrivalMove.event.appointmentInfo
                .appointmentWindowStart
            appointmentWindowEnd =
              pendingOutsideArrivalMove.event.appointmentInfo
                .appointmentWindowEnd
          } else {
            const msg =
              'Expected either an existing assignment in the assignment map or appointment info in a new event, but found neither'
            console.error(msg)
            throw new Error(msg)
          }

          const arrivalStart = BzDateFns.parseISO(appointmentWindowStart, tzId)
          const arrivalEnd = BzDateFns.parseISO(appointmentWindowEnd, tzId)

          const diffMinutes = BzDateFns.differenceInMinutes(
            newStart,
            arrivalStart,
            {
              roundingMethod: 'round',
            },
          )

          const newArrivalEnd = BzDateFns.roundToNearestMinutes(
            BzDateFns.addMinutes(arrivalEnd, diffMinutes),
            {
              nearestTo: 15,
              roundingMethod: 'round',
            },
          )

          setPendingChangeArgs.push({
            field: 'arrivalWindowChangeMap',
            key: pendingOutsideArrivalMove.changeInfo.appointmentGuid,
            value: {
              start: BzDateFns.formatISO(newStart, tzId),
              end: BzDateFns.formatISO(newArrivalEnd, tzId),
            },
          })
        }

        if (pendingOutsideArrivalMove.event.resource) {
          if (pendingOutsideArrivalMove.isCreate) {
            const { appointmentInfo, ...rest } = pendingOutsideArrivalMove.event

            setPendingChangeArgs.push({
              field: 'newEventMap',
              key: pendingOutsideArrivalMove.changeInfo.assignmentGuid,
              value: {
                ...rest,
                start: fixMbscDate(pendingOutsideArrivalMove.event.start),
                end: fixMbscDate(pendingOutsideArrivalMove.event.end),
                userGuids: [`${pendingOutsideArrivalMove.event.resource}`],
              },
            })
          } else {
            setPendingChangeArgs.push({
              field: 'eventChangeMap',
              key: pendingOutsideArrivalMove.changeInfo.assignmentGuid,
              value: pendingOutsideArrivalMove.changeInfo,
            })
          }
        } else {
          setEventPendingTech(pendingOutsideArrivalMove.event)
        }

        setPendingChanges(setPendingChangeArgs)
        setPendingOutsideArrivalMove(undefined)
      },
      [assignmentMap, pendingOutsideArrivalMove, setPendingChanges, tzId],
    )

    const currentWindowForOutsideMoveAppointment = useMemo(() => {
      if (pendingOutsideArrivalMove) {
        const appointment = bzExpect(
          appointmentMap[pendingOutsideArrivalMove?.changeInfo.appointmentGuid],
          'appointment',
          'Expected moved appointment to be in the appointment map',
        )
        return (
          pendingChanges.arrivalWindowChangeMap[
            pendingOutsideArrivalMove.changeInfo.appointmentGuid
          ] ?? {
            start: appointment.appointmentWindowStart,
            end: appointment.appointmentWindowEnd,
          }
        )
      }
    }, [
      pendingChanges.arrivalWindowChangeMap,
      pendingOutsideArrivalMove,
      appointmentMap,
    ])

    const [pendingBlockEdit, setPendingBlockEdit] =
      useState<BlockCalendarEvent>()

    const onEventClick = useCallback(
      (e: BzEventClickEvent) => {
        setForcePopoverHidden(true)
        if (isBlockEvent(e.event)) {
          setPendingBlockEdit(e.event)
        } else {
          setSelectedAppointmentGuid(e.event.appointmentGuid)
        }
      },
      [setForcePopoverHidden, setSelectedAppointmentGuid],
    )

    const onEditBlockModalClose = useCallback(() => {
      setForcePopoverHidden(false)
      setPendingBlockEdit(undefined)
    }, [setForcePopoverHidden])

    const onEditBlockModalSubmit = useCallback(
      (
        newEvent: BlockCalendarEvent,
        recurringChangeType: RecurringChangeType,
      ) => {
        if (newEvent && pendingBlockEdit) {
          if (newEvent.original) {
            resolveRecurringEventUpdate(
              setPendingChanges,
              {
                blockGuid: newEvent.blockGuid,
                userGuids: newEvent.userGuids,
                start: fixMbscDate(newEvent?.start),
                end: fixMbscDate(newEvent?.end),
                reasonType: newEvent.reasonType,
                reasonDescription: newEvent.reasonDescription,
                recurrenceRule: newEvent.recurring
                  ? `${newEvent.recurring}`
                  : undefined,
                recurrenceRuleExceptions: newEvent.recurringException
                  ? `${newEvent.recurringException}`
                  : undefined,
              },
              newEvent.original as BlockCalendarEvent,
              fixMbscDate(newEvent.start),
              recurringChangeType,
              tzId,
            )
          } else {
            setPendingChanges({
              field: 'eventChangeMap',
              key: newEvent.blockGuid,
              value: {
                blockGuid: newEvent.blockGuid,
                userGuids: newEvent.userGuids,
                start: fixMbscDate(newEvent?.start),
                end: fixMbscDate(newEvent?.end),
                reasonType: newEvent.reasonType,
                reasonDescription: newEvent.reasonDescription,
                recurrenceRule: newEvent.recurring
                  ? `${newEvent.recurring}`
                  : undefined,
                recurrenceRuleExceptions: newEvent.recurringException
                  ? `${newEvent.recurringException}`
                  : undefined,
              },
            })
          }
        }

        setPendingBlockEdit(undefined)
      },
      [pendingBlockEdit, setPendingChanges, tzId],
    )

    const onTechPickerModalSave = useCallback(
      (userGuids: Guid[]) => {
        if (eventPendingTech) {
          const existingEvent =
            eventMap[
              eventPendingTech.assignmentGuid ?? eventPendingTech.blockGuid
            ]
          if (existingEvent) {
            if (isBlockEvent(existingEvent)) {
              setPendingChanges({
                field: 'eventChangeMap',
                key: existingEvent.assignmentGuid ?? existingEvent.blockGuid,
                value: {
                  ...existingEvent,
                  recurrenceRule: existingEvent.recurring
                    ? `${existingEvent.recurring}`
                    : undefined,
                  recurrenceRuleExceptions: existingEvent.recurringException
                    ? `${existingEvent.recurringException}`
                    : undefined,
                  start: fixMbscDate(existingEvent.start),
                  end: fixMbscDate(existingEvent.end),
                  userGuids,
                },
              })
            } else {
              // Non-block events are assignments and assignments can only have one user.
              const originalUser = existingEvent.userGuids[0]
              const newUserGuids: Guid[] = []
              let seenOriginal = false
              for (const userGuid of userGuids) {
                if (userGuid === originalUser) {
                  seenOriginal = true
                } else {
                  newUserGuids.push(userGuid)
                }
              }

              const pendingChanges: SetPendingChangesArg[] = []

              // If we didn't find the original user in the new list, that's basically a "delete"
              if (!seenOriginal) {
                pendingChanges.push({
                  field: 'deletedEventMap',
                  key: existingEvent.assignmentGuid,
                  value: {
                    appointmentGuid: existingEvent.appointmentGuid,
                  },
                })
              }
              // Everyone else is an add
              for (const userGuid of newUserGuids) {
                const assignmentGuid = nextGuid()
                pendingChanges.push({
                  field: 'newEventMap',
                  key: assignmentGuid,
                  value: {
                    ...existingEvent,
                    assignmentGuid,
                    userGuids: [userGuid],
                  },
                })
              }
              setPendingChanges(pendingChanges)
            }
          } else {
            for (const userGuid of userGuids) {
              const { appointmentInfo, start, end, ...rest } = eventPendingTech

              setPendingChanges({
                field: 'newEventMap',
                key:
                  eventPendingTech.assignmentGuid ?? eventPendingTech.blockGuid,
                value: {
                  ...rest,
                  start: fixMbscDate(start),
                  end: fixMbscDate(end),
                  resource: userGuid,
                  userGuids,
                },
              })
            }
          }
        }
        setEventPendingTech(undefined)
      },
      [eventMap, eventPendingTech, setPendingChanges],
    )

    const [pendingNewEvent, setPendingNewEvent] = useState<BzCalendarEvent>()

    const onNewEventModalClose = useCallback(
      (newEvent?: NewEvent) => {
        if (newEvent) {
          setPendingChanges({
            field: 'newEventMap',
            key: newEvent.assignmentGuid ?? newEvent.blockGuid,
            value: newEvent,
          })
        }

        setPendingNewEvent(undefined)
      },
      [setPendingChanges],
    )

    const onEventCreate = useCallback(
      (e: BzEventCreateEvent | NewAssignmentCreateEvent) => {
        if (e.event.resource) {
          const user = techMap[`${e.event.resource}`]
          if (user.deactivatedAt) {
            message.warning(
              "You can't create a new event for a deactivated user.",
            )
            return false
          }
        }
        // I know this is an external event from "To Schedule"
        if (e.event.appointmentInfo) {
          const appointment =
            appointmentMap[e.event.appointmentInfo.appointmentGuid]

          if (appointment) {
            if (
              isOutsideOfArrivalWindow(
                fixMbscDate(e.event.start),
                appointment.appointmentGuid,
                appointment.appointmentWindowStart,
                appointment.appointmentWindowEnd,
                pendingChanges.arrivalWindowChangeMap,
              )
            ) {
              setPendingOutsideArrivalMove({
                changeInfo: {
                  assignmentGuid: e.event.assignmentGuid,
                  appointmentGuid: e.event.appointmentGuid,
                  userGuids: e.event.resource ? [`${e.event.resource}`] : [],
                  start: fixMbscDate(e.event.start),
                  end: fixMbscDate(e.event.end),
                },
                event: e.event,
                isCreate: true,
              })
              return false
            }
          }

          if (!e.event.resource) {
            setEventPendingTech(e.event)
            return false
          }
          const { start, end, appointmentInfo, ...rest } = e.event
          setPendingChanges({
            field: 'newEventMap',
            key: e.event.assignmentGuid,
            value: {
              ...rest,
              start: fixMbscDate(start),
              end: fixMbscDate(end),
              userGuids: [`${e.event.resource}`],
            },
          })
          return false
        } else if (e.action === 'drag' || e.action === 'click') {
          // When you edit a recurring event, for whatever reason Mobiscroll, does a "create" as well as an "update". I
          // want to skip the create. I can tell if it's a new event or an existing block that's being edited because an
          // existing one will have a block guid. Thus `isBlockEvent` inside this `onEventCreate` will only be true in
          // this strange case where we are modifying a recurring block (recurring appointments aren't a thing) and it's
          // telling us we created something. So skip.
          if (isBlockEvent(e.event)) {
            return false
          }

          if (playgroundMode) {
            message.error('You cannot create new events in Draft Mode.')
            return false
          }
          setPendingNewEvent(e.event)
          return false
        }
        return false
      },
      [
        techMap,
        message,
        appointmentMap,
        setPendingChanges,
        pendingChanges.arrivalWindowChangeMap,
        playgroundMode,
      ],
    )

    const [scheduleColors, setScheduleColors] = useState<MbscCalendarColor[]>(
      [],
    )

    const onEventDragStart = useCallback(
      (e: MbscEventDragEvent) => {
        setForcePopoverHidden(true)
        const colors: MbscCalendarColor[] = []
        const appointment =
          assignmentMap[e.event.assignmentGuid]?.appointment ??
          e.event.appointmentInfo
        if (appointment) {
          const { jobClass } = appointment.job.jobType
          const arrivalWindowChanges =
            pendingChanges.arrivalWindowChangeMap[e.event.appointmentGuid]
          // Color in the arrival window of the appointment
          colors.push({
            start:
              arrivalWindowChanges?.start ?? appointment.appointmentWindowStart,
            end: arrivalWindowChanges?.end ?? appointment.appointmentWindowEnd,
            // Add 77 to the end of the hex code to add the "a" of "rgba" to make it faint
            background: `${getColorForJobClass(jobClass).eventColor}77`,
          })
          // Grey out technicians who don't match the appointment type. Only relevant in dispatch mode.
          if (scheduleView === 'DISPATCH') {
            const start = BzDateFns.withTimeZone(
              selectedDate,
              tzId,
              BzDateFns.startOfDay,
            )
            const end = BzDateFns.withTimeZone(
              selectedDate,
              tzId,
              BzDateFns.endOfDay,
            )

            for (const tech of R.values(techMap)) {
              if (
                R.none(
                  role => isValidJobClassForRole(role.role, jobClass),
                  tech.roles,
                ) ||
                !eligibleToBeAssignedToJobAppointments(
                  tech.schedulingCapability,
                )
              ) {
                colors.push({
                  start,
                  end,
                  resource: tech.userGuid,
                  background: '#eeeeee',
                })
              }
            }
          }
        }

        setScheduleColors(colors)
      },
      [
        assignmentMap,
        pendingChanges.arrivalWindowChangeMap,
        scheduleView,
        selectedDate,
        setForcePopoverHidden,
        techMap,
        tzId,
      ],
    )

    const onEventDragEnd = useCallback(() => {
      setForcePopoverHidden(false)
      setScheduleColors([])
    }, [setForcePopoverHidden])

    const renderHeader = useCallback(() => {
      const value = BzDateFns.parseISO(selectedDate, tzId)
      return (
        <div className="row flex-between flex w-full items-center">
          <CalendarNav />
          {scheduleView !== 'ONE_WEEK' && <ScheduleWeatherWidget />}
          <div className="row flex items-center justify-end">
            <CalendarPrev />
            <CalendarToday />
            <CalendarNext />
            <DatePicker
              id="schedule-date-picker"
              allowClear={false}
              value={value}
              onChange={date => {
                setSelectedDate(BzDateFns.formatISO(bzExpect(date), tzId))
              }}
              format="MMM Do, YYYY"
            />
          </div>
        </div>
      )
    }, [scheduleView, selectedDate, setSelectedDate, tzId])

    // Mobiscroll's DatePicker fails to work across timezones. We convert this to a Date (implicitly in local time) to
    // make the DatePicker work correctly
    const mobiscrollSelectedDate = useMemo(
      () => BzDateFns.parseISO(selectedDate, tzId),
      [selectedDate, tzId],
    )

    return (
      <>
        <MobiscrollErrorBoundary>
          <Eventcalendar
            className={classNames({
              'can-manage-schedule': canManageSchedule,
            })}
            theme="windows"
            themeVariant="light"
            // NOTE: the options are Moment and Luxon. Even though we hate moment and would prefer to do something akin to
            // BzDateFns, there's not much we can do.
            timezonePlugin={momentTimezone}
            dataTimezone={tzId}
            displayTimezone={tzId}
            view={viewSettings}
            extendDefaultEvent={extendEventDefault}
            data={events}
            resources={resources}
            clickToCreate={canManageSchedule && 'double'}
            dragToCreate={canManageSchedule}
            dragToMove={canManageSchedule}
            dragToResize={canManageSchedule}
            externalDrop
            selectedDate={mobiscrollSelectedDate}
            onSelectedDateChange={onSelectedDateChange}
            onEventUpdate={onEventUpdate}
            onEventClick={onEventClick}
            onEventCreate={onEventCreate}
            eventDelete={false}
            renderScheduleEvent={renderScheduleEvent}
            renderResource={renderTech}
            renderResourceHeader={renderResourceHeader}
            renderDay={renderDay}
            renderHeader={renderHeader}
            colors={scheduleColors}
            onEventDragStart={onEventDragStart}
            onEventDragEnd={onEventDragEnd}
            showEventTooltip={false}
          />
        </MobiscrollErrorBoundary>
        {eventPendingTech && (
          <TechPickerModal
            onClose={() => setEventPendingTech(undefined)}
            onSave={onTechPickerModalSave}
            technicians={eventPendingTechValidTechs}
            prePopulatedChoices={eventPendingTech.userGuids}
          />
        )}
        {pendingNewEvent && (
          <NewEventModal
            onClose={onNewEventModalClose}
            pendingNewEvent={pendingNewEvent}
            technicians={techList}
            appointmentMap={appointmentMap}
            allAppointments={allAppointments}
          />
        )}
        {pendingOutsideArrivalMove &&
          currentWindowForOutsideMoveAppointment && (
            <OutsideArrivalWindowModal
              onClose={onPendingOutsideArrivalMoveClose}
              pendingChange={pendingOutsideArrivalMove.changeInfo}
              currentWindow={currentWindowForOutsideMoveAppointment}
            />
          )}
        {pendingRecurringUpdate && (
          <RecurringChangeModal
            onCancel={onRecurringUpdateCancel}
            onOk={onRecurringUpdateOk}
          />
        )}
        {pendingBlockEdit && (
          <EditBlockModal
            onClose={onEditBlockModalClose}
            onSubmit={onEditBlockModalSubmit}
            blockEvent={pendingBlockEdit}
            technicians={techList}
          />
        )}
        {checklistDrawerData && (
          <ChecklistsDrawer
            open={checklistDrawerOpen}
            appointment={checklistDrawerData}
            onClose={() => setChecklistAppointment(undefined)}
            techMap={techMap}
          />
        )}
        {deleteEventData && (
          <DeleteEventModal
            onClose={() => setDeleteEventData(undefined)}
            data={deleteEventData}
          />
        )}
      </>
    )
  },
)

import { Point } from '@breezy/shared'
import { useCallback } from 'react'

export const getBoundsFromPoints = (points: Point[]) => {
  const bounds = new google.maps.LatLngBounds()

  points
    .map(p => p.coordinates)
    .forEach(([lng, lat]) => {
      bounds.extend(new google.maps.LatLng(lat, lng))
    })

  return bounds
}

export const centerMapOnVisiblePoints = (
  map: google.maps.Map | null,
  points: Point[],
) => {
  if (!map || !points.length) return

  if (points.length === 1) {
    map.moveCamera({
      center: new google.maps.LatLng(
        points[0].coordinates[1],
        points[0].coordinates[0],
      ),
      zoom: 15,
    })
  } else {
    map.fitBounds(getBoundsFromPoints(points))
  }
}

export const useCenterMapOnVisiblePoints = (
  map: google.maps.Map | null,
  points: Point[],
) => {
  return useCallback(() => centerMapOnVisiblePoints(map, points), [map, points])
}

import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Fragment, memo } from 'react'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../../components/GqlQueryLoader/GqlQueryLoader'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import { COMPANY_USERS_SINGLE_QUERY } from '../JobsPage.gql'

type FilterTagV2AccountManagerLabelProps = {
  companyGuid: string
  accountManagerGuid: string
}

const FilterTagV2AccountManagerLabel = ({
  companyGuid,
  accountManagerGuid,
}: FilterTagV2AccountManagerLabelProps) => {
  const companyUserQuery = useQuery({
    query: COMPANY_USERS_SINGLE_QUERY,
    variables: {
      companyGuid,
      userGuid: accountManagerGuid,
    },
  })

  return (
    <GqlQueryLoader
      query={companyUserQuery}
      render={data => (
        <>{data.companyUsers[0]?.userByUserGuid.fullName ?? 'Company User'}</>
      )}
      loadingComponent={<>...</>}
      errorComponent={<>{accountManagerGuid}</>}
    />
  )
}

type FilterTagV2Props = {
  filterKey: string
  label: string
  option: string
  onClick: (filterKey: string, option: string) => void
}

const FilterTagV2 = ({
  filterKey: filter,
  label,
  option,
  onClick,
}: FilterTagV2Props) => {
  const { companyGuid } = useExpectedCompany()

  return (
    <div className="flex flex-row items-center justify-center space-x-2 rounded border border border-solid border-bz-gray-500 bg-white px-2 py-1 text-bz-gray-900">
      <div>
        <span className="font-semibold">{label}</span>
        <span className="text-bz-gray-700"> is </span>
        {filter === 'accountManagers' ? (
          <FilterTagV2AccountManagerLabel
            companyGuid={companyGuid}
            accountManagerGuid={option}
          />
        ) : (
          option
        )}
      </div>
      <FontAwesomeIcon
        icon={faClose}
        onClick={() => onClick(filter, option)}
        className="cursor-pointer"
      />
    </div>
  )
}

type FilterTagGroupV2Props = {
  filterKey: string
  label: string
  options: string[]
  onClick: (filterKey: string, option: string) => void
}

const FilterTagGroupV2 = ({
  filterKey,
  label,
  options,
  onClick,
}: FilterTagGroupV2Props) => {
  return (
    <div
      className={classNames(
        'mb-[2px] mr-2 flex flex-row items-center space-x-1 rounded-md p-1',
        {
          'bg-bz-gray-400': options.length > 1,
        },
      )}
    >
      {options.map((option, idx) => (
        <Fragment key={`${filterKey}-${option}`}>
          <FilterTagV2
            filterKey={filterKey}
            label={label}
            option={option}
            onClick={onClick}
          />

          {options.length > 1 && idx !== options.length - 1 && (
            <span className="font-semibold text-bz-gray-900">or</span>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export type FilterTagGroupListV2Filter = {
  key: string
  optionsSelected: string[]
}

type FilterTagGroupListV2Props = {
  filters: FilterTagGroupListV2Filter[]
  labels: { [key: string]: string }
  onClick: (filterKey: string, option: string) => void
}
export const FilterTagGroupListV2 = memo(
  ({ filters, labels, onClick }: FilterTagGroupListV2Props) => {
    return (
      // We have right and bottom margins for all our things so they have space between horizontally as we add more and
      // vertically if we spill over to the next line. So the ones on the right side and at the bottom will require too
      // much margin, so we compensate by having the container have negative margins on those sides.
      <div className="mb-[-2px] mr-[-8px] flex flex-row flex-wrap items-center">
        {filters.map(({ key, optionsSelected }, idx) => (
          <Fragment key={key}>
            <FilterTagGroupV2
              filterKey={key}
              label={labels[key] ?? key}
              options={optionsSelected}
              onClick={onClick}
            />

            {idx !== filters.length - 1 && (
              <span className="mb-[2px] mr-2 font-semibold">and</span>
            )}
          </Fragment>
        ))}
      </div>
    )
  },
)

export const BREEZY_LAT_LNG = {
  lat: 39.7605371,
  lng: -105.009517,
}

export const DEFAULT_BREEZY_ZOOM_LEVEL = 5

export interface Place {
  id: string
  lat: number
  lng: number
  name: string
}

export interface MarkerInfo {
  place: Place
  marker: google.maps.marker.AdvancedMarkerElement
  infoWindow: google.maps.InfoWindow
}

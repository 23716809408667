import {
  STATE_ABBREVIATIONS,
  STATE_ABBREVIATION_DISPLAY_NAMES,
  isNullish,
} from '@breezy/shared'
import { Input, Select } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import { BillingProfileFormSchema } from '../billing-profile-form'

export const BillingProfileSettingsBusinessInfoSection = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BillingProfileFormSchema>()

  return (
    <div className="flex flex-col gap-3">
      <Controller
        control={control}
        name="business.fullLegalName"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Full Business Legal Name</span>

            <Input
              {...field}
              onBlur={field.onBlur}
              placeholder="Enter full legal business name here..."
              status={errors.business?.fullLegalName ? 'error' : undefined}
            />

            {errors.business?.fullLegalName && (
              <span className="text-bz-red-500">
                {errors.business.fullLegalName.message}
              </span>
            )}
          </label>
        )}
      />
      <Controller
        control={control}
        name="business.contractorLicenseNumber"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>
              Contractor License # (This will be displayed on estimates &
              invoices)
            </span>

            <Input
              {...field}
              onBlur={field.onBlur}
              placeholder="Enter your Contractor License # here..."
              status={
                errors.business?.contractorLicenseNumber ? 'error' : undefined
              }
            />

            {errors.business?.contractorLicenseNumber && (
              <span className="text-bz-red-500">
                {errors.business.contractorLicenseNumber.message}
              </span>
            )}
          </label>
        )}
      />

      <Controller
        control={control}
        name="business.emailAddress"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Email Address</span>

            <Input
              {...field}
              onBlur={field.onBlur}
              placeholder="Enter business' email address here..."
              status={errors.business?.emailAddress ? 'error' : undefined}
            />

            {errors.business?.emailAddress && (
              <span className="text-bz-red-500">
                {errors.business.emailAddress.message}
              </span>
            )}
          </label>
        )}
      />

      <div className="flex flex-row gap-3">
        <Controller
          control={control}
          name="business.address.city"
          render={({ field }) => (
            <label className="flex  flex-1 flex-col gap-1">
              <span>City</span>

              <Input
                {...field}
                onBlur={field.onBlur}
                status={errors.business?.address?.city ? 'error' : undefined}
              />

              {errors.business?.address?.city && (
                <span className="text-bz-red-500">
                  {errors.business.address.city.message}
                </span>
              )}
            </label>
          )}
        />

        <Controller
          control={control}
          name="business.address.stateAbbreviation"
          render={({ field }) => (
            <label className="flex flex-1 flex-col gap-1">
              <span>State</span>

              <Select
                {...field}
                showSearch
                filterOption={(input, option) =>
                  isNullish(option)
                    ? false
                    : option.label.toLowerCase().includes(input.toLowerCase())
                }
                onBlur={field.onBlur}
                options={STATE_ABBREVIATIONS.map(stateAbbreviation => ({
                  label: STATE_ABBREVIATION_DISPLAY_NAMES[stateAbbreviation],
                  value: stateAbbreviation,
                }))}
                status={
                  errors.business?.address?.stateAbbreviation
                    ? 'error'
                    : undefined
                }
              />

              {errors.business?.address?.stateAbbreviation && (
                <span className="text-bz-red-500">
                  {errors.business.address.stateAbbreviation.message}
                </span>
              )}
            </label>
          )}
        />
      </div>

      <Controller
        control={control}
        name="business.phoneNumber"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Phone Number</span>

            <Input
              {...field}
              onBlur={field.onBlur}
              placeholder="Enter business' phone number here..."
              status={errors.business?.phoneNumber ? 'error' : undefined}
            />

            {errors.business?.phoneNumber && (
              <span className="text-bz-red-500">
                {errors.business.phoneNumber.message}
              </span>
            )}
          </label>
        )}
      />

      <Controller
        control={control}
        name="business.address.line1"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Address Line 1</span>

            <Input
              {...field}
              onBlur={field.onBlur}
              status={errors.business?.address?.line1 ? 'error' : undefined}
            />

            {errors.business?.address?.line1 && (
              <span className="text-bz-red-500">
                {errors.business.address.line1.message}
              </span>
            )}
          </label>
        )}
      />

      <Controller
        control={control}
        name="business.address.line2"
        render={({ field }) => (
          <label className="flex flex-col gap-1">
            <span>Address Line 2</span>

            <Input
              {...field}
              onBlur={field.onBlur}
              status={errors.business?.address?.line2 ? 'error' : undefined}
            />

            {errors.business?.address?.line2 && (
              <span className="text-bz-red-500">
                {errors.business.address.line2.message}
              </span>
            )}
          </label>
        )}
      />

      <Controller
        control={control}
        name="business.address.zipCode"
        render={({ field }) => (
          <label className="flex flex-1 flex-col gap-1">
            <span>Zip Code</span>

            <Input
              {...field}
              className="w-full"
              onBlur={field.onBlur}
              status={errors.business?.address?.zipCode ? 'error' : undefined}
              onChange={e => field.onChange(e.target.value)}
            />

            {errors.business?.address?.zipCode && (
              <span className="text-bz-red-500">
                {errors.business.address.zipCode.message}
              </span>
            )}
          </label>
        )}
      />
    </div>
  )
}

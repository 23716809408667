import { phoneUtils } from '@breezy/shared'
import { useMemo } from 'react'
import { useHideCallerIdEnabled } from '../pages/SettingsPage/HideCallerIdPreferencePicker'
import { useFeatureFlag } from './useFeatureFlags'
import { useIsMobileOrTouchOrTechApp } from './useIsMobile'

export const useLinkedPhoneNumber = (phoneNumber?: string) => {
  const isTechApp = useIsMobileOrTouchOrTechApp()
  const isHideCallerIdFeatureEnabled = useFeatureFlag('hide-caller-id')
  const { hideCallerIdEnabled } = useHideCallerIdEnabled()

  return useMemo(() => {
    if (!phoneNumber) {
      return undefined
    }

    if (isTechApp && isHideCallerIdFeatureEnabled && hideCallerIdEnabled) {
      return phoneUtils.phoneNumberWithHiddenCallerId(phoneNumber)
    }

    return phoneUtils.trimPhoneNumber(phoneNumber)
  }, [
    phoneNumber,
    isTechApp,
    isHideCallerIdFeatureEnabled,
    hideCallerIdEnabled,
  ])
}

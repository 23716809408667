import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import { useCallback } from 'react'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'

export const AdminUpdateVapiPhoneNumber = () => {
  const message = useMessage()
  const updateVapiPhoneNumber =
    trpc.devTools['devtools:update-vapi-phone-number'].useMutation()

  const triggerUpdateVapiPhoneNumber = useCallback(() => {
    updateVapiPhoneNumber.mutate(undefined, {
      onSuccess: () => {
        message.success('VAPI phone number updated successfully')
      },
      onError: error => {
        message.error(`Failed to update VAPI phone number: ${error.message}`)
      },
    })
  }, [updateVapiPhoneNumber, message])

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex items-center space-x-2">
        <b>Update Local VAPI Webhook</b>
        <Tooltip title="Only run this if you're using VAPI with an ngrok tunnel during local development">
          <FontAwesomeIcon icon={faCircleInfo} />
        </Tooltip>
      </div>
      <Button
        className="max-w-[100px]"
        loading={updateVapiPhoneNumber.isLoading}
        onClick={triggerUpdateVapiPhoneNumber}
      >
        Update
      </Button>
    </div>
  )
}

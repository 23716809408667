import { Drawer } from 'antd'
import React from 'react'
import { useWindowSize } from 'react-use'
import {
  InstalledHvacSystemUpsertForm,
  InstalledHvacSystemUpsertFormProps,
} from '../InstalledHvacSystemForm/InstalledHvacSystemForm'

export type InstalledHvacSystemUpsertDrawerProps =
  InstalledHvacSystemUpsertFormProps & {
    isOpen: boolean
  }

export const InstalledHvacSystemUpsertDrawer =
  React.memo<InstalledHvacSystemUpsertDrawerProps>(
    ({ isOpen, ...installedHvacSystemUpsertFormProps }) => {
      const windowSize = useWindowSize()

      return (
        <Drawer
          zIndex={1004}
          title={`${
            installedHvacSystemUpsertFormProps.mode === 'update'
              ? 'Edit'
              : 'New'
          } HVAC System`}
          onClose={installedHvacSystemUpsertFormProps.onCancel}
          open={isOpen}
          width={
            windowSize.width < 640
              ? windowSize.width
              : Math.min(windowSize.width, 720)
          }
          destroyOnClose
          height={windowSize.width < 640 ? windowSize.height : undefined}
          placement={windowSize.width < 640 ? 'bottom' : 'right'}
        >
          <InstalledHvacSystemUpsertForm
            {...installedHvacSystemUpsertFormProps}
          />
        </Drawer>
      )
    },
  )

export default InstalledHvacSystemUpsertDrawer

import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useCallback } from 'react'
import { trpc } from '../../hooks/trpc'

type FormSchema = {
  fileLink: string
  startIndexInclusive: number
  endIndexInclusive: number | undefined
}

export const DataMigrationView = React.memo(() => {
  const migrateData =
    trpc.devTools['devtools:account-full-history-migration'].useMutation()
  const [form] = useForm<FormSchema>()

  const [modal, contextHolder] = Modal.useModal()

  const migrateDataViaApi = useCallback(
    async (values: FormSchema) => {
      try {
        const result = await migrateData.mutateAsync(values)
        modal.confirm({
          title: 'Migration Complete',
          content: (
            <>
              Successfully migrated {result.successCount} accounts.
              <br />
              Errors: {result.errorCount}
              {result.errors.map((error, idx) => (
                <div key={idx}>
                  Account: {error.accountGuid} at index {error.accountIndex}
                  <br />
                  Message: {error.message}
                  <br />
                  Cause: {`${error.cause}`}
                  <br />
                  Stack: {error.stack}
                </div>
              ))}
            </>
          ),
        })
      } catch (error: unknown) {
        modal.error({
          title: 'Error',
          content: error instanceof Error ? error.message : 'Unknown error',
        })
      }
    },
    [migrateData, modal],
  )

  return (
    <div style={{ marginTop: 32 }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={migrateDataViaApi}
        validateTrigger="onBlur"
        initialValues={{
          fileLink:
            'https://breezy-onboarding-resources.s3.us-east-1.amazonaws.com/example.json',
          startIndexInclusive: 0,
        }}
      >
        <Row>
          <Col xs={8}>
            <Row>
              <Col xs={24}>
                <Form.Item name="fileLink" label="Link to JSON file">
                  <Input type="url" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="startIndexInclusive"
                  label="Index of Account to Start (Inclusive)"
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="endIndexInclusive"
                  label="End Index (Exclusive)"
                  help="Leave blank to process the entire file"
                >
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={migrateData.isLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      {contextHolder}
    </div>
  )
})

import { isNullish, noOp } from '@breezy/shared'
import { faX, faXmark } from '@fortawesome/pro-light-svg-icons'
import { Drawer, Modal } from 'antd'
import cn from 'classnames'
import React, { useEffect } from 'react'
import { useTechAppBottomOffset } from '../../components/TechnicianApp/BottomNavBar/CurrentVisitCard/CurrentVisitProvider'
import { useIntercom } from '../../hooks/useIntercom'
import useIsMobile, { useIsSmallScreen } from '../../hooks/useIsMobile'
import { m } from '../../utils/react-utils'
import FaIconButton from '../FaIconButton/FaIconButton'
import PageTitle, { PageTitleProps } from '../PageTitle/PageTitle'
export type CancelProps = {
  readonly onCancel: () => void
}

type BzDrawerProps = PageTitleProps & {
  readonly item: CancelProps | undefined
  readonly children: React.ReactNode
  readonly preferredWidth?: number
  readonly destroyOnClose?: boolean
  readonly title?: React.ReactNode
  readonly useTabletModal?: boolean
  readonly footer?: React.ReactNode
  readonly showClose?: boolean
  readonly className?: string
  readonly bodyStyles?: React.CSSProperties
  readonly mobileWrapperClassName?: string
}

const BzMobileDrawer = m<BzDrawerProps>((props: BzDrawerProps) => {
  const { bottomOffset } = useTechAppBottomOffset()
  const {
    item,
    children,
    destroyOnClose,
    title,
    bodyStyles = { paddingBottom: bottomOffset ? 0 : 56 },
    showClose = true,
    mobileWrapperClassName = 'px-2',
  } = props

  return (
    <Drawer
      title={title ?? <PageTitle {...props} />}
      open={!!item}
      bodyStyle={props.footer ? {} : bodyStyles}
      footer={props.footer}
      closable={false}
      destroyOnClose={destroyOnClose}
      onClose={item?.onCancel}
      className={cn('rounded-t-2xl', props.className)}
      style={{ paddingBottom: bottomOffset ? `${bottomOffset}px` : undefined }}
      extra={
        showClose ? (
          <FaIconButton
            icon={faXmark}
            size={16}
            onClick={item ? () => item?.onCancel() : noOp}
          />
        ) : null
      }
      placement={'bottom'}
      height="large"
    >
      {item && <div className={mobileWrapperClassName}>{children}</div>}
    </Drawer>
  )
})

const BzTabletDrawer = m<BzDrawerProps>((props: BzDrawerProps) => {
  const { bottomOffset } = useTechAppBottomOffset()

  const {
    item,
    children,
    title,
    destroyOnClose,
    bodyStyles = { paddingBottom: bottomOffset },
    showClose = true,
    footer,
  } = props
  return (
    <Modal
      open={!!item}
      className="financing-wizard-modal h-[90%] w-[75%] max-w-[800px]"
      title={title ?? <PageTitle {...props} />}
      onCancel={item?.onCancel}
      maskClosable={false}
      closeIcon={showClose}
      footer={footer ?? null}
      destroyOnClose={destroyOnClose}
      styles={{
        body: bodyStyles,
      }}
      style={{ paddingBottom: bottomOffset ? `${bottomOffset}px` : undefined }}
    >
      {children}
    </Modal>
  )
})
const BzDesktopDrawer = m<BzDrawerProps>((props: BzDrawerProps) => {
  const { bottomOffset } = useTechAppBottomOffset()

  const {
    item,
    children,
    preferredWidth,
    destroyOnClose,
    title,
    bodyStyles = { paddingBottom: bottomOffset || 80 },
    showClose = true,
  } = props

  return (
    <Drawer
      title={title ?? <PageTitle {...props} />}
      open={!!item}
      footer={props.footer}
      closable={false}
      destroyOnClose={destroyOnClose}
      styles={{
        header: { paddingTop: 20 },
        body: props.footer ? {} : bodyStyles,
        footer:
          props.footer && bottomOffset ? { paddingBottom: bottomOffset } : {},
      }}
      onClose={item?.onCancel}
      width={preferredWidth ? preferredWidth : undefined}
      className={props.className}
      extra={
        showClose ? (
          <FaIconButton
            icon={faX}
            onClick={item ? () => item?.onCancel() : noOp}
          />
        ) : null
      }
    >
      {item && children}
    </Drawer>
  )
})

const BzDrawer = m<BzDrawerProps>(({ useTabletModal = false, ...rest }) => {
  const isMobile = useIsMobile()
  const isSmallScreen = useIsSmallScreen()
  const { setLauncherVisible } = useIntercom()

  useEffect(() => {
    if (!isNullish(rest.item)) {
      setLauncherVisible(false)
    } else {
      setLauncherVisible(true)
    }
  }, [rest.item, setLauncherVisible])

  if (isMobile) {
    return <BzMobileDrawer {...rest} />
  }

  if (isSmallScreen && useTabletModal) {
    return <BzTabletDrawer {...rest} />
  }

  return <BzDesktopDrawer {...rest} />
})

export default BzDrawer

import { JobGuid, isPoint, milesToMeters } from '@breezy/shared'

import { TimeZoneId } from '@breezy/shared'
import {
  faCircleExclamation,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMap } from '@vis.gl/react-google-maps'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Job } from 'src/gql/queries/Jobs.gql'
import { BASIC_KANBAN_CARD_STYLES } from '../../../components/Kanban/Kanban'
import { KanbanJob } from '../../../components/Kanban/kanbanUtils'
import BzAdvancedMarker from '../../../components/MapView/BzAdvancedMarker/BzAdvancedMarker'
import ReactMapView from '../../../components/MapView/ReactMapView'
import { useCenterMapOnPointWithRadius } from '../../../components/MapView/hooks/useCenterMapOnPointWithRadius'
import { useCenterMapOnVisiblePoints } from '../../../components/MapView/hooks/useCenterMapOnVisiblePoints'
import { useDefaultBounds } from '../../../components/MapView/hooks/useDefaultBounds'
import { Link } from '../../../elements/Link/Link'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import tailwindConfig from '../../../tailwind.config'
import { JobKanbanContext } from '../JobKanbanContext'
import { JobLifecycle } from '../JobsPage.gql'
import { BasicJobCard } from './BasicJobCard/BasicJobCard'
import {
  JobMenuDropdownOnChangeJobTypeHandler,
  JobMenuDropdownOnCreateLinkedJobHandler,
  JobMenuDropdownOnEditJobHandler,
  JobMenuDropdownOnUpdatePipelineStatusHandler,
} from './JobMenuDropdown'
import { JobCard } from './JobsView'

const JOBS_MAP_ID = '36b410976c29c4bc'

interface JobsMapViewProps {
  jobs: KanbanJob[]
  lifecycles: JobLifecycle[]
  onCardEditClicked: JobMenuDropdownOnEditJobHandler
  onCardCreateLinkedJobClicked: JobMenuDropdownOnCreateLinkedJobHandler
  onUpdateJobPipelineStatus: JobMenuDropdownOnUpdatePipelineStatusHandler
  onChangeJobType: JobMenuDropdownOnChangeJobTypeHandler
}

const DEFAULT_RADIUS_MILES = 5

export const JobsMapView = React.memo<JobsMapViewProps>(
  ({
    jobs,
    lifecycles,
    onCardEditClicked,
    onCardCreateLinkedJobClicked,
    onUpdateJobPipelineStatus,
    onChangeJobType,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const map = useMap(JOBS_MAP_ID)

    const visibleJobs = useMemo(
      () => jobs.filter(job => isPoint(job.job.location.address.geoLocation)),
      [jobs],
    )

    const visibleJobPoints = useMemo(
      () => visibleJobs.map(j => j.job.location.address.geoLocation),
      [visibleJobs],
    )

    const invalidLocationJobGuidMap = useMemo(
      () =>
        jobs
          .map(j => ({
            jobGuid: j.job.jobGuid,
            geoLocation: j.job.location.address.geoLocation,
          }))
          .filter(g => !isPoint(g.geoLocation))
          .reduce((acc, curr) => {
            acc[curr.jobGuid] = true
            return acc
          }, {} as Record<JobGuid, true>),
      [jobs],
    )

    const [highlightedJobGuid, setHighlightedJobGuid] = useState<string>()

    const highlightedJobPoint = useMemo(
      () =>
        jobs.find(j => j.job.jobGuid === highlightedJobGuid)?.job.location
          .address.geoLocation,
      [highlightedJobGuid, jobs],
    )

    const centerMapOnVisibleJobs = useCenterMapOnVisiblePoints(
      map,
      visibleJobPoints,
    )

    const centerMapOnHighlightedJob = useCenterMapOnPointWithRadius(
      map,
      highlightedJobPoint,
      milesToMeters(DEFAULT_RADIUS_MILES),
    )

    const defaultBounds = useDefaultBounds(visibleJobPoints)

    const centerMap = useCallback(() => {
      if (!map) return

      if (highlightedJobGuid) {
        centerMapOnHighlightedJob()
      } else {
        centerMapOnVisibleJobs()
      }
    }, [
      centerMapOnHighlightedJob,
      centerMapOnVisibleJobs,
      highlightedJobGuid,
      map,
    ])

    useEffect(() => {
      centerMap()
    }, [centerMap])

    const clearHighlightedJob = useCallback(
      () => setHighlightedJobGuid(undefined),
      [],
    )
    const setHighlightedJob = useCallback(
      (jobGuid: JobGuid) => setHighlightedJobGuid(jobGuid),
      [],
    )

    return (
      <div className="flex min-h-0 flex-1 flex-row">
        <div className="w-[350px] space-y-2 overflow-auto pr-4">
          {jobs.map(job => {
            const isHighlighted = highlightedJobGuid === job.job.jobGuid
            const isInvalid = invalidLocationJobGuidMap[job.job.jobGuid]
            return (
              <div
                key={job.job.jobGuid}
                className={classNames(
                  BASIC_KANBAN_CARD_STYLES,
                  'relative cursor-pointer hover:border-bz-primary',
                  {
                    'border-bz-primary': isHighlighted,
                  },
                )}
                data-dd-action-name="bz-jobs-map-job-card"
                onClick={() =>
                  setHighlightedJobGuid(
                    isHighlighted ? undefined : job.job.jobGuid,
                  )
                }
              >
                <JobCard
                  job={job.job}
                  lifecycles={lifecycles}
                  onEditClicked={onCardEditClicked}
                  onCreateLinkedJobClicked={onCardCreateLinkedJobClicked}
                  onUpdateJobPipelineStatus={onUpdateJobPipelineStatus}
                  onChangeJobType={onChangeJobType}
                />
                {isInvalid && (
                  <div className="absolute right-[-4px] top-[-4px]">
                    <Tooltip title="We could not find this address in Google Maps">
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="text-lg text-bz-orange-500"
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <ReactMapView
          id={JOBS_MAP_ID}
          // passing in a tailwind classname bricks the react-google-maps styles so I'm passing in
          // this border radius here
          style={{ borderRadius: '8px' }}
          // TODO use the actual GCP mapId
          mapId={JOBS_MAP_ID}
          defaultBounds={defaultBounds}
          onClick={clearHighlightedJob}
          mapTypeControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          clickableIcons={false}
        >
          {visibleJobs.map(job => (
            <JobPin
              key={job.job.jobGuid}
              job={job.job}
              lifecycles={lifecycles}
              onClick={setHighlightedJob}
              highlightedJobGuid={highlightedJobGuid}
              tzId={tzId}
              onCardEditClicked={onCardEditClicked}
              onCardCreateLinkedJobClicked={onCardCreateLinkedJobClicked}
              onUpdateJobPipelineStatus={onUpdateJobPipelineStatus}
              onChangeJobType={onChangeJobType}
            />
          ))}
        </ReactMapView>
      </div>
    )
  },
)

type JobPinProps = {
  job: Job
  lifecycles: JobLifecycle[]
  highlightedJobGuid: JobGuid | undefined
  tzId: TimeZoneId
  onClick: (jobGuid: JobGuid) => void
  onCardEditClicked: JobMenuDropdownOnEditJobHandler
  onCardCreateLinkedJobClicked: JobMenuDropdownOnCreateLinkedJobHandler
  onUpdateJobPipelineStatus: JobMenuDropdownOnUpdatePipelineStatusHandler
  onChangeJobType: JobMenuDropdownOnChangeJobTypeHandler
}

const JobPin = React.memo<JobPinProps>(
  ({
    job,
    lifecycles,
    onClick,
    highlightedJobGuid,
    tzId,
    onCardEditClicked,
    onCardCreateLinkedJobClicked,
    onUpdateJobPipelineStatus,
    onChangeJobType,
  }) => {
    return (
      <BzAdvancedMarker
        markerState="default"
        onClick={() => onClick(job.jobGuid)}
        position={{
          lng: job.location.address.geoLocation.coordinates[0] || 0,
          lat: job.location.address.geoLocation.coordinates[1] || 0,
        }}
        title={'Job pin.'}
        pinIcon={faWrench}
        pinColorConfig={{
          active: tailwindConfig.theme.extend.colors['bz-green'][700],
          default: tailwindConfig.theme.extend.colors['bz-gray'][400],
        }}
        pinIconColorConfig={{
          active: 'white',
          default: 'text-bz-green-700',
        }}
        renderPopupIf={highlightedJobGuid === job.jobGuid}
        ddPinActionName="bz-job-map-job-pin"
        popupContent={
          <JobsPopup
            job={job}
            lifecycles={lifecycles}
            tzId={tzId}
            onCardEditClicked={onCardEditClicked}
            onCardCreateLinkedJobClicked={onCardCreateLinkedJobClicked}
            onUpdateJobPipelineStatus={onUpdateJobPipelineStatus}
            onChangeJobType={onChangeJobType}
          />
        }
        zIndex={1}
      />
    )
  },
)

const JobsPopup = React.memo<
  Omit<JobPinProps, 'onClick' | 'highlightedJobGuid'>
>(
  ({
    job,
    tzId,
    lifecycles,
    onCardEditClicked,
    onCardCreateLinkedJobClicked,
    onUpdateJobPipelineStatus,
    onChangeJobType,
  }) => {
    const { displayPropertiesSettings } = useContext(JobKanbanContext)

    return (
      <div className="flex flex-col space-y-2 p-4 pb-0">
        <BasicJobCard
          job={job}
          lifecycles={lifecycles}
          tzId={tzId}
          displayPropertiesSettings={displayPropertiesSettings}
          onEditClicked={onCardEditClicked}
          onCreateLinkedJobClicked={onCardCreateLinkedJobClicked}
          onUpdateJobPipelineStatus={onUpdateJobPipelineStatus}
          onChangeJobType={onChangeJobType}
        />
        <Link to={`/jobs/${job.jobGuid}`}>See details</Link>
      </div>
    )
  },
)

import { gql } from '../../generated'

export const UPSERT_ACCOUNTING_CART_ITEM_TYPE_ACCOUNTS_CONFIG_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertAccountingCartItemTypeAccountsConfig(
      $object: AccCartItemTypeAccountsConfigInsertInput = {}
    ) {
      insertAccCartItemTypeAccountsConfigOne(
        object: $object
        onConflict: {
          constraint: acc_cart_item_type_accounts_config_company_guid_accounting_inte
          updateColumns: [
            defaultItemIncomeAccountId
            serviceItemDefaultIncomeAccountId
            materialItemDefaultIncomeAccountId
            equipmentItemDefaultIncomeAccountId
            laborItemDefaultIncomeAccountId
            membershipItemDefaultIncomeAccountId
            updatedAt
          ]
        }
      ) {
        __typename
      }
    }
  `)

export const FETCH_ACCOUNTING_CART_ITEM_TYPE_ACCOUNTS_QUERY =
  gql(/* GraphQL */ `
    query FetchAccountingCartItemTypeAccounts($companyGuid: uuid!) {
      accCartItemTypeAccountsConfig(
        where: { companyGuid: { _eq: $companyGuid } }
      ) {
        defaultItemIncomeAccountId
        equipmentItemDefaultIncomeAccountId
        laborItemDefaultIncomeAccountId
        materialItemDefaultIncomeAccountId
        membershipItemDefaultIncomeAccountId
        serviceItemDefaultIncomeAccountId
      }
    }
  `)

export const FETCH_ACCOUNTING_PAYOUT_ACCOUNTS_QUERY = gql(/* GraphQL */ `
  query FetchAccountingPayoutAccountsConfig($companyGuid: uuid!) {
    accPayoutAccountsConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      payoutDepositAccountId
      payoutFeesAccountId
      payoutPaymentProcessorBalanceAccountId
      payoutRefundsAccountId
      accountingIntegrationType
    }
  }
`)

export const UPSERT_ACCOUNTING_PAYOUT_ACCOUNTS_MUTATION = gql(/* GraphQL */ `
  mutation UpsertAccountingPayoutAccountsConfig(
    $object: AccPayoutAccountsConfigInsertInput = {}
  ) {
    insertAccPayoutAccountsConfigOne(
      object: $object
      onConflict: {
        constraint: acc_payout_accounts_config_company_guid_accounting_integrat_key
        updateColumns: [
          payoutDepositAccountId
          payoutFeesAccountId
          payoutPaymentProcessorBalanceAccountId
          payoutRefundsAccountId
          updatedAt
        ]
      }
    ) {
      __typename
    }
  }
`)

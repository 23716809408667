import {
  isNullish,
  JOB_REQUEST_TYPE_TO_JOB_CLASS_MAP,
  jobClassDisplayNames,
} from '@breezy/shared'
import { JobLead } from './types'

export const jobLeadsHeader = (jobLead: JobLead) => {
  const nameToken = [jobLead.contactFirstName, jobLead.contactLastName]
    .map(str => str?.trim())
    .filter(Boolean)
    .join(' ')

  const jobClassToken = [jobLead.requestType]
    .filter(requestType => !isNullish(requestType))
    .map(
      requestType =>
        jobClassDisplayNames[JOB_REQUEST_TYPE_TO_JOB_CLASS_MAP[requestType]],
    )
    .join('')

  return (
    [nameToken, jobClassToken].filter(Boolean).join(' • ') ?? 'Unknown Lead'
  )
}

import { Email } from '@breezy/shared'
import { Body, Container, Head, Hr, Html, Img, Section } from '@react-email/components'
import parse from 'html-react-parser'
import React from 'react'
import Tailwind from '../Tailwind/Tailwind'

const BREEZY_LOGO_URL = 'https://s3.us-west-1.amazonaws.com/www.getbreezyapp.com/images/Breezy-01.png'

type BasicLeftAlignEmailProps = Email

const BasicLeftAlignEmail = React.memo<BasicLeftAlignEmailProps>(({ message, whiteLabel }) => {
  return (
    <Html>
      <Tailwind>
        <Head />
        <Body className="bg-white font-body p-6 text-left text-sm">
          <Container>
            <Section className="mb-6">
              <Img
                alt="Company logo"
                src={whiteLabel?.companyLogoUrl ?? BREEZY_LOGO_URL}
                height={36}
                className="block border-0 leading-100 outline-none decoration-none object-contain object-left max-w-full"
              />
            </Section>
            <Section>{parse(message.replace(/\n/g, '<br />'))}</Section>
            <br />
            <br />
            <Hr className="my-5" />

            <Section className="text-center text-sm text-[#A9B3BC]">
              This is a business transaction email. Please do not reply to this email.
            </Section>
          </Container>
        </Body>
      </Tailwind>
    </Html>
  )
})

export default BasicLeftAlignEmail

import { useSyncInvoice } from '../../hooks/useSyncInvoice'
import { typedMemo } from '../../utils/react-utils'
import {
  BasicQuickbooksSyncButton,
  QuickbooksSyncButtonBaseProps,
} from '../Quickbooks/QuickbooksSyncButton'

type QuickbooksAccountingSyncInvoiceButtonProps =
  QuickbooksSyncButtonBaseProps & {
    invoiceGuid: string
    invoiceDisplayId?: string
    isStale?: boolean
    loading?: boolean
    onSuccess?: () => void
  }

export const QuickbooksAccountingSyncInvoiceButton = typedMemo(
  ({
    invoiceGuid,
    invoiceDisplayId,
    isStale,
    loading,
    onSuccess,
    ...props
  }: QuickbooksAccountingSyncInvoiceButtonProps) => {
    const { onSyncClick, loading: syncLoading } = useSyncInvoice({
      accountingIntegrationType: 'QBD',
      invoiceGuid,
      invoiceDisplayId,
      onSuccess,
    })

    return (
      <BasicQuickbooksSyncButton
        {...props}
        isStale={isStale}
        loading={loading || syncLoading}
        onSyncClick={onSyncClick}
      />
    )
  },
)

import { mapQueryVisitsToVisitViewModels } from '@breezy/backend/src/application-types'
import {
  BzDateFns,
  BzDateTime,
  Guid,
  InstalledEquipment,
  JobClass,
  LocalDateString,
  MaintenancePlanDefinition,
  MaintenancePlanPaymentInterval,
  PaymentMethodDisplayNames,
  VisitViewModel,
  formatUsc,
  getBillingAnchorDayLabel,
} from '@breezy/shared'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { Button, message } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery, useSubscription } from 'urql'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { PaymentSuccessMethod } from '../../components/PaymentWorkflow/PaymentWorkflowWizard'
import {
  ItemPicker,
  ItemPickerItem,
} from '../../components/Pricebook/ItemPicker'
import UrqlSubscriptionLoader from '../../components/UrqlSubscriptionLoader/UrqlSubscriptionLoader'
import VisitStatusTag from '../../components/Visits/VisitStatusTag'
import { DetailRow } from '../../elements/StatusCard/DetailRow'
import { StatusCard } from '../../elements/StatusCard/StatusCard'
import { gql } from '../../generated'
import { trpc } from '../../hooks/trpc'
import useAppNav from '../../hooks/useAppNav'
import { useIsMobileOrTouchOrTechApp } from '../../hooks/useIsMobile'
import { useIsTechApp } from '../../providers/AppContextWrapper'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { toPlanBillingLabel } from './MaintenancePlanV3Utils'
export type ActivatedMaintenancePlanData = {
  isFreePlan: boolean
  isImportedPlan: boolean
  isAutoRenewing: boolean
  billingPaymentInterval: MaintenancePlanPaymentInterval
  billingAnchorDay: string
  activationDate: LocalDateString
  accountGuid: Guid
  locationGuid: Guid
}

type MaintenancePlanActivatedModalProps = {
  maintenancePlanGuid: Guid
  jobGuid?: Guid
  isImport?: boolean
  data: ActivatedMaintenancePlanData
  planDefinition: MaintenancePlanDefinition
  onClose: () => void

  primaryContactEmail?: string
  paymentMethod?: PaymentSuccessMethod
  paymentAmountUsc?: number
  hideViewPlanDetails?: boolean
  closeText?: string
}

type VisitTitleAndContentProps = {
  visit: VisitViewModel
  dataTestId?: string
  index: number
}
const VisitTitleAndContent = React.memo<VisitTitleAndContentProps>(
  ({ visit, dataTestId, index }) => {
    return (
      <div
        className="flex w-full flex-col gap-y-[2px] overflow-hidden py-5"
        data-testid={`${dataTestId}-${index}`}
      >
        <div className="mb-1 text-base font-semibold">{visit.name}</div>
        <div className="flex gap-x-[6px]">
          <div className="text-sm font-semibold">Status</div>
          <VisitStatusTag
            status={visit.status}
            className="flex max-h-5 items-center text-sm"
          />
        </div>
        <div className="flex gap-x-[6px]">
          <div className="text-sm font-semibold">Placeholder Date</div>
          <div className="text-sm">
            {BzDateFns.localDateToFormattedDateString(
              visit.affinityDate,
              'MMMM yyyy',
            )}
          </div>
        </div>
        <div className="flex gap-x-[6px]">
          <div className="text-sm font-semibold">Equipment</div>
          {visit.visitEquipment.length > 0 ? (
            <div className="truncate text-sm">
              {visit.visitEquipment
                .map(e => InstalledEquipment.calculateFriendlyName(e))
                .join(', ')}
            </div>
          ) : (
            <div className="text-sm text-bz-text-tertiary">N/A</div>
          )}
        </div>
      </div>
    )
  },
)

interface VisitPickerProps {
  visitOptions: ItemPickerItem[]
  selectedVisitGuid: Guid | undefined
  onCancel: () => void
  onSave: () => Promise<void>
  onItemSelect: (id: string, count: number) => void
  isSubmitting: boolean
}

export const VisitPicker = React.memo<VisitPickerProps>(
  ({
    visitOptions,
    selectedVisitGuid,
    onCancel,
    onSave,
    onItemSelect,
    isSubmitting,
  }) => {
    return (
      <ItemPicker
        canSelectMultiple={false}
        items={visitOptions}
        title="Select visit"
        emptyState=""
        onCancel={onCancel}
        onBackFallback={onCancel}
        onSave={onSave}
        selectedItemCountMap={
          selectedVisitGuid ? { [selectedVisitGuid]: 1 } : {}
        }
        onItemSelect={onItemSelect}
        addText="Save"
        itemLabel=""
        isSubmitting={isSubmitting}
        isLoading={false}
        hideMultipleQuantityPicker
        hideCart
        showItemValue={false}
      />
    )
  },
)

const FetchMostRecentMaintenancePlanJobAppointment = gql(/* GraphQL */ `
  query MostRecentAppointment($accountGuid: uuid!, $locationGuid: uuid!) {
    jobAppointments(
      limit: 1
      orderBy: { appointmentWindowStart: DESC }
      where: {
        job: {
          accountGuid: { _eq: $accountGuid }
          locationGuid: { _eq: $locationGuid }
        }
      }
    ) {
      appointmentWindowStart
      jobAppointmentGuid
    }
  }
`)

export const MaintenancePlanActivatedModalInner = React.memo<
  MaintenancePlanActivatedModalProps & {
    visits: VisitViewModel[]
  }
>(
  ({
    primaryContactEmail,
    maintenancePlanGuid,
    jobGuid,
    isImport,
    data,
    planDefinition,
    paymentMethod,
    paymentAmountUsc,
    onClose,
    visits,
    hideViewPlanDetails = false,
    closeText = 'Close This Page',
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const appNav = useAppNav()
    const isAutoRenewing = data.isAutoRenewing
    const isPaidPlan = !data.isFreePlan && !data.isImportedPlan
    const shouldShowMobile = useIsMobileOrTouchOrTechApp()
    const isTechApp = useIsTechApp()
    const [isSelectingVisit, setIsSelectingVisit] = useState(false)
    const upsertJobMaintenancePlanVisitMut =
      trpc.jobs['jobs:upsert-maintenance-plan-visit'].useMutation()

    const [selectedVisitGuid, setSelectedVisitGuid] = useState<
      Guid | undefined
    >()

    const fetchMostRecentMaintenancePlanJobAppointment = useQuery({
      query: FetchMostRecentMaintenancePlanJobAppointment,
      variables: {
        accountGuid: data.accountGuid,
        locationGuid: data.locationGuid,
      },
    })

    const onConsumeVisit = useCallback(async () => {
      if (!jobGuid) {
        message.error(
          'There was an error linking the visit to the appointment. Close this page and try again.',
        )
        console.error('Missing jobGuid')
        return
      }

      if (!selectedVisitGuid) {
        message.error('Please select a visit')
        return
      }

      try {
        await upsertJobMaintenancePlanVisitMut.mutateAsync({
          jobGuid,
          visitGuid: selectedVisitGuid,
        })
      } catch (e) {
        console.error(e)
        message.error('Failed to update maintenance plan visit')
      }
      message.success('Successfully linked visit to appointment')
      onClose()
    }, [jobGuid, selectedVisitGuid, onClose, upsertJobMaintenancePlanVisitMut])

    const visitOptions = useMemo(() => {
      return visits.map(
        (item, index): ItemPickerItem => ({
          id: item.visitGuid,
          name: item.name,
          customNode: (
            <VisitTitleAndContent
              visit={item}
              dataTestId="visit-option"
              index={index}
            />
          ),
          value: 0,
        }),
      )
    }, [visits])

    const content = useMemo(() => {
      if (isSelectingVisit) {
        return (
          <VisitPicker
            visitOptions={visitOptions}
            selectedVisitGuid={selectedVisitGuid}
            onCancel={() => setIsSelectingVisit(false)}
            onSave={onConsumeVisit}
            onItemSelect={(id, count) => setSelectedVisitGuid(id)}
            isSubmitting={upsertJobMaintenancePlanVisitMut.isLoading}
          />
        )
      }

      return (
        <StatusCard
          type="success"
          icon={faCheck}
          title={isImport ? 'Plan Imported' : 'Plan Activated'}
          description={
            isImport
              ? 'The maintenance plan was successfully imported.'
              : 'The maintenance plan was successfully activated.'
          }
          footer={
            <div className="mt-4 flex w-full flex-col gap-y-2 pb-6">
              {jobGuid && shouldShowMobile ? (
                <Button
                  className="w-full"
                  size="large"
                  type="primary"
                  data-dd-action-name="bz-maintenance-plan-wizard-use-plan-visit-now"
                  onClick={() => setIsSelectingVisit(true)}
                >
                  Use Plan Visit Now
                </Button>
              ) : (
                <Button
                  className="w-full"
                  size="large"
                  type="primary"
                  data-dd-action-name="bz-maintenance-plan-wizard-schedule"
                  onClick={() =>
                    appNav.navigateToCreateNewJob({
                      jobClass: JobClass.MAINTENANCE.toString(),
                      accountGuid: data.accountGuid,
                      locationGuid: data.locationGuid,
                    })
                  }
                >
                  Schedule a Maintenance Plan Job
                </Button>
              )}
              {!hideViewPlanDetails && (
                <Button
                  className="w-full"
                  size="large"
                  data-dd-action-name="bz-maintenance-plan-wizard-view-plan-details"
                  onClick={() =>
                    appNav.navigateToMaintenancePlanDetailsPage(
                      maintenancePlanGuid,
                    )
                  }
                >
                  View Plan Details
                </Button>
              )}
              {isTechApp ? (
                <GqlQueryLoader
                  query={fetchMostRecentMaintenancePlanJobAppointment}
                  render={data => {
                    const mostRecentAppointmentGuid =
                      data?.jobAppointments[0]?.jobAppointmentGuid

                    return (
                      <Button
                        className="w-full"
                        size="large"
                        data-dd-action-name="bz-maintenance-plan-wizard-view-appointment-details"
                        onClick={
                          mostRecentAppointmentGuid
                            ? () =>
                                appNav.navigateToAppointmentDetails(
                                  mostRecentAppointmentGuid,
                                )
                            : onClose
                        }
                      >
                        {mostRecentAppointmentGuid
                          ? 'View Appointment Details'
                          : 'Close This Page'}
                      </Button>
                    )
                  }}
                />
              ) : (
                <Button
                  className="w-full"
                  size="large"
                  data-dd-action-name="bz-maintenance-plan-wizard-close-this-page"
                  onClick={onClose}
                >
                  {closeText}
                </Button>
              )}
            </div>
          }
        >
          {primaryContactEmail && (
            <DetailRow label="Account Email" value={primaryContactEmail} />
          )}
          <DetailRow label="Plan Name" value={planDefinition.name} />
          {isPaidPlan && isAutoRenewing && (
            <DetailRow
              label="Billing Frequency"
              value={toPlanBillingLabel(
                data.isFreePlan,
                data.isAutoRenewing,
                data.billingPaymentInterval,
              )}
            />
          )}
          {isPaidPlan && isAutoRenewing && (
            <DetailRow
              label="Billing Anchor Day"
              value={getBillingAnchorDayLabel(
                Number.parseInt(data.billingAnchorDay),
                data.billingPaymentInterval,
              )}
            />
          )}
          <DetailRow
            label="Plan Activation Date"
            value={BzDateTime.fromDateString(
              data.activationDate,
              tzId,
            ).toHumanFriendlyMonthDayYear()}
          />
          <DetailRow
            label={
              isAutoRenewing ? 'Plan Renewal Date' : 'Plan Expiration Date'
            }
            value={BzDateTime.fromDateString(data.activationDate, tzId)
              .plusYears(1)
              .toHumanFriendlyMonthDayYear()}
          />
          <DetailRow
            label="Auto-renewal"
            value={isAutoRenewing ? 'Yes' : 'No'}
          />
          {paymentMethod && (
            <DetailRow
              label="Payment Method"
              value={
                paymentMethod === 'PAYMENT_LINK'
                  ? 'Payment Link'
                  : PaymentMethodDisplayNames[paymentMethod]
              }
            />
          )}
          {paymentAmountUsc && (
            <DetailRow
              label={
                paymentMethod === 'PAYMENT_LINK'
                  ? 'Amount To Be Paid'
                  : 'Payment Amount'
              }
              value={formatUsc(paymentAmountUsc)}
            />
          )}
        </StatusCard>
      )
    }, [
      appNav,
      closeText,
      data.accountGuid,
      data.activationDate,
      data.billingAnchorDay,
      data.billingPaymentInterval,
      data.isAutoRenewing,
      data.isFreePlan,
      data.locationGuid,
      fetchMostRecentMaintenancePlanJobAppointment,
      hideViewPlanDetails,
      isAutoRenewing,
      isImport,
      isPaidPlan,
      isSelectingVisit,
      isTechApp,
      jobGuid,
      maintenancePlanGuid,
      onClose,
      onConsumeVisit,
      paymentAmountUsc,
      paymentMethod,
      planDefinition.name,
      primaryContactEmail,
      selectedVisitGuid,
      shouldShowMobile,
      tzId,
      upsertJobMaintenancePlanVisitMut.isLoading,
      visitOptions,
    ])
    return (
      <OnsiteModal
        modalClassName="z-[1020] maintenance-plan-activated-modal"
        className="maintenance-plan-activated-modal"
        size={'default'}
      >
        {content}
      </OnsiteModal>
    )
  },
)

const FetchActivatedMaintenancePlanVisitsSubscription = gql(/* GraphQL */ `
  subscription FetchActivatedMaintenancePlanData($maintenancePlanGuid: uuid!) {
    maintenancePlansQueryable(
      where: { maintenancePlanGuid: { _eq: $maintenancePlanGuid } }
    ) {
      maintenancePlanVisits {
        ...MaintenancePlanVisit
      }
    }
  }
`)

export const MaintenancePlanActivatedModal =
  React.memo<MaintenancePlanActivatedModalProps>(
    ({ maintenancePlanGuid, ...props }) => {
      const [activatedMaintenancePlanDataSubscription] = useSubscription({
        query: FetchActivatedMaintenancePlanVisitsSubscription,
        variables: {
          maintenancePlanGuid,
        },
      })
      return (
        <UrqlSubscriptionLoader
          subscription={activatedMaintenancePlanDataSubscription}
          render={data => {
            return (
              <MaintenancePlanActivatedModalInner
                maintenancePlanGuid={maintenancePlanGuid}
                visits={mapQueryVisitsToVisitViewModels(
                  data?.maintenancePlansQueryable[0].maintenancePlanVisits ??
                    [],
                )}
                {...props}
              />
            )
          }}
        />
      )
    },
  )

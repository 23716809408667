import { BzDateFns, Guid, R, formatUsc, noOp } from '@breezy/shared'
import { List, Switch } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import {
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import BzRadioButton from '../../../elements/BzRadioButton/BzRadioButton'
import { trpc } from '../../../hooks/trpc'
import useIsMobile from '../../../hooks/useIsMobile'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useModalState } from '../../../utils/react-utils'
import { DEFAULT_ESTIMATE_OPTION_NAME, Option } from '../estimatesFlowUtils'
import { SendAcceptedEstimateForm } from './SendAcceptedEstimateForm'

type SelectOptionFormProps = {
  onClose: () => void
  onSuccess?: () => void
  onCancel?: () => void
  onBack?: () => void
  // When false (default), this is the form for when you manually set the estimate to "Accepted" and need to select the
  // option. When true, it's the flow when you change the option of an already-accepted estimate
  changeMode?: boolean
  options: Option[]
  estimateGuid: Guid
  accountGuid: Guid
  displayId: number
  contactFirstName: string
  emailAddress?: string
  phoneNumber?: string
}

export const SelectOptionForm = React.memo<SelectOptionFormProps>(
  ({
    onClose,
    onSuccess,
    onCancel,
    onBack,
    changeMode,
    options: externalOptions,
    estimateGuid,
    accountGuid,
    displayId,
    contactFirstName,
    emailAddress,
    phoneNumber,
  }) => {
    const isMobile = useIsMobile()
    const tzId = useExpectedCompanyTimeZoneId()

    const [selectedOnAcceptOptionGuid, setSelectedOnAcceptOptionGuid] =
      useState<Guid>()

    const options = useMemo(
      () => R.sortBy(R.prop('seq'), externalOptions),
      [externalOptions],
    )

    const selectedOption = useMemo(
      () => options.find(option => option.selected),
      [options],
    )

    const setAcceptedMutation =
      trpc.invoice['invoicing:estimatev2:set-accepted'].useMutation()

    const onAccept = useCallback(async () => {
      const selectedOptionGuid =
        options.length === 1
          ? options[0].optionGuid
          : selectedOnAcceptOptionGuid
      if (selectedOptionGuid) {
        await setAcceptedMutation.mutateAsync({
          estimateGuid,
          accountGuid,
          selectedOptionGuid,
          noEmail: true,
        })
        onSuccess?.()
        onClose()
      }
    }, [
      accountGuid,
      estimateGuid,
      onClose,
      onSuccess,
      options,
      selectedOnAcceptOptionGuid,
      setAcceptedMutation,
    ])

    const [notifyCustomer, setNotifyCustomer] = useState(true)

    const [sendModeOpen, openSendMode, closeSendMode] = useModalState()

    if (sendModeOpen) {
      return (
        <SendAcceptedEstimateForm
          onClose={onClose}
          onSuccess={onAccept}
          onCancel={closeSendMode}
          onBack={closeSendMode}
          contactFirstName={contactFirstName}
          displayId={displayId}
          emailAddress={emailAddress}
          phoneNumber={phoneNumber}
          type={changeMode ? 'optionChanged' : 'acceptedForYou'}
        />
      )
    }

    return (
      <OnsiteModalContent
        unpadBottom
        header={changeMode ? 'Change option' : 'Select option'}
        onClose={onClose}
        onBack={onBack}
        footer={
          <OnsiteModalFooter
            onCancel={onCancel ?? onClose}
            onSubmit={() => (notifyCustomer ? openSendMode() : onAccept())}
            submitText={notifyCustomer ? 'Next' : 'Save'}
            loading={setAcceptedMutation.isLoading}
            submitDisabled={options.length > 1 && !selectedOnAcceptOptionGuid}
          />
        }
      >
        <div className="text-base">
          {options.length === 1 ? (
            <div className={isMobile ? 'mb-4' : 'mb-6'}>
              You are hereby accepting Estimate #{displayId} for{' '}
              <span className="font-semibold">
                {formatUsc(options[0].totalUsc)}
              </span>{' '}
              on {BzDateFns.format(BzDateFns.now(tzId), 'MMMM d, yyyy')}.
            </div>
          ) : (
            <div
              className={classNames(
                'max-h-[50vh] overflow-auto',
                isMobile ? 'mr-[-1rem] pr-4' : 'mr-[-1.5rem] pr-6',
              )}
            >
              <List
                className="border-0 border-t border-solid border-[#0505050f]"
                dataSource={options}
                renderItem={option => {
                  const isPreviouslyAcceptedOption =
                    option.optionGuid === selectedOption?.optionGuid
                  const isCurrentlySelectedOption =
                    option.optionGuid === selectedOnAcceptOptionGuid
                  return (
                    <List.Item
                      className={classNames(
                        'flex flex-row items-center py-5 text-base',
                        {
                          'opacity-50': isPreviouslyAcceptedOption,
                        },
                      )}
                      onClick={() =>
                        !isPreviouslyAcceptedOption &&
                        setSelectedOnAcceptOptionGuid(option.optionGuid)
                      }
                    >
                      <div
                        className={classNames(
                          'min-w-0 flex-1 truncate font-semibold transition-colors',
                          {
                            'text-bz-primary':
                              isPreviouslyAcceptedOption ||
                              isCurrentlySelectedOption,
                          },
                        )}
                      >
                        {option.seq + 1}.{' '}
                        {option.displayName || DEFAULT_ESTIMATE_OPTION_NAME}
                      </div>
                      <div className="ml-4 flex flex-row items-center">
                        <div className="w-[80px] whitespace-nowrap text-right">
                          {formatUsc(option.totalUsc)}
                        </div>
                        <BzRadioButton
                          value=""
                          className="ml-3"
                          checked={
                            isPreviouslyAcceptedOption ||
                            isCurrentlySelectedOption
                          }
                          padRight={false}
                          // We're handling this in the click on the parent so don't do anything
                          onChange={noOp}
                        />
                      </div>
                    </List.Item>
                  )
                }}
              />
            </div>
          )}

          <div
            className={classNames(
              'mt-4 flex flex-row items-center border-0 border-t-4 border-solid border-t-bz-gray-400 py-3',
              isMobile ? 'mx-[-1rem] px-4' : 'mx-[-1.5rem] px-6',
            )}
          >
            <div className="flex-1 text-base font-semibold">
              Notify the customer of the change
            </div>

            <Switch checked={notifyCustomer} onChange={setNotifyCustomer} />
          </div>
        </div>
      </OnsiteModalContent>
    )
  },
)

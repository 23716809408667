import { CardOnFile } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'antd'
import Form from 'antd/lib/form/Form'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../../adam-components/OnsiteModal/OnsiteModal'
import FormBody from '../../../../elements/Forms/FormBody'
import { ReactHookFormItem } from '../../../../elements/Forms/ReactHookFormItem'
import { TextField } from '../../../../elements/Forms/TextField'
import { useReactHookFormSubmit } from '../../../../elements/Forms/useReactHookFormSubmit'
import { trpc } from '../../../../hooks/trpc'
import { useExpectedTilledMerchantId } from '../../../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompanyGuid } from '../../../../providers/PrincipalUser'

const EditCardOnFileFormSchema = z.object({
  displayName: z.string().min(1),
})

type EditCardOnFileFormData = z.infer<typeof EditCardOnFileFormSchema>

type EditPaymentMethodModalProps = {
  // If we support ACH in the future, we'll expand to include that type
  cardOnFile: CardOnFile
  onClose: () => void
  refetch: () => void
}

const EditPaymentMethodModal = React.memo<EditPaymentMethodModalProps>(
  ({ cardOnFile, onClose, refetch }) => {
    const companyGuid = useExpectedCompanyGuid()
    const tilledMerchantId = useExpectedTilledMerchantId()

    const updatePaymentMethodMutation =
      trpc.payments['payments:payment-method:update'].useMutation()
    const {
      control,
      formState: { errors, isValid },
      handleSubmit,
    } = useForm<EditCardOnFileFormData>({
      defaultValues: {
        displayName: cardOnFile.displayName,
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: zodResolver(EditCardOnFileFormSchema),
    })

    const [submitElement, triggerSubmit] = useReactHookFormSubmit()

    const isLoading = updatePaymentMethodMutation.isLoading

    const onSubmit = useCallback(
      async (data: EditCardOnFileFormData) => {
        await updatePaymentMethodMutation.mutateAsync({
          companyGuid,
          merchantId: tilledMerchantId,
          paymentMethodRecordGuid: cardOnFile.paymentMethodRecordGuid,
          displayName: data.displayName,
        })
        refetch()
        onClose()
      },
      [
        updatePaymentMethodMutation,
        companyGuid,
        tilledMerchantId,
        cardOnFile.paymentMethodRecordGuid,
        refetch,
        onClose,
      ],
    )
    return (
      <OnsiteModal onClose={onClose} size="default">
        <OnsiteModalContent
          onClose={onClose}
          disabled={isLoading}
          header="Edit Payment Method"
          footer={
            <div className="flex gap-3 bg-white">
              {onClose && (
                <Button
                  block
                  size="large"
                  onClick={onClose}
                  disabled={isLoading}
                  data-dd-action-name="bz-financing-prequal-send-link-cancel-button-click"
                >
                  Cancel
                </Button>
              )}
              <Button
                block
                type="primary"
                size="large"
                data-testid="prequal-send-link-button"
                data-dd-action-name="bz-financing-prequal-send-link-button-click"
                htmlType="submit"
                onClick={triggerSubmit}
                loading={isLoading}
                disabled={isLoading || !isValid}
              >
                Save
              </Button>
            </div>
          }
        >
          <Form
            className="flex min-h-0 flex-1 flex-col"
            layout="vertical"
            onSubmitCapture={handleSubmit(onSubmit)}
          >
            <FormBody>
              <ReactHookFormItem
                control={control}
                name="displayName"
                label="Display Name"
                required
                errors={errors}
                render={({ field }) => (
                  <TextField
                    {...field}
                    ddActionName="BZ Payment Workflow - Card On File - Display Name"
                  />
                )}
              />
            </FormBody>

            {submitElement}
          </Form>
        </OnsiteModalContent>
      </OnsiteModal>
    )
  },
)

export default EditPaymentMethodModal

import { convertPricebookItemsToCsvFile } from '@breezy/shared'
import { useCallback } from 'react'
import { useQuickbooksOnlineEnabled } from '../../../providers/CompanyFinancialConfigWrapper'
import { useMessage } from '../../../utils/antd-utils'
import { downloadCsv } from '../../../utils/export-to-csv'
import { usePricebookAdmin } from './usePricebookAdmin'

export const useDownloadPricebookItemsCsv = () => {
  const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()
  const { pricebookItems, categories, qboIncomeAccounts } = usePricebookAdmin()
  const message = useMessage()

  return useCallback(
    (fileName: string) => {
      if (!pricebookItems) {
        message.error('No pricebook items to export.')
        return
      }
      const csvFile = convertPricebookItemsToCsvFile(
        pricebookItems,
        categories || [],
        isQuickbooksOnlineEnabled,
        qboIncomeAccounts || [],
      )

      downloadCsv(csvFile, `${fileName}.csv`)

      message.success('Pricebook exported successfully.')
    },
    [
      categories,
      message,
      pricebookItems,
      isQuickbooksOnlineEnabled,
      qboIncomeAccounts,
    ],
  )
}

import { PageHeader } from '@ant-design/pro-components'
import {
  BzDateFns,
  copyToClipboard,
  JobLeadAvailability,
  minifyUrl,
} from '@breezy/shared'
import { faCopy } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ConfigProvider, Divider, Empty, List, message } from 'antd'
import React, { ComponentProps, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSubscription } from 'urql'
import { Page } from '../../components/Page/Page'
import ProgressiveJobCreationModal from '../../components/ProgressiveJobCreationModal/ProgressiveJobCreationModal'
import UrqlSubscriptionLoader from '../../components/UrqlSubscriptionLoader/UrqlSubscriptionLoader'
import { getConfig } from '../../config'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { trpc } from '../../hooks/trpc'
import useIsMobile from '../../hooks/useIsMobile'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { CreateNewJobLeadForm } from './CreateNewJobLeadForm'
import { FETCH_JOB_LEADS_SUBSCRIPTION } from './JobLeads.gql'
import { JobLeadView } from './JobLeadView'
import { JobLead } from './types'
import { WithCompanyLeadSources } from './WithCompanyLeadSources'

type NotificationContentProps = {
  data: JobLead[]
  companyMarketWebsiteLeadSourceGuid?: string
  refetch: () => void
}

const accountAppUrl = getConfig().accountAppFullUrl
const getBookingLinkUrl = (accountAppUrl: string, companyGuid: string) =>
  `${accountAppUrl}/book-now?companyGuid=${companyGuid}`

const JobLeadsContent = React.memo<NotificationContentProps>(
  ({ data, companyMarketWebsiteLeadSourceGuid, refetch }) => {
    const [, setSearchParams] = useSearchParams()

    const [open, converted, archived] = useMemo(() => {
      const open: JobLead[] = []
      const converted: JobLead[] = []
      const archived: JobLead[] = []
      for (const jobLead of data) {
        if (jobLead.jobLeadStatus === 'open') {
          open.push(jobLead)
        } else if (jobLead.jobLeadStatus === 'converted') {
          converted.push(jobLead)
        } else if (jobLead.jobLeadStatus === 'archived') {
          archived.push(jobLead)
        } else {
          open.push(jobLead)
        }
      }
      return [open, converted, archived]
    }, [data])

    const [jobLeadToConvert, setJobLeadToConvert] = useState<
      JobLead | undefined
    >(undefined)

    const onConvertJob = useCallback(
      (jobLead: JobLead) => {
        if (companyMarketWebsiteLeadSourceGuid) {
          setSearchParams({
            leadSourceGuid: companyMarketWebsiteLeadSourceGuid,
          })
        }
        setJobLeadToConvert(jobLead)
      },
      [
        companyMarketWebsiteLeadSourceGuid,
        setSearchParams,
        setJobLeadToConvert,
      ],
    )

    return (
      <ConfigProvider
        renderEmpty={() => <Empty description="No Job Requests" />}
      >
        <div>
          {(
            [
              ['Open', open],
              ['Converted', converted],
              ['Archived', archived],
            ] as const
          ).map(([header, list]) => (
            <React.Fragment key={header}>
              <Divider orientation="left" orientationMargin={0}>
                {header}
              </Divider>
              <List
                dataSource={list}
                renderItem={jobLead => (
                  <List.Item>
                    <JobLeadView
                      key={jobLead.jobLeadGuid}
                      jobLead={jobLead}
                      refetch={refetch}
                      onConvertJob={onConvertJob}
                    />
                  </List.Item>
                )}
              />
            </React.Fragment>
          ))}
        </div>
        {!!jobLeadToConvert && (
          <ProgressiveJobCreationModal
            isOpen
            jobLead={jobLeadToConvert}
            setIsOpen={open => {
              if (!open) {
                setJobLeadToConvert(undefined)
                setSearchParams({})
              }
            }}
          />
        )}
      </ConfigProvider>
    )
  },
)

export const JobLeadsPage = React.memo(() => {
  const isMobile = useIsMobile()
  const companyGuid = useExpectedCompanyGuid()

  const [creatingNewJobLead, setCreatingNewJobLead] = useState(false)
  const [isCopyingBookingLink, setIsCopyingBookingLink] = useState(false)
  const onCreateNewJobLeadClick = useCallback(() => {
    setCreatingNewJobLead(true)
  }, [setCreatingNewJobLead])

  const cancelCreateNewJobLead = useCallback(() => {
    setCreatingNewJobLead(false)
  }, [setCreatingNewJobLead])

  const [endOfDayToday] = useState(BzDateFns.endOfToday())
  const [ninetyDaysAgo] = useState(BzDateFns.subDays(endOfDayToday, 90))

  const fetchJobLeadsQuery = useSubscription({
    query: FETCH_JOB_LEADS_SUBSCRIPTION,
    variables: {
      companyGuid,
      timeStart: ninetyDaysAgo.toISOString(),
      timeEnd: endOfDayToday.toISOString(),
    },
  })

  const refetchJobLeads = useCallback(() => {
    fetchJobLeadsQuery[1]({ requestPolicy: 'network-only' })
  }, [fetchJobLeadsQuery])

  const createJobLeadsMutation = trpc.jobLeads['job-leads:create'].useMutation()

  const onCopyBookingLinkClick = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      try {
        setIsCopyingBookingLink(true)
        const url = await minifyUrl(
          getBookingLinkUrl(accountAppUrl, companyGuid),
        )
        await copyToClipboard(url)
        message.success('Copied!', 2)
      } catch (e) {
        console.error('Failed to copy booking link', e)
        message.error('Failed to copy booking link')
      } finally {
        setIsCopyingBookingLink(false)
      }
    },
    [companyGuid],
  )
  const onCreateNewJobLeadSubmit: ComponentProps<
    typeof CreateNewJobLeadForm
  >['onSubmit'] = useCallback(
    async data => {
      try {
        const times: JobLeadAvailability['times'] = []
        if (data.preferredAvailabilityDate) {
          times.push({
            type: 'freeform',
            value: `${BzDateFns.localDateToFriendlyDateString(
              data.preferredAvailabilityDate,
            )}, Windows: ${
              data.preferredAvailabilityTimeWindows?.join(', ') ?? '—'
            }`,
          })
        }

        if (data.backupAvailabilityDate) {
          times.push({
            type: 'freeform',
            value: `${BzDateFns.localDateToFriendlyDateString(
              data.backupAvailabilityDate,
            )}, Windows: ${
              data.backupAvailabilityTimeWindows?.join(', ') ?? '—'
            }`,
          })
        }

        await createJobLeadsMutation.mutateAsync({
          ...data,
          appointmentAvailabilities:
            times.length > 0
              ? {
                  times: times,
                }
              : undefined,
        })

        refetchJobLeads()
        setCreatingNewJobLead(false)
        message.success('Successfully create new job lead')
      } catch (e) {
        console.error(
          `Failed to create new job lead. Please try again in a few minutes`,
          e,
        )
        message.error(
          `Failed to create new job lead. Please try again in a few minutes`,
        )
      }
    },
    [createJobLeadsMutation, refetchJobLeads],
  )

  return (
    <Page requiresCompanyUser>
      <div
        className="card container m-auto overflow-y-scroll"
        style={
          isMobile
            ? {
                padding: '4px',
                height: '70%',
                maxWidth: '100vw',
                boxSizing: 'border-box',
              }
            : undefined
        }
      >
        <PageHeader
          title="Job Requests"
          extra={
            <div className="flex flex-row gap-2">
              <Button
                onClick={onCopyBookingLinkClick}
                loading={isCopyingBookingLink}
                icon={<FontAwesomeIcon icon={faCopy} />}
              >
                Copy Booking Link
              </Button>
              <Button type="primary" onClick={onCreateNewJobLeadClick}>
                Create New Job Request
              </Button>
            </div>
          }
        >
          <WithCompanyLeadSources
            render={({ companyMarketWebsiteLeadSourceGuid }) => (
              <UrqlSubscriptionLoader
                subscription={fetchJobLeadsQuery[0]}
                render={data => {
                  return (
                    <JobLeadsContent
                      data={data.jobLeads}
                      companyMarketWebsiteLeadSourceGuid={
                        companyMarketWebsiteLeadSourceGuid
                      }
                      refetch={refetchJobLeads}
                    />
                  )
                }}
              />
            )}
          />
        </PageHeader>
      </div>
      <BzDrawer
        destroyOnClose
        title="Create New Job Request"
        preferredWidth={720}
        showClose
        item={
          creatingNewJobLead ? { onCancel: cancelCreateNewJobLead } : undefined
        }
        children={
          <CreateNewJobLeadForm
            onCancel={cancelCreateNewJobLead}
            onSubmit={onCreateNewJobLeadSubmit}
            isLoading={createJobLeadsMutation.isLoading}
          />
        }
      ></BzDrawer>
    </Page>
  )
})

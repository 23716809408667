import {
  AttributionLinkingStrategyValues,
  EquipmentTypeSchema,
  InvoiceTerm,
  bzOptional,
  defaultInvoiceDisclaimer,
  guidSchema,
  timeZoneIdSchema,
} from '@breezy/shared'
import { z } from 'zod'

const yesNoSchema = z.enum(['Yes', 'No']).default('No')

export const companyDetailsFormSchema = z.object({
  companyGuid: bzOptional(guidSchema),
  companyName: z.string().trim(),
  businessFullLegalName: z.string().trim(),
  contractorLicenseNumber: bzOptional(z.string().trim()),
  timezone: timeZoneIdSchema,
  businessAddressLine1: z.string().trim(),
  businessAddressLine2: bzOptional(z.string().trim()),
  businessAddressCity: z.string().trim(),
  businessAddressStateAbbreviation: z.string().trim(),
  businessAddressZipCode: z.string().trim(),
  defaultInvoiceTerm: z.nativeEnum(InvoiceTerm),
  defaultTaxRate: z.string().trim(),
  websiteURL: z.string().trim(),
  websiteDisplayName: z.string().trim(),
  businessEmailAddress: z.string().trim(),
  companyLogoURL: z.string().trim(),
  estimateDisclaimer: z.string().trim(),
  invoiceDisclaimer: bzOptional(z.string().trim()).default(
    defaultInvoiceDisclaimer,
  ),
  invoiceMemo: z.string().trim(),
})

export type CompanyDetailsFormSchema = z.infer<typeof companyDetailsFormSchema>

export const teamMembersFormSchema = z
  .object({
    firstName: z.string().trim(),
    lastName: z.string().trim(),
    emailAddress: z.string().trim(),
    password: z.string().trim(),
    'phone#': z.string().trim(),
    'installTech?': yesNoSchema,
    'salesTech?': yesNoSchema,
    'serviceTech?': yesNoSchema,
    'maintenanceTech?': yesNoSchema,
    'customerServiceRep?': yesNoSchema,
    'dispatcher?': yesNoSchema,
    'accountant?': yesNoSchema,
    'admin?': yesNoSchema,
    'superAdmin?': yesNoSchema,
    'desktopAccess?': yesNoSchema,
    'mobileAccess?': yesNoSchema,
  })
  .array()

export type TeamMembersFormSchema = z.infer<typeof teamMembersFormSchema>

export const leadSourcesFormSchema = z.object({
  canonicalCategory: z.string(),
  leadSourceNameOverride: bzOptional(z.string()),
  defaultAttributionLinkingStrategy: z.enum(AttributionLinkingStrategyValues),
  attributionLinkingStrategyOverride: bzOptional(
    z.enum(AttributionLinkingStrategyValues),
  ),
  defaultAttributionPrompt: z.string(),
  attributionPromptOverride: bzOptional(z.string()),
})

export type LeadSourcesFormSchema = z.infer<typeof leadSourcesFormSchema>

export const equipmentTypesFormSchema = z.object({
  equipmentType: EquipmentTypeSchema,
  enabled: yesNoSchema,
})

export type EquipmentTypesFormSchema = z.infer<typeof equipmentTypesFormSchema>

export const createCompanyFormSchema = z.object({
  companyDetails: companyDetailsFormSchema,
  teamMembers: teamMembersFormSchema,
  leadSources: leadSourcesFormSchema.array(),
  hiddenEquipmentTypes: equipmentTypesFormSchema.array(),
})

export type CreateCompanyFormSchema = z.infer<typeof createCompanyFormSchema>

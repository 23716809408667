import { convertQueryToReviewTypeIdMap, isNullish } from '@breezy/shared'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import { gql } from '../generated'
import { useExpectedCompanyGuid } from '../providers/PrincipalUser'

export const END_OF_VISIT_REVIEW_SETTINGS = gql(/* GraphQL */ `
  query EndOfVisitReviewSettings($companyGuid: uuid!) {
    companyConfigByPk(companyGuid: $companyGuid) {
      googlePlaceId
      yelpId
      facebookId
      angiId
    }
    companyNotificationSettingsByPk(companyGuid: $companyGuid) {
      endOfVisitSendReviewLinkEnabled
      endOfVisitSendReviewLinkFrequency
      endOfVisitSendReviewLinkCount
      endOfVisitSendReviewLinkType
    }
  }
`)

export const useEndOfVisitReviewSettings = () => {
  const companyGuid = useExpectedCompanyGuid()
  const query = useQuery({
    query: END_OF_VISIT_REVIEW_SETTINGS,
    variables: {
      companyGuid,
    },
  })

  const [{ data: endOfVisitNotificationSettings, fetching }] = query
  const sendReviewLinkEnabled = useMemo(
    () =>
      endOfVisitNotificationSettings?.companyNotificationSettingsByPk
        ?.endOfVisitSendReviewLinkEnabled,
    [endOfVisitNotificationSettings],
  )

  const endOfVisitReviewLinkType = useMemo(
    () =>
      endOfVisitNotificationSettings?.companyNotificationSettingsByPk
        ?.endOfVisitSendReviewLinkType,
    [endOfVisitNotificationSettings],
  )

  const endOfVisitReviewTypeIdMap = useMemo(() => {
    return convertQueryToReviewTypeIdMap(
      endOfVisitNotificationSettings?.companyConfigByPk,
    )
  }, [endOfVisitNotificationSettings])

  const reviewIdConfig = useMemo(() => {
    return {
      googlePlaceId:
        endOfVisitNotificationSettings?.companyConfigByPk?.googlePlaceId,
      yelpId: endOfVisitNotificationSettings?.companyConfigByPk?.yelpId,
      facebookId: endOfVisitNotificationSettings?.companyConfigByPk?.facebookId,
      angiId: endOfVisitNotificationSettings?.companyConfigByPk?.angiId,
    }
  }, [endOfVisitNotificationSettings])

  const hasConfiguredReviewIds = useMemo(() => {
    return Object.values(endOfVisitReviewTypeIdMap).some(id => !isNullish(id))
  }, [endOfVisitReviewTypeIdMap])

  return {
    query,
    isEndOfVisitReviewLinkEnabled:
      sendReviewLinkEnabled && hasConfiguredReviewIds,
    endOfVisitReviewLinkType,
    endOfVisitReviewTypeIdMap,
    reviewIdConfig,
    fetching,
  }
}

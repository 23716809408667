import {
  CalculatePaths,
  createQueryStringFromFields,
  GetNewInvoiceQueryProps,
  Guid,
  OfficeRoutes,
  ParamsFromRoute,
} from '@breezy/shared'
import { useCallback } from 'react'
import { NavigateOptions, useNavigate } from 'react-router-dom'

type NavigateToJobCreationProps = {
  readonly phoneNumber?: string
  readonly createNewAccount?: boolean
  readonly accountGuid?: string
  readonly contactGuid?: string
  readonly locationGuid?: string
  readonly jobClass?: string
  readonly linkedJobGuid?: string
  readonly leadSourceGuid?: string
  readonly firstName?: string
  readonly lastName?: string
  readonly linkToIntegratedCallGuid?: string
}

const useAppNavigation = () => {
  const navigate = useNavigate()

  const navigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const navigateToJobDetailsPage = useCallback(
    (jobGuid: string) => {
      navigate(CalculatePaths.jobDetails({ jobGuid }))
    },
    [navigate],
  )

  const navigateToAccountDetailsPage = useCallback(
    (accountGuid: string, shouldCreateJob?: boolean) => {
      const path =
        CalculatePaths.accountDetails({ accountGuid }) +
        (shouldCreateJob ? '?shouldCreateJob=true' : '')
      navigate(path)
    },
    [navigate],
  )

  const navigateToCreateNewAccountPage = useCallback(
    (queryParams?: Record<string, string | undefined>) => {
      navigate('/accounts/new' + createQueryStringFromFields(queryParams ?? {}))
    },
    [navigate],
  )

  const navigateToMaintenancePlanDetailsPage = useCallback(
    (maintenancePlanGuid: string) => {
      navigate(
        CalculatePaths.maintenancePlanDetails({
          maintenancePlanGuid,
        }),
      )
    },
    [navigate],
  )

  const navigateToMaintenancePlanPayPage = useCallback(
    (maintenancePlanGuid: string, updatePaymentMethod?: boolean) => {
      navigate(
        CalculatePaths.maintenancePlanPay({
          maintenancePlanGuid,
          updatePaymentMethod: updatePaymentMethod ? 'true' : undefined,
        }),
      )
    },
    [navigate],
  )

  const navigateToLocationDetailsPage = useCallback(
    (locationGuid: string) => {
      navigate(
        CalculatePaths.locationDetails({
          locationGuid,
        }),
      )
    },
    [navigate],
  )

  const navigateToNotifications = useCallback(() => {
    navigate('/notifications')
  }, [navigate])

  const navigateToCreateNewInvoiceV2 = useCallback(
    (accountGuid: Guid, jobGuid: Guid, fields?: GetNewInvoiceQueryProps) => {
      navigate(CalculatePaths.newInvoiceV2({ accountGuid, jobGuid, fields }))
    },
    [navigate],
  )

  const navigateToCreateNewJob = useCallback(
    (fields: NavigateToJobCreationProps) => {
      navigate(CalculatePaths.jobCreation(fields))
    },
    [navigate],
  )
  const navigateToCreateNewEstimate = useCallback(
    (...props: Parameters<typeof CalculatePaths.newEstimate>) => {
      navigate(CalculatePaths.newEstimate(...props))
    },
    [navigate],
  )

  const navigateToAccountList = useCallback(() => {
    navigate('/accounts')
  }, [navigate])

  const navigateToSchedule = useCallback(() => {
    navigate('/schedule')
  }, [navigate])

  const navigateToInvoiceOverviewPage = useCallback(
    (invoiceGuid: string) => {
      navigate(CalculatePaths.invoiceOverview({ invoiceGuid }))
    },
    [navigate],
  )

  const navigateToEntityHistoryViewer = useCallback(
    (entityGuid: string) => {
      navigate(CalculatePaths.entityHistoryViewer({ entityGuid }))
    },
    [navigate],
  )

  const navigateToRefundPage = useCallback(
    (
      options: ParamsFromRoute<typeof OfficeRoutes.REFUND_PAYMENT>,
      navOptions?: NavigateOptions | undefined,
    ) => {
      navigate(OfficeRoutes.REFUND_PAYMENT.build(options), navOptions)
    },
    [navigate],
  )

  const navigateToTeamPage = useCallback(() => {
    navigate(OfficeRoutes.TEAM.path)
  }, [navigate])

  const navigateToUserProfilePage = useCallback(() => {
    navigate(OfficeRoutes.USER_PROFILE.path)
  }, [navigate])

  const navigateToCreateTeamMemberPage = useCallback(() => {
    navigate(OfficeRoutes.CREATE.path)
  }, [navigate])

  const navigateToEditTeamMemberPage = useCallback(
    (userGuid: string) => {
      navigate(OfficeRoutes.EDIT.build({ params: { userGuid } }))
    },
    [navigate],
  )

  const navigateToQuickbooksConfig = useCallback(() => {
    navigate(OfficeRoutes.QUICKBOOKS.path)
  }, [navigate])

  const navigateToTimesheetDetailsPage = useCallback(
    (userGuid: string) => {
      navigate(OfficeRoutes.TIMESHEET_DETAILS.build({ params: { userGuid } }))
    },
    [navigate],
  )

  const navigateToAppointmentDetails = useCallback(
    (appointmentGuid: string) => {
      navigate(CalculatePaths.appointmentDetails({ appointmentGuid }))
    },
    [navigate],
  )

  const navigateToJobRequestsPage = useCallback(() => {
    navigate(CalculatePaths.jobRequests())
  }, [navigate])

  return {
    navigateBack,
    navigateToAccountDetailsPage,
    navigateToCreateNewInvoiceV2,
    navigateToCreateNewAccountPage,
    navigateToCreateNewJob,
    navigateToLocationDetailsPage,
    navigateToMaintenancePlanDetailsPage,
    navigateToMaintenancePlanPayPage,
    navigateToNotifications,
    navigateToJobDetailsPage,
    navigateToAccountList,
    navigateToSchedule,
    navigateToInvoiceOverviewPage,
    navigateToEntityHistoryViewer,
    navigateToRefundPage,
    navigateToTeamPage,
    navigateToUserProfilePage,
    navigateToCreateTeamMemberPage,
    navigateToEditTeamMemberPage,
    navigateToQuickbooksConfig,
    navigateToTimesheetDetailsPage,
    navigateToAppointmentDetails,
    navigateToJobRequestsPage,
    navigateToCreateNewEstimate,
  }
}

export default useAppNavigation

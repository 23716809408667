import { AppointmentGuid, bzExpect, Guid } from '@breezy/shared'
import { Form, Input, Radio, Select } from 'antd'
import React, { useCallback, useState } from 'react'
import { useMutation } from 'urql'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { useMessage } from '../../utils/antd-utils'
import {
  CANCEL_APPOINTMENT_MUTATION,
  DELETE_ASSIGNMENT_MUTATION,
} from './CancelAppointmentModal.gql'

const CANCEL_REASON_OPTIONS = [
  { value: 'Need to Reschedule' },
  { value: 'Issue is resolved' },
  { value: 'Went with another contractor' },
  { value: 'Other' },
]

export type CancelAppointmentModalProps = {
  appointmentGuid: AppointmentGuid
  onClose: () => void
  assignmentTechName?: string
  assignmentGuid?: Guid
  onCancelAssignmentOverride?: () => void
  onConfirm?: () => void
}

export const CancelAppointmentModal = React.memo<CancelAppointmentModalProps>(
  ({
    onClose,
    appointmentGuid,
    assignmentGuid,
    assignmentTechName,
    onCancelAssignmentOverride,
    onConfirm: externalOnConfirm,
  }) => {
    const message = useMessage()

    const orCancelVisit = !!assignmentGuid

    // If we are just canceling visit, this is true. Otherwise, we default to just removing the assignment
    const [cancelVisit, setCancelVisit] = useState(() => !orCancelVisit)

    const [reason, setReason] = useState(CANCEL_REASON_OPTIONS[0].value)
    const [note, setNote] = useState('')

    const [{ fetching: cancelingAppointment }, cancelAppointment] = useMutation(
      CANCEL_APPOINTMENT_MUTATION,
    )

    const onCancelVisit = useCallback(async () => {
      const res = await cancelAppointment({
        jobAppointmentGuid: appointmentGuid,
        cancellationStatus: {
          jobAppointmentGuid: appointmentGuid,
          canceled: true,
          reason,
          note,
        },
      })
      if (res.error) {
        console.error('Could not cancel visit', res.error)
        message.error('Failed to cancel visit')
      } else {
        message.success('Visit cancelled')
      }
      onClose()
    }, [appointmentGuid, cancelAppointment, message, note, onClose, reason])

    const [{ fetching: deletingAssignment }, deleteAssignment] = useMutation(
      DELETE_ASSIGNMENT_MUTATION,
    )

    const onRemoveAssignment = useCallback(() => {
      if (onCancelAssignmentOverride) {
        onCancelAssignmentOverride()
      } else {
        deleteAssignment({
          jobAppointmentAssignmentGuid: bzExpect(
            assignmentGuid,
            'assignmentGuid',
            'Expected an assignment guid to be present when canceling an assignment.',
          ),
        })
      }
    }, [assignmentGuid, deleteAssignment, onCancelAssignmentOverride])

    const onConfirm = useCallback(async () => {
      externalOnConfirm?.()
      if (cancelVisit) {
        onCancelVisit()
      } else {
        onRemoveAssignment()
      }
    }, [cancelVisit, externalOnConfirm, onCancelVisit, onRemoveAssignment])
    return (
      <OnsiteConfirmModal
        danger
        header={
          orCancelVisit ? 'Cancel visit or remove assignment' : 'Cancel visit'
        }
        onCancel={onClose}
        onConfirm={onConfirm}
        confirmText={cancelVisit ? 'Cancel visit' : 'Remove assignment'}
        loading={cancelingAppointment || deletingAssignment}
      >
        <div>
          {orCancelVisit
            ? 'Would you like to remove the assignment or cancel the visit entirely?'
            : 'Are you sure you want to cancel this visit? This will remove all technician assignments associated with this visit.'}
        </div>
        {orCancelVisit && (
          <Radio.Group
            value={cancelVisit ? 'CANCEL_VISIT' : 'REMOVE_ASSIGNMENT'}
            onChange={e => setCancelVisit(e.target.value === 'CANCEL_VISIT')}
          >
            <div className="mt-6 flex flex-col space-y-4">
              <Radio value="REMOVE_ASSIGNMENT">
                Remove assignment from {assignmentTechName ?? 'this technician'}
              </Radio>
              <Radio value="CANCEL_VISIT">Cancel Visit</Radio>
            </div>
          </Radio.Group>
        )}
        {cancelVisit && (
          <Form layout="vertical" className="mb-[-16px] mt-6">
            <Form.Item label="Cancelation Reason" required>
              <Select
                size="large"
                onChange={value => setReason(value)}
                options={CANCEL_REASON_OPTIONS}
                value={reason}
              />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  Note{' '}
                  <span className="font-normal text-bz-text-description">
                    (optional)
                  </span>
                </div>
              }
            >
              <Input.TextArea
                value={note}
                onChange={e => setNote(e.target.value)}
                rows={3}
              />
            </Form.Item>
          </Form>
        )}
      </OnsiteConfirmModal>
    )
  },
)

import { NotificationPreferenceType } from '@breezy/shared'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import { DocumentType, gql } from '../../generated'
import { JobPointOfContact } from './visitConfirmationUtils'

const JOB_POINT_OF_CONTACT_FRAGMENT = gql(/* GraphQL */ `
  fragment JobPointOfContactFragment on Contacts {
    notificationPreferenceType
    primaryEmailAddress {
      emailAddress
    }
    primaryPhoneNumber {
      phoneNumber
    }
  }
`)

export type JobPointOfContactFragment = DocumentType<
  typeof JOB_POINT_OF_CONTACT_FRAGMENT
>

export const JOB_POINT_OF_CONTACT_QUERY = gql(/* GraphQL */ `
  query JobPointOfContactNotificationPreferenceType($jobGuid: uuid!) {
    jobsByPk(jobGuid: $jobGuid) {
      pointOfContact {
        ...JobPointOfContactFragment
      }
    }
  }
`)

export const convertToJobPointOfContact = (
  data: JobPointOfContactFragment | undefined,
): JobPointOfContact => {
  if (!data) {
    return {
      notificationPreferenceType: NotificationPreferenceType.Email,
    }
  }
  return {
    notificationPreferenceType:
      data.notificationPreferenceType ?? NotificationPreferenceType.Email,
    primaryEmailAddress: data.primaryEmailAddress?.emailAddress,
    primaryPhoneNumber: data.primaryPhoneNumber?.phoneNumber,
  }
}

export const useJobPointOfContact = (jobGuid?: string) => {
  const jobPointOfContactQuery = useQuery({
    query: JOB_POINT_OF_CONTACT_QUERY,
    pause: jobGuid ? false : true,
    variables: { jobGuid: jobGuid ?? '' },
  })

  const fetchingJobPointOfContact =
    jobGuid && jobPointOfContactQuery[0].fetching

  const pointOfContact = useMemo<JobPointOfContact>(
    () =>
      convertToJobPointOfContact(
        jobPointOfContactQuery[0].data?.jobsByPk?.pointOfContact,
      ),
    [jobPointOfContactQuery],
  )

  return {
    jobPointOfContactQuery,
    pointOfContact,
    fetchingJobPointOfContact,
  }
}

import {
  COMMON_HVAC_FILTER_SIZES,
  InstalledEquipment,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  LocationGuid,
  ZoningInfo,
  effectiveLocationLongDisplayName,
  fuzzyMatch,
  nextGuid,
} from '@breezy/shared'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutoComplete, Button, Divider, Form, Input, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'
import { DrawerLocation } from '../TechnicianApp/TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'
import { useUpsertInstalledHvacSystemAndLinkedEquipment } from './useUpsertInstallHvacSystemAndLinkedEquipment'
type InstalledHvacSystemUpsertFormMode =
  | 'create-for-location'
  | 'create-for-account'
  | 'update'

export type UpsertInstalledHvacSystemFormSchema = {
  selectedLocationGuid: LocationGuid
  friendlyName: string
  filterSize: string
  notes: string
  zones: ZoningInfo['zones']
  linkedInstalledEquipment: string[]
}

type CommonInstalledHvacSystemUpsertFormProps = {
  mode: InstalledHvacSystemUpsertFormMode
  onCancel: () => void
  onMutate: () => void
  justifyFormCancelSubmitButtons?: 'start' | 'end'
  showDivider?: boolean
}
type CreateForLocationInstalledHvacSystemFormProps =
  CommonInstalledHvacSystemUpsertFormProps & {
    mode: 'create-for-location'
    location: DrawerLocation
  }

type CreateForAccountInstalledHvacSystemFormProps =
  CommonInstalledHvacSystemUpsertFormProps & {
    mode: 'create-for-account'
    availableLocations: DrawerLocation[]
  }

type UpdateInstalledHvacSystemFormProps =
  CommonInstalledHvacSystemUpsertFormProps & {
    mode: 'update'
    initialValues: InstalledHvacSystem
    location: DrawerLocation
  }

export type InstalledHvacSystemUpsertFormProps =
  | CreateForLocationInstalledHvacSystemFormProps
  | CreateForAccountInstalledHvacSystemFormProps
  | UpdateInstalledHvacSystemFormProps

export const InstalledHvacSystemUpsertForm = ({
  onCancel,
  onMutate,
  justifyFormCancelSubmitButtons,
  showDivider = false,
  ...rest
}: InstalledHvacSystemUpsertFormProps) => {
  const message = useMessage()
  const companyGuid = useExpectedCompany().companyGuid
  const [form] = useForm<UpsertInstalledHvacSystemFormSchema>()

  const [isMutating, setIsMutating] = useState(false)
  const trpcClient = trpc.useContext().client
  const [installedEquipmentForLocation, setInstalledEquipmentForLocation] =
    useState<InstalledEquipmentSummary[]>()

  const { upsert: upsertInstallHvacSystemAndLinkedEquipmentMutation } =
    useUpsertInstalledHvacSystemAndLinkedEquipment()

  const fetchInstallEquipmentForLocation = useCallback(
    async (locationGuid: LocationGuid) => {
      setInstalledEquipmentForLocation(
        await trpcClient.installedEquipment['installed-equipment:query'].query({
          type: 'by-location-guid',
          locationGuid,
          companyGuid,
        }),
      )
    },
    [companyGuid, trpcClient],
  )

  const onLocationSelected = useCallback(
    (locationGuid: LocationGuid) => {
      form.setFieldValue('linkedInstalledEquipment', [])
      return fetchInstallEquipmentForLocation(locationGuid)
    },
    [fetchInstallEquipmentForLocation, form],
  )

  useEffect(() => {
    if (rest.mode === 'update' || rest.mode === 'create-for-location') {
      fetchInstallEquipmentForLocation(rest.location.locationGuid)
    } else if (
      rest.mode === 'create-for-account' &&
      rest.availableLocations.length === 1
    ) {
      fetchInstallEquipmentForLocation(rest.availableLocations[0].locationGuid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only want this to fire once
  }, [])

  const onFinish = useCallback(
    async (values: UpsertInstalledHvacSystemFormSchema) => {
      try {
        setIsMutating(true)
        await upsertInstallHvacSystemAndLinkedEquipmentMutation({
          installedHvacSystemGuid:
            rest.mode === 'update'
              ? rest.initialValues.installedHvacSystemGuid
              : nextGuid(),
          locationGuid: values.selectedLocationGuid,
          filterSize: values.filterSize,
          friendlyName: values.friendlyName,
          notes: values.notes,
          linkedInstalledEquipmentGuids: values.linkedInstalledEquipment ?? [],
          zoningInfoJson: {
            zones: values.zones ?? [],
          },
        })
        onMutate()
      } catch (e) {
        console.error(
          `Failed to upsert install hvac system and linked equipment`,
          e,
        )
        message.error(`There was an issue saving the system information`)
      } finally {
        setIsMutating(false)
      }
    },
    [
      onMutate,
      rest,
      upsertInstallHvacSystemAndLinkedEquipmentMutation,
      message,
    ],
  )

  const selectableLocations = useMemo(() => {
    if (rest.mode === 'create-for-account') {
      return rest.availableLocations
    } else {
      return [rest.location]
    }
  }, [rest])

  return (
    <div className="w-full p-2 sm:p-0">
      <Form
        disabled={isMutating}
        form={form}
        layout="vertical"
        validateTrigger="onBlur"
        onFinish={onFinish}
        initialValues={
          rest.mode === 'update'
            ? {
                friendlyName: rest.initialValues.friendlyName,
                selectedLocationGuid: rest.location.locationGuid,
                filterSize: rest.initialValues.filterSize,
                notes: rest.initialValues.notes,
                zones: rest.initialValues.zoningInfo.zones,
                linkedInstalledEquipment:
                  rest.initialValues.installedEquipment.map(
                    e => e.installedEquipmentGuid,
                  ),
              }
            : rest.mode === 'create-for-location'
            ? {
                selectedLocationGuid: rest.location.locationGuid,
              }
            : {
                selectedLocationGuid:
                  rest.availableLocations.length === 1
                    ? rest.availableLocations[0].locationGuid
                    : null,
              }
        }
      >
        <div className={`space-x flex w-full flex-col sm:flex-row`}>
          <div className="w-full sm:w-3/4">
            <Form.Item
              name="selectedLocationGuid"
              label="Location"
              className="semibold_14_22 grey9"
            >
              <Select
                placeholder="Select a Location"
                onChange={onLocationSelected}
                disabled={
                  rest.mode === 'update' || rest.mode === 'create-for-location'
                }
              >
                {selectableLocations.map(location => {
                  return (
                    <Select.Option
                      key={location.locationGuid}
                      value={location.locationGuid}
                    >
                      {effectiveLocationLongDisplayName(location)}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className={`space-x flex w-full flex-col sm:flex-row`}>
          <div className="w-full sm:w-3/4">
            <Form.Item
              required
              label="System Name"
              name="friendlyName"
              className="semibold_14_22 grey9"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>

        <div className={`space-x flex w-full flex-col sm:flex-row`}>
          <div className="mt-3 w-full">
            <Form.Item
              name="linkedInstalledEquipment"
              label="Linked Equipment"
              className="semibold_14_22 grey9"
            >
              <Select
                mode="multiple"
                placeholder="Link installed equipment to system"
                disabled={!installedEquipmentForLocation}
                notFoundContent={
                  <div>No Equipment exists for selected the location</div>
                }
              >
                {(installedEquipmentForLocation ?? []).map(equipment => {
                  return (
                    <Select.Option
                      key={equipment.installedEquipmentGuid}
                      value={equipment.installedEquipmentGuid}
                    >
                      {InstalledEquipment.calculateIdentifyingName(equipment)}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={`space-x flex w-full flex-col sm:flex-row`}>
          <div className="w-full sm:w-3/4">
            <Form.Item
              label="Filter Size"
              name="filterSize"
              className="semibold_14_22 grey9"
            >
              <AutoComplete
                placeholder="Select a Filter Size"
                options={COMMON_HVAC_FILTER_SIZES.map(opt => ({
                  value: opt,
                  label: opt,
                }))}
                filterOption={(inputValue, option) =>
                  option?.value ? fuzzyMatch(option?.value, inputValue) : false
                }
              />
            </Form.Item>
          </div>
        </div>
        <div className={`space-x mt-3  flex w-full flex-col sm:flex-row`}>
          <div className="w-full">
            <div className="semibold_16_24 grey9 mb-3">Zoning</div>
            <Form.List name="zones">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map(field => (
                    <div className="flex flex-row items-center" key={field.key}>
                      <Form.Item
                        {...field}
                        label="Zone Name"
                        className="semibold_14_22 grey9"
                        name={[field.name, 'name']}
                      >
                        <Input />
                      </Form.Item>
                      <Button type="link" onClick={() => remove(field.name)}>
                        Remove
                      </Button>
                    </div>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<FontAwesomeIcon className="mr-2" icon={faPlus} />}
                    >
                      Add Zone
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </div>
        </div>
        <div className={`space-x flex w-full flex-col sm:flex-row`}>
          <div className="w-full">
            <Form.Item
              required
              label="System Notes"
              name="notes"
              className="semibold_14_22 grey9"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </div>
        </div>
        {showDivider && <Divider />}
        <FormCancelSubmitButtons
          onCancel={onCancel}
          justify={justifyFormCancelSubmitButtons}
        />
      </Form>
    </div>
  )
}

import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spin } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { trpc } from '../../hooks/trpc'
import { useQuickbooksOnlineEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { useMessage } from '../../utils/antd-utils'
import { m } from '../../utils/react-utils'

const IS_AUTHORIZED_POLLING_PERIOD = 5000

export type QuickbooksAuthorizeButtonProps = {
  onGetAuthURI: () => Promise<unknown>
  isAuthorized: boolean
  loading: boolean
  setLoading: (loading: boolean) => void
  setPollingEnabled: (enabled: boolean) => void
  onClick?: () => void
}

// There is an error when I let this live in the component itself. I think it's because
// I sometimes put the component inside a Modal that's inside a tRPC failure, and the
// reference to tRPC doesn't work.
export const useQuickbooksAuthorizeButtonProps =
  (): QuickbooksAuthorizeButtonProps => {
    const message = useMessage()
    const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()

    const getAuthUrlQuery = trpc.qbo['finance-app:get-auth-url'].useQuery(
      undefined,
      {
        enabled: false,
      },
    )
    const onGetAuthURI = useCallback(async () => {
      const res = await getAuthUrlQuery.refetch()
      if (res.data?.uri) {
        window.open(res.data.uri, '_blank')
      } else {
        message.error('There was a problem connecting to Quickbooks')
      }
      // eslint doesn't realize that getAuthUrlQuery.refetch is all we use. It wants me
      // to include the whole `getAuthUrlQuery` object, which changes constantly and
      // causes a shallow-unequal chain reaction.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAuthUrlQuery.refetch])

    const incomeAccountQuery = trpc.qbo[
      'finance-app:get-income-accounts'
    ].useQuery(undefined, {
      enabled: false,
      retry: false,
      onError: () => {
        // noop. We are checking separately for a failure and don't want the default
        // popup.
      },
    })

    const getIsAuthorized = useCallback(async () => {
      const res = await incomeAccountQuery.refetch()
      return res.isSuccess
      // eslint doesn't realize that getAuthUrlQuery.refetch is all we use. It wants me
      // to include the whole `getAuthUrlQuery` object, which changes constantly and
      // causes a shallow-unequal chain reaction.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incomeAccountQuery.refetch])

    const [isAuthorized, setIsAuthorized] = useState(false)
    const [loading, setLoading] = useState(false)

    const [pollingEnabled, setPollingEnabled] = useState(false)

    useEffect(() => {
      if (!isQuickbooksOnlineEnabled) {
        return
      }
      if (!isAuthorized) {
        let nextTimeout: NodeJS.Timeout
        const pollIsAuthorized = async () => {
          const isNowAuthorized = await getIsAuthorized()
          if (isNowAuthorized) {
            setIsAuthorized(true)
            setLoading(false)
          } else if (pollingEnabled) {
            nextTimeout = setTimeout(
              pollIsAuthorized,
              IS_AUTHORIZED_POLLING_PERIOD,
            )
          }
        }

        pollIsAuthorized()

        return () => {
          if (nextTimeout) {
            clearTimeout(nextTimeout)
          }
        }
      }
    }, [
      getIsAuthorized,
      isAuthorized,
      isQuickbooksOnlineEnabled,
      pollingEnabled,
    ])

    return useMemo(
      () => ({
        onGetAuthURI,
        isAuthorized,
        loading,
        setLoading,
        setPollingEnabled,
      }),
      [isAuthorized, loading, onGetAuthURI],
    )
  }

export const QuickbooksAuthorizeButton = m<QuickbooksAuthorizeButtonProps>(
  ({
    onGetAuthURI,
    isAuthorized,
    loading,
    setLoading,
    onClick,
    setPollingEnabled,
  }) => {
    useEffect(() => {
      setPollingEnabled(true)
      return () => {
        setPollingEnabled(false)
      }
    }, [setPollingEnabled])

    const fullOnClick = useCallback(() => {
      onClick?.()
      setLoading(true)
      onGetAuthURI()
    }, [onClick, setLoading, onGetAuthURI])

    return isAuthorized ? (
      <div className="flex items-center text-lg">
        Authorized with Quickbooks
        <FontAwesomeIcon className="ml-2 text-[#2c9f1c]" icon={faCircleCheck} />
      </div>
    ) : (
      <div className="inline-block">
        <Spin
          spinning={loading}
          size="large"
          indicator={<FontAwesomeIcon icon={faSpinnerThird} spin />}
        >
          <Button size="large" className="b-0 p-0" onClick={fullOnClick}>
            <img
              alt="Connect to Quickbooks Online"
              src="https://static.developer.intuit.com/images/C2QB_auth.png"
              className="h-full"
            />
          </Button>
        </Spin>
      </div>
    )
  },
)

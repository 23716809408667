import { gql } from '../../generated'

export const UNLINK_JOB_FROM_INTEGRATED_PHONE_CALL_MUTATION = gql(`
  mutation UnlinkJobFromIntegratedPhoneCall(
    $companyGuid: uuid!
    $integratedPhoneCallGuid: uuid!
    $jobGuid: uuid!
  ) {
    deleteIntegratedPhoneCallJobs(
      where: {
        companyGuid: { _eq: $companyGuid }
        integratedPhoneCallGuid: { _eq: $integratedPhoneCallGuid }
        jobGuid: { _eq: $jobGuid }
      }
    ) {
      affectedRows
    }
  }
`)

import {
  DiscountType,
  RichDiscountUsc,
  bzOptional,
  htmlStringSchema,
  usCentsToUsd,
  usdToUsCents,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { CloseConfirmModal } from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { NumberField } from '../../elements/Forms/NumberField'
import { RadioButtonField } from '../../elements/Forms/RadioButtonField'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { RichTextAreaField } from '../../elements/Forms/RichTextAreaField'
import { TextField } from '../../elements/Forms/TextField'
import { useReactHookFormSubmit } from '../../elements/Forms/useReactHookFormSubmit'
import { useModalState } from '../../utils/react-utils'

const discountFormSchema = z
  .object({
    name: z.string().min(1, 'Required'),
    descriptionHtml: bzOptional(htmlStringSchema),
    type: z.nativeEnum(DiscountType),
    discountRate: bzOptional(z.number()),
    discountAmountUsd: bzOptional(z.number()),
  })
  .refine(
    data => {
      return !(data.type === DiscountType.RATE && !data.discountRate)
    },
    {
      message: 'Discount rate is required',
      path: ['discountRate'],
    },
  )
  .refine(
    data => {
      return !(data.type === DiscountType.FLAT && !data.discountAmountUsd)
    },
    {
      message: 'Discount amount is required',
      path: ['discountAmountUsd'],
    },
  )

type DiscountFormSchema = z.infer<typeof discountFormSchema>

type RichDiscountFormProps = {
  onCancel: () => void
  onBack?: () => void
  onSave: (discount: RichDiscountUsc) => void
  setIsDirty?: (isDirty: boolean) => void
  defaultValues?: RichDiscountUsc
  title?: string
  disableRate?: boolean
}

export const RichDiscountForm = React.memo<RichDiscountFormProps>(
  ({
    onCancel,
    onSave,
    setIsDirty,
    defaultValues,
    title = 'Create discount',
    onBack,
    disableRate,
  }) => {
    useEffect(() => {
      return () => {
        setIsDirty?.(false)
      }
      // I only want this to run on dismount. If they are messing around with the "setIsDirty" function I don't want to
      // constantly call it and unset it.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
      formState: { errors, isDirty },
      control,
      handleSubmit,
      watch,
    } = useForm<DiscountFormSchema>({
      resolver: zodResolver(discountFormSchema),
      defaultValues: defaultValues
        ? {
            ...defaultValues,
            discountAmountUsd: defaultValues.discountAmountUsc
              ? usCentsToUsd(defaultValues.discountAmountUsc)
              : undefined,
            discountRate: defaultValues.discountRate
              ? defaultValues.discountRate * 100
              : undefined,
          }
        : {
            type: DiscountType.FLAT,
          },
    })

    useEffect(() => {
      setIsDirty?.(isDirty)
    }, [isDirty, setIsDirty])

    const onSubmit = useCallback(
      (data: DiscountFormSchema) => {
        const base = {
          name: data.name,
          descriptionHtml: data.descriptionHtml ?? '',
        }
        onSave(
          data.type === DiscountType.FLAT
            ? {
                ...base,
                type: DiscountType.FLAT,
                discountAmountUsc: data.discountAmountUsd
                  ? usdToUsCents(data.discountAmountUsd)
                  : 0,
              }
            : {
                ...base,
                type: DiscountType.RATE,
                discountRate: (data.discountRate ?? 0) / 100,
              },
        )
      },
      [onSave],
    )

    const type = watch('type')

    const [submitElement, triggerSubmit] = useReactHookFormSubmit()

    const typeOptions = useMemo(
      () => [
        { label: 'Fixed', value: DiscountType.FLAT },
        {
          label: 'Percentage',
          value: DiscountType.RATE,
          disabled: disableRate,
        },
      ],
      [disableRate],
    )

    const [confirmCancelOpen, openConfirmCancel, closeConfirmCancel] =
      useModalState()

    const onCancelWithConfirm = useCallback(() => {
      if (isDirty) {
        openConfirmCancel()
      } else {
        onCancel()
      }
    }, [isDirty, onCancel, openConfirmCancel])

    const onBackWithConfirm = useMemo(
      () =>
        onBack
          ? () => {
              if (isDirty) {
                openConfirmCancel()
              } else {
                onBack()
              }
            }
          : undefined,
      [isDirty, onBack, openConfirmCancel],
    )

    return (
      <OnsiteModalContent
        header={title}
        onClose={onCancelWithConfirm}
        onBack={onBackWithConfirm}
        footer={
          <OnsiteModalFooter
            onCancel={onCancelWithConfirm}
            onSubmit={triggerSubmit}
          />
        }
      >
        <div className="flex min-h-0 flex-1 flex-col">
          <Form
            onSubmitCapture={handleSubmit(onSubmit)}
            layout="vertical"
            className="flex min-h-0 flex-1 flex-col"
          >
            <ReactHookFormItem
              required
              control={control}
              name="type"
              label="Type"
              errors={errors}
              render={({ field }) => (
                <RadioButtonField
                  optionType="button"
                  size="large"
                  {...field}
                  options={typeOptions}
                />
              )}
            />
            <ReactHookFormItem
              required
              control={control}
              name="name"
              label="Discount Name"
              errors={errors}
              render={({ field }) => <TextField {...field} />}
            />
            <ReactHookFormItem
              control={control}
              name="descriptionHtml"
              label="Description"
              errors={errors}
              render={({ field }) => <RichTextAreaField {...field} />}
            />
            {type === DiscountType.FLAT ? (
              <ReactHookFormItem
                key="flat"
                required
                control={control}
                name="discountAmountUsd"
                label="Discount (USD)"
                errors={errors}
                render={({ field }) => (
                  <NumberField isMoney min={0} {...field} />
                )}
              />
            ) : (
              <ReactHookFormItem
                key="rate"
                required
                control={control}
                name="discountRate"
                label="Discount (%)"
                errors={errors}
                render={({ field }) => (
                  <NumberField max={100} suffix="%" {...field} />
                )}
              />
            )}
            {submitElement}
          </Form>
        </div>
        {confirmCancelOpen && (
          <CloseConfirmModal
            onCancel={closeConfirmCancel}
            onConfirm={() => {
              closeConfirmCancel()
              onBack ? onBack() : onCancel()
            }}
          />
        )}
      </OnsiteModalContent>
    )
  },
)

import { bzExpect } from '@breezy/shared'
import React, { useCallback } from 'react'
import {
  CancelAppointmentModal,
  CancelAppointmentModalProps,
} from '../../components/CancelAppointmentModal/CancelAppointmentModal'
import { useSchedulePendingChanges } from './PendingChanges/SchedulePendingChangesContext'

type ScheduleCancelAppointmentModalProps = Omit<
  CancelAppointmentModalProps,
  'onCancelAssignment'
>

export const ScheduleCancelAppointmentModal =
  React.memo<ScheduleCancelAppointmentModalProps>(
    ({ appointmentGuid, assignmentGuid, ...rest }) => {
      const { setPendingChanges } = useSchedulePendingChanges()

      const onCancelAssignment = useCallback(() => {
        setPendingChanges({
          field: 'deletedEventMap',
          key: bzExpect(
            assignmentGuid,
            'assignmentGuid',
            'Expected an assignment guid to be present when canceling an assignment.',
          ),
          value: {
            appointmentGuid,
          },
        })
      }, [appointmentGuid, assignmentGuid, setPendingChanges])

      return (
        <CancelAppointmentModal
          {...rest}
          appointmentGuid={appointmentGuid}
          onCancelAssignmentOverride={onCancelAssignment}
          assignmentGuid={assignmentGuid}
        />
      )
    },
  )

import {
  groupBy,
  nextGuid,
  twilioPhoneNumberToBreezyPhone,
} from '@breezy/shared'
import {
  faArrowDownLeft,
  faArrowUpRight,
  faPhonePlus,
  faVoicemail,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Radio, Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useSubscription } from 'urql'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import { Card } from '../../elements/Card/Card'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from '../../providers/PrincipalUser'
import CommCallSearcher from './CommCallSearcher'
import { CommContactCard } from './CommContactCard'
import { ConvoView } from './CommConvoView'
import {
  DISMISS_MISSED_CALL,
  GET_RECENT_PHONE_CALLS_SUB,
} from './CommsPage.gql'
import { getContactMethodId } from './CommUtils'

const emptyArray = [] as const

const colorNotMissed = '#595959'
const colorMissed = '#ff4d50'
const iconStyle = {
  height: '16px',
  width: '16px',
  marginRight: '4px',
  marginBottom: '-2px',
}
const OutboundPhoneIcon = (color: string) => (
  <FontAwesomeIcon icon={faArrowUpRight} style={{ color, ...iconStyle }} />
)
const InboundPhoneIcon = (color: string) => (
  <FontAwesomeIcon icon={faArrowDownLeft} style={{ color, ...iconStyle }} />
)
const VoicemailIcon = () => (
  <FontAwesomeIcon
    icon={faVoicemail}
    style={{ color: colorMissed, ...iconStyle }}
  />
)

enum ConvoFilterOptions {
  ALL_OPEN_CALLS = 'ALL_OPEN_CALLS',
  UNREAD_CALLS = 'UNREAD_CALLS',
  CLOSED_CALLS = 'CLOSED_CALLS',
}

enum SortOptions {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
}

export const CommsPage = React.memo(() => {
  const userGuid = useExpectedUserGuid()
  const companyGuid = useExpectedCompanyGuid()
  const [selectedContactMethodId, setSelectedContactMethodId] = React.useState<
    string | null
  >(null)
  const [justMarkedMissedCallUnread, setJustMarkedMissedCallUnread] =
    React.useState(false)
  const [filterOption, setFilterOption] = useState<ConvoFilterOptions>(
    ConvoFilterOptions.ALL_OPEN_CALLS,
  )
  const [sortOption, setSortOption] = useState<SortOptions>(SortOptions.NEWEST)
  const [isCallSearcherOpen, setIsCallSearcherOpen] = useState(false)

  const [{ data: commsData, fetching: commsFetching }] = useSubscription({
    query: GET_RECENT_PHONE_CALLS_SUB,
    variables: {
      companyGuid: companyGuid,
    },
  })

  const groupedCardsData = useMemo(() => {
    return groupBy(commsData?.integratedPhoneCalls ?? [], p =>
      getContactMethodId(p),
    )
  }, [commsData?.integratedPhoneCalls])

  const contactCardsData = useMemo(() => {
    return Object.fromEntries(
      Object.entries(groupedCardsData).map(([contactMethodId, calls]) => {
        const contact = calls[0].contact
        const missedCall = calls[0].integratedPhoneMissedCalls[0]
        const voicemail = missedCall?.integratedPhoneVoicemails[0]
        const userOrYouDisplayName =
          calls[0].user?.userGuid === userGuid
            ? 'You'
            : calls[0].user?.firstName ?? 'You'

        let lastMessagePreview = ``
        if (!calls[0].endedAt) {
          lastMessagePreview = `Call in progress...`
        } else if (voicemail) {
          if (voicemail.recordingTranscript) {
            lastMessagePreview = `Voicemail: ${voicemail?.recordingTranscript}`
          } else {
            lastMessagePreview = `Left a voicemail`
          }
        } else if (calls[0].direction === 'inbound' && !!missedCall) {
          lastMessagePreview = `Missed Call`
        } else if (calls[0].direction === 'inbound') {
          lastMessagePreview = `Called ${userOrYouDisplayName}`
        } else {
          lastMessagePreview = `${userOrYouDisplayName} Called`
        }

        const isUnread = calls.some(
          call =>
            call.integratedPhoneMissedCalls.length > 0 &&
            call.integratedPhoneMissedCalls.some(
              mc =>
                (mc.integratedPhoneMissedCallDismissals ?? emptyArray)
                  .length === 0,
            ),
        )

        const isOpen = calls.some(call => !call.completedAt)
        const isClosed = calls.every(call => !!call.completedAt)

        return [
          contactMethodId,
          {
            contactMethodId: contactMethodId,
            avatarText: contact
              ? `${contact.firstName[0]}${contact.lastName[0]}`
              : '?',
            displayName:
              contact?.fullName ||
              (calls[0].direction === 'inbound'
                ? twilioPhoneNumberToBreezyPhone(calls[0].fromPhoneNumber)
                : twilioPhoneNumberToBreezyPhone(calls[0].toPhoneNumber)),
            lastContactAt: calls[0].startedAt,
            isUnread,
            isOpen,
            isClosed,
            lastMessagePreviewIcon: voicemail
              ? VoicemailIcon()
              : calls[0].direction === 'inbound'
              ? InboundPhoneIcon(missedCall ? colorMissed : colorNotMissed)
              : OutboundPhoneIcon(missedCall ? colorMissed : colorNotMissed),
            lastMessagePreview: lastMessagePreview,
          },
        ]
      }),
    )
  }, [groupedCardsData, userGuid])

  const filteredContactCardsDataArr = useMemo(() => {
    const allContacts = Object.values(contactCardsData)
    switch (filterOption) {
      case ConvoFilterOptions.UNREAD_CALLS:
        return allContacts.filter(contact => contact.isUnread)
      case ConvoFilterOptions.CLOSED_CALLS:
        return allContacts.filter(contact => contact.isClosed)
      case ConvoFilterOptions.ALL_OPEN_CALLS:
      default:
        return allContacts.filter(contact => contact.isOpen)
    }
  }, [contactCardsData, filterOption])

  const sortedContactCardsDataArr = useMemo(() => {
    return filteredContactCardsDataArr.sort((a, b) => {
      const timeA = new Date(a.lastContactAt).getTime()
      const timeB = new Date(b.lastContactAt).getTime()
      return sortOption === SortOptions.NEWEST ? timeB - timeA : timeA - timeB
    })
  }, [filteredContactCardsDataArr, sortOption])

  const [, dismissMissedCallMut] = useMutation(DISMISS_MISSED_CALL)

  const currentContactCallsData = useMemo(() => {
    return selectedContactMethodId
      ? groupedCardsData[selectedContactMethodId]
      : []
  }, [groupedCardsData, selectedContactMethodId])

  useEffect(() => {
    if (!currentContactCallsData || !!justMarkedMissedCallUnread) return

    const callsToDismiss = currentContactCallsData
      .filter(call => call.integratedPhoneMissedCalls?.length > 0)
      .filter(call =>
        call.integratedPhoneMissedCalls.some(
          mc =>
            (mc.integratedPhoneMissedCallDismissals ?? emptyArray).length === 0,
        ),
      )

    const missedCallsToDismiss = callsToDismiss.flatMap(
      call => call.integratedPhoneMissedCalls,
    )
    missedCallsToDismiss.forEach(mc => {
      dismissMissedCallMut({
        obj: {
          integratedPhoneMissedCallDismissal: nextGuid(),
          integratedPhoneMissedCallGuid: mc.integratedPhoneMissedCallGuid,
          companyGuid,
          userGuid,
          dismissedAt: new Date().toISOString(),
        },
      })
    })
  }, [
    currentContactCallsData,
    dismissMissedCallMut,
    companyGuid,
    userGuid,
    justMarkedMissedCallUnread,
  ])

  useEffect(() => {
    if (!selectedContactMethodId && sortedContactCardsDataArr.length > 0) {
      setSelectedContactMethodId(sortedContactCardsDataArr[0].contactMethodId)
    }
  }, [sortedContactCardsDataArr, selectedContactMethodId])

  return (
    <Page requiresCompanyUser className="flex">
      <Card className="flex flex-1 flex-col" noPadding>
        <div className="flex max-h-full w-full flex-row divide-x divide-gray-300">
          <div className="flex min-w-[420px] flex-col border-0 border-r border-solid border-gray-100">
            <div className="mb-0 flex items-center justify-between border-0 border-b border-solid border-gray-100 px-4 py-5">
              <Radio.Group
                value={filterOption}
                onChange={e => setFilterOption(e.target.value)}
              >
                <Radio.Button value={ConvoFilterOptions.ALL_OPEN_CALLS}>
                  Open
                </Radio.Button>
                <Radio.Button value={ConvoFilterOptions.UNREAD_CALLS}>
                  Unread
                </Radio.Button>
                <Radio.Button value={ConvoFilterOptions.CLOSED_CALLS}>
                  Closed
                </Radio.Button>
              </Radio.Group>
              <div className="flex items-center gap-x-2">
                <Select
                  value={sortOption}
                  onChange={value => setSortOption(value)}
                  style={{ width: 100 }}
                >
                  <Select.Option value={SortOptions.NEWEST}>
                    Newest
                  </Select.Option>
                  <Select.Option value={SortOptions.OLDEST}>
                    Oldest
                  </Select.Option>
                </Select>
                <FaIconButton
                  icon={faPhonePlus}
                  tooltip="Initiate a call"
                  onClick={() => setIsCallSearcherOpen(true)}
                />
              </div>
            </div>
            <div className="flex h-full max-h-full flex-col gap-y-1 overflow-y-auto p-2 pb-8">
              {commsFetching && sortedContactCardsDataArr.length < 1 && (
                <div className="center-children-vh flex min-h-[600px] flex-1 items-center justify-center">
                  <LoadingSpinner />
                </div>
              )}
              {!commsFetching && sortedContactCardsDataArr.length === 0 && (
                <div className="flex h-full flex-col items-center justify-center">
                  <div className="mb-4 text-6xl">😊</div>
                  <p className="text-lg text-gray-600">
                    No calls found matching your current filters
                  </p>
                </div>
              )}
              {sortedContactCardsDataArr.length > 0 &&
                sortedContactCardsDataArr.map((contact, index) => (
                  <CommContactCard
                    key={index}
                    {...contact}
                    onClick={() => {
                      setJustMarkedMissedCallUnread(false)
                      setSelectedContactMethodId(
                        contact.contactMethodId ?? null,
                      )
                    }}
                    isSelected={
                      selectedContactMethodId === contact.contactMethodId
                    }
                  />
                ))}
            </div>
          </div>
          <div className="flex w-3/4 flex-col">
            {commsFetching && sortedContactCardsDataArr.length < 1 && (
              <div className="center-children-vh flex min-h-[600px] flex-1 items-center justify-center">
                <LoadingSpinner />
              </div>
            )}

            {selectedContactMethodId &&
              !!groupedCardsData[selectedContactMethodId] && (
                <ConvoView
                  calls={groupedCardsData[selectedContactMethodId]}
                  onMarkMissedCallUnread={() =>
                    setJustMarkedMissedCallUnread(true)
                  }
                />
              )}
            {!commsFetching && !selectedContactMethodId && (
              <div className="flex h-full flex-col items-center justify-center">
                <p className="text-lg text-gray-600">
                  Select a contact to view their conversation
                </p>
              </div>
            )}
          </div>
        </div>
      </Card>
      <CommCallSearcher
        isOpen={isCallSearcherOpen}
        setIsOpen={setIsCallSearcherOpen}
      />
    </Page>
  )
})

import { gql } from '../../generated'

export const READ_UPCOMING_APPOINTMENTS_QUERY = gql(/* GraphQL */ `
  query ReadUpcomingAppointments(
    $endWindow: timestamptz!
    $startWindow: timestamptz!
  ) {
    jobAppointments(
      where: {
        _and: [
          {
            appointmentWindowEnd: { _lte: $endWindow }
            appointmentWindowStart: { _gte: $startWindow }
          }
          {
            _or: [
              { cancellationStatus: { canceled: { _neq: true } } }
              { _not: { cancellationStatus: {} } }
            ]
          }
        ]
      }
      orderBy: { appointmentWindowStart: ASC }
    ) {
      appointmentGuid: jobAppointmentGuid
      appointmentReferenceNumber
      appointmentWindowEnd
      appointmentWindowStart
      appointmentType
      endOfAppointmentNextSteps
      sendConfirmationEnabled
      notificationType
      sendConfirmationTo
      confirmationLastSentAt
      sendReminderEnabled
      reminderLastSentAt
      assignments {
        assignmentStart
        assignmentEnd
        assignmentGuid: jobAppointmentAssignmentGuid
        assignmentStatus {
          jobAppointmentAssignmentStatusType
        }
        technician {
          deactivatedAt
          firstName
          lastName
          userGuid
          roles: userRoles {
            role
          }
          phoneNumbers: userPhoneNumbers {
            phoneNumber {
              companyGuid
              phoneNumber
              phoneNumberGuid
              type
            }
          }
          emailAddress
        }
      }
      description
      job {
        equipmentTypeJobLinks {
          estimatedEquipmentAge
          equipmentType
          relationshipType
          equipmentTypeName {
            name
          }
        }
        jobGuid
        jobType {
          name
          jobTypeGuid
          jobClass
          jobLifecycleGuid
        }
        accountGuid
        account {
          accountDisplayName
        }
        displayId
        location {
          address {
            addressGuid
            canonicalFullAddress
            city
            line1
            line2
            stateAbbreviation
            zipCode
            geoLocation
          }
          locationGuid
          propertyType
        }
        pointOfContact {
          firstName
          lastName
          primaryPhoneNumber {
            phoneNumber
            type
          }
          primaryEmailAddress {
            emailAddress
          }
        }
      }
      cancellationStatus {
        canceled
      }
      confirmationStatus {
        confirmed
      }
    }
  }
`)

export const NUM_INVOICES_QUERY = gql(/* GraphQL */ `
  query NumberOfInvoices {
    invoicesAggregate {
      aggregate {
        count
      }
    }
  }
`)

export const INVOICES_BY_LEAD_SOURCE_REPORT_QUERY = gql(/* GraphQL */ `
  query InvoicesByLeadSourceReport(
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    invoices(
      where: {
        issuedAt: { _gte: $startDate, _lte: $endDate }
        jobLink: { jobGuid: { _isNull: false } }
        status: { _eq: "PAID" }
        paymentRecordsAggregate: { count: { predicate: { _gt: 0 } } }
      }
    ) {
      displayId
      paymentRecordsAggregate {
        aggregate {
          sum {
            amountUsd
          }
          max {
            occurredAt
          }
        }
      }
      issuedAt
      jobLink {
        job {
          displayId
          location {
            address {
              city
              line1
              line2
              stateAbbreviation
              zipCode
            }
          }
          pointOfContact {
            fullName
          }
          jobLeadSource {
            companyLeadSource {
              canonicalLeadSourceName
              canonicalLeadSourceNameOverride
            }
          }
        }
      }
    }
  }
`)

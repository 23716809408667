import {
  CartItem,
  DiscountType,
  Guid,
  R,
  RichDiscountUsc,
  cartItemToCartItemUsc,
  cartItemUscToCartItem,
  isNullish,
  nextGuid,
} from '@breezy/shared'
import {
  faAdd,
  faCopy,
  faDollar,
  faEdit,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input } from 'antd'
import classNames from 'classnames'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ActionBar } from '../../adam-components/ActionBar/ActionBar'
import {
  ActionsModalAction,
  ActionsModalContent,
} from '../../adam-components/OnsiteModal/ActionsModalContent'
import {
  OnsiteBasicModal,
  OnsiteConfirmModal,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import { useGoBack } from '../../adam-components/OnsitePage/onsitePageUtils'
import {
  DiscountMultiPicker,
  DiscountPickerDiscount,
} from '../../components/Pricebook/DiscountMultiPicker'
import { PricebookItemEditModal } from '../../components/Pricebook/PricebookItemEditModal'
import { PricebookItemPicker } from '../../components/Pricebook/PricebookItemPicker'
import { RichDiscountEditModal } from '../../components/Pricebook/RichDiscountEditModal'
import { OnPhotoUploadChange } from '../../components/Upload/AsyncPhotoUpload'
import { useSynchronousUpload } from '../../components/Upload/Upload'
import { WysiwygEditor } from '../../components/WysiwygEditor/WysiwygEditor'
import { Link } from '../../elements/Link/Link'
import useIsMobile from '../../hooks/useIsMobile'
import { useUnsavedChangesWarning } from '../../hooks/useUnsavedChangesWarning'
import { BlockerFunction, useBlocker } from '../../providers/BlockerWrapper'
import {
  useBooleanState,
  useModalState,
  useStateWithSideEffect,
  useStrictContext,
} from '../../utils/react-utils'
import { useRedirectOnSave } from '../../utils/routerUtils'
import { EditOptionFeaturedPhotoWithThumbnail } from './EditOptionFeaturedPhotoWithThumbnail'
import { OptionContainer } from './OptionContainer'
import { RecommendWidget } from './components'
import {
  EstimatesContext,
  Option,
  OptionCartItem,
  OptionWithoutTotal,
  isEstimateCreatePath,
  isEstimateOverviewPath,
} from './estimatesFlowUtils'

type ItemActionsModalProps = {
  header: string
  onCancel: () => void
  onEdit: () => void
  onDuplicate?: () => void
  onDelete: () => void
}

const ItemActionsModal = React.memo<ItemActionsModalProps>(
  ({ header, onCancel, onEdit, onDuplicate, onDelete }) => {
    return (
      <OnsiteBasicModal
        headerBordered
        onClose={onCancel}
        open
        header={header}
        size="small"
      >
        <ActionsModalContent>
          <ActionsModalAction
            onClick={onEdit}
            icon={<FontAwesomeIcon icon={faEdit} />}
          >
            Edit
          </ActionsModalAction>
          {onDuplicate && (
            <ActionsModalAction
              onClick={onDuplicate}
              icon={<FontAwesomeIcon icon={faCopy} />}
            >
              Duplicate
            </ActionsModalAction>
          )}
          <ActionsModalAction
            danger
            onClick={onDelete}
            icon={<FontAwesomeIcon icon={faTrash} />}
          >
            Remove
          </ActionsModalAction>
        </ActionsModalContent>
      </OnsiteBasicModal>
    )
  },
)

type EditOptionViewProps = {
  index: number
  editingOption?: Option
  onSave: (option: OptionWithoutTotal) => void
  onCancelSideEffect?: () => void
  defaultDiscounts: DiscountPickerDiscount[]
}

export const EditOptionView = React.memo<EditOptionViewProps>(
  ({
    index,
    editingOption,
    onSave: externalOnSave,
    onCancelSideEffect,
    defaultDiscounts,
  }) => {
    const { logoPhotoGuid, logoPhotoCdnUrl } =
      useStrictContext(EstimatesContext)
    const isMobile = useIsMobile()
    const goBack = useGoBack()

    const [isDirty, setDirty, clearDirtyFlag] = useBooleanState()

    const [displayName, setDisplayName] = useStateWithSideEffect(
      setDirty,
      editingOption?.displayName ?? '',
    )

    const [descriptionHtml, setDescriptionHtml] = useStateWithSideEffect(
      setDirty,
      editingOption?.descriptionHtml ?? '',
    )

    const [lineItems, setLineItems] = useStateWithSideEffect(
      setDirty,
      editingOption?.lineItems ?? [],
    )
    const [discounts, setDiscounts] = useStateWithSideEffect(
      setDirty,
      editingOption?.discounts ?? defaultDiscounts,
    )

    const [recommended, setRecommended] = useStateWithSideEffect(
      setDirty,
      editingOption?.recommended ?? false,
    )

    const [featuredPhotoGuid, setFeaturedPhotoGuid] = useStateWithSideEffect<
      Guid | undefined
    >(setDirty, editingOption?.featuredPhotoGuid ?? logoPhotoGuid ?? undefined)

    const [featuredPhotoCdnUrl, setFeaturedPhotoCdnUrl] =
      useStateWithSideEffect<string | undefined>(
        setDirty,
        editingOption?.featuredPhotoCdnUrl ?? logoPhotoCdnUrl ?? undefined,
      )

    const isInvalid = useMemo(() => {
      return !lineItems.length
    }, [lineItems.length])

    const [itemPickerOpen, openItemPicker, closeItemPicker] = useModalState()

    const onItemsSubmit = useCallback(
      (items: CartItem[]) => {
        setLineItems(existing => [
          ...existing,
          ...items.map((item, i) => {
            const itemUsc = cartItemToCartItemUsc(item)
            return {
              ...itemUsc,
              seq: existing.length + i,
              cartItemGuid: nextGuid(),
            }
          }),
        ])
        closeItemPicker()
      },
      [closeItemPicker, setLineItems],
    )

    const [editingLineItemIndex, setEditingLineItemIndex] = useState<number>()

    const editingLineItem = useMemo(
      () =>
        isNullish(editingLineItemIndex)
          ? undefined
          : cartItemUscToCartItem(lineItems[editingLineItemIndex]),
      [editingLineItemIndex, lineItems],
    )

    const clearEditingLineItem = useCallback(
      () => setEditingLineItemIndex(undefined),
      [],
    )

    const onLineItemEdit = useCallback(
      (item: CartItem) => {
        if (!isNullish(editingLineItemIndex)) {
          if (item.quantity === 0) {
            setLineItems(R.remove(editingLineItemIndex, 1))
          } else {
            setLineItems(items => {
              const existingCartItemGuid =
                items[editingLineItemIndex].cartItemGuid
              const newItemUsc = cartItemToCartItemUsc(item)
              const newItem: OptionCartItem = {
                ...newItemUsc,
                seq: items[editingLineItemIndex].seq,
                cartItemGuid: existingCartItemGuid,
              }
              return R.update(editingLineItemIndex, newItem, items)
            })
          }
        }
        clearEditingLineItem()
      },
      [clearEditingLineItem, editingLineItemIndex, setLineItems],
    )

    const [discountPickerOpen, openDiscountPicker, closeDiscountPicker] =
      useModalState()

    const onDiscountsSubmit = useCallback(
      (discounts: DiscountPickerDiscount[]) => {
        setDiscounts(existing => [...existing, ...discounts])
        closeDiscountPicker()
      },
      [closeDiscountPicker, setDiscounts],
    )

    const [successfullySaved, setSuccessfullySaved] = useState(false)
    useRedirectOnSave(goBack, successfullySaved)

    const onSave = useCallback(() => {
      externalOnSave({
        optionGuid: editingOption?.optionGuid ?? nextGuid(),
        displayName,
        descriptionHtml,
        lineItems,
        discounts,
        recommended,
        featuredPhotoGuid,
        featuredPhotoCdnUrl,
        selected: !!editingOption?.selected,
        seq: editingOption ? editingOption.seq : index,
      })
      clearDirtyFlag()
      setSuccessfullySaved(true)
    }, [
      clearDirtyFlag,
      descriptionHtml,
      discounts,
      displayName,
      editingOption,
      externalOnSave,
      featuredPhotoCdnUrl,
      featuredPhotoGuid,
      index,
      lineItems,
      recommended,
    ])

    const [openedLineItemIndex, setOpenedLineItemIndex] = useState<number>()

    const onLineItemClick = useCallback((index: number) => {
      setOpenedLineItemIndex(index)
    }, [])

    const onLineItemActionsCancel = useCallback(() => {
      setOpenedLineItemIndex(undefined)
    }, [])

    const onLineItemActionsEdit = useCallback(() => {
      setEditingLineItemIndex(openedLineItemIndex)
      setOpenedLineItemIndex(undefined)
    }, [openedLineItemIndex])

    const onLineItemActionsDuplicate = useCallback(() => {
      setLineItems(items => {
        if (isNullish(openedLineItemIndex)) {
          return items
        }
        return R.insert(
          openedLineItemIndex + 1,
          {
            ...items[openedLineItemIndex],
            cartItemGuid: nextGuid(),
          },
          items,
        )
      })
      setOpenedLineItemIndex(undefined)
    }, [openedLineItemIndex, setLineItems])

    const onLineItemActionsDelete = useCallback(() => {
      setLineItems(items => {
        if (isNullish(openedLineItemIndex)) {
          return items
        }
        return R.remove(openedLineItemIndex, 1, items)
      })
      setOpenedLineItemIndex(undefined)
    }, [openedLineItemIndex, setLineItems])

    const [openedDiscountIndex, setOpenedDiscountIndex] = useState<number>()
    const [editingDiscountIndex, setEditingDiscountIndex] = useState<number>()

    const editingDiscount = useMemo(
      () =>
        isNullish(editingDiscountIndex)
          ? undefined
          : discounts[editingDiscountIndex],
      [discounts, editingDiscountIndex],
    )

    const clearEditingDiscount = useCallback(
      () => setEditingDiscountIndex(undefined),
      [],
    )

    const onDiscountActionsCancel = useCallback(() => {
      setOpenedDiscountIndex(undefined)
    }, [])

    const onDiscountActionsEdit = useCallback(() => {
      setEditingDiscountIndex(openedDiscountIndex)
      setOpenedDiscountIndex(undefined)
    }, [openedDiscountIndex])

    const onDiscountActionsDuplicate = useMemo(() => {
      if (isNullish(openedDiscountIndex)) {
        return undefined
      }
      const openedDiscount = discounts[openedDiscountIndex]
      // You can't have two percentage discounts so you can't duplicate one
      if (openedDiscount.type === DiscountType.RATE) {
        return undefined
      }
      return () => {
        setDiscounts(items => {
          if (isNullish(openedDiscountIndex)) {
            return items
          }
          return R.insert(
            openedDiscountIndex + 1,
            {
              ...items[openedDiscountIndex],
              discountGuid: nextGuid(),
            },
            items,
          )
        })
        setOpenedDiscountIndex(undefined)
      }
    }, [discounts, openedDiscountIndex, setDiscounts])

    const onDiscountActionsDelete = useCallback(() => {
      setDiscounts(items => {
        if (isNullish(openedDiscountIndex)) {
          return items
        }
        return R.remove(openedDiscountIndex, 1, items)
      })
      setOpenedDiscountIndex(undefined)
    }, [openedDiscountIndex, setDiscounts])

    const onDiscountEdit = useCallback(
      (discount: RichDiscountUsc) => {
        if (!isNullish(editingDiscountIndex)) {
          if (
            (discount.type === DiscountType.FLAT &&
              !discount.discountAmountUsc) ||
            (discount.type === DiscountType.RATE && !discount.discountRate)
          ) {
            setDiscounts(R.remove(editingDiscountIndex, 1))
          } else {
            setDiscounts(items => {
              const existingDiscountGuid =
                items[editingDiscountIndex].discountGuid

              const newItem: DiscountPickerDiscount = {
                ...discount,
                discountGuid: existingDiscountGuid,
              }
              return R.update(editingDiscountIndex, newItem, items)
            })
          }
        }
        clearEditingDiscount()
      },
      [clearEditingDiscount, editingDiscountIndex, setDiscounts],
    )

    const onReorder = useCallback(
      (itemIndex: number, dropIndex: number) => {
        setLineItems(lineItems => {
          const reorderedLineItems = R.move(
            itemIndex,
            dropIndex < itemIndex ? dropIndex + 1 : dropIndex,
            lineItems,
          )

          const reSequencedLineItems = reorderedLineItems.map((item, i) => ({
            ...item,
            seq: i,
          }))
          return reSequencedLineItems
        })
      },
      [setLineItems],
    )

    const orderedLineItems = useMemo(
      () => R.sortBy(R.prop('seq'), lineItems),
      [lineItems],
    )

    const shouldBlock = useCallback<BlockerFunction>(() => isDirty, [isDirty])

    const blockMatcher = useCallback(() => {
      const url = new URL(window.location.href)
      const isOverviewPage = isEstimateOverviewPath(url.pathname)
      const isCreatePage = isEstimateCreatePath(url.pathname)
      const isCorrectPathName = !!(isOverviewPage || isCreatePage)
      return isCorrectPathName && url.search.includes('option')
    }, [])

    const blocker = useBlocker(
      'estimatesEditOptionView',
      shouldBlock,
      blockMatcher,
    )

    useUnsavedChangesWarning(isDirty)

    const containerRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
      if (containerRef.current?.parentElement?.parentElement) {
        containerRef.current.parentElement.parentElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    }, [])

    const onCancel = useCallback(() => {
      onCancelSideEffect?.()
      goBack()
    }, [goBack, onCancelSideEffect])

    const onUploadChange: OnPhotoUploadChange = useSynchronousUpload(record => {
      setFeaturedPhotoGuid(record.photoGuid)
      setFeaturedPhotoCdnUrl(record.cdnUrl)
    })
    const onLineItemPhotoSelect = useCallback(
      (record: { photoGuid?: Guid; cdnUrl?: string }) => {
        setFeaturedPhotoGuid(record.photoGuid)
        setFeaturedPhotoCdnUrl(record.cdnUrl)
      },
      [setFeaturedPhotoCdnUrl, setFeaturedPhotoGuid],
    )

    const onRevertToDefaultPhoto = useCallback(() => {
      setFeaturedPhotoGuid(logoPhotoGuid)
      setFeaturedPhotoCdnUrl(logoPhotoCdnUrl)
    }, [
      logoPhotoCdnUrl,
      logoPhotoGuid,
      setFeaturedPhotoCdnUrl,
      setFeaturedPhotoGuid,
    ])

    const [chooseFeaturedImageOpen, setChooseFeaturedImageOpen] =
      useState(false)

    return (
      <div ref={containerRef}>
        <OnsitePageSection>
          <OptionContainer
            isEditing
            lineItems={orderedLineItems}
            openItemPicker={openItemPicker}
            onLineItemClick={onLineItemClick}
            onDiscountClick={setOpenedDiscountIndex}
            onReorder={onReorder}
            discounts={discounts}
            recommended={recommended}
            header={
              <Form layout="vertical">
                <Form.Item>
                  <div className="flex items-center gap-3">
                    <EditOptionFeaturedPhotoWithThumbnail
                      thumbnail={{
                        width: 104,
                        height: 104,
                      }}
                      sourcePhotoUrl={logoPhotoCdnUrl}
                      photoUrl={featuredPhotoCdnUrl}
                      onPhotoUploadChange={onUploadChange}
                      onLineItemPhotoSelect={onLineItemPhotoSelect}
                      onRevertToDefaultPhoto={onRevertToDefaultPhoto}
                      lineItems={lineItems}
                      chooseFeaturedImageOpen={chooseFeaturedImageOpen}
                      setChooseFeaturedImageOpen={setChooseFeaturedImageOpen}
                    />
                    <div className="flex max-w-[400px] flex-col">
                      <div className="text-sm font-semibold leading-[22px]">
                        Featured Image
                      </div>
                      <div className="text-sm text-bz-text-secondary">
                        Square aspect ratio, .PNG and .JPG file types, maximum
                        file size 25 MB.
                      </div>
                      <Link
                        bold={false}
                        onClick={() => setChooseFeaturedImageOpen(true)}
                      >
                        Choose Image
                      </Link>
                    </div>
                  </div>
                </Form.Item>

                <Form.Item label="Display Name">
                  <Input
                    name="displayName"
                    value={displayName}
                    onChange={e => setDisplayName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Description" className="mb-0">
                  <WysiwygEditor
                    value={descriptionHtml}
                    onChange={value => setDescriptionHtml(value)}
                    editorClassName="bg-white"
                    dataTestId="description-tiptap-editor"
                  />
                </Form.Item>
              </Form>
            }
            footer={
              <div className="flex flex-row items-center justify-between pt-2">
                <div className="flex flex-1 flex-row flex-wrap items-center gap-3">
                  <Button
                    type="primary"
                    size="large"
                    icon={<FontAwesomeIcon icon={faAdd} />}
                    onClick={openItemPicker}
                    className={classNames({ 'flex-1': isMobile })}
                  >
                    Add Line Item
                  </Button>
                  <Button
                    size="large"
                    icon={<FontAwesomeIcon icon={faDollar} />}
                    onClick={openDiscountPicker}
                    className={classNames({ 'flex-1': isMobile })}
                  >
                    Add Discount
                  </Button>
                </div>
                {!isMobile && (
                  <RecommendWidget
                    recommended={recommended}
                    onChange={setRecommended}
                  />
                )}
              </div>
            }
          />
        </OnsitePageSection>
        <ActionBar>
          <Button block size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            block
            size="large"
            type="primary"
            onClick={onSave}
            disabled={isInvalid}
          >
            Save
          </Button>
        </ActionBar>
        {itemPickerOpen && (
          <PricebookItemPicker
            onSubmit={onItemsSubmit}
            onCancel={closeItemPicker}
          />
        )}
        {editingLineItem && (
          <PricebookItemEditModal
            item={editingLineItem}
            onCancel={clearEditingLineItem}
            onSubmit={onLineItemEdit}
          />
        )}
        {discountPickerOpen && (
          <DiscountMultiPicker
            preExistingDiscounts={discounts}
            onSubmit={onDiscountsSubmit}
            onCancel={closeDiscountPicker}
          />
        )}
        {editingDiscount && (
          <RichDiscountEditModal
            item={editingDiscount}
            onSubmit={onDiscountEdit}
            onCancel={clearEditingDiscount}
            // We can't mix and match rate and flat discounts. We also can't have more than one rate discount. If we
            // have one discount, then it's a rate or a flat. Either way, if they edit it they can switch it. If we have
            // more than one discount, the only way that's possible is if they have two or more flats. You can't mix and
            // match, so modifying this one to be a rate would be mixing and matching with the other one.
            disableRate={discounts.length > 1}
          />
        )}

        {!isNullish(openedLineItemIndex) && (
          <ItemActionsModal
            header="Line item actions"
            onCancel={onLineItemActionsCancel}
            onEdit={onLineItemActionsEdit}
            onDuplicate={onLineItemActionsDuplicate}
            onDelete={onLineItemActionsDelete}
          />
        )}
        {!isNullish(openedDiscountIndex) && (
          <ItemActionsModal
            header="Discount actions"
            onCancel={onDiscountActionsCancel}
            onEdit={onDiscountActionsEdit}
            onDuplicate={onDiscountActionsDuplicate}
            onDelete={onDiscountActionsDelete}
          />
        )}
        {blocker.state === 'blocked' && (
          <OnsiteConfirmModal
            danger
            header="Are you sure you want to exit?"
            onCancel={blocker.reset}
            onConfirm={blocker.proceed}
            confirmText="Yes, Exit"
          >
            You have unsaved changes. If you exit, they will be lost.
            <br />
            Are you sure you want to exit?
          </OnsiteConfirmModal>
        )}
      </div>
    )
  },
)

import {
  BzAddress,
  Tag,
  invoiceV2ToInvoiceV1Status,
  isNullish,
  usCentsToUsd,
} from '@breezy/shared'
import {
  faFileInvoiceDollar,
  faReceipt,
} from '@fortawesome/pro-light-svg-icons'
import {
  faLocationDot,
  faPhone,
  faUser,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from 'antd'
import classnames from 'classnames'
import { memo, useMemo } from 'react'
import { InvoicesTagRow } from '../../../../components/InvoicesTagRow/InvoiceTagRow'
import { getNextAppointmentAndTechs } from '../../../../components/Kanban/kanbanUtils'
import { StackedAvatars } from '../../../../components/StackedAvatars/StackedAvatars'
import { TagList } from '../../../../components/Tags'
import { Job } from '../../../../gql/queries/Jobs.gql'
import { JobLifecycleDisplayPropertySettings } from '../../../../hooks/useJobLifecyclePersistedDisplay'
import { JobLifecycle } from '../../JobsPage.gql'
import { EstimatesTagList } from '../EstimatesTagList'
import {
  JobMenuDropdown,
  JobMenuDropdownOnChangeJobTypeHandler,
  JobMenuDropdownOnCreateLinkedJobHandler,
  JobMenuDropdownOnEditJobHandler,
  JobMenuDropdownOnUpdatePipelineStatusHandler,
  JobMenuDropdownProps,
} from '../JobMenuDropdown'
import { BasicJobCardSection } from './BasicJobCardSection'

interface BasicJobCardProps {
  job: Job
  lifecycles: JobLifecycle[]
  tzId: string
  displayPropertiesSettings: JobLifecycleDisplayPropertySettings
  onEditClicked: JobMenuDropdownOnEditJobHandler
  onCreateLinkedJobClicked: JobMenuDropdownOnCreateLinkedJobHandler
  onUpdateJobPipelineStatus: JobMenuDropdownOnUpdatePipelineStatusHandler
  onChangeJobType: JobMenuDropdownOnChangeJobTypeHandler
}

export const BasicJobCard = memo(
  ({
    job,
    lifecycles,
    tzId,
    displayPropertiesSettings,
    onEditClicked,
    onCreateLinkedJobClicked,
    onUpdateJobPipelineStatus,
    onChangeJobType,
  }: BasicJobCardProps) => {
    const { displayId, jobType, location, account, appointments, tags } = job

    const hasInvoice = useMemo(
      () => job.jobInvoices.length > 0,
      [job.jobInvoices.length],
    )
    const uniqueTags = useMemo(() => {
      const jobTags = tags.map(({ tag }) => tag)
      const accountTags = account.tags.map(({ tag }) => tag)
      const uniqueTagsMap = new Map<string, Tag>()

      for (let i = 0; i < jobTags.length; i++) {
        const tag = jobTags[i]
        uniqueTagsMap.set(tag.tagGuid, tag)
      }

      for (let i = 0; i < accountTags.length; i++) {
        const tag = accountTags[i]
        uniqueTagsMap.set(tag.tagGuid, tag)
      }

      return Array.from(uniqueTagsMap.values())
    }, [account.tags, tags])

    const primaryContact = useMemo(() => {
      const contact = account.accountContacts.find(c => c.primary)

      if (!contact) {
        return ''
      }
      return `${contact.contact.firstName} ${contact.contact.lastName} - ${
        contact.contact.primaryPhoneNumber?.phoneNumber ?? 'No Phone Number'
      }`
    }, [account.accountContacts])

    const [nextAppointment, technicians] = useMemo(
      () => getNextAppointmentAndTechs(appointments, tzId),
      [appointments, tzId],
    )

    const estimates: Job['estimates'] = useMemo(() => {
      const items: Job['estimates'] = []

      for (const estimate of job.estimates) {
        items.push(estimate)
      }

      for (const { estimate } of job.linkedJobEstimates) {
        items.push(estimate)
      }

      return items
    }, [job.estimates, job.linkedJobEstimates])

    const dropdownJobItem = useMemo(() => {
      const item: JobMenuDropdownProps['job'] = {
        jobGuid: job.jobGuid,
        accountGuid: job.account.accountGuid,
        locationGuid: job.location.locationGuid,
        jobLifecycleStatus: {
          jobLifecycleGuid: job.jobLifecycleStatus.jobLifecycleGuid,
        },
        isCreatedLinkedJob: job.linkedToJobs.length > 0,
      }

      if (job.linkedJobs.length > 0) {
        const linkedJob = job.linkedJobs[0]
        item.linkedJob = {
          jobGuid: linkedJob.job.jobGuid,
          jobType: linkedJob.job.jobType.name,
          displayId: linkedJob.job.displayId,
        }
      }

      return item
    }, [
      job.account.accountGuid,
      job.jobGuid,
      job.jobLifecycleStatus.jobLifecycleGuid,
      job.linkedJobs,
      job.linkedToJobs.length,
      job.location.locationGuid,
    ])

    return (
      <div className="relative flex flex-col gap-1 text-sm text-bz-gray-900">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center gap-2">
            <BasicJobCardSection visible={!!displayPropertiesSettings['ID']}>
              <div className="text-xs font-semibold text-bz-gray-700">
                JOB #{displayId}
              </div>
            </BasicJobCardSection>

            <BasicJobCardSection
              visible={!!displayPropertiesSettings['Job Class']}
            >
              <div
                className={classnames(
                  'rounded-md border border-solid px-1  text-xs',
                  {
                    'border-[#B7EB8F] bg-[#F6FFED] text-[#52C41A]':
                      jobType.jobClass === 'INSTALL',
                    'border-[#ADC6FF] bg-[#F0F5FF] text-[#2F54EB]':
                      jobType.jobClass === 'SERVICE',
                    'border-[#FFADD2] bg-[#FFF0F6] text-[#EB2F96]':
                      jobType.jobClass === 'MAINTENANCE',
                  },
                )}
              >
                {jobType.jobClass.slice(0, 4).toUpperCase()}
              </div>
            </BasicJobCardSection>

            <JobMenuDropdown
              job={dropdownJobItem}
              lifecycles={lifecycles}
              onEditJob={onEditClicked}
              onCreateLinkedJob={onCreateLinkedJobClicked}
              onUpdatePipelineStatus={onUpdateJobPipelineStatus}
              onChangeJobType={onChangeJobType}
            />
          </div>

          <BasicJobCardSection
            visible={!!displayPropertiesSettings['Job Type']}
          >
            <div className="text-sm font-semibold">{jobType.name}</div>
          </BasicJobCardSection>
        </div>

        <BasicJobCardSection
          visible={!!displayPropertiesSettings['Account Name']}
        >
          <div>{account.accountDisplayName}</div>
        </BasicJobCardSection>

        <BasicJobCardSection visible={!!displayPropertiesSettings['Location']}>
          <div className="flex w-full flex-row items-center space-x-2">
            <FontAwesomeIcon icon={faLocationDot} scale={0.5} />
            <span>
              {BzAddress.formatAddressLine1And2Condensed(location.address)},{' '}
              {location.address.zipCode.slice(0, 5)}
            </span>
          </div>
        </BasicJobCardSection>

        <BasicJobCardSection
          visible={!!displayPropertiesSettings['Point of Contact']}
        >
          <div className="flex w-full flex-row items-center space-x-2">
            <FontAwesomeIcon icon={faPhone} scale={0.5} />
            <span>{primaryContact}</span>
          </div>
        </BasicJobCardSection>

        <BasicJobCardSection
          visible={
            !!displayPropertiesSettings['Account Manager'] &&
            !isNullish(account.accountManager)
          }
        >
          <div className="flex w-full flex-row items-center space-x-2">
            <FontAwesomeIcon icon={faUser} scale={0.5} />
            <span>
              {`${account.accountManager?.firstName} ${account.accountManager?.lastName}`}{' '}
              (Acct. Manager)
            </span>
          </div>
        </BasicJobCardSection>

        <div className="mb-2 flex flex-col gap-2">
          <BasicJobCardSection
            visible={
              !!displayPropertiesSettings['Estimates'] && estimates.length > 0
            }
          >
            <div className="flex w-full flex-row items-center gap-2">
              <FontAwesomeIcon icon={faReceipt} />

              <EstimatesTagList estimates={estimates} limit={1} />
            </div>
          </BasicJobCardSection>

          <BasicJobCardSection
            visible={!!displayPropertiesSettings['Invoices'] && hasInvoice}
          >
            <div className="flex w-full flex-row items-center gap-2">
              <FontAwesomeIcon icon={faFileInvoiceDollar} />

              <InvoicesTagRow
                invoices={job.jobInvoices.map(ji => ({
                  invoiceGuid: ji.invoice.invoiceGuid,
                  displayId: ji.invoice.displayId.toString(),
                  totalAmountUsd: usCentsToUsd(ji.invoice.totalUsc),
                  invoiceStatus: invoiceV2ToInvoiceV1Status(ji.invoice.status),
                }))}
                limit={1}
              />
            </div>
          </BasicJobCardSection>
        </div>

        <BasicJobCardSection visible={!!displayPropertiesSettings['Tags']}>
          <TagList
            tags={uniqueTags}
            tagStyleVersion="v2"
            compact
            numTagsDisplayed={2}
            spacingY={2}
            truncateLength={20}
          />
        </BasicJobCardSection>

        <BasicJobCardSection
          visible={
            !!displayPropertiesSettings['Next Appointment'] &&
            nextAppointment !== ''
          }
        >
          <div>
            <Divider className="my-2" />

            <div className="flex items-center">
              {technicians.length ? (
                <span className="mr-1">
                  <StackedAvatars users={technicians} />
                </span>
              ) : null}

              <div className="flex-1">{nextAppointment}</div>
            </div>
          </div>
        </BasicJobCardSection>
      </div>
    )
  },
)

import {
  AccountingIntegrationType,
  AccountingIntegrationTypeFriendlyNames,
} from '@breezy/shared/src/domain/Accounting/AccountingServiceTypes'
import { useCallback } from 'react'
import { useMessage, useModal } from '../utils/antd-utils'
import { trpc } from './trpc'

type UseSyncInvoiceProps = {
  accountingIntegrationType: AccountingIntegrationType
  invoiceGuid: string
  invoiceDisplayId?: string
  onSuccess?: () => void
  onError?: (error: string) => void
}

export const useSyncInvoice = ({
  accountingIntegrationType,
  invoiceGuid,
  invoiceDisplayId,
  onSuccess,
  onError,
}: UseSyncInvoiceProps) => {
  const friendlyName =
    AccountingIntegrationTypeFriendlyNames[accountingIntegrationType]
  const message = useMessage()
  const Modal = useModal()

  const syncMutation = trpc.accounting[
    'accounting-app:sync-invoice'
  ].useMutation({
    onError: e => {
      const content = e?.message || 'Please try again later or contact support.'

      console.error(JSON.stringify(e, null, 2))

      Modal.error({
        title: `Could not sync with ${friendlyName} - Invoice ${
          invoiceDisplayId ?? invoiceGuid
        }`,
        content,
      })
      onError?.(content)
    },
    onSuccess: () => {
      message.success(
        `Successfully synced with ${friendlyName}. ${
          invoiceDisplayId ? `Invoice ${invoiceDisplayId}` : ''
        }`,
      )
      onSuccess?.()
    },
  })

  const onSyncClick = useCallback(
    (force?: boolean) =>
      syncMutation.mutateAsync({
        invoiceGuid,
        force,
      }),
    [syncMutation, invoiceGuid],
  )
  return { onSyncClick, loading: syncMutation.isLoading }
}

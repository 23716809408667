import {
  AbridgedInvoiceViewModel,
  AppointmentChecklistInstance,
  AppointmentType,
  AssignedApptViewModel,
  AssignmentWithTechnicianNameDTO,
  BzContact,
  calculateInferredAppointmentStatus,
  EquipmentTypeJobLink,
  formatEquipmentJobLink,
  JobGuid,
  MaintenancePlanMinimalInfo,
  nextGuid,
  SimpleContactDtoWithAccountGuid,
  TimeWindowDto,
  TimeZoneId,
  ZoneId,
} from '@breezy/shared'
import { Row, Typography } from 'antd'
import classNames from 'classnames'
import React, { ReactNode, useMemo } from 'react'
import { AbbreviatedList } from '../../../../elements/AbbreviatedList/AbbreviatedList'
import { AppointmentStatusTag } from '../../../../pages/ScheduleV2Page/AppointmentStatusTag'
import { m } from '../../../../utils/react-utils'
import { ContactLink } from '../../../ContactLabel/ContactLabel'
import { DateTimeWindow, DateTimeWindowView } from '../../../Dates'
import { InvoicesTagRow } from '../../../InvoicesTagRow/InvoiceTagRow'
import LocationInfoLink, {
  LocationInfoLinkLocation,
  LocationInfoLinkSimple,
} from '../../../LocationInfoLink'
import VerticalKeyValue from '../../../Page/Columns/TagColumn/VerticalKeyValue'
import { Tag } from '../../../Tags'
import { TagList } from '../../../Tags/TagList'
import JobDetailsLink from '../JobDetailsLink/JobDetailsLink'

type AppointmentCardProps = {
  jobGuid: JobGuid
  jobType: {
    name: string
  }
  tzId: TimeZoneId
  invoices?: AbridgedInvoiceViewModel[]
  accountTags?: string[]
  maintenancePlan?: MaintenancePlanMinimalInfo
  pointOfContact?: SimpleContactDtoWithAccountGuid
  appointmentCanceled?: boolean
  assignments?: AssignmentWithTechnicianNameDTO[]
  appointmentType?: AppointmentType
  location?: LocationInfoLinkLocation
  customerArrivalWindow?: TimeWindowDto
  equipmentTypeJobLinks?: EquipmentTypeJobLink[]
  checklists?: AppointmentChecklistInstance[]
  showTechnician?: boolean
  bottomElement?: React.ReactNode
  onChecklistClick?: () => void
  alert?: ReactNode
}

// TODO: consolidate with the MyAppointmentCard component
export const AppointmentCard = React.memo<AppointmentCardProps>(
  ({
    jobGuid,
    jobType,
    tzId,
    invoices,
    pointOfContact,
    accountTags,
    appointmentType,
    appointmentCanceled = false,
    assignments,
    location,
    customerArrivalWindow,
    equipmentTypeJobLinks,
    checklists,
    showTechnician = false,
    bottomElement,
    onChecklistClick,
    alert,
  }) => {
    return (
      <div className="flex max-w-[304px] flex-col gap-3 overflow-hidden text-bz-gray-900">
        {alert && <div className="flex-grow flex-wrap">{alert}</div>}
        <div className="flex items-center gap-4">
          {/* TODO: implement link capture/redirect */}
          <div className="flex-grow flex-wrap">
            <JobDetailsLink
              jobGuid={jobGuid}
              jobType={jobType}
              extra={
                appointmentType && (
                  <span className="ml-2 text-black">({appointmentType})</span>
                )
              }
            />
          </div>

          <div className="flex-shrink-0">
            <AppointmentStatusTag
              status={
                assignments?.length
                  ? calculateInferredAppointmentStatus(
                      appointmentCanceled,
                      assignments,
                    )
                  : 'UNASSIGNED'
              }
            />
          </div>
        </div>
        {pointOfContact && (
          <div className="min-w-full">
            <div className="semibold_14_22 gray9">Point of Contact</div>
            <div className="regular_14_22 gray9">{pointOfContact.name}</div>
            <div className="regular_14_22 gray9">
              {pointOfContact.phoneNumber
                ? pointOfContact.phoneNumber
                : pointOfContact.email}
            </div>
          </div>
        )}
        {accountTags && (
          <VerticalKeyValue
            spacing="medium"
            pair={{
              key: 'Account Tags',
              value: (
                <TagList
                  tags={accountTags.map(x => ({
                    name: x,
                    tagGuid: nextGuid(),
                    color: 'gray',
                  }))}
                />
              ),
            }}
          />
        )}
        {location && (
          <div className="min-w-full">
            <LocationInfoLink location={location} addressSingleLine />
          </div>
        )}
        {customerArrivalWindow && (
          <div>
            <div className="semibold_14_22 gray9">Customer Arrival Window</div>
            <DateTimeWindowView
              startWindowIsoWithOffsetTimestamp={customerArrivalWindow.start}
              endWindowIsoWithOffsetTimestamp={customerArrivalWindow.end}
              includeDate={true}
              overrideClassName="regular_14_22 gray9"
              zoneId={ZoneId.of(tzId)}
            />
          </div>
        )}
        <div className="flex flex-col">
          <div className="semibold_14_22 gray9">
            {showTechnician
              ? 'Assigned Technician'
              : 'Technician Assigned Time'}
          </div>
          {assignments?.length ? (
            assignments.map(assignment => (
              <div
                key={assignment.assignmentGuid}
                className={classNames({
                  'mb-2':
                    assignment.assignmentGuid !==
                    assignments[assignments.length - 1].assignmentGuid, // if not the last assignment, add a linebreak
                })}
              >
                {showTechnician ? (
                  <div className="regular_14_22 gray9">{`${assignment.technicianFirstName} ${assignment.technicianLastName}`}</div>
                ) : null}
                <DateTimeWindowView
                  startWindowIsoWithOffsetTimestamp={
                    assignment.timeWindow.start
                  }
                  endWindowIsoWithOffsetTimestamp={assignment.timeWindow.end}
                  includeDate={true}
                  overrideClassName="regular_14_22 gray9"
                  zoneId={ZoneId.of(tzId)}
                />
              </div>
            ))
          ) : (
            <span className="regular_14_22 gray9 italic">
              No one has been assigned yet
            </span>
          )}
        </div>
        {invoices && invoices.length > 0 && (
          <VerticalKeyValue
            spacing="medium"
            pair={{
              key: 'Invoices',
              value: <InvoicesTagRow invoices={invoices} limit={2} />,
            }}
          />
        )}
        {equipmentTypeJobLinks && (
          <div className="min-w-full">
            <div className="semibold_14_22 gray9">Equipment</div>
            <BastardizedEquipmentJobLinkTagsList
              equipmentTypeJobLinks={equipmentTypeJobLinks}
            />
          </div>
        )}
        {(checklists?.length ?? 0) > 0 && (
          <VerticalKeyValue
            spacing="medium"
            pair={{
              key: 'Checklists',
              value: (
                <Typography.Link
                  className="mt-0"
                  onClick={e => {
                    e.stopPropagation()
                    onChecklistClick?.()
                  }}
                >
                  {checklists && checklists[0].checklist.name}
                </Typography.Link>
              ),
            }}
          />
        )}
        {!!bottomElement && bottomElement}
      </div>
    )
  },
)

type BastardizedEquipmentJobLinkTagsListProps = {
  equipmentTypeJobLinks: EquipmentTypeJobLink[]
}
const BastardizedEquipmentJobLinkTagsList =
  React.memo<BastardizedEquipmentJobLinkTagsListProps>(
    ({ equipmentTypeJobLinks }) => {
      return equipmentTypeJobLinks.length > 0 ? (
        <AbbreviatedList
          spacingClassName="space-x-1.5"
          backgroundColorClassName=""
        >
          {equipmentTypeJobLinks.map(equipment => (
            <Tag
              key={equipment.equipmentType}
              compact
              tagStyleVersion="v2"
              noPopover
              hideIcon
              tag={{ name: formatEquipmentJobLink(equipment) }}
              className="mr-0"
            />
          ))}
        </AbbreviatedList>
      ) : (
        <div className="regular_14_22 gray9">No equipment</div>
      )
    },
  )

type MyAppointmentCardProps = {
  appointmentDetails: AssignedApptViewModel
  myAssignment: AssignmentWithTechnicianNameDTO | undefined
}

export const MyAppointmentCard = m<MyAppointmentCardProps>(
  ({ appointmentDetails, myAssignment }) => {
    const pointOfContact = useMemo(
      () => BzContact.create(appointmentDetails.pointOfContact),
      [appointmentDetails.pointOfContact],
    )
    return (
      <div className="flex flex-col gap-3 text-bz-gray-900">
        <Row justify="space-between" className="items-center">
          <JobDetailsLink
            {...appointmentDetails}
            extra={
              myAssignment && (
                <span className="ml-2 text-black">
                  ({appointmentDetails.appointment.appointmentType})
                </span>
              )
            }
          />
          {myAssignment && (
            <AppointmentStatusTag
              status={calculateInferredAppointmentStatus(
                appointmentDetails.appointment.appointmentCanceled,
                [myAssignment],
              )}
            />
          )}
        </Row>

        <div className="min-w-full">
          <LocationInfoLinkSimple location={appointmentDetails.location} />
        </div>

        {myAssignment && (
          <div>
            <div className="semibold_14_22 gray9">Technician Assigned Time</div>
            <DateTimeWindow
              startWindowIsoWithOffsetTimestamp={myAssignment.timeWindow.start}
              endWindowIsoWithOffsetTimestamp={myAssignment.timeWindow.end}
              includeDate={true}
              overrideClassName="regular_14_22 gray9"
              zone={{
                type: 'company-timezone-of-principal-user',
              }}
            />
          </div>
        )}
        <div>
          <div className="semibold_14_22 gray9">Customer Arrival Window</div>
          <DateTimeWindow
            startWindowIsoWithOffsetTimestamp={
              appointmentDetails.appointment.timeWindow.start
            }
            endWindowIsoWithOffsetTimestamp={
              appointmentDetails.appointment.timeWindow.end
            }
            includeDate={true}
            overrideClassName="regular_14_22 gray9"
            zone={{
              type: 'company-timezone-of-principal-user',
            }}
          />
        </div>

        <div className="min-w-full">
          <div className="semibold_14_22 gray9">Point of Contact</div>
          <ContactLink
            className="regular_14_22"
            {...appointmentDetails}
            contactFullName={appointmentDetails.pointOfContact}
          />
          {pointOfContact.primaryPhoneNumber?.phoneNumber && (
            <div>{pointOfContact.primaryPhoneNumber.phoneNumber}</div>
          )}
        </div>

        <div className="min-w-full">
          <div className="semibold_14_22 gray9">Equipment</div>
          <div className="regular_14_22 gray9 w-fit">
            {appointmentDetails.equipmentTypeJobLinks.length
              ? appointmentDetails.equipmentTypeJobLinks.map(link => (
                  <div key={link.equipmentType}>
                    {formatEquipmentJobLink(link)}
                  </div>
                ))
              : 'No equipment'}
          </div>
        </div>

        {appointmentDetails.maintenancePlans.length > 0 && (
          <div className="min-w-full">
            <VerticalKeyValue
              pair={{
                key: 'Maintenance Plan',
                value: appointmentDetails.maintenancePlans[0].planTypeName,
              }}
              spacing="medium"
            />
          </div>
        )}
      </div>
    )
  },
)

import {
  faEllipsis,
  faLink,
  faUnlink,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Modal, message } from 'antd'
import { useMemo, useState } from 'react'
import { useMutation } from 'urql'
import { JobPickerSelect } from '../../components/JobPickerSelect/JobPickerSelect'
import { INSERT_JOB_PHONE_CALL_LINK_MUTATION } from '../../components/ProgressiveJobCreationModal/JobPhoneCallLink.gql'
import { useUnlinkJobFromIntegratedPhoneCall } from '../../hooks/mutate/useUnlinkJobFromIntegratedPhoneCall'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'

export const ActionMenuEllipsis = ({
  jobGuid,
  accountGuid,
  integratedPhoneCallGuid,
}: {
  jobGuid?: string
  accountGuid?: string
  integratedPhoneCallGuid: string
}) => {
  const companyGuid = useExpectedCompanyGuid()
  const unlinkJob = useUnlinkJobFromIntegratedPhoneCall()
  const [showLinkToJobModal, setShowLinkToJobModal] = useState(false)

  const [, linkToPhoneCall] = useMutation(INSERT_JOB_PHONE_CALL_LINK_MUTATION)

  const items = useMemo(() => {
    if (jobGuid) {
      return [
        {
          key: 'unlink',
          label: 'Unlink Job',
          icon: <FontAwesomeIcon icon={faUnlink} />,
          onClick: () =>
            unlinkJob(companyGuid, integratedPhoneCallGuid, jobGuid),
        },
      ]
    } else if (accountGuid) {
      return [
        {
          key: 'link',
          label: 'Link to Job',
          icon: <FontAwesomeIcon icon={faLink} />,
          onClick: () => {
            setShowLinkToJobModal(true)
          },
        },
      ]
    } else {
      return []
    }
  }, [jobGuid, accountGuid, companyGuid, integratedPhoneCallGuid, unlinkJob])

  return (
    <>
      {items.length > 0 && (
        <Dropdown menu={{ items }} trigger={['click']}>
          <div className="center-children-vh h-6 w-6 cursor-pointer">
            <FontAwesomeIcon
              icon={faEllipsis}
              className="text-[16px] text-[#1F1F1F]"
            />
          </div>
        </Dropdown>
      )}
      {showLinkToJobModal && accountGuid && (
        <Modal
          title="Link to Job"
          open={showLinkToJobModal}
          onCancel={() => setShowLinkToJobModal(false)}
          footer={null}
        >
          <div className="w-full">
            <JobPickerSelect
              accountGuid={accountGuid}
              onChange={selectedJobGuid => {
                setShowLinkToJobModal(false)
                linkToPhoneCall({
                  object: {
                    integratedPhoneCallGuid,
                    jobGuid: selectedJobGuid,
                    companyGuid,
                  },
                })
                  .then(() => {
                    setShowLinkToJobModal(false)
                  })
                  .catch((error: unknown) => {
                    message.error('Failed to link job')
                  })
              }}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

import { gql } from '../../generated'

export const JOB_PICKER_SELECT_QUERY = gql(`
query GetJobsPickerDataForAccount($accountGuid: uuid!, $companyGuid: uuid!) {
  jobs(where: {_and: {accountGuid: {_eq: $accountGuid}, companyGuid: {_eq: $companyGuid}}}) {
    jobGuid
    displayId
    jobType {
      name
    }
    createdAt
  }
}
`)

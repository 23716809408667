import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type CommAvatarProps = {
  avatarText?: string
  isSelected?: boolean
  useCompanyColors?: boolean
}

export const CommAvatar = React.memo<CommAvatarProps>(
  ({ avatarText, isSelected, useCompanyColors }) => {
    const bgDefaultColor = isSelected ? '#d9d9d9' : '#f0f0f0'
    const bgCompanyColor = isSelected ? '#b5daf8' : '#e5f4ff'
    const bgColor = useCompanyColors ? bgCompanyColor : bgDefaultColor
    const textColor = useCompanyColors ? '#1578ff' : '#8c8c8c'
    return (
      <div
        className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full"
        style={{ backgroundColor: bgColor }}
      >
        {avatarText && avatarText !== '?' ? (
          <span
            className={`text-sm font-semibold`}
            style={{ color: textColor }}
          >
            {avatarText}
          </span>
        ) : (
          <FontAwesomeIcon
            icon={faUser}
            className={`text-sm`}
            style={{ color: textColor }}
          />
        )}
      </div>
    )
  },
)

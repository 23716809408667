import { isNullish } from '@breezy/shared'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import useIsMobile from '../../hooks/useIsMobile'

type ThemeProps = {
  iconBackgroundColorClassName: string
  iconColorClassName: string
  borderClassName: string
  backgroundColorClassName: string
}

const THEMES = {
  default: {
    iconBackgroundColorClassName: 'bg-bz-fill-tertiary',
    iconColorClassName: 'text-bz-text-secondary',
    borderClassName: 'border-bz-gray-500',
    backgroundColorClassName: 'bg-white',
  },
  warning: {
    iconBackgroundColorClassName: 'bg-bz-orange-200',
    iconColorClassName: 'text-bz-orange-800',
    borderClassName: 'border-bz-orange-300',
    backgroundColorClassName: 'bg-bz-orange-100',
  },
  muted: {
    iconBackgroundColorClassName: 'bg-bz-fill-secondary',
    iconColorClassName: 'text-bz-text-secondary',
    borderClassName: 'border-bz-border',
    backgroundColorClassName: 'bg-bz-fill-quaternary',
  },
} satisfies Record<string, ThemeProps>

const getThemeProps = (theme?: OnsitePageSimpleSectionItemTheme): ThemeProps =>
  THEMES[theme ?? 'default']

export type OnsitePageSimpleSectionItemTheme = keyof typeof THEMES

type ItemIconActionRowProps = React.PropsWithChildren<{
  icon?: React.ReactNode

  // This flag is used to render an empty icon to maintain the icon layout but you don't want to render an icon
  emptyIcon?: boolean
  onClick?: () => void
  rightContent?: React.ReactNode
  mainContentDisabled?: boolean
  className?: string
  footer?: React.ReactNode
  subContent?: React.ReactNode
  squareIcon?: boolean
  iconBackgroundColorClassName?: string
  accentColor?: string
  theme?: OnsitePageSimpleSectionItemTheme
  smallIconMode?: boolean
}>

export const ItemIconActionRow = React.memo<ItemIconActionRowProps>(
  ({
    children,
    icon,
    emptyIcon = false,
    onClick,
    rightContent,
    mainContentDisabled,
    className,
    footer,
    subContent,
    squareIcon,
    iconBackgroundColorClassName,
    accentColor,
    theme,
    smallIconMode,
  }) => {
    const isMobile = useIsMobile()
    const [templateColsStyle, footerClassName] = useMemo(() => {
      /**
       * The shape of this thing is kind of like this on non-mobile or smallIconVersion:
       *
       * +------+------------------------------+---------------+
       * | icon | main content                 | right content |
       * +------+------------------------------+---------------+
       * |      | footer                                       |
       * +------+----------------------------------------------+
       *
       * - The icon and the right content fit their content and the main content fills in the remaining space
       * - The footer aligns with the main content and spans across the right content, if it's there
       *
       * For mobile it looks like this:
       *
       * +------+------------------------------+---------------+
       * | icon | main content                 | right content |
       * +------+------------------------------+---------------+
       * | footer                                              |
       * +-----------------------------------------------------+
       *
       * - The only difference is the footer is the full bottom, including spanning across the icon.
       * - If `smallIconVersion` is true, we do the non-mobile version, otherwise it depends on viewport
       *
       */

      // Main content
      const templateColsParts = ['1fr']
      // On mobile we just span all columns
      const footerClassNames: string[] = []

      const showFooterFull =
        (isNullish(smallIconMode) && isMobile) || !smallIconMode

      if (showFooterFull) {
        footerClassNames.push('col-span-full')
      }

      if (icon || emptyIcon) {
        templateColsParts.unshift('auto')
        // If it's not full footer we start after the icon
        if (!showFooterFull) {
          footerClassNames.push('col-start-2')
        }
      }
      if (rightContent || onClick) {
        templateColsParts.push('auto')
        // On not-full-footer we already span the full length. Otherwise, just end at the end.
        if (!showFooterFull) {
          footerClassNames.push('col-end-[-1]')
        }
      }
      return [templateColsParts.join(' '), footerClassNames.join(' ')]
    }, [smallIconMode, isMobile, icon, emptyIcon, rightContent, onClick])

    let iconSizeClasses = 'h-10 w-10'
    if (smallIconMode) {
      const classes = ['mt-1']
      if (squareIcon) {
        classes.push('h-8 w-8')
      } else {
        classes.push('h-[34px] w-[34px]')
      }
      iconSizeClasses = classNames(classes)
    }
    return (
      <div
        onClick={mainContentDisabled ? undefined : onClick}
        style={{
          gridTemplateColumns: templateColsStyle,
        }}
        className={classNames(
          'relative grid items-start gap-3 overflow-hidden',
          {
            'cursor-pointer': !!onClick && !mainContentDisabled,
            'text-bz-gray-700': mainContentDisabled,
            'pt-5': !!accentColor,
          },
          className,
        )}
      >
        {accentColor && (
          <div
            className="absolute inset-x-0 top-0 h-2"
            style={{ backgroundColor: accentColor }}
          />
        )}
        {icon && (
          <div
            className={classNames(
              'relative flex items-center justify-center',
              iconSizeClasses,
              iconBackgroundColorClassName ??
                getThemeProps(theme).iconBackgroundColorClassName,
              getThemeProps(theme).iconColorClassName,
              squareIcon ? 'rounded-md' : 'rounded-full',
            )}
          >
            {icon}
          </div>
        )}
        {emptyIcon && (
          <div
            className={classNames(
              'relative flex items-center justify-center',
              iconSizeClasses,
            )}
          />
        )}

        <div
          className={classNames(
            'flex min-h-10 flex-1 flex-col justify-center text-base font-semibold',
            {
              'text-bz-gray-700': mainContentDisabled,
            },
          )}
        >
          {children}
          {subContent ? (
            <div
              className={classNames(
                'font-normal text-bz-text-secondary',
                smallIconMode ? 'text-base' : 'text-sm',
              )}
            >
              {subContent}
            </div>
          ) : undefined}
        </div>
        {rightContent ? (
          rightContent
        ) : onClick ? (
          <div className="flex h-full items-center">
            <FontAwesomeIcon
              className="text-base text-bz-gray-1000"
              icon={faChevronRight}
            />
          </div>
        ) : undefined}
        {footer ? <div className={footerClassName}>{footer}</div> : undefined}
      </div>
    )
  },
)

type OnsitePageSimpleSectionItemProps = ItemIconActionRowProps & {
  layoutClassName?: string
  borderColorClassName?: string
  footer?: React.ReactNode
  shadowClassName?: string
}

export const OnsitePageSimpleSectionItem =
  React.memo<OnsitePageSimpleSectionItemProps>(
    ({
      className,
      layoutClassName,
      borderColorClassName,
      theme,
      shadowClassName = 'shadow-sm',
      ...rest
    }) => {
      const isMobile = useIsMobile()
      return (
        <ItemIconActionRow
          {...rest}
          theme={theme}
          className={classNames(
            'min-h-[72px] rounded-xl border border-solid',
            isMobile ? 'p-4' : 'p-6',
            layoutClassName,
            borderColorClassName ?? getThemeProps(theme).borderClassName,
            getThemeProps(theme).backgroundColorClassName,
            shadowClassName,
            className,
          )}
        />
      )
    },
  )

import { BzDateFns, IsoDateString, TimeZoneId } from '@breezy/shared'
import React, { useEffect, useState } from 'react'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'

const formatDistanceToNowAbbreviated = (
  date: IsoDateString,
  tzId: TimeZoneId,
) => {
  return BzDateFns.formatDistanceStrict(
    BzDateFns.parseISO(date, tzId),
    BzDateFns.now(tzId),
    {
      addSuffix: true,
    },
  )
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace(' weeks', 'w')
    .replace(' week', 'w')
    .replace(' months', 'mo')
    .replace(' month', 'mo')
    .replace(' years', 'y')
    .replace(' year', 'y')
}

type TimeAgoProps = {
  prefix?: string
  date: IsoDateString
}

export const TimeAgo = React.memo<TimeAgoProps>(({ prefix, date }) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      // Update state to trigger re-render
      setCounter(prevCounter => prevCounter + 1)
    }, 15000) // 15000ms = 15 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval)
  }, []) //

  return (
    <div key={counter} className="text-xs text-bz-gray-800">
      {prefix ? prefix : ''}
      {formatDistanceToNowAbbreviated(date, tzId)}
    </div>
  )
})

import { noOp, twilioPhoneNumberToBreezyPhone } from '@breezy/shared'
import { motion } from 'framer-motion'
import React from 'react'
import { BzCloseButton } from '../../elements/BzCloseButton/BzCloseButton'
import { LoadingSpinner } from '../LoadingSpinner'
import { CallAvatar } from './CallAvatar'

type CallCardProps = {
  header: React.ReactNode
  body: React.ReactNode
  controlBar: React.ReactNode
  extraContent?: React.ReactNode
}

export const CallCard = React.memo<CallCardProps>(
  ({ header, body, controlBar, extraContent }) => {
    const width = 'w-[340px]'
    return (
      <motion.div
        className={`relative z-[111111] rounded-xl border border-solid border-[#D9D9D9] bg-white shadow-[0px_6px_16px_0px_rgba(0,0,0,0.08),0px_3px_6px_-4px_rgba(0,0,0,0.12),0px_9px_28px_8px_rgba(0,0,0,0.05)] ${width}`}
        initial={{ minHeight: '210px' }}
        animate={{ minHeight: extraContent ? '452px' : '210px' }}
        transition={{ duration: 0.3 }}
      >
        <div className="p-6 pb-3 pt-4">
          {header}
          {body}
          {controlBar}
        </div>
        {extraContent && (
          <motion.div
            initial={{ scaleY: 0, opacity: 0, height: '0px' }}
            animate={{ scaleY: 1, opacity: 1, height: 'auto' }}
            transition={{ duration: 0.3 }}
          >
            <div className="mt-4 border-0 border-t border-solid border-gray-300 p-6 pt-3">
              {extraContent}
            </div>
          </motion.div>
        )}
      </motion.div>
    )
  },
)

export type CallHeaderProps = {
  content: React.ReactNode
  onClose?: () => void
}

export const CallHeader = React.memo<CallHeaderProps>(
  ({ content, onClose }) => {
    return (
      <div className="flex items-center justify-between p-0 pb-4">
        <div className="text-base font-semibold leading-6 text-[#1F1F1F]">
          {content}
        </div>
        <BzCloseButton onClose={onClose ?? noOp} disabled={!onClose} />
      </div>
    )
  },
)

export type CallInfoBodyProps = {
  contactIsKnown: boolean
  avatarText?: string
  contactName?: string
  leadSourceName?: string
  leadSourceGuid?: string
  contactGuid?: string
  accountGuid?: string
  phoneNumber: string
  isAwaitingAnswer?: boolean
  isActiveCall?: boolean
}

export const CallInfoBody = React.memo<CallInfoBodyProps>(
  ({
    contactIsKnown,
    avatarText,
    contactName,
    leadSourceName,
    phoneNumber,
    isAwaitingAnswer,
    isActiveCall = false,
  }) => {
    return (
      <div className="mb-6 flex flex-row items-center gap-x-3">
        <div className="relative">
          <CallAvatar avatarText={avatarText} />
          {isAwaitingAnswer && (
            <div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-50">
              <LoadingSpinner spinnerColor="#1677ff" />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-y-0">
          <h2
            className={`m-0 text-base font-semibold leading-6 ${
              isActiveCall ? 'text-[#1F1F1F]' : 'text-[#8c8c8c]'
            }`}
          >
            {contactIsKnown
              ? contactName || 'Unknown contact'
              : twilioPhoneNumberToBreezyPhone(phoneNumber)}
          </h2>
          <p className="m-0 text-[14px] font-normal leading-[22px] text-[#8C8C8C]">
            {contactIsKnown
              ? twilioPhoneNumberToBreezyPhone(phoneNumber)
              : 'Unknown contact'}
          </p>
          <p className="m-0 text-[14px] font-normal leading-[22px] text-[#8C8C8C]">
            {leadSourceName || 'No Lead Source'}
          </p>
        </div>
      </div>
    )
  },
)

import { CalculatePaths, Guid, JobGuid, formatUsc } from '@breezy/shared'
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons'
import React, { useCallback, useMemo } from 'react'
import { useSubscription } from 'urql'
import { LocalDateView } from '../../../elements/DateView/LocalDateView'
import { SimpleDateView } from '../../../elements/DateView/SimpleDateView'
import { Link } from '../../../elements/Link/Link'
import { InvoicesBoolExp } from '../../../generated/user/graphql'
import useAppNavigation from '../../../hooks/useAppNav'
import InvoiceStatusTag from '../../Invoicing/InvoiceStatusTag/InvoiceStatusTag'
import { LoadingSpinner } from '../../LoadingSpinner'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem, {
  ContentListItem,
} from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'
import { INVOICE_COLLAPSIBLE_DATA_SUBSCRIPTION } from './InvoicesCollapsible.gql'

type InvoicesV2CollapsibleBaseProps = {
  accountGuid: Guid
  jobGuid?: JobGuid
  maintenancePlanGuid?: Guid
  maintenancePlanVisitLinkedJobGuids?: JobGuid[]
  locationGuid?: Guid
  jobAppointmentGuid?: JobGuid
  technicianAppStyle?: boolean
}

type WithCreateProps = {
  allowCreate: true
  jobGuid: JobGuid
}
type WithoutCreateProps = {
  allowCreate?: false
  jobGuid?: JobGuid
}

type InvoicesV2CollapsibleProps = InvoicesV2CollapsibleBaseProps &
  (WithCreateProps | WithoutCreateProps) & {
    jobAppointmentGuid?: Guid
  }

export const InvoicesV2Collapsible = React.memo<InvoicesV2CollapsibleProps>(
  ({
    technicianAppStyle,
    allowCreate,
    accountGuid,
    jobGuid,
    maintenancePlanGuid,
    maintenancePlanVisitLinkedJobGuids,
    locationGuid,
    jobAppointmentGuid,
  }) => {
    const appNav = useAppNavigation()
    const onPlus = useCallback(
      () =>
        allowCreate &&
        appNav.navigateToCreateNewInvoiceV2(accountGuid, jobGuid, {
          jobAppointmentGuid,
        }),
      [accountGuid, allowCreate, appNav, jobAppointmentGuid, jobGuid],
    )

    const whereClause = useMemo(() => {
      const whereClause: InvoicesBoolExp = {
        accountGuid: { _eq: accountGuid },
      }
      if (jobGuid) {
        whereClause.jobLink = { jobGuid: { _eq: jobGuid } }
      }
      if (maintenancePlanGuid) {
        const maintenancePlanPredicates: InvoicesBoolExp[] = [
          {
            maintenancePlanLink: {
              maintenancePlanGuid: { _eq: maintenancePlanGuid },
            },
          },
        ]

        if (maintenancePlanVisitLinkedJobGuids?.length) {
          maintenancePlanPredicates.push({
            jobLink: {
              jobGuid: { _in: maintenancePlanVisitLinkedJobGuids },
            },
          })
        }
        whereClause._or = maintenancePlanPredicates
      }

      if (locationGuid) {
        whereClause.locationLink = { locationGuid: { _eq: locationGuid } }
      }
      if (jobAppointmentGuid) {
        whereClause.jobAppointmentLink = {
          jobAppointmentGuid: { _eq: jobAppointmentGuid },
        }
      }

      return whereClause
    }, [
      accountGuid,
      jobAppointmentGuid,
      jobGuid,
      locationGuid,
      maintenancePlanGuid,
      maintenancePlanVisitLinkedJobGuids,
    ])

    const [{ data, fetching }] = useSubscription({
      query: INVOICE_COLLAPSIBLE_DATA_SUBSCRIPTION,
      variables: {
        where: whereClause,
      },
    })

    const filteredInvoices = useMemo(() => {
      if (jobGuid) {
        return data?.invoices.filter(
          invoice => invoice.jobLink?.jobGuid === jobGuid,
        )
      }
      return data?.invoices ?? []
    }, [data?.invoices, jobGuid])
    return (
      <BzCollapsible
        title="Invoices"
        titleIcon={faFileInvoiceDollar}
        technicianAppStyle={technicianAppStyle}
        onPlus={allowCreate ? onPlus : undefined}
      >
        {fetching ? (
          <LoadingSpinner />
        ) : (
          filteredInvoices?.map(
            ({
              serviceCompletionDate,
              displayId,
              invoiceGuid,
              status,
              totalUsc,
              maintenancePlanLink,
              jobLink,
              issuedAt,
              invoiceTerm,
            }) => {
              const contentListItems: ContentListItem[] = []
              const isCoveredByMaintenancePlan =
                !!maintenancePlanLink?.maintenancePlanGuid && totalUsc === 0
              isCoveredByMaintenancePlan &&
                contentListItems.push({
                  key: 'Covered By Maintenance Plan',
                  value: 'Yes',
                })

              const isRecurringPayment = invoiceTerm === 'AUTO'
              if (isRecurringPayment) {
                contentListItems.push({
                  key: 'Recurring Maintenance Plan Payment',
                  value: 'Yes',
                })
              } else {
                contentListItems.push({
                  key: 'Completion Date',
                  value: serviceCompletionDate ? (
                    <LocalDateView localDate={serviceCompletionDate} />
                  ) : (
                    'N/A'
                  ),
                })
              }

              if (issuedAt) {
                contentListItems.push({
                  key: 'Issued Date',
                  value: <SimpleDateView isoDate={issuedAt} />,
                })
              }

              contentListItems.push({
                key: 'Total Price',
                value: formatUsc(totalUsc),
              })

              if (jobLink) {
                contentListItems.push({
                  key: 'Job',
                  value: (
                    <Link
                      bold={false}
                      to={CalculatePaths.jobDetails({
                        jobGuid: jobLink.jobGuid,
                      })}
                    >
                      #{jobLink.job.displayId}
                    </Link>
                  ),
                })
              }

              const titleSuffix = isCoveredByMaintenancePlan
                ? '- Covered - Zero Ticket'
                : isRecurringPayment
                ? '- Plan Auto-Payment'
                : ''
              const title = `Invoice #${displayId} ${titleSuffix}`.trimEnd()

              return (
                <CollapsibleItem
                  key={invoiceGuid}
                  tag={<InvoiceStatusTag status={status} />}
                  title={title}
                  contentList={contentListItems}
                  onTitleClick={() =>
                    appNav.navigateToInvoiceOverviewPage(invoiceGuid)
                  }
                  alwaysOpen
                />
              )
            },
          )
        )}
      </BzCollapsible>
    )
  },
)

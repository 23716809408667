import {
  faCalendarPlus,
  faCheck,
  faHouseUser,
  faMailboxFlagUp,
  faPhoneVolume,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { message } from 'antd'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation } from 'urql'
import { useTwilioPhone } from '../../components/PhoneIntegrated/useTwilioPhone'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import useAppNav from '../../hooks/useAppNav'
import { useExpectedUserGuid } from '../../providers/PrincipalUser'
import { CommAvatar } from './CommAvatar'
import { ConvoElement } from './CommConvoElements'
import {
  COMPLETE_CALLS,
  DELETE_MISSED_CALL_DISMISSALS,
  RecentPhoneCall,
  UNCOMPLETE_CALL,
} from './CommsPage.gql'
import { CommsPageTextActionButton } from './CommsPageTextActionButton'
import {
  getAvatarText,
  getBreezyFormatExternalPhoneNumber,
  getDisplayNameShortFormat,
} from './CommUtils'

type ConvoViewProps = {
  calls: RecentPhoneCall[]
  onMarkMissedCallUnread: () => void
}

export const ConvoView = React.memo<ConvoViewProps>(
  ({ calls, onMarkMissedCallUnread }) => {
    const userGuid = useExpectedUserGuid()
    const call = calls.length > 0 ? calls[0] : undefined
    const contact = call?.contact
    const avatarText = getAvatarText(contact)
    const phoneNumber = call
      ? getBreezyFormatExternalPhoneNumber(call)
      : undefined
    const displayName = getDisplayNameShortFormat(contact, phoneNumber ?? '')
    const leadSourceName =
      call?.integratedPhone?.companyLeadSource
        ?.canonicalLeadSourceNameOverride ??
      call?.integratedPhone?.companyLeadSource?.canonicalLeadSourceName ??
      call?.integratedPhone?.assistantIntegratedPhone?.assistant?.name ??
      'Default Phone'
    const isCallCompleted = calls.every(call => !!call.completedAt)
    const mostRecentCall = useMemo(() => {
      return calls.length > 0 ? calls[0] : undefined
    }, [calls])

    const appNav = useAppNav()
    const { status, beginVoiceCallTo } = useTwilioPhone()

    const onCreateJob = useCallback(() => {
      appNav.navigateToCreateNewJob({
        accountGuid: call?.accountGuid,
        contactGuid: contact?.contactGuid,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        phoneNumber,
        createNewAccount: !contact,
        leadSourceGuid:
          call?.integratedPhone?.companyLeadSource?.companyLeadSourceGuid,
        linkToIntegratedCallGuid: mostRecentCall?.integratedPhoneCallGuid,
      })
    }, [
      appNav,
      contact,
      call?.accountGuid,
      call?.integratedPhone?.companyLeadSource?.companyLeadSourceGuid,
      phoneNumber,
      mostRecentCall?.integratedPhoneCallGuid,
    ])

    const onViewAccount = useCallback(() => {
      if (call?.accountGuid) {
        appNav.navigateToAccountDetailsPage(call.accountGuid)
      }
    }, [appNav, call?.accountGuid])

    const onInitiateCall = useCallback(() => {
      if (phoneNumber) {
        beginVoiceCallTo({
          phoneNumber,
          contact:
            contact && contact.accountContacts.length > 0
              ? {
                  contactGuid: contact.contactGuid,
                  accountGuid: contact.accountContacts[0].account.accountGuid,
                  firstName: contact.firstName,
                  lastName: contact.lastName,
                  fullName: `${contact.firstName} ${contact.lastName}`.trim(),
                }
              : undefined,
        })
      } else {
        message.error('No phone number available to call')
      }
    }, [beginVoiceCallTo, phoneNumber, contact])

    const sortedCalls = useMemo(() => {
      return [...calls].sort((a, b) => {
        const dateA = new Date(a.startedAt)
        const dateB = new Date(b.startedAt)
        return dateA.getTime() - dateB.getTime()
      })
    }, [calls])

    const scrollRef = useRef<HTMLDivElement>(null)

    const lastMissedCall = sortedCalls.findLast(
      call => (call.integratedPhoneMissedCalls?.length ?? 0) > 0,
    )

    const [, markMissedCallUnreadMut] = useMutation(
      DELETE_MISSED_CALL_DISMISSALS,
    )

    const [, completeCallsMut] = useMutation(COMPLETE_CALLS)
    const [, uncompleteCallMut] = useMutation(UNCOMPLETE_CALL)
    const [isCompletingCalls, setIsCompletingCalls] = useState(false)
    const [isUncompletingCalls, setIsUncompletingCalls] = useState(false)
    const [isMarkingMissedCallUnread, setIsMarkingMissedCallUnread] =
      useState(false)

    const markMissedCallUnread = useCallback(() => {
      if (!lastMissedCall) return

      setIsMarkingMissedCallUnread(true)
      markMissedCallUnreadMut({
        integratedPhoneMissedCallGuid:
          lastMissedCall.integratedPhoneMissedCalls[0]
            .integratedPhoneMissedCallGuid,
      })
        .then(() => {
          setIsMarkingMissedCallUnread(false)
          message.success('Missed call marked as unread successfully')
          onMarkMissedCallUnread()
        })
        .catch(error => {
          message.error(
            'Failed to mark missed call as unread: ' + error.message,
          )
          setIsMarkingMissedCallUnread(false)
        })
    }, [lastMissedCall, markMissedCallUnreadMut, onMarkMissedCallUnread])

    const markCallsCompleted = useCallback(() => {
      setIsCompletingCalls(true)
      const uncompletedCalls = sortedCalls.filter(call => !call.completedAt)
      const now = new Date().toISOString()

      completeCallsMut({
        integratedPhoneCallGuids: uncompletedCalls.map(
          call => call.integratedPhoneCallGuid,
        ),
        completedAt: now,
        completedByUserGuid: userGuid,
      })
        .then(() => {
          message.success('Conversation marked as closed successfully')
          setIsCompletingCalls(false)
        })
        .catch(error => {
          message.error(
            'Failed to mark conversation as closed: ' + error.message,
          )
          setIsCompletingCalls(false)
        })
    }, [sortedCalls, completeCallsMut, userGuid])

    const markConvoUncompleted = useCallback(() => {
      setIsUncompletingCalls(true)
      const mostRecentCall = sortedCalls[sortedCalls.length - 1]
      if (mostRecentCall && mostRecentCall.completedAt) {
        uncompleteCallMut({
          integratedPhoneCallGuid: mostRecentCall.integratedPhoneCallGuid,
        })
          .then(() => {
            message.success('Conversation marked as open successfully')
            setIsUncompletingCalls(false)
          })
          .catch(error => {
            message.error(
              'Failed to mark conversation as open: ' + error.message,
            )
            setIsUncompletingCalls(false)
          })
      }
    }, [sortedCalls, uncompleteCallMut])

    useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
      }
    }, [sortedCalls])

    return (
      <div className="flex w-full flex-col overflow-hidden">
        <div className="flex items-center justify-between border-0 border-b border-solid border-gray-100 p-4">
          <div className="flex flex-row gap-x-2">
            <CommAvatar avatarText={avatarText} />
            <div className="flex w-full flex-col">
              <div className="space-between mb-[2px] flex w-full items-center">
                <div className="text-[16px] font-semibold leading-6 text-[#1F1F1F]">
                  {displayName}
                </div>
              </div>
              <div className="space-between flex w-full items-center">
                <div className="max-w-[272px] truncate text-[12px] leading-[16px] text-[#595959]">
                  {phoneNumber} • {leadSourceName}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <CommsPageTextActionButton onClick={onCreateJob}>
              <FontAwesomeIcon
                icon={faCalendarPlus}
                style={{ marginRight: '8px' }}
              />{' '}
              Create Job
            </CommsPageTextActionButton>
            <FaIconButton
              icon={faPhoneVolume}
              tooltip="Call"
              onClick={onInitiateCall}
              disabled={status !== 'connected'}
            />
            {call?.accountGuid && (
              <FaIconButton
                icon={faHouseUser}
                onClick={onViewAccount}
                tooltip="View Account"
              />
            )}
            {isCallCompleted && (
              <FaIconButton
                icon={faCheck}
                onClick={markConvoUncompleted}
                tooltip="Mark as open"
                style={{ color: '#00993a' }}
                isLoading={isUncompletingCalls}
              />
            )}
            {!isCallCompleted && (
              <FaIconButton
                icon={faCheck}
                onClick={markCallsCompleted}
                tooltip="Mark as closed"
                isLoading={isCompletingCalls}
              />
            )}
            {lastMissedCall && (
              <FaIconButton
                icon={faMailboxFlagUp}
                onClick={markMissedCallUnread}
                tooltip="Mark as unread"
                isLoading={isMarkingMissedCallUnread}
              />
            )}
          </div>
        </div>
        <div
          ref={scrollRef}
          className="flex flex-1 flex-col gap-y-6 overflow-y-auto px-4 pb-[80px] pt-4"
        >
          {sortedCalls.map((call, index) => (
            <div key={index} className="mb-4">
              <ConvoElement call={call} />
            </div>
          ))}
        </div>
      </div>
    )
  },
)

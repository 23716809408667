import {
  faCheck,
  faEnvelopeOpenText,
  faX,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Radio, Switch } from 'antd'
import React from 'react'
import { Card } from '../../adam-components/Card/Card'
import { ItemIconActionRow } from '../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import ThinDivider from '../../elements/ThinDivider'

type SendConfirmationRowProps = {
  to?: string
  sendConfirmationEnabled: boolean
  notificationType?: 'SMS' | 'EMAIL'
  onChangeSendConfirmationEnabled: (value: boolean) => void
  onChangeNotificationType: (value: 'SMS' | 'EMAIL') => void
  onChangeTo: (value: string) => void
}

type SendReminderRowProps = {
  isVisitWithin24Hours?: boolean
  sendReminderEnabled: boolean
  onChangeSendReminderEnabled: (value: boolean) => void
}

type VisitConfirmationCardProps = SendConfirmationRowProps &
  SendReminderRowProps

export const VisitConfirmationCard = React.memo<VisitConfirmationCardProps>(
  props => {
    return (
      <Card>
        <SendConfirmationRow {...props} />
        <div
          className={`duration-400 overflow-hidden transition-all ease-in-out ${
            props.sendConfirmationEnabled
              ? 'max-h-[200px] opacity-100'
              : 'max-h-0 opacity-0'
          }`}
        >
          <div className="flex flex-row gap-3 overflow-hidden">
            <div className="flex min-w-10" />
            <ThinDivider widthPx={1} />
          </div>
          <SendReminderRow {...props} />
        </div>
      </Card>
    )
  },
)

const SendConfirmationRow = React.memo<SendConfirmationRowProps>(
  ({
    sendConfirmationEnabled,
    to,
    notificationType,
    onChangeSendConfirmationEnabled,
    onChangeNotificationType,
    onChangeTo,
  }) => {
    return (
      <ItemIconActionRow
        icon={<FontAwesomeIcon icon={faEnvelopeOpenText} />}
        subContent={
          <div className="flex flex-col gap-3">
            <div className="text-bz-text">
              The customer will receive messages with visit details, including
              confirmations, cancellations, and changes, with options to cancel
              or reschedule.
            </div>
            {sendConfirmationEnabled && (
              <div
                className={`flex flex-wrap gap-2 transition-all duration-150 ease-in-out ${
                  sendConfirmationEnabled
                    ? 'translate-y-0 opacity-100'
                    : '-translate-y-4 opacity-0'
                }`}
              >
                <Radio.Group
                  value={notificationType}
                  onChange={e => {
                    onChangeNotificationType(e.target.value)
                  }}
                  optionType="button"
                >
                  <Radio value="EMAIL">Email</Radio>
                  <Radio value="SMS">SMS</Radio>
                </Radio.Group>
                <div className="mx-2 my-[2px] min-h-0 w-[1px] bg-bz-gray-500" />
                {notificationType === 'EMAIL' && (
                  <Input
                    className="min-w-[160px] flex-1"
                    value={to}
                    onChange={e => onChangeTo(e.target.value)}
                    placeholder="Email Address"
                  />
                )}
                {notificationType === 'SMS' && (
                  <Input
                    className="min-w-[160px] flex-1"
                    value={to}
                    onChange={e => onChangeTo(e.target.value)}
                    placeholder="Phone Number"
                  />
                )}
              </div>
            )}
          </div>
        }
        rightContent={
          <Switch
            checked={sendConfirmationEnabled}
            onChange={value => {
              onChangeSendConfirmationEnabled(value)
            }}
            unCheckedChildren={<FontAwesomeIcon icon={faX} />}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
          />
        }
      >
        <div className="text-base">Send Visit Communications</div>
      </ItemIconActionRow>
    )
  },
)

const SendReminderRow = React.memo<SendReminderRowProps>(
  ({
    sendReminderEnabled,
    onChangeSendReminderEnabled,
    isVisitWithin24Hours,
  }) => {
    return (
      <ItemIconActionRow
        emptyIcon
        subContent={
          <div className="text-bz-text">
            The customer will receive a reminder message the day before their
            visit.
          </div>
        }
        rightContent={
          <Switch
            checked={sendReminderEnabled}
            disabled={isVisitWithin24Hours}
            onChange={value => onChangeSendReminderEnabled(value)}
            unCheckedChildren={<FontAwesomeIcon icon={faX} />}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
          />
        }
      >
        <div className="text-base">Send 24-Hour Reminder</div>
      </ItemIconActionRow>
    )
  },
)

import { BzAddress, noOp } from '@breezy/shared'
import { useMapLink } from '../../../hooks/useMapLink'
import './AddressMultiLineView.less'

type AddressMultiLineViewProps = {
  readonly displayName?: string
  readonly address: BzAddress
  readonly labelClassName?: string
  readonly linkToMapApp?: boolean
  readonly addressSingleLine?: boolean
  readonly onClick?: () => void
}

const AddressMultiLineView = ({
  displayName,
  address,
  labelClassName = '',
  linkToMapApp = true,
  addressSingleLine = false,
  onClick,
}: AddressMultiLineViewProps) => {
  const addressContent = (
    <div
      className="address-two-line-view w-fit"
      onClick={!linkToMapApp ? onClick : noOp}
    >
      <p className={'regular_14_22 w-fit ' + labelClassName}>
        {address.line1}
        {addressSingleLine && address.line2 ? `, ${address.line2}` : ''}
      </p>
      {address.line2 && !addressSingleLine ? (
        <p className={'regular_14_22 w-fit ' + labelClassName}>
          {address.line2}
        </p>
      ) : null}
      <p className={'regular_14_22 ellipsis w-fit ' + labelClassName}>
        {address.city}, {address.stateAbbreviation}, {address.zip5}
      </p>
      {displayName && (
        <p className={'regular_14_22 w-fit ' + labelClassName}>
          ({displayName})
        </p>
      )}
    </div>
  )

  const [mapsLink] = useMapLink(address)

  if (!linkToMapApp) {
    return addressContent
  }

  return (
    <div className="address-two-line-view w-fit">
      <a href={mapsLink} target="_blank" rel="noreferrer" className="w-fit ">
        {addressContent}
      </a>
    </div>
  )
}

export default AddressMultiLineView

import { BzDateFns, isNullish } from '@breezy/shared'
import { faPause, faPlay, faStop } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React from 'react'
import useIsMobile from 'src/hooks/useIsMobile'
import { Card } from '../../adam-components/Card/Card'
import { trpc } from '../../hooks/trpc'
import {
  UserTimeClockCurrentAssignment,
  useTimeClock,
} from '../../providers/TimeClockProvider/TimeClockProvider'
import tailwindConfig from '../../tailwind.config'
import { Styled } from '../../utils/Stylable'

export type UserTimeClockStatusCardProps = {
  hideClockInOutButton?: boolean
}

export const UserTimeClockStatusCard = React.memo<UserTimeClockStatusCardProps>(
  ({ hideClockInOutButton }) => {
    const {
      currentAssignment,
      refetch,
      isClockedIn,
      clockedInTime,
      fetching,
      localElapsedDurationMin,
      clockIn,
    } = useTimeClock()

    if (!isClockedIn) {
      return (
        <Card>
          <div className="flex w-full flex-row items-center">
            <div className="flex flex-col">
              <h2 className="m-0 text-base font-bold text-bz-gray-1000">
                Please clock in!
              </h2>
              <span className="text-xs text-bz-gray-1000">
                Your time is not being recorded.
              </span>
            </div>

            <Button
              className="ml-auto"
              type="primary"
              onClick={() => {
                clockIn()
                refetch()
              }}
            >
              Clock In
            </Button>
          </div>
        </Card>
      )
    }

    return (
      <Card>
        <div className="flex flex-row items-center gap-4">
          <ClockedInTimeDisplay clockedInTime={clockedInTime} />
          <ClockInElapsedDisplay
            currentAssignment={currentAssignment}
            localElapsedDurationMin={localElapsedDurationMin}
          />

          <ClockControlsRow
            currentAssignment={currentAssignment}
            refetch={refetch}
            fetching={fetching}
            hideClockInOutButton={hideClockInOutButton}
          />
        </div>
      </Card>
    )
  },
)

type ClockControlsRowProps = {
  currentAssignment?: UserTimeClockCurrentAssignment
  refetch: () => void
  fetching: boolean
  hideClockInOutButton?: boolean
}

export const ClockControlsRow = React.memo<Styled<ClockControlsRowProps>>(
  ({
    currentAssignment,
    refetch,
    fetching,
    hideClockInOutButton,
    className,
  }) => {
    const isMobile = useIsMobile()
    const updateTimeClockStatus =
      trpc.timesheets['timesheets:update-time-clock-status'].useMutation()

    const updatePauseResumeStatus =
      trpc.assignments['assignments:update-pause-resume-status'].useMutation()
    return (
      <div className={`ml-auto flex flex-row gap-3 ${className}`}>
        {!isNullish(currentAssignment) && (
          <Button
            className="w-full border-bz-gray-900"
            shape={isMobile ? 'circle' : 'default'}
            icon={
              <FontAwesomeIcon
                icon={
                  ['DRIVE-TIME', 'ON-SITE'].includes(currentAssignment.activity)
                    ? faPause
                    : faPlay
                }
                color={tailwindConfig.theme.extend.colors['bz-gray'][900]}
                size="lg"
              />
            }
            onClick={() => {
              const {
                jobGuid,
                jobAppointmentGuid,
                jobAppointmentAssignmentGuid,
              } = currentAssignment
              updatePauseResumeStatus.mutate({
                jobGuid,
                jobAppointmentGuid,
                jobAppointmentAssignmentGuid,
              })
              refetch()
            }}
            disabled={updateTimeClockStatus.isLoading || fetching}
          >
            {!isMobile && (
              <span className="text-sm font-semibold text-bz-gray-900">
                {['DRIVE-TIME', 'ON-SITE'].includes(currentAssignment.activity)
                  ? 'Pause'
                  : 'Resume'}
              </span>
            )}
          </Button>
        )}

        {isNullish(hideClockInOutButton) || !hideClockInOutButton ? (
          <Button
            className="w-full border-bz-red-600"
            shape={isMobile ? 'circle' : 'default'}
            icon={
              <FontAwesomeIcon
                icon={faStop}
                color={tailwindConfig.theme.extend.colors['bz-red'][600]}
                size="lg"
              />
            }
            onClick={() => {
              updateTimeClockStatus.mutate({})
              refetch()
            }}
            disabled={updateTimeClockStatus.isLoading || fetching}
          >
            {!isMobile && (
              <span className="text-sm font-semibold text-bz-red-600">
                Clock Out
              </span>
            )}
          </Button>
        ) : null}
      </div>
    )
  },
)

type ClockedInTimeDisplayProps = {
  clockedInTime?: Date
}

export const ClockedInTimeDisplay = React.memo<ClockedInTimeDisplayProps>(
  ({ clockedInTime }) => {
    return (
      <div className="flex flex-1 flex-col">
        <span className="text-base font-bold text-bz-gray-1000">
          {isNullish(clockedInTime)
            ? 'Unknown'
            : BzDateFns.format(clockedInTime, 'h:mm a')}
        </span>
        <span className="text-xs text-bz-gray-1000">clocked in</span>
      </div>
    )
  },
)

type ClockInElapsedDisplayProps = {
  currentAssignment?: UserTimeClockCurrentAssignment
  localElapsedDurationMin: number
}

export const ClockInElapsedDisplay = React.memo<ClockInElapsedDisplayProps>(
  ({ currentAssignment, localElapsedDurationMin }) => {
    const elapsedHours = Math.floor(localElapsedDurationMin / 60)
    const elapsedMinutes = localElapsedDurationMin % 60
    return (
      <div className="flex flex-1 flex-col">
        <span
          className="text-base font-bold"
          style={{
            color:
              isNullish(currentAssignment) ||
              ['IDLE', 'DRIVE-TIME', 'ON-SITE'].includes(
                currentAssignment.activity,
              )
                ? tailwindConfig.theme.extend.colors['bz-green'][800]
                : tailwindConfig.theme.extend.colors['bz-orange'][700],
          }}
        >
          {elapsedHours ? `${elapsedHours} h ` : ''}
          {elapsedMinutes} min
        </span>
        <span className="text-xs text-bz-gray-1000">
          {isNullish(currentAssignment) ||
          ['IDLE', 'DRIVE-TIME', 'ON-SITE'].includes(currentAssignment.activity)
            ? 'elapsed'
            : 'paused'}
        </span>
      </div>
    )
  },
)

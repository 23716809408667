import { PageHeader } from '@ant-design/pro-components'
import { WorkingHours } from '@breezy/shared'
import { faClock } from '@fortawesome/pro-light-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, InputNumber } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { Page } from '../Page/Page'
import TrpcQueryLoader from '../TrpcQueryLoader'

const workingHoursFormSchema = z.object({
  startHour: z.number().min(0).max(24),
  endHour: z.number().min(0).max(24),
})

type WorkingHoursFormSchema = z.infer<typeof workingHoursFormSchema>

interface WorkingHoursEditViewProps {
  startHour: number
  endHour: number
  onUpdated: () => void
}

const WorkingHoursEditView = (props: WorkingHoursEditViewProps) => {
  const company = useExpectedCompany()
  const message = useMessage()

  const setWorkingHoursMut =
    trpc.scheduling['scheduling:set-company-working-hours'].useMutation()

  const form = useForm<WorkingHoursFormSchema>({
    resolver: zodResolver(workingHoursFormSchema),
    defaultValues: {
      startHour: props.startHour,
      endHour: props.endHour,
    },
  })

  const onWorkingHoursSave = form.handleSubmit(data => {
    if (data.startHour >= data.endHour) {
      form.setError(
        'startHour',
        { message: 'Start Hour cannot be greater or equal to the End Hour' },
        { shouldFocus: true },
      )

      return
    }

    setWorkingHoursMut.mutate(
      { startHour: data.startHour, endHour: data.endHour },
      {
        onSuccess: () => {
          form.reset({ startHour: data.startHour, endHour: data.endHour })
          props.onUpdated()
          message.success('Working hours updated')
        },
      },
    )
  })

  return (
    <div className="flex flex-col p-4">
      <p>Timezone: {company?.timezone}</p>

      <ThinDivider />

      <form className="flex flex-col gap-3" onSubmit={onWorkingHoursSave}>
        <Controller
          control={form.control}
          name="startHour"
          render={({ field }) => (
            <label className="flex flex-row items-center gap-2">
              <span className="font-bold">
                Business Start Hour (24 Hour){' '}
                <span className="text-bz-red-500">*</span>
              </span>

              <InputNumber
                {...field}
                min={0}
                max={23}
                status={
                  form.formState.errors.startHour?.message ? 'error' : undefined
                }
              />

              {form.formState.errors.startHour?.message && (
                <span className="text-bz-red-500">
                  {form.formState.errors.startHour?.message}
                </span>
              )}
            </label>
          )}
        />

        <Controller
          control={form.control}
          name="endHour"
          render={({ field }) => (
            <label className="flex flex-row items-center gap-2">
              <span className="font-bold">
                Business End Hour (24 Hour){' '}
                <span className="text-bz-red-500">*</span>
              </span>

              <InputNumber
                {...field}
                min={0}
                max={23}
                status={
                  form.formState.errors.endHour?.message ? 'error' : undefined
                }
              />

              {form.formState.errors.endHour?.message && (
                <span className="text-bz-red-500">
                  {form.formState.errors.endHour?.message}
                </span>
              )}
            </label>
          )}
        />

        <Button
          type="primary"
          htmlType="submit"
          className="w-max"
          disabled={!form.formState.isValid || !form.formState.isDirty}
        >
          Save
        </Button>
      </form>
    </div>
  )
}

const WorkingHoursEditViewPage = () => {
  const query =
    trpc.scheduling['scheduling:get-company-working-hours'].useQuery()

  return (
    <Page requiresCompanyUser={true}>
      <PageHeader
        title={<PageTitle title="Company Working Hours" icon={faClock} />}
      />

      <TrpcQueryLoader
        query={query}
        render={(workingHours: WorkingHours) => (
          <WorkingHoursEditView
            {...workingHours}
            onUpdated={() => query.refetch()}
          />
        )}
      />
    </Page>
  )
}

export default WorkingHoursEditViewPage

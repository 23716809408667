import {
  MaintenancePlanCollapsibleViewModel,
  MaintenancePlanStatus,
  formatUsc,
  getAllInstalledEquipmentGuidsFromVisits,
  getNumUnusedMaintenancePlanVisits,
} from '@breezy/shared'
import React, { useMemo } from 'react'
import ThinDivider from '../../../elements/ThinDivider'
import useAppNavigation from '../../../hooks/useAppNav'
import { useCompanyMaintenancePlansEnabled } from '../../../hooks/useCompanyMaintenancePlansEnabled'
import MaintenancePlanStatusTag from '../../../pages/MaintenancePlanDetailsPage/MaintenancePlanStatusTag'
import tailwindConfig from '../../../tailwind.config'
import { MaintenancePlanIcon } from '../../../utils/feature-icons'
import { m } from '../../../utils/react-utils'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

type MaintenancePlansCollapsibleContentProps = {
  plans: MaintenancePlanCollapsibleViewModel[]
  activeMaintenancePlanGuid?: string
  hideCanceledPlans?: boolean
}

export const MaintenancePlansCollapsibleContent =
  React.memo<MaintenancePlansCollapsibleContentProps>(
    ({ plans, activeMaintenancePlanGuid, hideCanceledPlans = false }) => {
      const appNav = useAppNavigation()

      const sortedPlans = useMemo(() => {
        const filteredPlans = hideCanceledPlans
          ? plans.filter(p => p.status !== MaintenancePlanStatus.CANCELED)
          : plans

        return filteredPlans.sort((a, b) => {
          if (a.maintenancePlanGuid === activeMaintenancePlanGuid) return -1
          if (b.maintenancePlanGuid === activeMaintenancePlanGuid) return 1
          return 0
        })
      }, [plans, hideCanceledPlans, activeMaintenancePlanGuid])
      return (
        <>
          {sortedPlans.map((p, index) => (
            <React.Fragment key={p.maintenancePlanGuid}>
              <CollapsibleItem
                title={p.locationAddress.line1}
                tag={
                  <MaintenancePlanStatusTag
                    status={p.status}
                    paymentFlow={p.paymentFlow}
                  />
                }
                onTitleClick={() =>
                  appNav.navigateToMaintenancePlanDetailsPage(
                    p.maintenancePlanGuid,
                  )
                }
                contentList={[
                  { key: 'Type', value: p.planTypeName },
                  { key: 'Yearly Revenue', value: formatUsc(p.yearlyPriceUsc) },
                  {
                    key: 'Num Covered Equipment',
                    value: getAllInstalledEquipmentGuidsFromVisits(
                      p.visits,
                    ).length.toString(),
                  },
                  {
                    key: 'Num Unused Visits',
                    value: getNumUnusedMaintenancePlanVisits(
                      p.visits,
                    ).toString(),
                  },
                ]}
                alwaysOpen
              />
              {sortedPlans.length > 1 &&
                index === 0 &&
                p.maintenancePlanGuid === activeMaintenancePlanGuid && (
                  <ThinDivider
                    widthPx={1}
                    borderColorClassName={
                      tailwindConfig.theme.extend.colors.bz.border
                    }
                    styleOverrides={{ marginBottom: 4 }}
                  />
                )}
            </React.Fragment>
          ))}
        </>
      )
    },
  )

type MaintenancePlansCollapsibleProps =
  MaintenancePlansCollapsibleContentProps & {
    createNew?: () => void
    titleOverride?: string
    technicianAppStyle?: boolean
  }

const MaintenancePlansCollapsible = m<MaintenancePlansCollapsibleProps>(
  ({
    plans,
    createNew,
    titleOverride,
    technicianAppStyle,
    activeMaintenancePlanGuid,
    hideCanceledPlans = false,
  }) => {
    const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()

    if (!isCompanyMaintenancePlansEnabled) {
      return null
    }

    return (
      <BzCollapsible
        title={titleOverride ?? 'Maintenance Plans'}
        titleIcon={MaintenancePlanIcon}
        technicianAppStyle={technicianAppStyle}
        onPlus={createNew}
        count={plans.length}
      >
        <MaintenancePlansCollapsibleContent
          plans={plans}
          activeMaintenancePlanGuid={activeMaintenancePlanGuid}
          hideCanceledPlans={hideCanceledPlans}
        />
      </BzCollapsible>
    )
  },
)

export default MaintenancePlansCollapsible

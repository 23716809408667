import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import cn from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { dayBreakBlue1, dayBreakBlue6, gray8 } from '../../themes/theme'

export type FaIconButtonsProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  icon: IconProp
  tooltip?: string
  size?: number
  className?: string
  style?: React.CSSProperties
  isLoading?: boolean
  disabled?: boolean
}

const FaIconButton = ({
  style = {},
  className,
  tooltip,
  onClick,
  icon,
  size = 16,
  isLoading = false,
  disabled = false,
}: FaIconButtonsProps) => {
  const [hovered, setHovered] = useState(false)
  const hoverStyle = useMemo(() => {
    return hovered && !disabled
      ? { backgroundColor: dayBreakBlue1, borderRadius: '50%' }
      : {}
  }, [hovered, disabled])

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!disabled && !isLoading && onClick) {
        onClick(event)
      }
    },
    [disabled, isLoading, onClick],
  )

  const getColor = useCallback(() => {
    if (disabled) return gray8
    if (style.color) return style.color
    return hovered ? dayBreakBlue6 : gray8
  }, [disabled, style.color, hovered])

  return (
    <Tooltip title={tooltip} mouseEnterDelay={tooltip ? 0.1 : 999999}>
      <div
        onClick={handleClick}
        onMouseEnter={() => !disabled && setHovered(true)}
        onMouseLeave={() => !disabled && setHovered(false)}
        style={{
          ...hoverStyle,
          ...style,
          width: size + 16,
          height: size + 16,
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.5 : 1,
        }}
        className={cn(className, 'center-children-vh')}
      >
        {isLoading ? (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            className="animate-spin"
            style={{
              color: getColor(),
              fontSize: size,
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={icon}
            style={{
              color: getColor(),
              fontSize: size,
            }}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default FaIconButton

import {
  BzDateFns,
  LinkedNoteViewModel,
  NoteLinkData,
  User,
} from '@breezy/shared'
import {
  faEdit,
  faEllipsis,
  faTrash,
  faUser,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import {
  ActionsModalAction,
  ActionsModalContent,
} from '../../../adam-components/OnsiteModal/ActionsModalContent'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../../adam-components/OnsiteModal/useCloseConfirmModal'
import { HtmlRenderer } from '../../../elements/HtmlRenderer/HtmlRenderer'
import { trpc } from '../../../hooks/trpc'
import {
  useExpectedCompanyTimeZoneId,
  useExpectedPrincipal,
} from '../../../providers/PrincipalUser'
import { Styled } from '../../../utils/Stylable'
import { useMessage } from '../../../utils/antd-utils'
import { useModalState } from '../../../utils/react-utils'
import { LoadingSpinner } from '../../LoadingSpinner'
import { NoteEditModal } from './NoteEditModal'
import './NoteRowItem.less'

type NoteRowItemProps = Styled<{
  note: LinkedNoteViewModel
  linkData: NoteLinkData
  userMap: Record<string, User>
  onMutate: () => void
  highlighted?: boolean
  editable?: boolean
}>

export const NoteRowItem = React.memo<NoteRowItemProps>(
  ({
    note,
    userMap,
    className,
    highlighted,
    onMutate,
    linkData,
    editable = true,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const message = useMessage()
    const [isMutating, setIsMutating] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const avatarString = note.avatarAltShortString || note.userDisplayName[0]

    const userGuid = useExpectedPrincipal().userGuid
    const isMyNote = useMemo(
      () => userGuid === note.userGuid,
      [userGuid, note.userGuid],
    )

    const taggedUsers = useMemo(() => {
      const taggedUsers: { id: string; displayName: string }[] = []
      for (const taggedUserGuid of note.taggedUsers) {
        const userInfo = userMap[taggedUserGuid]
        if (userInfo) {
          taggedUsers.push({
            id: taggedUserGuid,
            displayName: `${userInfo.firstName} ${userInfo.lastName.charAt(
              0,
            )}.`,
          })
        }
      }
      return taggedUsers
    }, [note.taggedUsers, userMap])

    const deleteNoteMut = trpc.notes['notes:delete'].useMutation()
    const deleteNote = useCallback(async () => {
      setIsMutating(true)
      try {
        await deleteNoteMut.mutateAsync({ id: note.noteGuid })
        setIsDeleted(true)
        setIsMutating(false)
        message.success('Note successfully deleted')
        onMutate?.()
      } catch (e) {
        setIsMutating(false)
        message.error(`Failed to delete note`)
      }
    }, [deleteNoteMut, onMutate, note.noteGuid, message])

    const canDelete = useMemo(() => editable, [editable])
    const canEdit = useMemo(() => editable && isMyNote, [editable, isMyNote])

    const deactivatedAt = useMemo(() => {
      const user = userMap[note.userGuid]
      return user?.deactivatedAt
    }, [note.userGuid, userMap])

    const createdAt = useMemo(
      () =>
        BzDateFns.formatFromISO(note.createdAt, 'MMM. d, yyyy @ h:mm a', tzId),
      [note.createdAt, tzId],
    )

    const [actionsOpen, openActions, closeActions] = useModalState()
    const [editModeOpen, openEditMode, closeEditMode] = useModalState()

    const [withConfirmation, closeConfirmProps] = useCloseConfirmModal({
      noUnsavedChangesWarning: true,
    })

    const deleteNoteWithConfirm = useCallback(() => {
      withConfirmation(deleteNote)
    }, [deleteNote, withConfirmation])

    if (isDeleted) {
      return null
    }

    if (isMutating) {
      return (
        <div className="min-h-[80px]">
          <LoadingSpinner />
        </div>
      )
    }

    let renderedAvatar = (
      <div
        className={classNames(
          'mr-3 flex h-11 w-11 items-center justify-center rounded-full bg-bz-fill-tertiary font-semibold',
          deactivatedAt ? 'text-bz-text-tertiary' : 'text-bz-text-secondary',
        )}
      >
        {avatarString}
      </div>
    )

    let renderedName = (
      <div
        className={classNames('font-semibold', {
          'text-bz-text-tertiary': deactivatedAt,
        })}
      >
        {note.firstName} {note.lastName.charAt(0)}.
      </div>
    )

    if (deactivatedAt) {
      const msg = `User deactivated on ${BzDateFns.formatFromISO(
        deactivatedAt,
        'MMMM d, yyyy',
        tzId,
      )}`
      renderedAvatar = (
        <Popover content={<div>{msg}</div>}>{renderedAvatar}</Popover>
      )
      renderedName = (
        <Popover content={<div>{msg}</div>}>{renderedName}</Popover>
      )
    }

    return (
      <>
        <div
          className={classNames(
            'mt-4 flex flex-row items-start text-base',
            {
              'note-row-item-flashing': highlighted,
            },
            className,
          )}
        >
          {renderedAvatar}
          <div className="min-w-0 flex-1 border-0 border-b border-solid border-bz-border-secondary pb-3">
            <div className="flex flex-row items-center *:truncate *:whitespace-nowrap">
              {renderedName}
              <div className="mx-1.5 min-w-0 flex-1 text-sm text-bz-text-secondary">
                {createdAt}
              </div>
              {(canEdit || canDelete) && (
                <Button
                  onClick={openActions}
                  type="text"
                  size="small"
                  icon={<FontAwesomeIcon icon={faEllipsis} />}
                />
              )}
            </div>
            <div>
              <HtmlRenderer htmlContent={note.value} />
            </div>
            {note.taggedUsers.length > 0 && (
              <div className="mt-1 flex flex-wrap items-center gap-2">
                {taggedUsers.map(({ id, displayName }) => (
                  <div
                    key={id}
                    className="flex flex-row items-center whitespace-nowrap rounded border border-solid border-bz-border px-2 py-1"
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      className="mr-1 h-4 w-4 text-bz-text-secondary"
                    />
                    <div className="whitespace-nowrap text-xs">
                      {displayName}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <OnsiteBasicModal
          headerBordered
          onClose={closeActions}
          open={actionsOpen}
          header="Note actions"
          size="small"
        >
          <ActionsModalContent>
            {canEdit && (
              <ActionsModalAction
                onClick={() => {
                  closeActions()
                  openEditMode()
                }}
                icon={<FontAwesomeIcon icon={faEdit} />}
              >
                Edit
              </ActionsModalAction>
            )}

            {canDelete && (
              <ActionsModalAction
                danger
                onClick={() => {
                  closeActions()
                  deleteNoteWithConfirm()
                }}
                icon={<FontAwesomeIcon icon={faTrash} />}
              >
                Delete
              </ActionsModalAction>
            )}
          </ActionsModalContent>
        </OnsiteBasicModal>
        {editModeOpen && (
          <NoteEditModal
            linkData={linkData}
            note={note}
            header="Edit note"
            onMutate={onMutate}
            onCancel={closeEditMode}
          />
        )}
        <CloseConfirmModal
          {...closeConfirmProps}
          header="Delete note?"
          confirmText="Yes, Delete"
        >
          Are you sure you want to delete{' '}
          <strong>{note.firstName}'s note</strong>? This action cannot be
          undone.
        </CloseConfirmModal>
      </>
    )
  },
)

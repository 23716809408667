import { PageHeader } from '@ant-design/pro-components'
import {
  isNullish,
  notificationSettingsSchema,
  R,
  REMINDER_FREQUENCIES,
  REMINDER_FREQUENCY_METADATA,
  toPlural,
} from '@breezy/shared'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form, message, Select } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'urql'
import { z } from 'zod'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import {
  CategorySection,
  CheckField,
  SubSection,
} from '../../components/Settings/SettingsBuilderComponents'
import { Card } from '../../elements/Card/Card'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { TextField } from '../../elements/Forms/TextField'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { UPSERT_COMPANY_NOTIFICATIONS_SETTINGS_MUTATION } from '../../gql/CompanyConfig.gql'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { NOTIFICATION_SETTINGS_QUERY } from './SettingsNotificationPage.gql'

type NotificationSettings = z.infer<typeof notificationSettingsSchema>

export const SettingsNotificationsPage = React.memo(() => {
  const { companyGuid, merchantId } = useExpectedCompany()

  const invoicePaymentRemindersEnabled = !isNullish(merchantId)

  const [{ data, fetching }] = useQuery({
    query: NOTIFICATION_SETTINGS_QUERY,
    variables: {
      companyGuid,
    },
  })

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    reset,
    watch,
  } = useForm<NotificationSettings>({
    resolver: zodResolver(notificationSettingsSchema),
    // Doing `.parse({})` on our schema will give it to us with all the default values filled in.
    defaultValues: notificationSettingsSchema.parse({}),
  })

  const [{ fetching: submitting }, executeMutation] = useMutation(
    UPSERT_COMPANY_NOTIFICATIONS_SETTINGS_MUTATION,
  )

  const onSubmit = handleSubmit(async data => {
    try {
      await executeMutation({
        settings: {
          companyGuid,
          ...data,
        },
      })
      message.success(`Successfully Saved Your Changes`)
    } catch (e) {
      console.error(`Failed to save company notification settings`, e)
      message.error(
        `Failed to save your changes. Please try again in a few minutes`,
      )
    }
  })

  useEffect(() => {
    if (data?.companyNotificationSettingsByPk) {
      reset(data.companyNotificationSettingsByPk)
    }
  }, [data, reset])

  const estimateReminderFrequency = watch('estimateReminderFrequency')
  const invoicePaymentReminderFrequency = watch(
    'invoicePaymentReminderFrequency',
  )

  const RemindersForm = useCallback(
    ({
      frequencyKey,
      countKey,
      doNotSend,
    }: {
      frequencyKey: keyof NotificationSettings
      countKey: keyof NotificationSettings
      doNotSend?: boolean
    }) => {
      return (
        <div className="flex flex-row space-x-4 *:min-w-0 *:flex-1">
          <ReactHookFormItem
            errors={errors}
            name={frequencyKey}
            control={control}
            label="Send Reminder"
            noBottomMargin
            render={({ field }) => (
              <Select
                value={field.value}
                disabled={submitting}
                popupMatchSelectWidth={false}
                options={REMINDER_FREQUENCIES.map(key => ({
                  value: key,
                  label: REMINDER_FREQUENCY_METADATA[key].label,
                }))}
                onChange={value => field.onChange(value)}
              />
            )}
          />
          <ReactHookFormItem
            errors={errors}
            name={countKey}
            control={control}
            label="Follow-up"
            noBottomMargin
            render={({ field }) => (
              <Select
                value={field.value}
                disabled={submitting || doNotSend}
                popupMatchSelectWidth={false}
                options={R.range(1, 4).map(value => ({
                  value,
                  label: `${value} ${toPlural(value, 'time')}`,
                }))}
                onChange={value => field.onChange(value)}
              />
            )}
          />
        </div>
      )
    },
    [control, errors, submitting],
  )

  return (
    <div className="flex w-full min-w-[700px] flex-col">
      <PageHeader title={<PageTitle title="Notifications" icon={faBell} />} />
      {fetching ? (
        <LoadingSpinner />
      ) : (
        <Card className="min-h-0 w-full overflow-auto p-2">
          <Form onSubmitCapture={onSubmit} layout="vertical">
            <div className="flex flex-row items-start justify-between border-0 border-b border-solid border-b-bz-gray-400 pb-4">
              <div className="max-w-[512px] text-bz-gray-900">
                Make sure that the right people are being notified when
                something important happens.
              </div>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                disabled={!isDirty}
              >
                Save Changes
              </Button>
            </div>
            <div className="divide-y divide-solid divide-bz-gray-400">
              <CategorySection
                title="Reply To"
                subtitle="When your customer replies to an automated email, where should it go?"
              >
                <SubSection
                  title="Email"
                  subtitle="When a customer replies to an automated email, it will get sent to this email address."
                >
                  <ReactHookFormItem
                    errors={errors}
                    name="replyToEmail"
                    control={control}
                    noBottomMargin
                    render={({ field }) => (
                      <TextField
                        // https://1password.community/discussion/comment/625320/#Comment_625320
                        id="search-hide-password-manager"
                        autoComplete="off"
                        size="middle"
                        placeholder="Email address"
                        className="min-w-[250px]"
                        {...field}
                      />
                    )}
                  />
                </SubSection>
              </CategorySection>
              <CategorySection
                title="Estimates"
                subtitle="Notification settings for notifying users or customers about estimates."
              >
                <SubSection
                  title="Accepted Estimates"
                  subtitle="Configure who will be notified when a customer accepts an estimate. Users will be sent an in-app notification and an email."
                >
                  <CheckField
                    defaultValue={false}
                    errors={errors}
                    name="estimateAcceptNotifyCreator"
                    control={control}
                    disabled={submitting}
                    label="Notify the estimate creator"
                  />

                  <CheckField
                    defaultValue={[]}
                    errors={errors}
                    name="estimateAcceptNotifyUserGuids"
                    control={control}
                    disabled={submitting}
                    label="Notify specific users"
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        disabled={submitting}
                        className="min-w-[250px]"
                        mode="multiple"
                        popupMatchSelectWidth={false}
                        placeholder="Select users"
                        options={data?.companyUsers.map(
                          ({ userGuid, userByUserGuid: { fullName } }) => ({
                            value: userGuid,
                            label: fullName,
                          }),
                        )}
                        onChange={value => field.onChange(value)}
                      />
                    )}
                  />

                  <CheckField
                    errors={errors}
                    name="estimateAcceptNotifyEmail"
                    control={control}
                    label="Send a copy to a specific email address"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        // https://1password.community/discussion/comment/625320/#Comment_625320
                        id="search-hide-password-manager"
                        autoComplete="off"
                        size="middle"
                        placeholder="Email address"
                        className="min-w-[250px]"
                        {...field}
                      />
                    )}
                  />
                </SubSection>
                <SubSection
                  title="Viewed Estimates"
                  subtitle="Configure who will be notified when a customer views an estimate that has been sent to them. Users will be sent an in-app notification and an email."
                >
                  <CheckField
                    defaultValue={false}
                    errors={errors}
                    name="estimateReviewedNotifyCreator"
                    control={control}
                    disabled={submitting}
                    label="Notify the estimate creator"
                  />

                  <CheckField
                    defaultValue={[]}
                    errors={errors}
                    name="estimateReviewedNotifyUserGuids"
                    control={control}
                    disabled={submitting}
                    label="Notify specific users"
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        disabled={submitting}
                        className="min-w-[250px]"
                        mode="multiple"
                        popupMatchSelectWidth={false}
                        placeholder="Select users"
                        options={data?.companyUsers.map(
                          ({ userGuid, userByUserGuid: { fullName } }) => ({
                            value: userGuid,
                            label: fullName,
                          }),
                        )}
                        onChange={value => field.onChange(value)}
                      />
                    )}
                  />
                </SubSection>

                <SubSection
                  title="Estimate Reminder"
                  subtitle="Configure when the customer will be reminded about an outstanding estimate that's been sent to them."
                >
                  <RemindersForm
                    frequencyKey="estimateReminderFrequency"
                    countKey="estimateReminderCount"
                    doNotSend={estimateReminderFrequency === 'DO_NOT_SEND'}
                  />
                </SubSection>
              </CategorySection>
              {/* TODO: right now the only invoice notification setting is about payment reminders. If that flag is off,
                  we want to hide the whole section. If we add new sections, we should remove this overall switch. */}
              {invoicePaymentRemindersEnabled && (
                <CategorySection
                  title="Invoices"
                  subtitle="Notification settings for notifying users or customers about invoices."
                >
                  {invoicePaymentRemindersEnabled && (
                    <SubSection
                      title="Payment Reminder"
                      subtitle="Configure when the customer will be reminded about an outstanding invoice that's been sent to them."
                    >
                      <RemindersForm
                        frequencyKey="invoicePaymentReminderFrequency"
                        countKey="invoicePaymentReminderCount"
                        doNotSend={
                          invoicePaymentReminderFrequency === 'DO_NOT_SEND'
                        }
                      />
                    </SubSection>
                  )}
                </CategorySection>
              )}
              <CategorySection
                title="Failed Payments"
                subtitle="Notification settings for notifying users about failed payments."
              >
                <SubSection
                  title="Failed Payments"
                  subtitle="Configure who will be notified when a payment fails."
                >
                  <CheckField
                    defaultValue={false}
                    errors={errors}
                    name="paymentFailedNotifyCreator"
                    control={control}
                    disabled={submitting}
                    label="Notify the invoice creator"
                  />
                  <CheckField
                    defaultValue={[]}
                    errors={errors}
                    name="paymentFailedNotifyUserGuids"
                    control={control}
                    disabled={submitting}
                    label="Notify specific users"
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        disabled={submitting}
                        className="min-w-[250px]"
                        mode="multiple"
                        popupMatchSelectWidth={false}
                        placeholder="Select users"
                        options={data?.companyUsers.map(
                          ({ userGuid, userByUserGuid: { fullName } }) => ({
                            value: userGuid,
                            label: fullName,
                          }),
                        )}
                        onChange={value => field.onChange(value)}
                      />
                    )}
                  />
                  <CheckField
                    errors={errors}
                    name="paymentFailedNotifyEmail"
                    control={control}
                    label="Send a copy to a specific email address"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        // https://1password.community/discussion/comment/625320/#Comment_625320
                        id="search-hide-password-manager"
                        autoComplete="off"
                        size="middle"
                        placeholder="Email address"
                        className="min-w-[250px]"
                        {...field}
                      />
                    )}
                  />
                </SubSection>
              </CategorySection>

              <BehindFeatureFlag
                enabledFeatureFlag="job-leads"
                render={
                  <CategorySection
                    title="Job Requests"
                    subtitle="Notification settings for Job Requests."
                  >
                    <SubSection
                      title="Job Request Created"
                      subtitle="Configure who will be notified when a new Job Request is created"
                    >
                      <CheckField
                        defaultValue={[]}
                        errors={errors}
                        name="jobLeadCreatedUserGuids"
                        control={control}
                        disabled={submitting}
                        label="Send notification to specific users"
                        render={({ field }) => (
                          <Select
                            value={field.value}
                            disabled={submitting}
                            className="min-w-[250px]"
                            mode="multiple"
                            popupMatchSelectWidth={false}
                            placeholder="Select users"
                            options={data?.companyUsers.map(
                              ({ userGuid, userByUserGuid: { fullName } }) => ({
                                value: userGuid,
                                label: fullName,
                              }),
                            )}
                            onChange={value => field.onChange(value)}
                          />
                        )}
                      />
                      <CheckField
                        errors={errors}
                        name="jobLeadCreatedEmail"
                        control={control}
                        label="Notify a specific email address"
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            // https://1password.community/discussion/comment/625320/#Comment_625320
                            id="search-hide-password-manager"
                            autoComplete="off"
                            size="middle"
                            placeholder="Email address"
                            className="min-w-[250px]"
                            {...field}
                          />
                        )}
                      />
                    </SubSection>
                  </CategorySection>
                }
              />

              <CategorySection
                title="Nightly Reports"
                subtitle="Notification settings for sending users nightly reports."
              >
                <SubSection
                  title="Material Restock Report"
                  subtitle="Configure who will be notified when materials are used on jobs."
                >
                  <CheckField
                    defaultValue={[]}
                    errors={errors}
                    name="materialRestockReportUserGuids"
                    control={control}
                    disabled={submitting}
                    label="Send report to specific users"
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        disabled={submitting}
                        className="min-w-[250px]"
                        mode="multiple"
                        popupMatchSelectWidth={false}
                        placeholder="Select users"
                        options={data?.companyUsers.map(
                          ({ userGuid, userByUserGuid: { fullName } }) => ({
                            value: userGuid,
                            label: fullName,
                          }),
                        )}
                        onChange={value => field.onChange(value)}
                      />
                    )}
                  />
                  <CheckField
                    errors={errors}
                    name="materialRestockReportEmail"
                    control={control}
                    label="Send a copy to a specific email address"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        // https://1password.community/discussion/comment/625320/#Comment_625320
                        id="search-hide-password-manager"
                        autoComplete="off"
                        size="middle"
                        placeholder="Email address"
                        className="min-w-[250px]"
                        {...field}
                      />
                    )}
                  />
                </SubSection>
              </CategorySection>
            </div>
          </Form>
        </Card>
      )}
    </div>
  )
})

import {
  DateTimeFormatter,
  ENGLISH_LOCALE,
  InstalledEquipment,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  ProbabilityOfEquipmentReplacement,
  formatEquipmentTypeString,
  isNullish,
} from '@breezy/shared'
import { faAirConditioner } from '@fortawesome/pro-regular-svg-icons'
import { Divider } from 'antd'
import { useState } from 'react'
import { EmDash } from '../../../elements/EmDash/EmDash'
import { HtmlRenderer } from '../../../elements/HtmlRenderer/HtmlRenderer'
import { MaybeDataPoint } from '../../../elements/MaybeDataPoint/MaybeDataPoint'
import { DateFormat } from '../../Dates'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

export const InstalledHvacSystemsCollapsible = ({
  installedHvacSystems,
  onEditInstalledHvacSystem,
  onEditInstalledEquipment,
  onAddInstalledHvacSystem: onAdd,
  editable = true,
}: {
  installedHvacSystems: InstalledHvacSystem[]
  onEditInstalledHvacSystem?: (installedHvacSystem: InstalledHvacSystem) => void
  onEditInstalledEquipment?: (
    installedEquipment: InstalledEquipmentSummary,
  ) => void
  onAddInstalledHvacSystem?: () => void
  editable?: boolean
}) => {
  return (
    <BzCollapsible
      title="HVAC Systems"
      titleIcon={faAirConditioner}
      onPlus={editable && onAdd ? onAdd : undefined}
    >
      {installedHvacSystems.map(
        (installedHvacSystem, installedHvacSystemIndex) => {
          return (
            <InstalledHvacSystemCollapsibleItem
              key={installedHvacSystem.installedHvacSystemGuid}
              installedHvacSystem={installedHvacSystem}
              installedHvacSystemIndex={installedHvacSystemIndex}
              onEditInstalledEquipment={onEditInstalledEquipment}
              onEditInstalledHvacSystem={onEditInstalledHvacSystem}
              editable={editable}
            />
          )
        },
      )}
    </BzCollapsible>
  )
}

export const InstalledHvacSystemCollapsibleItem = ({
  installedHvacSystem,
  installedHvacSystemIndex,
  onEditInstalledEquipment,
  onEditInstalledHvacSystem,
  editable = true,
}: {
  installedHvacSystem: InstalledHvacSystem
  installedHvacSystemIndex: number
  onEditInstalledEquipment?: (equipment: InstalledEquipmentSummary) => void
  onEditInstalledHvacSystem?: (installedHvacSystem: InstalledHvacSystem) => void
  editable?: boolean
}) => {
  return (
    <div key={installedHvacSystem.installedHvacSystemGuid} className="mt-2">
      <CollapsibleItem
        key={installedHvacSystem.installedHvacSystemGuid}
        title={installedHvacSystem.friendlyName}
        defaultOpen={installedHvacSystemIndex < 3}
        contentList={[
          {
            key: 'Equipment',
            value: installedHvacSystem.installedEquipment.map(equipment => {
              return (
                <NestedInstalledEquipmentView
                  equipment={equipment}
                  key={equipment.installedEquipmentGuid}
                  onEditEquipmentClicked={onEditInstalledEquipment}
                  editable={editable}
                />
              )
            }),
          },
          {
            key: 'Zoning:',
            value: (
              <>
                {installedHvacSystem.zoningInfo.zones.length === 0 && (
                  <>No Zoning</>
                )}
                <div>
                  {installedHvacSystem.zoningInfo.zones.map(zone => {
                    return <div>{zone.name}</div>
                  })}
                </div>
              </>
            ),
          },
          {
            key: 'Filter Size:',
            value: (
              <MaybeDataPoint>{installedHvacSystem.filterSize}</MaybeDataPoint>
            ),
          },
          {
            key: 'System Notes:',
            value: installedHvacSystem.notes ? (
              <HtmlRenderer htmlContent={installedHvacSystem.notes} />
            ) : (
              <EmDash />
            ),
          },
        ]}
        onEdit={
          editable && onEditInstalledHvacSystem
            ? () => onEditInstalledHvacSystem(installedHvacSystem)
            : undefined
        }
      />
    </div>
  )
}

const NestedInstalledEquipmentView = ({
  equipment,
  onEditEquipmentClicked,
  editable = true,
}: {
  equipment: InstalledEquipmentSummary
  onEditEquipmentClicked?: (equipment: InstalledEquipmentSummary) => void
  editable?: boolean
}) => {
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [now] = useState(new Date())
  const probabilityOfEquipmentReplace =
    InstalledEquipment.getProbabilityOfEquipmentReplacement(now, equipment)
  const probabilityOfReplacementClassName =
    probabilityOfEquipmentReplace ===
    ProbabilityOfEquipmentReplacement.EXTREMELY_PROBABLE
      ? 'text-green-700 font-semibold'
      : probabilityOfEquipmentReplace ===
        ProbabilityOfEquipmentReplacement.HIGH_PROBABILITY
      ? 'text-orange-700 font-semibold'
      : ''

  const yearsEquipmentAge = InstalledEquipment.yearsEquipmentAge(now, equipment)

  return (
    <div>
      <div className="flex items-center justify-between gap-5 break-words">
        <div className="">
          {InstalledEquipment.calculateFriendlyName(equipment)}
          {!isNullish(yearsEquipmentAge) && (
            <>
              {' ('}
              {yearsEquipmentAge === 1 && (
                <span className={probabilityOfReplacementClassName}>
                  1 year old
                </span>
              )}
              {yearsEquipmentAge !== 1 && (
                <span className={probabilityOfReplacementClassName}>
                  {yearsEquipmentAge} years old
                </span>
              )}
              {')'}
            </>
          )}
        </div>
        <div className="hidden gap-4 sm:flex">
          <div
            className="cursor-pointer text-bz-primary hover:opacity-90"
            onClick={e => {
              setDetailsOpen(!detailsOpen)
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            {!detailsOpen && <>See More</>}
            {detailsOpen && <>See Less</>}
          </div>
          {editable && onEditEquipmentClicked && (
            <div
              className="cursor-pointer text-bz-primary hover:opacity-90"
              onClick={e => {
                onEditEquipmentClicked(equipment)
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              Edit
            </div>
          )}
        </div>
      </div>
      {detailsOpen && (
        <div className="mb-4 mt-2">
          <NestedInstalledEquipmentPopoverView equipment={equipment} />
        </div>
      )}
    </div>
  )
}

const NestedInstalledEquipmentPopoverView = ({
  equipment,
}: {
  equipment: InstalledEquipmentSummary
}) => {
  return (
    <div className="w-full rounded border border-gray-500 bg-white p-5 shadow-md">
      <div className="">
        <div className="semibold_16_24 grey9">Equipment Info</div>
        <Divider className="m-1" />
      </div>
      <div className="flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Equipment Type:</div>
        <div>{formatEquipmentTypeString(equipment.equipmentType)}</div>
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Manufacturer:</div>
        <div>
          <MaybeDataPoint>{equipment.manufacturer}</MaybeDataPoint>
        </div>
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Model Number:</div>
        <div>
          <MaybeDataPoint>{equipment.modelNumber}</MaybeDataPoint>
        </div>
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Serial Number:</div>
        <div>
          <MaybeDataPoint>{equipment.serialNumber}</MaybeDataPoint>
        </div>
      </div>
      <div className="pt-3">
        <div className="semibold_16_24 grey9">Install Info</div>
        <Divider className="m-1" />
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Installation Date:</div>
        <div>
          <MaybeDataPoint>
            {equipment.installationDate?.format(
              DateTimeFormatter.ofPattern(DateFormat['MM/dd/YYYY']).withLocale(
                ENGLISH_LOCALE,
              ),
            )}
          </MaybeDataPoint>
        </div>
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Installation Party:</div>
        <div>
          <MaybeDataPoint>{equipment.installationParty}</MaybeDataPoint>
        </div>
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Manufacturing Date:</div>
        <div>
          <MaybeDataPoint>
            {equipment.manufacturingDate?.format(
              DateTimeFormatter.ofPattern(DateFormat['MM/dd/YYYY']).withLocale(
                ENGLISH_LOCALE,
              ),
            )}
          </MaybeDataPoint>
        </div>
      </div>
      <div className="pt-3">
        <div className="semibold_16_24 grey9">Manufacturer Warranty</div>
        <Divider className="m-1" />
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8 break-words">
          Manufacturer Warranty Terms:
        </div>
        <div className="break-words">
          <MaybeDataPoint>{equipment.manufacturerWarrantyTerms}</MaybeDataPoint>
        </div>
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8 break-words">
          Manufacturer Warranty Start Date:
        </div>
        <div className="break-words">
          <MaybeDataPoint>
            {equipment.manufacturerWarrantyStartDate?.format(
              DateTimeFormatter.ofPattern(DateFormat['MM/dd/YYYY']).withLocale(
                ENGLISH_LOCALE,
              ),
            )}
          </MaybeDataPoint>
        </div>
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Manufacturer End Date:</div>
        <div>
          <MaybeDataPoint>
            {' '}
            {equipment.manufacturerWarrantyEndDate?.format(
              DateTimeFormatter.ofPattern(DateFormat['MM/dd/YYYY']).withLocale(
                ENGLISH_LOCALE,
              ),
            )}
          </MaybeDataPoint>
        </div>
      </div>
      <div className="pt-3">
        <div className="semibold_16_24 grey9">Labor Warranty</div>
        <Divider className="m-1" />
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Labor Warranty Terms:</div>
        <div>
          <MaybeDataPoint>{equipment.laborWarrantyTerms}</MaybeDataPoint>
        </div>
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Equipment Dimensions:</div>
        <div>
          <MaybeDataPoint>{equipment.equipmentDimensions}</MaybeDataPoint>
        </div>
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Labor Warranty Start Date:</div>
        <div>
          <MaybeDataPoint>
            {equipment.laborWarrantyStartDate?.format(
              DateTimeFormatter.ofPattern(DateFormat['MM/dd/YYYY']).withLocale(
                ENGLISH_LOCALE,
              ),
            )}
          </MaybeDataPoint>
        </div>
      </div>
      <div className="mt-1 flex justify-between gap-3">
        <div className="semibold_14_22 grey8">Labor Warranty End Date:</div>
        <div>
          <MaybeDataPoint>
            {equipment.laborWarrantyEndDate?.format(
              DateTimeFormatter.ofPattern(DateFormat['MM/dd/YYYY']).withLocale(
                ENGLISH_LOCALE,
              ),
            )}
          </MaybeDataPoint>
        </div>
      </div>
    </div>
  )
}

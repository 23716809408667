import { PermissionV2 } from '@breezy/shared'
import { DEFAULT_PERMISSION_CONTROLS_STATE } from './state'

export type RankedPermission = {
  permission?: PermissionV2
  rank: number
  label?: string
  childPermissionGroups?: ChildPermissionGroup[]
}

export type DefaultPermissions = {
  defaultPermissions: PermissionV2[]
}
export type SelectedPermissions = {
  selectedPermissions: PermissionV2[]
}
export type RankedPermissions = {
  rankedPermissions: RankedPermission[]
}

export type CheckablePermissions = {
  checkablePermissions: PermissionV2[]
}
export type RankedPermissionGroup = RankedPermissions &
  DefaultPermissions &
  SelectedPermissions

export type CheckablePermissionGroup = CheckablePermissions &
  DefaultPermissions &
  SelectedPermissions

export type PermissionGroup = RankedPermissionGroup | CheckablePermissionGroup

export type ChildPermissionGroup = DefaultPermissions &
  Partial<CheckablePermissions> &
  Partial<RankedPermissions>

export type PermissionControlsState = {
  isSuperAdmin: boolean
  initialPermissions: PermissionV2[]
  selectedPermissions: PermissionV2[]
  CAPABILITIES: {
    customDiscounts: boolean
    aiEstimateDescriptions: boolean
  }
  OFFICE_EXPERIENCE: typeof DEFAULT_PERMISSION_CONTROLS_STATE.OFFICE_EXPERIENCE
  FIELD_EXPERIENCE: typeof DEFAULT_PERMISSION_CONTROLS_STATE.FIELD_EXPERIENCE
}

export type PermissionControlsStateChildrenKeys = {
  [K in Extract<
    keyof PermissionControlsState,
    'OFFICE_EXPERIENCE' | 'FIELD_EXPERIENCE'
  >]: keyof PermissionControlsState[K]['permissionCategories']
}

export type PermissionCategory =
  PermissionControlsStateChildrenKeys[keyof PermissionControlsStateChildrenKeys]

export type PermissionCategoryState =
  | PermissionControlsState['OFFICE_EXPERIENCE']['permissionCategories'][OfficePermissionCategory]
  | PermissionControlsState['FIELD_EXPERIENCE']['permissionCategories'][FieldPermissionCategory]

export type OfficePermissionCategory =
  keyof PermissionControlsState['OFFICE_EXPERIENCE']['permissionCategories']
export type FieldPermissionCategory =
  keyof PermissionControlsState['FIELD_EXPERIENCE']['permissionCategories']

export function isOfficeExperienceCategory(
  category: PermissionCategory,
): category is OfficePermissionCategory {
  return (
    category in
    DEFAULT_PERMISSION_CONTROLS_STATE.OFFICE_EXPERIENCE.permissionCategories
  )
}

export function isFieldExperienceCategory(
  category: PermissionCategory,
): category is FieldPermissionCategory {
  return (
    category in
    DEFAULT_PERMISSION_CONTROLS_STATE.FIELD_EXPERIENCE.permissionCategories
  )
}

export function isRankedPermissionGroup(
  group: PermissionGroup | ChildPermissionGroup,
): group is RankedPermissionGroup {
  return !!(group as RankedPermissionGroup)?.rankedPermissions
}

export function isCheckablePermissionGroup(
  group: PermissionGroup | ChildPermissionGroup,
): group is CheckablePermissionGroup {
  return !!(group as CheckablePermissionGroup)?.checkablePermissions
}

import { QboPayoutAccounts } from '@breezy/shared'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'
import { BzTitleAndExplanation } from '../../elements/BzTitleAndExplanation/BzTitleAndExplanation'
import { useQboAccountTreeData } from '../../hooks/Quickbooks/useQboAccountTreeData'
import { trpc } from '../../hooks/trpc'
import { useQuickbooksOnlineEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { useMessage } from '../../utils/antd-utils'
import { m } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'
import { QuickbooksAccountSelectionFormItem } from './QuickbooksAccountSelectionFormItem'

const QUICKBOOKS_PAYOUTS_ENABLED = true
const UPSERT_SAVE_ERROR_MESSAGE =
  'There was an unexpected error saving the Payout Accounts Config'

type QuickbooksPayoutAccountsConfigProps = {
  cfg?: QboPayoutAccounts
}

export const QuickbooksPayoutAccountsConfig =
  m<QuickbooksPayoutAccountsConfigProps>(({ cfg }) => {
    const message = useMessage()
    const [isAsync, setIsAsync] = useState(false)
    const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()

    const qboAccounts = useQboAccountTreeData()
    const savePayoutAccountsMut =
      trpc.qbo['finance-app:upsert-payout-accounts-config'].useMutation()
    const [form] = useForm<QboPayoutAccounts>()
    const onFinish = async (values: QboPayoutAccounts) => {
      setIsAsync(true)
      savePayoutAccountsMut.mutate(
        {
          ...values,
        },
        {
          onSuccess: () => {
            setIsAsync(false)
          },
          onError: e => {
            console.error(UPSERT_SAVE_ERROR_MESSAGE, e)
            message.error(UPSERT_SAVE_ERROR_MESSAGE)
            setIsAsync(false)
          },
        },
      )
    }

    if (isAsync || !qboAccounts)
      return (
        <div className="min-h-[320px] min-w-[240px]">
          <LoadingSpinner />
        </div>
      )
    if (
      !isQuickbooksOnlineEnabled ||
      !QUICKBOOKS_PAYOUTS_ENABLED ||
      !qboAccounts ||
      qboAccounts.length < 1
    )
      return null

    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={cfg}
        onFinish={onFinish}
      >
        <BzTitleAndExplanation
          title="Payout Accounts"
          description="Configuring these account will allow Breezy to Auto-Sync your bank payouts from your payment processor to Quickbooks. This is recommended to ease your accounting reconciliation process."
          attentionType="info"
        />
        <QuickbooksAccountSelectionFormItem
          required
          formItemName="depositAccountId"
          formItemLabel="Payout Deposit Bank Account"
          financeAccountsTreeData={qboAccounts.filter(
            x => x.accountType === 'Bank',
          )}
          explanationTooltip="This is the account that will be used to record the deposit of your payouts from Breezy. Typically this ought to be your primary income Bank Account."
        />
        <QuickbooksAccountSelectionFormItem
          required
          formItemName="feesExpenseAccountId"
          formItemLabel="Payout Fees Expense Account"
          financeAccountsTreeData={qboAccounts.filter(
            x => x.accountType === 'Expense',
          )}
          explanationTooltip="This is the account that will be used to record the fees charged by payment processors from your payouts. Typically this ought to be an expense account. The fees generally result from Bank charges, Credit Card Processing Charges, and Payment Processor Account Fees."
        />
        <QuickbooksAccountSelectionFormItem
          required
          formItemName="refundsExpenseAccountId"
          formItemLabel="Payout Refunds Income Account"
          financeAccountsTreeData={qboAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="This is the account that will be used to record the refunds from your payouts. Typically this ought to be a contra-income account. The refunds generally result from manual refunds issued to your customers."
        />
        <QuickbooksAccountSelectionFormItem
          required
          formItemName="paymentProcessorBalanceAccountId"
          formItemLabel="Payment Processor Balance Account"
          financeAccountsTreeData={qboAccounts.filter(
            x => x.accountType !== 'Income' && x.accountType !== 'Expense',
          )}
          explanationTooltip="This is the account that will be used to record the balance adjustment from your payouts. Typically this ought to be a trust account. The balance adjustment generally results from the difference between the total of your payouts and the total of your charges and refunds."
        />
        <FormCancelSubmitButtons />
      </Form>
    )
  })

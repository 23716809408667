import { AccountingGeneralLedgerAccount, bzExpect } from '@breezy/shared'
import { useCallback, useEffect, useState } from 'react'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useModal } from '../../utils/antd-utils'
import { trpc } from '../trpc'

export type FinanceAccountTreeData = {
  value: string
  title: React.ReactNode
  accountName: string
  accountType: string
  children?: FinanceAccountTreeData[]
}

const COULD_NOT_FETCH_ERROR_MESSAGE_TITLE =
  'Could not fetch Accounting accounts.'

const AccountTitle = ({
  name,
  accountType,
}: AccountingGeneralLedgerAccount): React.ReactNode => {
  return (
    <div className="row flex-between">
      <div className="text-ellipsis whitespace-nowrap">{name}</div>{' '}
      <div className="grey7 ml-1 truncate pr-2 text-right">
        <i>{accountType}</i>
      </div>
    </div>
  )
}

export const accountingAccountsToTreeData = (
  data: AccountingGeneralLedgerAccount[],
) => {
  const itemMap: Record<string, FinanceAccountTreeData> = {}
  const parentIdToChildrenIds: Record<string, string[]> = {}
  const parentItems: FinanceAccountTreeData[] = []

  for (const { id, name, parentId, accountType } of data) {
    const treeItem = {
      value: id,
      accountName: name,
      accountType,
      title: AccountTitle({ id, name, parentId, accountType }),
    }
    itemMap[id] = treeItem

    if (parentId) {
      if (!parentIdToChildrenIds[parentId]) {
        parentIdToChildrenIds[parentId] = []
      }
      parentIdToChildrenIds[parentId].push(id)
    } else {
      parentItems.push(treeItem)
    }
  }

  const fillChildren = (item: FinanceAccountTreeData) => {
    const childrenIds = parentIdToChildrenIds[item.value]
    if (childrenIds) {
      const children = childrenIds.map(id => {
        const childItem = itemMap[id]
        fillChildren(childItem)
        return childItem
      })
      item.children = children
    }
  }

  parentItems.forEach(fillChildren)

  return parentItems
}

export const useAccountingAccountTreeData = ():
  | FinanceAccountTreeData[]
  | undefined => {
  const Modal = useModal()
  const title = COULD_NOT_FETCH_ERROR_MESSAGE_TITLE
  const companyGuid = useExpectedCompanyGuid()

  const [treeData, setTreeData] = useState<FinanceAccountTreeData[]>()

  const incomeAccountQuery = trpc.accounting[
    'accounting-app:query-accounts'
  ].useQuery(
    { companyGuid },
    {
      enabled: false,
      retry: false,
      onError: () => {},
    },
  )

  const refetch = useCallback(() => {
    return incomeAccountQuery.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeAccountQuery.refetch])

  useEffect(() => {
    if (
      !incomeAccountQuery.isError ||
      (incomeAccountQuery.data?.length ?? 0) === 0
    ) {
      refetch().then(res => {
        setTreeData(
          accountingAccountsToTreeData(
            bzExpect(res.data, 'incomeAccountQuery.data'),
          ),
        )
      })
    } else if (
      incomeAccountQuery.isError &&
      !!incomeAccountQuery.error?.message
    ) {
      Modal.error({ title, content: incomeAccountQuery.error.message })
    }
  }, [
    incomeAccountQuery.data,
    incomeAccountQuery.isError,
    incomeAccountQuery.error?.message,
    refetch,
    title,
    Modal,
  ])

  return treeData
}

import { mapQueryVisitsToVisitViewModels } from '@breezy/backend/src/application-types'
import { MaintenancePlanCollapsibleViewModel } from '@breezy/shared'
import { useMemo } from 'react'
import { MaintenancePlanCollapsibleDataFragment } from '../../generated/user/graphql'

export const useMaintenancePlanCollapsibleData = <
  T extends MaintenancePlanCollapsibleDataFragment = MaintenancePlanCollapsibleDataFragment,
>(
  maintenancePlans: T[],
): (T & MaintenancePlanCollapsibleViewModel)[] => {
  const maintenancePlansCollapsibleData = useMemo<
    (T & MaintenancePlanCollapsibleViewModel)[]
  >(() => {
    return maintenancePlans.reduce((list, mp) => {
      // Because the table doesn't have real fkeys the codegen doesn't know that the location is always present
      if (!mp.location) {
        return list
      }
      return [
        ...list,
        {
          ...mp,
          planTypeName: mp.maintenancePlanDefinition?.marketingInfo?.name ?? '',
          locationAddress: mp.location.address,
          visits: mapQueryVisitsToVisitViewModels(mp.maintenancePlanVisits),
        },
      ]
    }, [] as (T & MaintenancePlanCollapsibleViewModel)[])
  }, [maintenancePlans])
  return maintenancePlansCollapsibleData
}

import { PageHeader } from '@ant-design/pro-components'
import {
  bzOptional,
  DEFAULT_BRANDI_PROMPT_HTML,
  DEFAULT_END_CALL_MESSAGE,
  DEFAULT_FIRST_MESSAGE,
  HtmlString,
  htmlStringSchema,
  isNullish,
  nextGuid,
  optionalTelephoneNumberSchema,
  phoneUtils,
} from '@breezy/shared'
import { faCopy, faRobot } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Card, Form, Input } from 'antd'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'urql'
import { z } from 'zod'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import {
  CategorySection,
  SubSection,
} from '../../components/Settings/SettingsBuilderComponents'
import CopyToClipboard from '../../elements/CopyToClipboard/CopyToClipboard'
import { PhoneNumberField } from '../../elements/Forms/PhoneNumberField'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { RichTextAreaField } from '../../elements/Forms/RichTextAreaField'
import { TextField } from '../../elements/Forms/TextField'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { gql } from '../../generated/user'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'

export const assistantSettingsFormSchema = z.object({
  name: bzOptional(z.string().min(1)),
  firstMessage: bzOptional(z.string()).default(DEFAULT_FIRST_MESSAGE),
  endCallMessage: bzOptional(z.string()).default(DEFAULT_END_CALL_MESSAGE),
  forwardPhoneNumber: optionalTelephoneNumberSchema,
  prompt: bzOptional(htmlStringSchema).default(DEFAULT_BRANDI_PROMPT_HTML),
})

export type AssistantSettingsFormData = z.infer<
  typeof assistantSettingsFormSchema
>

const ASSISTANTS_SETTINGS_QUERY = gql(/* GraphQL */ `
  query AssistantsSettings($where: AssistantsBoolExp!) {
    assistants(where: $where) {
      assistantGuid
      name
      companyGuid
      assistantPhoneSettings {
        firstMessage
        promptHtml
        endCallMessage
        forwardPhoneNumber
      }
      assistantIntegratedPhones {
        integratedPhone {
          phoneNumber
        }
      }
    }
  }
`)

const UPSERT_ASSISTANTS_SETTINGS_MUTATION = gql(/* GraphQL */ `
  mutation UpsertAssistantsSettings(
    $object: AssistantsInsertInput!
    $onConflict: AssistantsOnConflict
  ) {
    insertAssistantsOne(object: $object, onConflict: $onConflict) {
      companyGuid
    }
  }
`)

export const AssistantsSettingsPage = React.memo(() => {
  const companyGuid = useExpectedCompanyGuid()
  const assistantsFeatureFlag = useFeatureFlag('assistant-settings')

  const assistantSettingsQuery = useQuery({
    query: ASSISTANTS_SETTINGS_QUERY,
    variables: {
      where: {
        companyGuid: { _eq: companyGuid },
      },
    },
  })

  if (!assistantsFeatureFlag) {
    return (
      <div className="flex w-full min-w-[700px] flex-col">
        <h1>Coming Soon</h1>
      </div>
    )
  }

  return (
    <GqlQueryLoader
      query={assistantSettingsQuery}
      render={data => (
        <AssistantsSettingsPageInner
          assistants={data.assistants
            .filter(a => !isNullish(a))
            .map(a => ({
              assistantGuid: a.assistantGuid,
              name: a.name,
              firstMessage: a.assistantPhoneSettings?.firstMessage,
              endCallMessage: a.assistantPhoneSettings?.endCallMessage,
              forwardPhoneNumber: a.assistantPhoneSettings?.forwardPhoneNumber,
              promptHtml: a.assistantPhoneSettings?.promptHtml,
              linkedPhoneNumber:
                a.assistantIntegratedPhones?.[0]?.integratedPhone?.phoneNumber,
            }))}
        />
      )}
    />
  )
})

type AssistantsSettingsPageProps = {
  assistants: {
    assistantGuid: string
    name: string | undefined
    linkedPhoneNumber: string | undefined
    firstMessage: string | undefined
    endCallMessage: string | undefined
    promptHtml: HtmlString | undefined
    forwardPhoneNumber: string | undefined
  }[]
}

const AssistantsSettingsPageInner = React.memo(
  ({ assistants }: AssistantsSettingsPageProps) => {
    const companyGuid = useExpectedCompanyGuid()
    const message = useMessage()

    const assistant = React.useMemo(
      () =>
        assistants[0] || {
          assistantGuid: nextGuid(),
          name: '',
          assistantPhoneSettings: {
            firstMessage: DEFAULT_FIRST_MESSAGE,
            endCallMessage: DEFAULT_END_CALL_MESSAGE,
            promptHtml: DEFAULT_BRANDI_PROMPT_HTML,
            forwardPhoneNumber: undefined,
          },
        },
      [assistants],
    )

    const {
      formState: { errors, isDirty },
      control,
      handleSubmit,
      reset,
    } = useForm<AssistantSettingsFormData>({
      resolver: zodResolver(assistantSettingsFormSchema),
      defaultValues: {
        name: assistant.name || undefined,
        firstMessage: assistant.firstMessage || DEFAULT_FIRST_MESSAGE,
        endCallMessage: assistant.endCallMessage || DEFAULT_END_CALL_MESSAGE,
        prompt: assistant.promptHtml || DEFAULT_BRANDI_PROMPT_HTML,
        forwardPhoneNumber: assistant.forwardPhoneNumber,
      },
      reValidateMode: 'onChange',
    })

    const [{ fetching: submitting }, executeMutation] = useMutation(
      UPSERT_ASSISTANTS_SETTINGS_MUTATION,
    )

    const onSubmit = handleSubmit(async data => {
      try {
        await executeMutation({
          object: {
            assistantGuid: assistant.assistantGuid,
            companyGuid,
            name: data.name,
            assistantPhoneSettings: {
              data: {
                firstMessage: data.firstMessage,
                promptHtml: data.prompt,
                endCallMessage: data.endCallMessage,
                forwardPhoneNumber: data.forwardPhoneNumber,
              },
              onConflict: {
                constraint: 'assistant_phone_settings_pkey',
                updateColumns: [
                  'firstMessage',
                  'promptHtml',
                  'endCallMessage',
                  'forwardPhoneNumber',
                ],
              },
            },
          },
          onConflict: {
            constraint: 'assistants_pkey',
            updateColumns: ['assistantGuid', 'name'],
          },
        })
        message.success('Assistant settings updated')
        reset(data)
      } catch (e) {
        message.error('Failed to update Assistant settings')
      }
    })

    return (
      <>
        <div className="flex w-full min-w-[700px] flex-col">
          <PageHeader
            title={
              <PageTitle
                title={
                  <div className="flex items-center space-x-2">
                    <span>AI Assistants</span>
                  </div>
                }
                icon={faRobot}
              />
            }
          />

          <Card className="min-h-0 w-full overflow-auto p-2">
            <Form onSubmitCapture={onSubmit} layout="vertical">
              <div className="flex flex-row items-start justify-between border-0 border-b border-solid border-b-bz-gray-400 pb-4">
                <div className="max-w-[768px] text-bz-gray-900">
                  AI Assistants help automate various tasks and provide
                  intelligent responses throughout the Breezy platform.
                </div>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={!isDirty}
                >
                  Save Changes
                </Button>
              </div>
              <div className="divide-y divide-solid divide-bz-gray-400">
                <CategorySection
                  title="Assistant Configuration"
                  subtitle="Configure how your AI assistants behave across the platform."
                  layout={{ leftContainerFlex: 1, rightContainerFlex: 2 }}
                  extra={
                    <>
                      <div className="mt-8 flex w-fit flex-col">
                        <div className="mb-1 text-sm font-semibold leading-6">
                          Assistant Phone Number
                        </div>
                        {assistant.linkedPhoneNumber ? (
                          <CopyToClipboard
                            payload={phoneUtils.tryFormat(
                              assistant.linkedPhoneNumber,
                            )}
                            label={
                              <span className="relative flex items-center gap-2">
                                <Input
                                  value={phoneUtils.tryFormat(
                                    assistant.linkedPhoneNumber,
                                  )}
                                  disabled
                                />
                                <Button
                                  type="text"
                                  icon={
                                    <FontAwesomeIcon
                                      className="text-bz-gray-700"
                                      icon={faCopy}
                                    />
                                  }
                                  className="absolute right-0"
                                />
                              </span>
                            }
                          />
                        ) : (
                          <div className="text-sm text-bz-gray-900">
                            Contact Breezy Support to link a phone number to
                            your assistant.
                          </div>
                        )}
                      </div>
                    </>
                  }
                >
                  <SubSection
                    title="Assistant Name"
                    subtitle="Give your AI assistant a name that will be used across the platform."
                  >
                    <ReactHookFormItem
                      control={control}
                      name="name"
                      errors={errors}
                      render={({ field }) => (
                        <TextField
                          disabled={submitting}
                          placeholder="Brandi"
                          {...field}
                        />
                      )}
                    />
                  </SubSection>

                  <SubSection
                    title="First Message"
                    tooltip="The first message your assistant will say when starting a conversation."
                  >
                    <ReactHookFormItem
                      control={control}
                      name="firstMessage"
                      errors={errors}
                      render={({ field }) => (
                        <TextField
                          disabled={submitting}
                          placeholder={DEFAULT_FIRST_MESSAGE}
                          {...field}
                        />
                      )}
                    />
                  </SubSection>
                  <SubSection
                    title="End Call Message"
                    tooltip="The message your assistant will say when ending a conversation."
                  >
                    <ReactHookFormItem
                      control={control}
                      name="endCallMessage"
                      errors={errors}
                      render={({ field }) => (
                        <TextField
                          disabled={submitting}
                          placeholder={DEFAULT_END_CALL_MESSAGE}
                          {...field}
                        />
                      )}
                    />
                  </SubSection>
                  <SubSection
                    title="On-Call Phone Number"
                    tooltip="The phone number to forward calls to if there's an emergency request. This will be used to forward calls to the on-call technician. Leave blank to disable."
                  >
                    <ReactHookFormItem
                      control={control}
                      name="forwardPhoneNumber"
                      errors={errors}
                      render={({ field }) => (
                        <PhoneNumberField
                          disabled={submitting}
                          placeholder="+13038643211"
                          {...field}
                        />
                      )}
                    />
                  </SubSection>

                  <SubSection
                    title="Prompt"
                    subtitle="These instructions help guide how the AI assistant interacts with users across the platform."
                  >
                    <ReactHookFormItem
                      control={control}
                      name="prompt"
                      errors={errors}
                      render={({ field }) => (
                        <RichTextAreaField
                          placeholder="Enter a prompt for how the assistant should answer the phone..."
                          disabled={submitting}
                          {...field}
                        />
                      )}
                    />
                  </SubSection>
                </CategorySection>
              </div>
            </Form>
          </Card>
        </div>
      </>
    )
  },
)

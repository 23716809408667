import {
  BzAddress,
  BzDateFns,
  bzExpect,
  CalculatePaths,
  toTitleCase,
} from '@breezy/shared'
import {
  faEllipsis,
  faLocationDot,
  faPhone,
  faUnlink,
  faUser,
  faUserHelmetSafety,
  faVoicemail,
  faWrench,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Typography } from 'antd'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { CallablePhoneLink } from '../../elements/CallablePhoneLink/CallablePhoneLink'
import ThinDivider from '../../elements/ThinDivider'
import { useUnlinkJobFromIntegratedPhoneCall } from '../../hooks/mutate/useUnlinkJobFromIntegratedPhoneCall'
import { TranscriptSummaryAndRecording } from '../../pages/CommsPage/CommConvoElements'
import { LinkedPhoneCalls } from '../../pages/JobDetailsPage/JobDetailsPage.gql'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { LoadingSpinner } from '../LoadingSpinner'

const { Text } = Typography

type Call =
  LinkedPhoneCalls['jobs'][0]['integratedPhoneCallJobs'][0]['integratedPhoneCall']

interface LinkedCallsListViewProps {
  isLoading: boolean
  calls: Call[]
  jobGuid?: string
}

const IconWrapper: React.FC<{ icon: IconDefinition }> = ({ icon }) => (
  <div className="mr-1 flex h-5 w-5 items-center justify-center">
    <FontAwesomeIcon icon={icon} className="h-4 w-4" />
  </div>
)

export const LinkedCallsListView: React.FC<LinkedCallsListViewProps> = ({
  calls,
  isLoading,
  jobGuid,
}) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const companyGuid = useExpectedCompanyGuid()
  const unlinkJob = useUnlinkJobFromIntegratedPhoneCall()
  const displayableCalls = useMemo(() => {
    return calls
      .filter(c => !!c.endedAt)
      .filter(
        c =>
          !c.integratedPhoneMissedCalls ||
          c.integratedPhoneMissedCalls.length > 0 ||
          c.integratedPhoneCallRecording,
      )
      .sort(
        (a, b) =>
          new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime(),
      )
  }, [calls])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Text strong>{displayableCalls.length} Calls</Text>
      <ThinDivider
        borderColorClassName={'#f6f6f6'}
        widthPx={1}
        styleOverrides={{ marginBottom: 0 }}
      />
      <div className="flex flex-col gap-y-5">
        {displayableCalls.map((call, index) => {
          const isVoicemail =
            call.integratedPhoneMissedCalls.length > 0 &&
            call.integratedPhoneMissedCalls[0].integratedPhoneVoicemails
              .length > 0
          const startedAt = BzDateFns.parseISO(call.startedAt, tzId)
          const menuItems = jobGuid
            ? [
                {
                  key: 'unlink',
                  label: 'Unlink Call',
                  icon: <IconWrapper icon={faUnlink} />,
                  onClick: () =>
                    unlinkJob(
                      companyGuid,
                      call.integratedPhoneCallGuid,
                      jobGuid,
                    ),
                },
              ]
            : []

          const recordingSummary = isVoicemail
            ? call.integratedPhoneMissedCalls[0].integratedPhoneVoicemails[0]
                .recordingSummary
            : call.integratedPhoneCallRecording?.recordingSummary

          const transcript = isVoicemail
            ? call.integratedPhoneMissedCalls[0].integratedPhoneVoicemails[0]
                .recordingTranscript
            : call.integratedPhoneCallRecording?.recordingTranscript

          const recordingUrl = isVoicemail
            ? call.integratedPhoneMissedCalls[0].integratedPhoneVoicemails[0]
                .recordingUrl
            : call.integratedPhoneCallRecording?.recordingUrl

          const ActivityItem = () => (
            <>
              {!!call.user?.fullName && (
                <div className="flex items-center text-[14px] font-normal leading-[22px] text-[#595959]">
                  <IconWrapper icon={faUserHelmetSafety} />
                  <span>
                    Spoke with {bzExpect(call.user?.fullName, 'User Full Name')}
                  </span>
                </div>
              )}
              {isVoicemail && (
                <div className="flex items-center text-[14px] font-normal leading-[22px] text-[#595959]">
                  <IconWrapper icon={faVoicemail} />
                  <span>Left a Voicemail</span>
                </div>
              )}
            </>
          )

          const linkedJob =
            call.integratedPhoneCallJobs.length > 0
              ? call.integratedPhoneCallJobs[0].job
              : undefined

          return (
            <div
              key={index}
              className={`flex flex-row gap-x-3 ${
                index === 0 ? 'mt-0' : ''
              } rounded-xl border border-solid border-[#D9D9D9] p-3 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.03),0px_1px_6px_-1px_rgba(0,0,0,0.02),0px_2px_4px_0px_rgba(0,0,0,0.02)]`}
            >
              <div className="w-full">
                <div className="flex flex-row justify-between">
                  <Text className="text-[14px] font-semibold leading-[22px] text-[#1F1F1F]">
                    {BzDateFns.format(startedAt, 'MMM dd, yyyy @ h:mm a')}
                  </Text>
                  {jobGuid && (
                    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
                      <div className="center-children-vh h-6 w-6 cursor-pointer">
                        <IconWrapper icon={faEllipsis} />
                      </div>
                    </Dropdown>
                  )}
                </div>
                <ThinDivider
                  borderColorClassName="#D9D9D9"
                  widthPx={1}
                  dividerStyle="dashed"
                  styleOverrides={{ marginBottom: 8, marginTop: 8 }}
                />

                <div className="flex flex-row gap-x-2">
                  <div className="flex w-1/2 flex-col">
                    <div className="flex items-center text-[14px] font-normal leading-[22px] text-[#595959]">
                      <IconWrapper icon={faUser} />
                      <span>{call.contact?.fullName ?? 'Unknown Contact'}</span>
                    </div>
                    <div className="flex items-center text-[14px] font-normal leading-[22px] text-[#595959]">
                      <IconWrapper icon={faPhone} />
                      <CallablePhoneLink
                        phoneNumber={bzExpect(
                          call.contact?.primaryPhoneNumber?.phoneNumber,
                          'Contact Phone Number',
                        )}
                      >
                        <span>
                          {call.contact?.primaryPhoneNumber?.phoneNumber}
                        </span>{' '}
                        <span className="text-[#595959]">
                          (
                          {toTitleCase(
                            call.contact?.primaryPhoneNumber?.type ?? '',
                          )}
                          )
                        </span>
                      </CallablePhoneLink>
                    </div>
                    {!jobGuid && <ActivityItem />}
                  </div>
                  <div className="flex w-1/2 flex-col">
                    {jobGuid && <ActivityItem />}
                    {!jobGuid && linkedJob && (
                      <div className="flex items-center text-[14px] font-normal leading-[22px] text-[#595959]">
                        <IconWrapper icon={faWrench} />
                        <Link
                          to={CalculatePaths.jobDetails({
                            jobGuid: linkedJob.jobGuid,
                          })}
                          className="text-blue-500 no-underline visited:text-blue-500"
                        >
                          {linkedJob.jobType.name} #{linkedJob.displayId}
                        </Link>
                      </div>
                    )}
                    {!jobGuid && linkedJob && linkedJob.location && (
                      <div className="flex items-center text-[14px] font-normal leading-[22px] text-[#595959]">
                        <IconWrapper icon={faLocationDot} />
                        <Link
                          to={CalculatePaths.locationDetails({
                            locationGuid: linkedJob.location.locationGuid,
                          })}
                          className="truncate text-blue-500 no-underline visited:text-blue-500"
                        >
                          {linkedJob.location.displayName ??
                            BzAddress.formatAddressSingleLine(
                              linkedJob.location.address,
                            )}
                        </Link>
                      </div>
                    )}
                  </div>
                </div>

                {(recordingSummary || transcript || recordingUrl) && (
                  <div className="mt-2 rounded-2xl bg-[#F5F5F5] p-[10px]">
                    <TranscriptSummaryAndRecording
                      recordingSummary={recordingSummary}
                      transcript={transcript}
                      recordingUrl={recordingUrl}
                    />
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

import { AccountingPayoutAccounts, isNullish } from '@breezy/shared'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useCallback } from 'react'
import { useMutation, useQuery } from 'urql'
import { BzTitleAndExplanation } from '../../elements/BzTitleAndExplanation/BzTitleAndExplanation'
import { useAccountingAccountTreeData } from '../../hooks/fetch/useFetchAccountingAccountTreeData'
import { useAccountingIntegrationType } from '../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { m } from '../../utils/react-utils'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'
import { LoadingSpinner } from '../LoadingSpinner'
import { QuickbooksAccountSelectionFormItem } from '../Quickbooks/QuickbooksAccountSelectionFormItem'
import {
  FETCH_ACCOUNTING_PAYOUT_ACCOUNTS_QUERY,
  UPSERT_ACCOUNTING_PAYOUT_ACCOUNTS_MUTATION,
} from './QuickbooksDesktop.gql'

const UPSERT_SAVE_ERROR_MESSAGE =
  'There was an unexpected error saving the Payout Accounts Config'

type AccountingPayoutAccountsConfigPanelProps = {
  cfg?: AccountingPayoutAccounts
}

export const AccountingPayoutAccountsConfigPanel =
  m<AccountingPayoutAccountsConfigPanelProps>(({ cfg }) => {
    const companyGuid = useExpectedCompanyGuid()
    const message = useMessage()
    const accountingAccounts = useAccountingAccountTreeData()
    const accountingIntegrationType = useAccountingIntegrationType()
    const [{ data, fetching }, refetch] = useQuery({
      query: FETCH_ACCOUNTING_PAYOUT_ACCOUNTS_QUERY,
      variables: { companyGuid },
    })
    const [{ fetching: upserting }, upsert] = useMutation(
      UPSERT_ACCOUNTING_PAYOUT_ACCOUNTS_MUTATION,
    )
    const isAsync = fetching || upserting

    const [form] = useForm<AccountingPayoutAccounts>()
    const onFinish = useCallback(
      async (values: AccountingPayoutAccounts) => {
        try {
          await upsert({
            object: {
              ...values,
              companyGuid,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              accountingIntegrationType,
            },
          })
        } catch (e) {
          console.error(UPSERT_SAVE_ERROR_MESSAGE, e)
          message.error(UPSERT_SAVE_ERROR_MESSAGE)
        } finally {
          refetch()
        }
      },
      [companyGuid, message, refetch, upsert, accountingIntegrationType],
    )

    if (isAsync || isNullish(accountingAccounts))
      return (
        <div className="flex min-h-[320px] min-w-[240px] items-center">
          <LoadingSpinner />
        </div>
      )

    if (!accountingAccounts || accountingAccounts.length < 1)
      return <p>Ledger Accounts not found - Cannot Configure Payout Accounts</p>

    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={cfg ?? data?.accPayoutAccountsConfig[0] ?? undefined}
        onFinish={onFinish}
      >
        <BzTitleAndExplanation
          title="Payout Accounts"
          description="Configuring these account will allow Breezy to Auto-Sync your bank payouts from your payment processor to your accounting software. This is recommended to ease your accounting reconciliation process."
          attentionType="info"
          titleClassName="font-semibold text-lg"
          className="mb-2"
        />
        <QuickbooksAccountSelectionFormItem
          required
          formItemName="payoutDepositAccountId"
          formItemLabel="Payout Deposit Bank Account"
          financeAccountsTreeData={accountingAccounts.filter(
            x => x.accountType === 'Bank',
          )}
          explanationTooltip="This is the account that will be used to record the deposit of your payouts from Breezy. Typically this ought to be your primary income Bank Account."
        />
        <QuickbooksAccountSelectionFormItem
          required
          formItemName="payoutFeesAccountId"
          formItemLabel="Payout Fees Expense Account"
          financeAccountsTreeData={accountingAccounts.filter(
            x => x.accountType === 'Expense',
          )}
          explanationTooltip="This is the account that will be used to record the fees charged by payment processors from your payouts. Typically this ought to be an expense account. The fees generally result from Bank charges, Credit Card Processing Charges, and Payment Processor Account Fees."
        />
        <QuickbooksAccountSelectionFormItem
          required
          formItemName="payoutRefundsAccountId"
          formItemLabel="Payout Refunds Income Account"
          financeAccountsTreeData={accountingAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="This is the account that will be used to record the refunds from your payouts. Typically this ought to be a contra-income account. The refunds generally result from manual refunds issued to your customers."
        />
        <QuickbooksAccountSelectionFormItem
          required
          formItemName="payoutPaymentProcessorBalanceAccountId"
          formItemLabel="Payment Processor Balance Account"
          financeAccountsTreeData={accountingAccounts.filter(
            x => x.accountType !== 'Income' && x.accountType !== 'Expense',
          )}
          explanationTooltip="This is the account that will be used to record the balance adjustment from your payouts. Typically this ought to be a trust account. The balance adjustment generally results from the difference between the total of your payouts and the total of your charges and refunds."
        />
        <FormCancelSubmitButtons />
      </Form>
    )
  })

import {
  DateTimeFormatter,
  ENGLISH_LOCALE,
  EquipmentCondition,
  EquipmentConditionDisplayNames,
  EquipmentDisplayNames,
  EquipmentInstallationParty,
  EquipmentInstallationPartyDisplayNames,
  EquipmentOperationalStatus,
  EquipmentOperationalStatusDisplayNames,
  EquipmentType,
  InstalledEquipmentSummary,
  isEquipmentType,
  isKeyOfEquipmentCondition,
  isKeyOfEquipmentInstallationParty,
  isKeyOfEquipmentOperationalStatus,
} from '@breezy/shared'
import { faCopy } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Modal } from 'antd'
import cn from 'classnames'
import React, { useState } from 'react'
import { CardBody } from '../../elements/Card/Card'
import CopyToClipboard from '../../elements/CopyToClipboard/CopyToClipboard'
import { EmDash } from '../../elements/EmDash/EmDash'
type EquipmentDetailsModalProps = {
  equipment: InstalledEquipmentSummary
  onClose: () => void
  open?: boolean
}

type EquipmentDetailsLinkProps = {
  equipment: InstalledEquipmentSummary
  linkText?: string
  className?: string
}

export const EquipmentDetailsLink = React.memo<EquipmentDetailsLinkProps>(
  ({ equipment, linkText = 'Loan', className }) => {
    const [open, setOpen] = useState(false)
    return (
      <>
        <div
          onClick={e => {
            e.stopPropagation()
            setOpen(true)
          }}
          className={cn('cursor-pointer text-bz-primary', className)}
        >
          {linkText}
        </div>
        {open && (
          <EquipmentDetailsModal
            equipment={equipment}
            onClose={() => setOpen(false)}
          />
        )}
      </>
    )
  },
)

export const EquipmentDetailsModal = React.memo<EquipmentDetailsModalProps>(
  ({ open = true, equipment, onClose }) => {
    return (
      <Modal
        open={open}
        zIndex={1004}
        onCancel={onClose}
        footer={null}
        width={520}
      >
        <CardBody hideTitleDivider title="Equipment Details">
          <div className="grid grid-cols-2 gap-x-2">
            <Column
              label="Equipment Type"
              value={
                isEquipmentType(equipment.equipmentType)
                  ? EquipmentDisplayNames[
                      EquipmentType[equipment.equipmentType]
                    ]
                  : EquipmentDisplayNames[EquipmentType.AIR_CONDITIONER]
              }
            />
            <Column
              label="Operational"
              value={
                isKeyOfEquipmentOperationalStatus(equipment.operationalStatus)
                  ? EquipmentOperationalStatusDisplayNames[
                      EquipmentOperationalStatus[equipment.operationalStatus]
                    ]
                  : EquipmentOperationalStatusDisplayNames[
                      EquipmentOperationalStatus.UNKNOWN
                    ]
              }
            />
            <Column
              label="Equipment Condition"
              value={
                isKeyOfEquipmentCondition(equipment.equipmentCondition)
                  ? EquipmentConditionDisplayNames[
                      EquipmentCondition[equipment.equipmentCondition]
                    ]
                  : ''
              }
            />
          </div>
          <Divider />
          {equipment.description && (
            <>
              <div className="col-span-2 mt-2">
                <Label>Description</Label>
                <div className="text-sm">{equipment.description}</div>
              </div>
              <Divider />
            </>
          )}
          <div className="grid grid-cols-2 gap-x-2">
            <Column
              label="Installation Date"
              value={
                equipment.installationDate ? (
                  equipment.installationDate.format(
                    DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                      ENGLISH_LOCALE,
                    ),
                  )
                ) : (
                  <EmDash />
                )
              }
            />
            <Column
              label="Installation Party"
              value={
                isKeyOfEquipmentInstallationParty(
                  equipment.installationParty,
                ) ? (
                  EquipmentInstallationPartyDisplayNames[
                    EquipmentInstallationParty[equipment.installationParty]
                  ]
                ) : (
                  <EmDash />
                )
              }
            />
          </div>
          <Divider />
          <div className="grid grid-cols-2 gap-x-2">
            <Column
              label="Manufacturer"
              value={equipment.manufacturer || <EmDash />}
            />
            <Column
              label="Model Number"
              value={
                equipment.modelNumber ? (
                  <CopyToClipboard
                    payload={equipment.modelNumber}
                    label={
                      <span className="flex items-center gap-2">
                        {equipment.modelNumber}
                        <FontAwesomeIcon icon={faCopy} />
                      </span>
                    }
                  />
                ) : (
                  <EmDash />
                )
              }
            />
          </div>
          <Divider />
          <div className="grid grid-cols-2 gap-x-2">
            <Column
              label="Serial Number"
              value={
                equipment.serialNumber ? (
                  <CopyToClipboard
                    payload={equipment.serialNumber}
                    label={
                      <span className="flex items-center gap-2">
                        {equipment.serialNumber}
                        <FontAwesomeIcon icon={faCopy} />
                      </span>
                    }
                  />
                ) : (
                  <EmDash />
                )
              }
            />
            <Column
              label="Avg. Life Expectancy (Years)"
              value={
                equipment.averageLifeExpectancyYears ? (
                  equipment.averageLifeExpectancyYears.toString()
                ) : (
                  <EmDash />
                )
              }
            />
          </div>
          <Divider />
          <div className="grid grid-cols-3 gap-x-2">
            <Column
              label="Manufacturing Date"
              value={
                equipment.manufacturingDate ? (
                  equipment.manufacturingDate.format(
                    DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                      ENGLISH_LOCALE,
                    ),
                  )
                ) : (
                  <EmDash />
                )
              }
            />
            <Column
              label="Man. Warranty Start Date"
              value={
                equipment.manufacturerWarrantyStartDate ? (
                  equipment.manufacturerWarrantyStartDate.format(
                    DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                      ENGLISH_LOCALE,
                    ),
                  )
                ) : (
                  <EmDash />
                )
              }
            />
            <Column
              label="Man. Warranty End Date"
              value={
                equipment.manufacturerWarrantyEndDate ? (
                  equipment.manufacturerWarrantyEndDate.format(
                    DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                      ENGLISH_LOCALE,
                    ),
                  )
                ) : (
                  <EmDash />
                )
              }
            />
          </div>
          <Divider />
          {equipment.manufacturerWarrantyTerms && (
            <>
              <div className="col-span-2 mt-2">
                <Label>Manufacturer Warranty Terms</Label>
                <div className="text-sm">
                  {equipment.manufacturerWarrantyTerms}
                </div>
              </div>
              <Divider />
            </>
          )}
          <div className="grid grid-cols-2 gap-x-2">
            <Column
              label="Labor Warranty Start Date"
              value={
                equipment.laborWarrantyStartDate ? (
                  equipment.laborWarrantyStartDate.format(
                    DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                      ENGLISH_LOCALE,
                    ),
                  )
                ) : (
                  <EmDash />
                )
              }
            />
            <Column
              label="Labor Warranty End Date"
              value={
                equipment.laborWarrantyEndDate ? (
                  equipment.laborWarrantyEndDate.format(
                    DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                      ENGLISH_LOCALE,
                    ),
                  )
                ) : (
                  <EmDash />
                )
              }
            />
          </div>
          {equipment.laborWarrantyTerms && (
            <>
              <div className="col-span-2 mt-2">
                <Label>Labor Warranty Terms</Label>
                <div className="text-sm">{equipment.laborWarrantyTerms}</div>
              </div>
              <Divider />
            </>
          )}
          {equipment.equipmentDimensions && (
            <>
              <div className="col-span-2 mt-2">
                <Label>Equipment Dimensions</Label>
                <div className="text-sm">{equipment.equipmentDimensions}</div>
              </div>
              <Divider />
            </>
          )}
        </CardBody>
      </Modal>
    )
  },
)

const Label = React.memo<React.PropsWithChildren>(({ children }) => {
  return (
    <div className="text-sm font-semibold text-bz-gray-900">{children}</div>
  )
})

const Column = React.memo<{ label: string; value: React.ReactNode }>(
  ({ label, value }) => {
    return (
      <div className="flex flex-col gap-1">
        <Label>{label}</Label>
        {<div className="text-sm">{value}</div>}
      </div>
    )
  },
)

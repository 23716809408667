import { gql } from 'src/generated'
import { useExpectedCompanyGuid } from 'src/providers/PrincipalUser'
import { useQuery } from 'urql'

const USE_COMPANY_HIDDEN_PAYMENT_METHODS_QUERY = gql(/* GraphQL */ `
  query UseCompanyHiddenPaymentMethodsQuery($companyGuid: uuid = "") {
    billingProfileHiddenPaymentMethods(
      where: { companyGuid: { _eq: $companyGuid } }
    ) {
      companyGuid
      paymentMethod
    }
  }
`)

export const useCompanyHiddenPaymentMethods = () => {
  const companyGuid = useExpectedCompanyGuid()

  const [res, refetch] = useQuery({
    query: USE_COMPANY_HIDDEN_PAYMENT_METHODS_QUERY,
    variables: { companyGuid },
  })

  return {
    hiddenPaymentMethods: res.data?.billingProfileHiddenPaymentMethods.map(
      paymentMethod => paymentMethod.paymentMethod,
    ),
    error: res.error,
    fetching: res.fetching,
    refetch,
  }
}

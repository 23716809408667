import { useQuery } from 'urql'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { FETCH_END_OF_JOB_END_OF_APPOINTMENT_WORKFLOW_ENABLED } from './useIsEndOfAppointmentWorkflowEnabledForCompany.gql'

export const useIsEndOfAppointmentWorkflowEnabledForCompany = ():
  | boolean
  | undefined => {
  const companyGuid = useExpectedCompanyGuid()
  const [query] = useQuery({
    query: FETCH_END_OF_JOB_END_OF_APPOINTMENT_WORKFLOW_ENABLED,
    variables: { companyGuid },
    requestPolicy: 'network-only',
  })

  return query.data?.companyConfigByPk?.endOfJobAppointmentWorkflowEnabled
}

import {
  appointmentConfigurationSettingsV2FormSchema,
  convertQueryToReviewTypeIdMap,
  isNullish,
  R,
  REMINDER_FREQUENCIES,
  REMINDER_FREQUENCY_METADATA,
  REVIEW_TYPES,
  ReviewType,
  ReviewTypeIdMap,
  ReviewTypeMetadata,
  toPlural,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form, Select } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'urql'
import { z } from 'zod'
import { GqlMultiQueryLoader } from '../../components/GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import {
  CategorySection,
  CheckField,
  SubSection,
} from '../../components/Settings/SettingsBuilderComponents'
import { ReviewTypeToSvg } from '../../components/TechnicianApp/MyAppointmentsPage/AppointmentDetail/EndOfAppointmentNextStepsForm/EndOfAppointmentNextStepsForm'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { Link } from '../../elements/Link/Link'
import {
  GetEndOfVisitNotificationSettingsQuery,
  GetNotificationSettingsQuery,
} from '../../generated/user/graphql'
import { useIsEndOfAppointmentWorkflowEnabledForCompany } from '../../hooks/fetch/useIsEndOfAppointmentWorkflowEnabledForCompany'
import { useEndOfVisitReviewSettings } from '../../hooks/useEndOfVisitReviewSettings'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { NOTIFICATION_SETTINGS_QUERY } from '../SettingsPage/SettingsNotificationPage.gql'
import { UPDATE_END_OF_APPOINTMENT_WORKFLOW_SETTINGS } from './AppointmentConfigurationSettingsPage.gql'

type AppointmentConfigurationSettingsV2FormData = z.infer<
  typeof appointmentConfigurationSettingsV2FormSchema
>

type EndOfVisitNotificationSettings =
  GetEndOfVisitNotificationSettingsQuery['companyNotificationSettingsByPk']
type ExistingNotificationSettings =
  GetNotificationSettingsQuery['companyNotificationSettingsByPk']

const getEndOfVisitDefaultReminderType = (
  endOfVisitReminderTypeIdMap: ReviewTypeIdMap,
): ReviewType | undefined => {
  return REVIEW_TYPES.find(type => endOfVisitReminderTypeIdMap[type])
}

type AppointmentConfigurationSettingsV2FormProps = {
  companyGuid: string
  isEndOfAppointmentWorkflowEnabledForCompany: boolean | undefined
  endOfVisitReminderTypeIdMap: ReviewTypeIdMap
  endOfVisitNotificationSettings: EndOfVisitNotificationSettings
  existingNotificationSettings: ExistingNotificationSettings
}

export const AppointmentConfigurationSettingsV2Page = React.memo(() => {
  const companyGuid = useExpectedCompanyGuid()

  const isEndOfAppointmentWorkflowEnabledForCompany =
    useIsEndOfAppointmentWorkflowEnabledForCompany()

  const notificationSettingsQuery = useQuery({
    query: NOTIFICATION_SETTINGS_QUERY,
    variables: {
      companyGuid,
    },
  })

  const { query: endOfVisitReviewSettingsQuery } = useEndOfVisitReviewSettings()

  return (
    <GqlMultiQueryLoader
      queries={[notificationSettingsQuery, endOfVisitReviewSettingsQuery]}
      loadingComponent={<LoadingSpinner />}
      render={([notificationSettings, endOfVisitReviewSettings]) => {
        const endOfVisitReminderTypeIdMap = convertQueryToReviewTypeIdMap(
          endOfVisitReviewSettings.companyConfigByPk,
        )
        return (
          <AppointmentConfigurationSettingsV2Form
            companyGuid={companyGuid}
            isEndOfAppointmentWorkflowEnabledForCompany={
              isEndOfAppointmentWorkflowEnabledForCompany
            }
            endOfVisitReminderTypeIdMap={endOfVisitReminderTypeIdMap}
            endOfVisitNotificationSettings={
              endOfVisitReviewSettings.companyNotificationSettingsByPk
            }
            existingNotificationSettings={
              notificationSettings.companyNotificationSettingsByPk
            }
          />
        )
      }}
    />
  )
})

const AppointmentConfigurationSettingsV2Form =
  React.memo<AppointmentConfigurationSettingsV2FormProps>(
    ({
      companyGuid,
      isEndOfAppointmentWorkflowEnabledForCompany,
      endOfVisitReminderTypeIdMap,
      endOfVisitNotificationSettings,
      existingNotificationSettings,
    }) => {
      const {
        formState: { errors, isDirty },
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
      } = useForm<AppointmentConfigurationSettingsV2FormData>({
        resolver: zodResolver(appointmentConfigurationSettingsV2FormSchema),
        defaultValues: appointmentConfigurationSettingsV2FormSchema.parse({}),
      })

      const defaultReviewLinkType = useMemo(() => {
        return getEndOfVisitDefaultReminderType(endOfVisitReminderTypeIdMap)
      }, [endOfVisitReminderTypeIdMap])

      const hasAtLeastOneReviewSiteConfigured = useMemo(() => {
        return !!defaultReviewLinkType
      }, [defaultReviewLinkType])

      useEffect(() => {
        if (
          !isNullish(isEndOfAppointmentWorkflowEnabledForCompany) &&
          endOfVisitNotificationSettings
        ) {
          reset({
            endOfAppointmentWorkflowEnabled:
              isEndOfAppointmentWorkflowEnabledForCompany,
            // If the company doesn't have any of the review ids setup, set the toggle to false
            endOfVisitSendReviewLinkEnabled: hasAtLeastOneReviewSiteConfigured
              ? endOfVisitNotificationSettings.endOfVisitSendReviewLinkEnabled
              : false,
            // If there's not a configured review type, set it to the first defined review type
            endOfVisitSendReviewLinkType:
              endOfVisitNotificationSettings.endOfVisitSendReviewLinkType
                ? endOfVisitNotificationSettings.endOfVisitSendReviewLinkType
                : defaultReviewLinkType,
            endOfVisitSendReviewLinkFrequency:
              endOfVisitNotificationSettings.endOfVisitSendReviewLinkFrequency
                ? endOfVisitNotificationSettings.endOfVisitSendReviewLinkFrequency
                : 'AFTER_5_DAYS',
            endOfVisitSendReviewLinkCount:
              endOfVisitNotificationSettings.endOfVisitSendReviewLinkCount
                ? endOfVisitNotificationSettings.endOfVisitSendReviewLinkCount
                : 2,
          })
        }
      }, [
        defaultReviewLinkType,
        endOfVisitNotificationSettings,
        hasAtLeastOneReviewSiteConfigured,
        isEndOfAppointmentWorkflowEnabledForCompany,
        reset,
        setValue,
      ])

      const [{ fetching: submitting }, executeMutation] = useMutation(
        UPDATE_END_OF_APPOINTMENT_WORKFLOW_SETTINGS,
      )

      const onSubmit = handleSubmit(data => {
        const { endOfAppointmentWorkflowEnabled, ...rest } = data
        const { __typename, ...notificationSettings } =
          existingNotificationSettings ?? {}
        executeMutation({
          companyGuid,
          enabled: endOfAppointmentWorkflowEnabled,
          notificationSettings: {
            companyGuid,
            ...notificationSettings,
            ...rest,
            endOfVisitSendReviewLinkEnabled: !endOfAppointmentWorkflowEnabled
              ? false
              : rest.endOfVisitSendReviewLinkEnabled,
          },
        })

        // Reset the form to the submitted values
        reset({
          endOfAppointmentWorkflowEnabled,
          endOfVisitSendReviewLinkEnabled: data.endOfVisitSendReviewLinkEnabled,
          endOfVisitSendReviewLinkType: data.endOfVisitSendReviewLinkType,
          endOfVisitSendReviewLinkFrequency:
            data.endOfVisitSendReviewLinkFrequency,
          endOfVisitSendReviewLinkCount: data.endOfVisitSendReviewLinkCount,
        })
      })

      const endOfVisitSendReviewLinkEnabled = watch(
        'endOfVisitSendReviewLinkEnabled',
      )
      const endOfVisitSendReviewLinkFrequency = watch(
        'endOfVisitSendReviewLinkFrequency',
      )
      const selectedReviewSiteType = watch('endOfVisitSendReviewLinkType')
      const pendingEndOfAppointmentWorkflowEnabled = watch(
        'endOfAppointmentWorkflowEnabled',
      )

      const selectedReviewSiteLinkPreview = useMemo(() => {
        const selectedReviewSiteId =
          endOfVisitReminderTypeIdMap[selectedReviewSiteType]

        if (!selectedReviewSiteId) {
          return undefined
        }

        return ReviewTypeMetadata[selectedReviewSiteType].reviewLinkFn(
          selectedReviewSiteId,
        )
      }, [selectedReviewSiteType, endOfVisitReminderTypeIdMap])

      const doNotSend = useMemo(() => {
        return endOfVisitSendReviewLinkFrequency === 'DO_NOT_SEND'
      }, [endOfVisitSendReviewLinkFrequency])

      const reviewSiteOptions = useMemo(() => {
        return REVIEW_TYPES.map(key => ({
          value: key,
          label: (
            <div className="flex flex-row items-center gap-2">
              <img
                src={ReviewTypeToSvg[key]}
                alt={ReviewTypeMetadata[key].displayName}
                className="h-4 w-4"
              />
              <span className="text-sm leading-[22px]">
                {ReviewTypeMetadata[key].displayName}
                {!endOfVisitReminderTypeIdMap[key] && ' (Not Configured)'}
              </span>
            </div>
          ),
          disabled: !endOfVisitReminderTypeIdMap[key],
        }))
      }, [endOfVisitReminderTypeIdMap])

      return (
        <Form onSubmitCapture={onSubmit} layout="vertical">
          <div className="flex flex-row items-start justify-between border-0 border-b border-solid border-b-bz-gray-400 pb-4">
            <div className="max-w-[512px] text-bz-gray-900">
              Enable or Disable certain features related to visits
            </div>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={!isDirty}
            >
              Save Changes
            </Button>
          </div>
          <div className="divide-y divide-solid divide-bz-gray-400">
            <CategorySection
              title="Technician Assignments"
              subtitle="Configure settings related to technician assignments"
            >
              <SubSection
                title="End of Visit Next Steps Form"
                subtitle="Technicians are required to submit a next steps form and a note for the dispatcher before closing the visit."
              >
                <CheckField
                  defaultValue={false}
                  errors={errors}
                  name="endOfAppointmentWorkflowEnabled"
                  control={control}
                  disabled={submitting}
                  label={`Enable End of Visit Next Steps Form`}
                />
              </SubSection>
              {pendingEndOfAppointmentWorkflowEnabled && (
                <SubSection
                  title="Review Link"
                  subtitle="The End of Visit form will include an option for technicians to send a review link to the customer if desired."
                >
                  <CheckField
                    defaultValue={true}
                    errors={errors}
                    name="endOfVisitSendReviewLinkEnabled"
                    control={control}
                    disabled={
                      submitting ||
                      !pendingEndOfAppointmentWorkflowEnabled ||
                      !reviewSiteOptions.some(option => !option.disabled)
                    }
                    label={
                      hasAtLeastOneReviewSiteConfigured
                        ? `Enable End of Visit Review Link`
                        : `Enable End of Visit Review Link (Contact Breezy Support to enable)`
                    }
                  />
                  {endOfVisitSendReviewLinkEnabled && (
                    <div className="mb-2 flex w-full flex-col">
                      <ReactHookFormItem
                        errors={errors}
                        name="endOfVisitSendReviewLinkType"
                        control={control}
                        label="Review Platform"
                        required
                        noBottomMargin
                        className="w-full"
                        outerClassName="w-full"
                        render={({ field }) => (
                          <Select
                            value={field.value}
                            disabled={
                              submitting || !endOfVisitSendReviewLinkEnabled
                            }
                            data-testid="endOfVisitSendReviewLinkType"
                            popupMatchSelectWidth={false}
                            options={reviewSiteOptions}
                            onChange={value => field.onChange(value)}
                          />
                        )}
                      />
                      {selectedReviewSiteLinkPreview && (
                        <div className="mt-[2px] inline-block">
                          <span className="mr-[2px] text-bz-text-description">
                            Users will be sent to
                          </span>
                          <Link
                            to={selectedReviewSiteLinkPreview}
                            target="_blank"
                          >
                            {selectedReviewSiteLinkPreview}
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                  {endOfVisitSendReviewLinkEnabled && (
                    <div className="flex flex-row space-x-4 *:min-w-0 *:flex-1">
                      <ReactHookFormItem
                        errors={errors}
                        name="endOfVisitSendReviewLinkFrequency"
                        control={control}
                        label="Send Reminder"
                        noBottomMargin
                        render={({ field }) => (
                          <Select
                            value={field.value}
                            disabled={
                              submitting || !endOfVisitSendReviewLinkEnabled
                            }
                            data-testid="endOfVisitSendReviewLinkFrequency"
                            popupMatchSelectWidth={false}
                            options={REMINDER_FREQUENCIES.map(key => ({
                              value: key,
                              label: REMINDER_FREQUENCY_METADATA[key].label,
                            }))}
                            onChange={value => field.onChange(value)}
                          />
                        )}
                      />
                      <ReactHookFormItem
                        errors={errors}
                        name="endOfVisitSendReviewLinkCount"
                        control={control}
                        label="Follow-up"
                        noBottomMargin
                        render={({ field }) => (
                          <Select
                            value={field.value}
                            disabled={
                              submitting ||
                              doNotSend ||
                              !endOfVisitSendReviewLinkEnabled
                            }
                            data-testid="endOfVisitSendReviewLinkCount"
                            popupMatchSelectWidth={false}
                            options={R.range(1, 4).map(value => ({
                              value,
                              label: `${value} ${toPlural(value, 'time')}`,
                            }))}
                            onChange={value => field.onChange(value)}
                          />
                        )}
                      />
                    </div>
                  )}
                </SubSection>
              )}
            </CategorySection>
          </div>
        </Form>
      )
    },
  )

import { message } from 'antd'
import { useMutation } from 'urql'
import { UNLINK_JOB_FROM_INTEGRATED_PHONE_CALL_MUTATION } from './unlinkJobFromIntegratedPhoneCall.gql'

export const useUnlinkJobFromIntegratedPhoneCall = () => {
  const [, unlinkJobFromIntegratedPhoneCall] = useMutation(
    UNLINK_JOB_FROM_INTEGRATED_PHONE_CALL_MUTATION,
  )

  const unlinkJob = async (
    companyGuid: string,
    integratedPhoneCallGuid: string,
    jobGuid: string,
  ) => {
    try {
      const result = await unlinkJobFromIntegratedPhoneCall({
        companyGuid,
        integratedPhoneCallGuid,
        jobGuid,
      })

      if (result.error) {
        message.error('Failed to unlink job from integrated phone call')
      }

      return result.data?.deleteIntegratedPhoneCallJobs?.affectedRows
    } catch (error) {
      console.error('Failed to unlink job from integrated phone call:', error)
      message.error('Failed to unlink job from integrated phone call')
      throw error
    }
  }

  return unlinkJob
}

import { Button } from 'antd'
import { useTimeClock } from '../../providers/TimeClockProvider/TimeClockProvider'
import { LoadingSpinner } from '../LoadingSpinner'
import {
  ClockControlsRow,
  ClockInElapsedDisplay,
  ClockedInTimeDisplay,
} from './UserTimeClockStatusCard'

export const UserTimeClockStatusCardV2 = () => {
  const {
    currentAssignment,
    refetch,
    isClockedIn,
    clockedInTime,
    fetching,
    localElapsedDurationMin,
    clockIn,
  } = useTimeClock()

  if (fetching) {
    return (
      <div className="mb-2 flex w-full min-w-[208px] max-w-[208px] flex-row flex-col items-center gap-y-3">
        <div className="flex w-full flex-col items-center">
          <h2 className="m-0 mb-4 text-base font-bold text-bz-gray-1000">
            Updating...
          </h2>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  if (!isClockedIn) {
    return <ClockInView clockIn={clockIn} />
  }

  return (
    <div className="mb-2 flex w-full min-w-[208px] max-w-[208px] flex-row flex-col items-center gap-y-3">
      <div className="flex w-full flex-col items-center">
        <h2 className="m-0 text-base font-bold text-bz-gray-1000">
          You're clocked in!
        </h2>
        <p className="m-0 mt-[-2px] text-bz-gray-1000">
          Your time is being recorded.
        </p>
      </div>

      <div className="flex w-full flex-row items-center gap-x-2 rounded-md bg-[#f5f5f5] p-[12px]">
        <ClockedInTimeDisplay clockedInTime={clockedInTime} />
        <ClockInElapsedDisplay
          currentAssignment={currentAssignment}
          localElapsedDurationMin={localElapsedDurationMin}
        />
      </div>

      <ClockControlsRow
        currentAssignment={currentAssignment}
        refetch={refetch}
        fetching={fetching}
        className="w-full"
      />
    </div>
  )
}

type ClockInViewProps = {
  clockIn: () => void
}

const ClockInView = ({ clockIn }: ClockInViewProps) => {
  return (
    <div className="mb-2 flex w-[260px] w-full min-w-[208px] max-w-[208px] flex-row flex-col items-center gap-y-2">
      <div className="flex w-full flex-col items-center">
        <h2 className="m-0 text-base font-bold text-bz-gray-1000">
          Please clock in!
        </h2>
        <span className="mt-[-2px] text-xs text-bz-gray-1000">
          Your time is NOT being recorded.
        </span>
      </div>

      <Button
        className="w-full"
        type="primary"
        onClick={() => {
          clockIn()
        }}
      >
        Clock In
      </Button>
    </div>
  )
}

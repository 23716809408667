import { OfficeRoutes, PermissionV1, PermissionV2 } from '@breezy/shared'
import { Button, Divider, Input } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { AdminDevTools } from '../../components/Admin/AdminDevTools/AdminDevTools'
import { CompaniesGrid } from '../../components/CompaniesGrid/CompaniesGrid'
import { Page } from '../../components/Page/Page'
import Authenticated from '../../components/Permissions/Authenticated/Authenticated'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { getConfig } from '../../config'
import { Link } from '../../elements/Link/Link'
import { trpc } from '../../hooks/trpc'
import { useAuth } from '../../hooks/useAuth'
import { useDebouncedSearchText } from '../../hooks/useDebouncedSearchText'
import { usePrincipalUser } from '../../providers/PrincipalUser'
import { DataMigrationView } from './DataMigrationView'
import { AdminUpdateVapiPhoneNumber } from './components/AdminUpdateVapiPhoneNumber'
import { CreateCompanyDrawer } from './components/CreateCompanyDrawer/CreateCompanyDrawer'

export function AdminPage() {
  const { user } = useAuth()
  const { principal } = usePrincipalUser()

  return (
    <Page requiresCompanyUser={false}>
      <>
        <h1>Breezy</h1>
      </>
      <Authenticated>
        {user && !user.emailVerified ? (
          <div>
            Please verify email your email to complete your registration
          </div>
        ) : (
          <>
            <div style={{ marginTop: 20 }}>
              <h2>Account Information</h2>
            </div>
            <table>
              <tbody>
                <tr>
                  <td>Email Address</td>
                  <td>{principal?.emailAddress}</td>
                </tr>
                <tr>
                  <td>Id</td>
                  <td>{principal?.userGuid}</td>
                </tr>
              </tbody>
            </table>

            <TrpcAdminPageWithDevTools />

            <Authorized
              to={PermissionV1.EXECUTE_DEV_TOOLS}
              allowsNonCompanyUser
            >
              <DataMigrationView />
            </Authorized>
            <Authorized
              to={PermissionV2.EXECUTE_DEV_TOOLS}
              allowsNonCompanyUser
            >
              <Link to={OfficeRoutes.AI_SANDBOX.path}>
                To Infinity and Beyond
              </Link>
            </Authorized>
            <Divider />
            <Authorized
              to={PermissionV2.EXECUTE_DEV_TOOLS}
              allowsNonCompanyUser
            >
              <Link to={OfficeRoutes.ANALYTICS_RECOMMENDATIONS.path}>
                Analytics - Recommendations
              </Link>
            </Authorized>
            <div style={{ marginTop: '40px' }}>
              <b>Read Users</b>
              <p>
                Super admins can read any user. Admins can read any user on
                their team. Employees can read only themselves.
              </p>
            </div>
          </>
        )}
      </Authenticated>
    </Page>
  )
}

const TrpcAdminPageWithDevTools = () => {
  const isProduction = getConfig().env === 'production' // todo refactor into hook, maybe introduce <RenderIf /> component
  const isLocalDev = getConfig().env === 'local-development'
  const findCompaniesQuery = trpc.companies['companies:fetch-all'].useQuery(
    undefined,
    {
      enabled: false,
    },
  )

  const [createCompanyDrawerOpen, setCreateCompanyDrawerOpen] = useState(false)
  const { searchText, debouncedSearchText, onSearch } = useDebouncedSearchText({
    wait: 250,
  })

  useEffect(() => {
    findCompaniesQuery.refetch()
    // Only fetch on mount. After that it's on demand
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!isProduction && (
        <Authorized to={PermissionV1.EXECUTE_DEV_TOOLS} allowsNonCompanyUser>
          <>
            <Divider />
            <b>Dev Tools</b>
            <AdminDevTools refetchCompanies={findCompaniesQuery.refetch} />
            <Divider />
            <div className="flex flex-row gap-x-4">
              <AdminSendWeeklyReminderDigestEmail />
              <AdminSendYesterdayMaterialsRestockReportEmail />
              {isLocalDev && <AdminUpdateVapiPhoneNumber />}
            </div>
            <Divider />
          </>
        </Authorized>
      )}
      <Authorized to={PermissionV1.CREATE_COMPANY} allowsNonCompanyUser>
        <>
          <TrpcQueryLoader
            query={findCompaniesQuery}
            errorMessage="Failed to load Companies"
            render={data => (
              <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                <div className="flex w-full flex-row items-center gap-3">
                  <h2>Companies</h2>

                  <Input
                    className="ml-auto w-72"
                    placeholder="Search for company's name..."
                    value={searchText}
                    onChange={onSearch}
                  />

                  <Button
                    type="primary"
                    onClick={() => setCreateCompanyDrawerOpen(true)}
                  >
                    + Create New Company
                  </Button>
                </div>

                <CompaniesGrid
                  companies={data.filter(company =>
                    company.name
                      .toLowerCase()
                      .includes(debouncedSearchText.toLowerCase()),
                  )}
                  onCompanyAction={findCompaniesQuery.refetch}
                />

                <Divider />

                <CreateCompanyDrawer
                  open={createCompanyDrawerOpen}
                  onCancel={() => setCreateCompanyDrawerOpen(false)}
                  onCompanyCreatedFinished={findCompaniesQuery.refetch}
                />
              </div>
            )}
          />
        </>
      </Authorized>
    </>
  )
}

const AdminSendWeeklyReminderDigestEmail = () => {
  const sendWeeklyReminderDigest =
    trpc.devTools['devtools:send-reminder-weekly-digest'].useMutation()

  const triggerWeeklyReminderDigest = useCallback(
    () => sendWeeklyReminderDigest.mutate(),
    [sendWeeklyReminderDigest],
  )

  return (
    <div className="flex flex-col space-y-3">
      <b>Send Weekly Reminder Digest</b>
      <Button className="max-w-[100px]" onClick={triggerWeeklyReminderDigest}>
        Send
      </Button>
    </div>
  )
}

const AdminSendYesterdayMaterialsRestockReportEmail = () => {
  const sendMut =
    trpc.devTools['devtools:send-materials-restock-report'].useMutation()

  const trigger = useCallback(() => sendMut.mutate(), [sendMut])

  return (
    <div className="flex flex-col space-y-3">
      <b>Send Materials Restock Report</b>
      <Button className="max-w-[100px]" onClick={trigger}>
        Send
      </Button>
    </div>
  )
}

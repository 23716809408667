import { z } from 'zod'

import { JobAppointmentGuid, phoneUtils } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { RadioButtonField } from '../../elements/Forms/RadioButtonField'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { TextField } from '../../elements/Forms/TextField'
import { useReactHookFormSubmit } from '../../elements/Forms/useReactHookFormSubmit'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { useMessage } from '../../utils/antd-utils'
import { JobPointOfContact, toNotificationType } from './visitConfirmationUtils'

type SendVisitConfirmationModalProps = {
  appointmentGuid: JobAppointmentGuid
  pointOfContact: JobPointOfContact
  onClose: () => void
}

const SendVisitConfirmationFormSchema = z
  .object({
    notificationType: z.enum(['SMS', 'EMAIL']),
    to: z.string(),
  })
  .refine(
    data => {
      if (data.notificationType === 'SMS') {
        return phoneUtils.isPossibleNumber(data.to, 'US')
      }

      return true
    },
    { message: 'Invalid Phone Number', path: ['to'] },
  )
  .refine(
    data => {
      if (data.notificationType === 'EMAIL') {
        return z.string().email().safeParse(data.to).success
      }
      return true
    },
    { message: 'Invalid Email Address', path: ['to'] },
  )

type SendVisitConfirmationFormData = z.infer<
  typeof SendVisitConfirmationFormSchema
>

export const SendVisitConfirmationModal =
  React.memo<SendVisitConfirmationModalProps>(
    ({ appointmentGuid, pointOfContact, onClose }) => {
      const message = useMessage()
      const {
        control,
        handleSubmit,
        formState: { isSubmitting, errors },
        setValue,
        watch,
      } = useForm<SendVisitConfirmationFormData>({
        defaultValues: {
          notificationType: pointOfContact.notificationPreferenceType
            ? toNotificationType(pointOfContact.notificationPreferenceType)
            : 'EMAIL',
          to: pointOfContact.notificationPreferenceType
            ? toNotificationType(pointOfContact.notificationPreferenceType) ===
              'EMAIL'
              ? pointOfContact.primaryEmailAddress
              : pointOfContact.primaryPhoneNumber
            : '',
        },
        resolver: zodResolver(SendVisitConfirmationFormSchema),
      })
      const notificationType = watch('notificationType')

      useEffect(() => {
        // If the notification type changes, update the to field to the correct value
        if (notificationType === 'SMS') {
          setValue('to', pointOfContact.primaryPhoneNumber ?? '')
        } else {
          setValue('to', pointOfContact.primaryEmailAddress ?? '')
        }
      }, [
        notificationType,
        pointOfContact.primaryEmailAddress,
        pointOfContact.primaryPhoneNumber,
        setValue,
        watch,
      ])

      const [submitElement, triggerSubmit] = useReactHookFormSubmit()
      const sendVisitConfirmationMut =
        trpc.appointments['appointments:send-visit-confirmation'].useMutation()

      const onSubmit = useCallback(
        async (data: SendVisitConfirmationFormData) => {
          try {
            await sendVisitConfirmationMut.mutateAsync({
              appointmentGuid,
              notificationType: data.notificationType,
              to: data.to,
            })
            message.success('Visit confirmation sent')
            onClose()
          } catch (e) {
            message.error('Failed to send visit confirmation')
          }
        },
        [appointmentGuid, message, onClose, sendVisitConfirmationMut],
      )

      return (
        <OnsiteConfirmModal
          header="Send visit confirmation"
          confirmText="Send Confirmation"
          onCancel={onClose}
          onConfirm={triggerSubmit}
          loading={sendVisitConfirmationMut.isLoading}
        >
          <div>
            Select how you’d like to send the customer the visit confirmation.
          </div>
          <ThinDivider
            widthPx={1}
            styleOverrides={{ marginTop: 16, marginBottom: 16 }}
          />
          <Form
            layout="vertical"
            disabled={isSubmitting}
            onSubmitCapture={handleSubmit(onSubmit)}
          >
            <ReactHookFormItem
              required
              control={control}
              name="notificationType"
              label="Message Type"
              errors={errors}
              render={({ field }) => (
                <RadioButtonField
                  optionType="button"
                  {...field}
                  options={[
                    { label: 'Via SMS', value: 'SMS' },
                    { label: 'Via Email', value: 'EMAIL' },
                  ]}
                />
              )}
            />
            <ReactHookFormItem
              control={control}
              outerClassName="mt-6"
              name="to"
              label={
                notificationType === 'SMS' ? 'Phone Number' : 'Email Address'
              }
              required
              errors={errors}
              render={({ field }) => <TextField size="middle" {...field} />}
            />
            {submitElement}
          </Form>
        </OnsiteConfirmModal>
      )
    },
  )

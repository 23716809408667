import {
  Address,
  BzDateFns,
  bzExpect,
  expectUsState,
  nextGuid,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { DatePicker, Form } from 'antd'
import dayjs from 'dayjs'
import { memo, useCallback, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  OnsiteBasicModal,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import AddressLineOneField from '../../elements/Forms/AddressLineOneField'
import { NumberField } from '../../elements/Forms/NumberField'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { StateField } from '../../elements/Forms/StateField'
import { TextField } from '../../elements/Forms/TextField'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import useIsMobile from '../../hooks/useIsMobile'
import { useMessage } from '../../utils/antd-utils'
import { preventFormSubmitOnEnter } from '../../utils/form-helpers'

import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import {
  CreateAccountLocationFormSchema,
  createAccountLocationFormSchema,
} from './createAccountLocationFormSchema'

type Props = {
  accountGuid: string
  onModalCancel: () => void
  onLocationCreated?: () => void
}
/**
 * A modal containing a form for creating a new account location.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.accountGuid - The GUID of the account for which the location is being created.
 * @param {Function} props.onModalCancel - Callback function to be called when the modal is cancelled.
 * @param {Function} [props.onLocationCreated] - Optional callback function to be called when a new location is successfully created.
 */
export const CreateAccountLocationModalForm = memo<Props>(
  ({ accountGuid, onModalCancel, onLocationCreated }) => {
    const message = useMessage()
    const btnFormSubmit = useRef<HTMLInputElement>(null)
    const isMobile = useIsMobile()
    const upsertLocationMutation =
      trpc.location['account-locations:upsert'].useMutation()

    const form = useForm<CreateAccountLocationFormSchema>({
      resolver: zodResolver(createAccountLocationFormSchema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

    const {
      control,
      formState: { errors, isDirty },
    } = form
    const [withConfirmation, closeConfirmProps] = useCloseConfirmModal({
      isDirty,
    })

    const onFormSubmit = form.handleSubmit(async values => {
      try {
        const stateAbbreviation = expectUsState(
          values.addressState,
        ).abbreviation
        const currentAccountGuid = bzExpect(
          accountGuid,
          'accountGuid',
          'Account Guid not found',
        )

        const locationGuid = nextGuid()

        upsertLocationMutation.mutate(
          {
            accountGuid: currentAccountGuid,
            location: {
              locationGuid: locationGuid,
              // displayName: values.displayName,
              address: {
                line1: values.addressLineOne,
                line2: values.addressLineTwo || undefined,
                city: values.addressCity,
                stateAbbreviation,
                zipCode: values.addressZipCode,
              },
              estimatedSquareFootage: values.estimatedSquareFootage,
              estimatedBuildDate: values.estimatedBuildDate
                ? BzDateFns.formatLocalDate(values.estimatedBuildDate)
                : undefined,
            },
            isArchived: false,
          },
          {
            onSuccess() {
              message.success('New location added successfully!')
              onLocationCreated?.()
              form.reset()
            },
            onError(err) {
              console.error(`Failed to create an account location: ${err}`)
              message.error(
                "Oops! We couldn't add the new location. Please try again, or reach out to our support team for help if the problem persists.",
              )
            },
          },
        )
      } catch (err) {
        console.error(`Failed to create an account location: ${err}`)
        message.error(
          "Oops! We couldn't add the new location. Please try again, or reach out to our support team for help if the problem persists.",
        )
      }
    })

    const onAddressChanged = useCallback(
      (address: Address | string) => {
        if (typeof address === 'string') {
          form.setValue('addressLineOne', address)
        } else {
          form.setValue('addressCity', address.city)
          form.setValue('addressState', address.stateAbbreviation)
          form.setValue('addressZipCode', address.zipCode)
          form.setValue('addressLineOne', address.line1)
          form.setValue('addressLineTwo', address.line2 ?? '')
          form.trigger()
        }
      },
      [form],
    )

    const onStateAbbreviationChange = useCallback(
      (state: string) => {
        form.setValue('addressState', state)
      },
      [form],
    )

    const onModalClose = useCallback(
      () =>
        withConfirmation(() => {
          onModalCancel()
          form.reset()
        }),
      [form, onModalCancel, withConfirmation],
    )

    return (
      <FormProvider {...form}>
        <OnsiteBasicModal
          open
          size="large"
          header="Create Location"
          onClose={onModalClose}
          footer={
            <OnsiteModalFooter
              loading={upsertLocationMutation.isLoading}
              onCancel={onModalClose}
              onSubmit={onFormSubmit}
            />
          }
        >
          <Form
            className="flex min-h-0 flex-1 flex-col"
            layout="vertical"
            onSubmitCapture={onFormSubmit}
            disabled={upsertLocationMutation.isLoading}
            onKeyDown={preventFormSubmitOnEnter}
          >
            <div className="flex flex-col gap-4">
              <ReactHookFormItem
                name="addressLineOne"
                label="Street address"
                required={true}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <AddressLineOneField
                    showTypedAddress
                    size={isMobile ? 'large' : 'middle'}
                    {...field}
                    onAddressChanged={onAddressChanged}
                  />
                )}
              />
              <ReactHookFormItem
                name="addressLineTwo"
                label="Apt, Unit, Building #"
                required={false}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Apt, Unit, Building #"
                    className="w-full"
                    size={isMobile ? 'large' : 'middle'}
                  />
                )}
              />
              <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                <ReactHookFormItem
                  name="addressCity"
                  label="City"
                  required={true}
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size={isMobile ? 'large' : 'middle'}
                    />
                  )}
                />
                <ReactHookFormItem
                  control={control}
                  name="addressState"
                  label="State"
                  required
                  errors={errors}
                  render={({ field }) => (
                    <StateField
                      {...field}
                      size={isMobile ? 'large' : 'middle'}
                      onChange={onStateAbbreviationChange}
                    />
                  )}
                />
                <ReactHookFormItem
                  name="addressZipCode"
                  label="Zip Code"
                  required={true}
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size={isMobile ? 'large' : 'middle'}
                    />
                  )}
                />
              </div>
              <ThinDivider widthPx={1} />
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <ReactHookFormItem
                  name="estimatedBuildDate"
                  label="Estimated Build Year"
                  required={false}
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      value={field.value ? dayjs(field.value) : null}
                      onChange={date =>
                        field.onChange(date ? date.toDate() : null)
                      }
                      format="YYYY"
                      data-testid="estimatedBuildDate"
                      allowClear
                      placeholder="Select date"
                      picker="year"
                      size={isMobile ? 'large' : 'middle'}
                      className="w-full"
                    />
                  )}
                />
                <ReactHookFormItem
                  name="estimatedSquareFootage"
                  label="Estimated Square Footage"
                  required={false}
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <NumberField
                      {...field}
                      className="w-full"
                      min={0}
                      max={100000}
                      size={isMobile ? 'large' : 'middle'}
                    />
                  )}
                />
              </div>
            </div>

            <input ref={btnFormSubmit} type="submit" className="hidden" />
          </Form>
        </OnsiteBasicModal>
        <CloseConfirmModal {...closeConfirmProps} />
      </FormProvider>
    )
  },
)

import {
  DiscountType,
  Guid,
  R,
  RichDiscountUsc,
  formatPercentage,
  formatUsc,
  nextGuid,
  usdToUsCents,
} from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { CloseConfirmModal } from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { useCanManageCustomDiscounts } from '../../hooks/permission/useCanManageCustomDiscounts'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import {
  ItemPicker,
  ItemPickerItem,
  ItemPickerRenderCreateItemProps,
} from './ItemPicker'
import { GET_PRICEBOOK_DISCOUNTS } from './PricebookPickers.gql'
import { RichDiscountForm } from './RichDiscountForm'

const getNumberFormatter = (type: DiscountType) =>
  type === DiscountType.RATE
    ? (value: number) => formatPercentage(value)
    : formatUsc

export type DiscountPickerDiscount = RichDiscountUsc & {
  discountGuid: Guid
}

type DiscountMultiPickerProps = {
  preExistingDiscounts: DiscountPickerDiscount[]
  isSubmitting?: boolean
  onSubmit: (items: DiscountPickerDiscount[]) => void
  onCancel: () => void
}

export const DiscountMultiPicker = React.memo<DiscountMultiPickerProps>(
  ({ onSubmit, onCancel, isSubmitting, preExistingDiscounts }) => {
    const companyGuid = useExpectedCompanyGuid()
    const canManageCustomDiscounts = useCanManageCustomDiscounts()

    const [{ data, fetching }] = useQuery({
      query: GET_PRICEBOOK_DISCOUNTS,
      variables: {
        companyGuid,
      },
    })

    const [selectedItemCountMap, setSelectedItemCountMap] = useState<
      Record<Guid, number>
    >({})

    const [adHocItems, setAdHocItems] = useState<DiscountPickerDiscount[]>([])

    const [hasFlatDiscount, hasRateDiscount] = useMemo(() => {
      let hasFlatDiscount = false
      let hasRateDiscount = false
      for (const discount of data?.pricebookDiscounts ?? []) {
        const count = selectedItemCountMap[discount.pricebookDiscountGuid]
        if (count) {
          if (discount.type === DiscountType.FLAT) {
            hasFlatDiscount = true
          } else {
            hasRateDiscount = true
          }
        }
      }
      for (const item of adHocItems) {
        const count = selectedItemCountMap[item.discountGuid]
        if (count) {
          if (item.type === DiscountType.FLAT) {
            hasFlatDiscount = true
          } else {
            hasRateDiscount = true
          }
        }
      }
      for (const item of preExistingDiscounts ?? []) {
        if (item.type === DiscountType.FLAT) {
          hasFlatDiscount = true
        } else {
          hasRateDiscount = true
        }
      }
      return [hasFlatDiscount, hasRateDiscount]
    }, [
      adHocItems,
      data?.pricebookDiscounts,
      preExistingDiscounts,
      selectedItemCountMap,
    ])

    const discountIsDisabled = useCallback(
      (discountGuid: Guid, type: DiscountType) => {
        return (
          // If the item is selected, we aren't disabling it
          !selectedItemCountMap[discountGuid] &&
          // If we have a rate discount, we disable everything else (we can only have one)
          (hasRateDiscount ||
            // Otherwise, we disable it if this is a rate and we already have a flat.
            (type === DiscountType.RATE && hasFlatDiscount))
        )
      },
      [hasFlatDiscount, hasRateDiscount, selectedItemCountMap],
    )

    const items = useMemo<ItemPickerItem[]>(
      () =>
        data?.pricebookDiscounts.map(discount => {
          return {
            id: discount.pricebookDiscountGuid,
            name: discount.name,
            value:
              discount.discountRate ??
              (discount.discountAmountUsd
                ? usdToUsCents(discount.discountAmountUsd)
                : 0),
            numberFormatter: getNumberFormatter(discount.type),
            disabled: discountIsDisabled(
              discount.pricebookDiscountGuid,
              discount.type,
            ),
          }
        }) ?? [],
      [data?.pricebookDiscounts, discountIsDisabled],
    )

    const onItemSelect = useCallback((id: Guid, count: number) => {
      setSelectedItemCountMap(map => ({ ...map, [id]: count }))
    }, [])

    const onSave = useCallback(() => {
      const items: DiscountPickerDiscount[] = []

      for (const discount of data?.pricebookDiscounts ?? []) {
        const count = selectedItemCountMap[discount.pricebookDiscountGuid]
        if (count) {
          const common = {
            name: discount.name,
            // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3188
            descriptionHtml: discount.description,
            discountGuid: nextGuid(),
          }

          items.push(
            discount.type === DiscountType.FLAT
              ? {
                  ...common,
                  type: DiscountType.FLAT,
                  discountAmountUsc: usdToUsCents(
                    discount.discountAmountUsd ?? 0,
                  ),
                }
              : {
                  ...common,
                  type: DiscountType.RATE,
                  discountRate: discount.discountRate ?? 0,
                },
          )
        }
      }

      for (const item of adHocItems) {
        const count = selectedItemCountMap[item.discountGuid]
        if (count) {
          items.push(item)
        }
      }

      onSubmit(items)
    }, [adHocItems, data?.pricebookDiscounts, onSubmit, selectedItemCountMap])

    const [createItemIsDirty, setCreateItemIsDirty] = useState(false)

    const renderCreateItem = useCallback(
      ({ onCancel, onSave }: ItemPickerRenderCreateItemProps) => (
        <RichDiscountForm
          title="Create discount"
          disableRate={hasFlatDiscount}
          setIsDirty={setCreateItemIsDirty}
          onCancel={onCancel}
          onBack={onCancel}
          onSave={discount => {
            const item = {
              ...discount,
              discountGuid: nextGuid(),
            }
            setAdHocItems(items => [...items, item])
            onSave(item.discountGuid, 1)
          }}
        />
      ),
      [hasFlatDiscount],
    )

    const adHocPickerItems = useMemo<ItemPickerItem[]>(
      () =>
        adHocItems.map(item => ({
          id: item.discountGuid,
          name: item.name,
          value: item.discountAmountUsc ?? item.discountRate ?? 0,
          numberFormatter: getNumberFormatter(item.type),
          disabled: discountIsDisabled(item.discountGuid, item.type),
        })),
      [adHocItems, discountIsDisabled],
    )

    const hasCartItems = useMemo(() => {
      for (const value of R.values(selectedItemCountMap)) {
        if (value) {
          return true
        }
      }
      return false
    }, [selectedItemCountMap])

    const [showConfirmCancel, setShowConfirmCancel] = useState(false)

    const ourOnCancel = useCallback(() => {
      if (hasCartItems || createItemIsDirty) {
        setShowConfirmCancel(true)
      } else {
        onCancel()
      }
    }, [createItemIsDirty, hasCartItems, onCancel])

    const resetShowConfirmCancel = useCallback(
      () => setShowConfirmCancel(false),
      [],
    )

    return (
      <OnsiteModal onClose={ourOnCancel} size="large">
        <ItemPicker
          appendAdHocItems
          canSelectMultiple
          hideMultipleQuantityPicker
          adHocEditButtonDisabled={hasRateDiscount}
          hideCart
          items={items}
          title="Add discount"
          subtitle="You can apply either multiple fixed amount discounts or one percentage-based discount, but not both types simultaneously."
          itemLabel="Discount"
          onCancel={ourOnCancel}
          onSave={onSave}
          selectedItemCountMap={selectedItemCountMap}
          onItemSelect={onItemSelect}
          renderCreateItem={
            canManageCustomDiscounts ? renderCreateItem : undefined
          }
          adHocItems={adHocPickerItems}
          isSubmitting={isSubmitting}
          isLoading={fetching}
        />
        {showConfirmCancel && (
          <CloseConfirmModal
            onCancel={resetShowConfirmCancel}
            onConfirm={onCancel}
          >
            {hasCartItems ? (
              <>
                You've selected discounts from the pricebook. If you exit, they
                won't be added.
                <br />
                <br />
                Are you sure you want to exit?
              </>
            ) : undefined}
          </CloseConfirmModal>
        )}
      </OnsiteModal>
    )
  },
)

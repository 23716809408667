import { Select } from 'antd'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useTwilioPhone } from './useTwilioPhone'

const AudioSettingsPanel = React.memo(() => {
  const { audioSettings, device } = useTwilioPhone()

  const speakerDevices = Array.from(
    device?.audio?.speakerDevices.get() ?? new Set<MediaDeviceInfo>(),
  )
  const [selectedDevices, setSelectedDevices] = useState<{
    inputDeviceId: string
    outputDeviceId: string
  }>({
    inputDeviceId: device?.audio?.inputDevice?.deviceId ?? 'default',
    outputDeviceId:
      speakerDevices.length > 0 ? speakerDevices[0].deviceId : 'default',
  })

  const handleInputChange = useCallback(
    (deviceId: string) => {
      const device =
        audioSettings.audioDevices.inputDevice.find(
          d => d.deviceId === deviceId,
        ) || null
      setSelectedDevices(prev => ({ ...prev, inputDeviceId: deviceId }))
      audioSettings.setInputDevice({ deviceId, label: device?.label || '' })
    },
    [audioSettings],
  )

  const handleSpeakerChange = useCallback(
    (deviceId: string) => {
      const device =
        audioSettings.audioDevices.outputDevice.find(
          d => d.deviceId === deviceId,
        ) || null
      setSelectedDevices(prev => ({ ...prev, outputDeviceId: deviceId }))
      audioSettings.setOutputDevice({ deviceId, label: device?.label || '' })
    },
    [audioSettings],
  )

  const inputOptions = useMemo(
    () =>
      audioSettings.audioDevices.inputDevice.map(device => ({
        value: device.deviceId,
        label: device.label,
      })),
    [audioSettings.audioDevices.inputDevice],
  )

  const outputOptions = useMemo(
    () =>
      audioSettings.audioDevices.outputDevice.map(device => ({
        value: device.deviceId,
        label: device.label,
      })),
    [audioSettings.audioDevices.outputDevice],
  )

  return (
    <div>
      <h2 className="mb-4 text-lg font-semibold">Audio Settings</h2>
      <div className="flex flex-col gap-y-4">
        <div>
          <label className="mb-2 block">Input Device</label>
          <Select
            className="z-[111113] w-full"
            value={selectedDevices.inputDeviceId || ''}
            onChange={handleInputChange}
            options={inputOptions}
            dropdownStyle={{ zIndex: 111112 }}
          />
        </div>
        <div>
          <label className="mb-2 block">Output Device</label>
          <Select
            className="w-full"
            value={selectedDevices.outputDeviceId || ''}
            onChange={handleSpeakerChange}
            options={outputOptions}
            dropdownStyle={{ zIndex: 111112 }}
          />
        </div>
      </div>
    </div>
  )
})

export default memo(AudioSettingsPanel)

import { gql } from '../generated'

export const GQL_READ_STALE_INVOICES_FOR_COMPANY = gql(`
  query ReadStaleInvoiceInfoForCompany($companyGuid: uuid!) {
    invoicesV2AccountingStale(where: {companyGuid: {_eq: $companyGuid}}) {
      invoiceGuid
      isStale
      syncedAt
      invoiceUpdatedAt
    }
  }
`)
